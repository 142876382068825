import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ucs-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {
  @Input() isEnabled;
  @Output() valueChanged = new EventEmitter();

  ngOnInit() {
    if (this.isEnabled === undefined) {
      this.isEnabled = true; // TODO what is the default? should i make the Input mandatory?
    }
  }

  /**
   * Used to toggle the enabled state
   * @param $event - DOM change event
   */
  onChange($event) {
    this.isEnabled = $event.target.checked;
    this.valueChanged.emit(this.isEnabled);
  }
}

