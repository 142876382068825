@if (label) {
  <div class="row mt-3 mb-2">
    <div class="col-12 text">
      <strong>{{label}}</strong>
      @if (popoverText) {
        <button
          type="button"
          class="button-borderless"
          [floatUi]="popoverText"
          [placement]="NgxFloatUiPlacements.TOP"
          [applyClass]="'location-popover-size'">
          <ucs-icon [shape]="'info'" [size]="16" [class]="'blue ms-1'"></ucs-icon>
        </button>
      }
    </div>
  </div>
}

<div [ngClass]="{
  'row':true,
  'mt-2':label === undefined,
  'mb-2':label === undefined}">
  <div class="col-12">
    <input [id]="id"
      [(ngModel)]="selectedValue"
      [typeahead]="aggregatedStates"
      [placeholder]="placeholder"
      typeaheadOptionField="option"
      (focus)="onFocus()"
      [typeaheadMinLength]=0
      [typeaheadOptionsLimit]="999"
      (typeaheadOnSelect)="onSelect($event)"
      (input)="onInput($event)"
      class="form-control text search-dropdown">
  </div>
</div>
