import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SalesPage} from '../../model/sales-page';
import * as SalesOfferActions from '../../store/sales-offer/sales-offer.actions';
import * as OfferActions from '../../store/offer/offer.actions';
import * as fromRoot from '../../store/app.reducers';
import {Store} from '@ngrx/store';
import {PageSettings} from '../../model/page-settings.model';
import {SalesPageSettings} from '../../model/sales-page-settings.model';
import {SystemSettingsService} from '../../service/system-settings.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ucs-sorting-selection',
  templateUrl: './sorting-selection.component.html',
  styleUrls: ['./sorting-selection.component.scss']
})
export class SortingSelectionComponent implements OnInit, OnDestroy {

  @Input() salesPage: SalesPage;
  // default sort order
  sortOption = 'EXPIRATION,ASC';
  possibleSort = [];
  defaultSortOptions = ['EXPIRATION,ASC', 'START,DESC',
    'PRICE,ASC', 'PRICE,DESC',
    'MILEAGE,ASC','MILEAGE,DESC',
    'POWER,ASC','POWER,DESC',
    'INITIAL_REGISTRATION,ASC', 'INITIAL_REGISTRATION,DESC'];

  finishedOffersSortOptions = [
    'FINISHED_OFFER,ASC',
    'FINISHED_OFFER,DESC'
  ];

  sortOptionsPerPage: {[key in SalesPage] : string[]} ={
    [SalesPage.SEARCH]: this.defaultSortOptions,
    [SalesPage.BOOKMARKS]: this.defaultSortOptions,
    [SalesPage.MY_AUCTIONS]: this.defaultSortOptions,
    [SalesPage.FINISHED_OFFERS]: this.finishedOffersSortOptions
  };


  pageSettings$ = this.store.select(fromRoot.getPageSettings);
  salesPageSettings$ = this.store.select(fromRoot.getSalesPageSettings);
  pageSettings: PageSettings | SalesPageSettings;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private store: Store<fromRoot.AppState>,
              private systemSettingsService: SystemSettingsService) {
  }
  ngOnInit(): void {
    this.possibleSort = this.sortOptionsPerPage[this.salesPage].map(value => value.split(',')[0]);

    this.subscribeToPageSettingsOfActiveSalesPage();
  }

  private updatePageSettings(pageSettings: PageSettings | SalesPageSettings): void {
    if(pageSettings) {
      this.pageSettings = {...pageSettings};
      if (!this.possibleSort.includes(this.pageSettings.sort)) {
        this.pageSettings.sort = this.sortOptionsPerPage[this.salesPage][0].split(',')[0];
        this.pageSettings.order = this.sortOptionsPerPage[this.salesPage][0].split(',')[1];
        this.dispatchUpdateToStore();
      }
      this.sortOption = this.pageSettings.sort + ',' + this.pageSettings.order;
    }
  }

  /**
   * selects sort from dropdown
   * @param {string} sortOption containing the sort option and sort order
   */
  selectSort(sortOption: string) {
    this.pageSettings.sort = sortOption.split(',')[0];
    this.pageSettings.order = sortOption.split(',')[1];
    this.pageSettings.page = 0;

    this.dispatchUpdateToStore();
  }

  dispatchUpdateToStore() {
    switch (this.salesPage) {
    case SalesPage.SEARCH:
      this.store.dispatch(new SalesOfferActions.UpdatePageSettings({...this.pageSettings} as SalesPageSettings));
      break;
    default:
      this.store.dispatch(new OfferActions.UpdatePageSettings({...this.pageSettings} as PageSettings));
    }
  }

  subscribeToPageSettingsOfActiveSalesPage() {
    switch (this.salesPage) {
    case SalesPage.SEARCH:
      this.salesPageSettings$.pipe(takeUntil(this.unsubscribe))
        .subscribe((salesPageSettings) => this.updatePageSettings(salesPageSettings));
      break;
    default:
      this.pageSettings$.pipe(takeUntil(this.unsubscribe))
        .subscribe((pageSettings) => this.updatePageSettings(pageSettings));
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
