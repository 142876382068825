<h1>{{ 'contact.contact' | translate }}</h1>

@if (userState.hasAnyRoleForPb && !userState.isClerkBmiPb) {
  @if (hasMoreThanOneChannel()) {
    <h3>{{ 'ucs.pb' | translate }}</h3>
  }
  <!--Manuals-->
  <h3>{{ 'contact.short-manual' | translate }}</h3>
  @if (userState.isBuyerPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
    <a
      href="../../../../../assets/manual/PB_UCS_Buyer_Manual_DE_2020_10.pdf"
    target="_blank">{{ 'contact.buyer-manual' | translate }}</a>
  }
  @if (userState.isBuyerPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
    <br>
    }
    @if (userState.isBuyerPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
      <a
        href="../../../../../assets/manual/PB_UCS_Buyer_Manual_EN_2020_10.pdf"
      target="_blank">{{ 'contact.buyer-manual' | translate }} ({{ 'terms.english-version' | translate }})</a>
    }
    @if (userState.isBuyerPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
      <br>
      }
      @if (userState.isCustodianPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
        <a
          href="../../../../../assets/manual/PB_UCS_Custodian_Manual_DE_2020_10.pdf"
        target="_blank">{{ 'contact.custodian-manual' | translate }}</a>
      }
      @if (userState.isCustodianPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
        <br>
        }
        @if (userState.isCustodianPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
          <a
            href="../../../../../assets/manual/PB_UCS_Custodian_Manual_EN_2020_10.pdf"
          target="_blank">{{ 'contact.custodian-manual' | translate }} ({{ 'terms.english-version' | translate }})</a>
        }
        @if (userState.isCustodianPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
          <br>
          }
          @if (userState.isSurveyorPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
            <div>
              <a href="../../../../../assets/manual/PB_UCS_Surveyor_Manual_DE_2020_10.pdf"
              target="_blank">{{ 'contact.surveyor-manual' | translate }}</a>
              <br>
                <a href="../../../../../assets/manual/PB_UCS_Surveyor_Manual_EN_2020_10.pdf"
                target="_blank">{{ 'contact.surveyor-manual' | translate }} ({{ 'terms.english-version' | translate }})</a>
                <br>
                  <a href="../../../../../assets/manual/SV_Profile_Exploitation_Vehicle_DE_2018.pdf"
                  target="_blank">{{ 'contact.sv-profile-exploitation-vehicle' | translate }}</a>
                  <br>
                    <a href="../../../../../assets/manual/SV_Anforderungsprofil_BMI.pdf"
                    target="_blank">{{ 'contact.bmi-manual' | translate }}</a>
                    <br>
                      <a href="../../../../../assets/manual/SV_Profile_Hull_Damages_DE_2015.pdf"
                      target="_blank">{{ 'contact.sv-profile-hull-damages' | translate }}</a>
                      <br>
                      </div>
                    }
                    @if (isAllowedToSeeAllManualsOrVideosFromPBHR() || ((userState.isBuyerPb || userState.isCustodianPb) && userState.userInfo.country === 'HR')) {
                      <div>
                        @if (userState.isBuyerPb || userState.isCustodianPb) {
                          <br>
                          }
                          @if (userState.isBuyerPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
                            <a
                              href="../../../../../assets/manual/PB_UCS_Buyer_Manual_HR_2022_09.pdf"
                            target="_blank">{{ 'contact.buyer-manual.hr' | translate }}</a>
                          }
                          @if (userState.isBuyerPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
                            <br>
                            }
                            @if (userState.isCustodianPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
                              <a
                                href="../../../../../assets/manual/PB_UCS_Custodian_Manual_HR_2022_09.pdf"
                              target="_blank">{{ 'contact.custodian-manual.hr' | translate }}</a>
                            }
                            @if (userState.isCustodianPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
                              <br>
                              }
                              @if (userState.isSurveyorPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
                                <a
                                  href="../../../../../assets/manual/PB_UCS_Surveyor_Manual_HR_2020_10.pdf"
                                target="_blank">{{ 'contact.surveyor-manual.hr' | translate }}</a>
                              }
                            </div>
                          }
                          <!--Videos-->
                          <h4>{{ 'contact.short-video' | translate }}</h4>
                          @if (userState.isBuyerPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
                            <a rel="noopener"
                              href="https://training.porschebank.at/verkauf/ucs"
                            target="_blank">{{ 'contact.buyer-video' | translate }}</a>
                          }
                          @if (userState.isBuyerPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
                            <br>
                            }
                            @if (userState.isCustodianPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
                              <a rel="noopener"
                                href="https://training.porschebank.at/verkauf/ucs"
                              target="_blank">{{ 'contact.custodian-video' | translate }}</a>
                            }
                            @if (userState.isCustodianPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
                              <br>
                              }
                              @if (userState.isSurveyorPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
                                <a rel="noopener"
                                  href="https://training.porschebank.at/verkauf/ucs"
                                target="_blank">{{ 'contact.surveyor-video' | translate }}</a>
                              }
                              @if (userState.isSurveyorPb || isAllowedToSeeAllManualsOrVideosFromPB()) {
                                <br>
                                }
                              }

                              <!--ALL UC and PIA manual-->
                              @if (userState.hasAnyRoleForAllUc) {
                                @if (hasMoreThanOneChannel()) {
                                  <h3>{{ 'ucs.all_uc' | translate }}</h3>
                                }
                                <h3>{{ 'contact.short-manual' | translate }}</h3>
                                <a href="../../../../../assets/manual/UCS_Manual_DE_v1.0.pdf"
                                target="_blank">{{ 'contact.general-short-manual' | translate }}</a>
                              }

                              @if (userState.hasAnyRoleForPia) {
                                @if (hasMoreThanOneChannel()) {
                                  <h3>{{ 'ucs.pia' | translate }}</h3>
                                }
                                <h3>{{ 'contact.short-manual' | translate }}</h3>
                                <a href="../../../../../assets/manual/PIA_UCS_Manual_Buyer_DE_2021_11.pdf"
                                target="_blank">{{ 'contact.general-short-manual' | translate }}</a>
                              }

                              <h3>{{ 'contact.general-questions' | translate }}</h3>

                              {{ 'contact.general-questions.text' | translate }}

                              @if (userState.hasAnyRoleForPia || userState.hasAnyRoleForAllUc) {
                                <h3>{{ 'contact.other-questions' | translate }}</h3>
                              }

                              @if (userState.hasAnyRoleForPia) {
                                <h4>{{ 'ucs.pia' | translate }}</h4>
                                Porsche Inter Auto GmbH & Co KG<br/>
                                Louise-Pi&euml;ch-Straße 2<br/>
                                5020 Salzburg<br/>
                                <a href="mailto:b2b@porsche.co.at">b2b&#64;porsche.co.at</a>
                              }

                              @if (userState.hasAnyRoleForPb) {
                                <h4>{{ 'ucs.pb' | translate }}</h4>
                                Porsche Bank Aktiengesellschaft<br/>
                                Vogelweiderstraße 75<br/>
                                5020 Salzburg<br/>
                                <a href="mailto:verwertung@porschebank.at">verwertung&#64;porschebank.at</a>
                              }

                              @if (userState.hasAnyRoleForAllUc) {
                                <h4>{{ 'ucs.all_uc' | translate }}</h4>
                                Porsche Austria GmbH & Co OG<br/>
                                Louise-Pi&euml;ch-Straße 2<br/>
                                5020 Salzburg<br/>
                                <a href="mailto:alluc@porsche.co.at">alluc&#64;porsche.co.at</a>
                              }

                              <h4>{{ 'contact.technical-questions' | translate }}</h4>

                              Porsche Informatik Service Desk<br/>
                              +43 662 4670 2222<br/>
                              <a href="https://support.porscheinformatik.com">https://support.porscheinformatik.com</a>
                              <div class="spacer"></div>
