import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {first} from 'rxjs/operators';
import {State} from '../../../store/user/user.reducers';
import {SystemSettingsService} from '../../../service/system-settings.service';

@Component({
  selector: 'ucs-net-price',
  templateUrl: './net-price.component.html'
})
export class NetPriceComponent implements OnInit {

  @Input() currentPrice: PriceDto;
  @Input() startPrice: PriceDto;
  @Input() vatType: VatType;
  @Input() novaPaid: boolean;
  @Input() offerType: OfferType;
  @Input() channel: DistributionChannel;

  userState: State;
  isNovaInfoGrossPriceEnabled: boolean;

  constructor(private store: Store<fromRoot.AppState>, private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit(): void {
    this.store
      .select(fromRoot.getUserState)
      .pipe(first())
      .subscribe(userState => {
        if (userState) { // wait for user state before we process because we need the country for price calculations
          this.userState = userState;
        }
      });

    this.systemSettingsService
      .isSystemFeatureActivatedForChannel('SHOW_NOVA_INFO_GROSS_PRICE_TO_FOREIGN_DEALERS', this.channel)
      .pipe(first())
      .subscribe(enabled => {
        this.isNovaInfoGrossPriceEnabled = enabled;
      });
  }
}
