import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {OfferService} from '../../service/offer.service';
import {DealerService} from '../../service/dealer.service';
import {TranslateService} from '@ngx-translate/core';
import {TimerService} from '../../service/timer.service';
import {isAuctionOfferDto} from '../../misc/typeguard';
import {first, takeUntil} from 'rxjs/operators';
import {SystemSettingsService} from '../../service/system-settings.service';
import {ToastAlertService} from '../../service/toast-alert.service';

@Component({
  selector: 'ucs-cascade-dealer-access-groups',
  templateUrl: './cascade-dealer-access-groups.component.html',
  styleUrls: ['./cascade-dealer-access-groups.component.scss']
})

/**
 * The component to choose the recipients of this offer
 * Without cascade (STANDARD), with cascade for domestic (DOMESTIC)
 * or selection of single dealers.
 */
export class CascadeDealerAccessGroupsComponent implements OnChanges, OnInit, OnDestroy {

  @Input() offerUpdateDto: OfferUpdateDto;
  @Input() offerDto: OfferDto;
  @Input() expandingOffer: boolean;
  @Input() resetCascadeEvent: Observable<void>;
  @Output() updateAccessGroup = new EventEmitter<void>();
  @Output() cascadeChange = new EventEmitter<boolean>();

  readonly STANDARD_SELECTION = 'STANDARD';
  readonly DOMESTIC_SELECTION = 'DOMESTIC';
  readonly INDIVIDUAL_SELECTION = 'INDIVIDUAL';
  readonly SCRAP_DISPOSAL = 'SCRAP_DISPOSAL';

  accessGroupSettings: { [key: string]: boolean } = {};
  standardAccessGroupSettings: { [key: string]: boolean } = {};
  domesticAccessGroupSettings: { [key: string]: boolean } = {};
  individualAccessGroupSettings: { [key: string]: boolean } = {};
  showNotifyDeliveryDealer = false;
  selectedCascade = this.STANDARD_SELECTION;

  addedDealers: DealerBaseDto[];
  isCascadeSelectionEnabled = true;
  isStatusPreparation = false;
  isStatusCancelledOrExpired = false;


  hasNonExcludedOffers: boolean;
  dealerAutoCompleteSourceFunction: (filter: string) => Observable<DealerDetailDto[]>;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private offerService: OfferService,
              private alertService: ToastAlertService,
              protected dealerService: DealerService,
              private translate: TranslateService,
              private timerService: TimerService,
              private systemSettings: SystemSettingsService) {
  }

  /**
   * Check if the offer is with cascade initially or not.
   */
  ngOnInit(): void {
    //FIXME UCSDEV-10551 this settings should not be user related but offer related.
    //Thus the settings should be checked directly against the backend
    this.systemSettings
      .getSystemFeatureSettingForChannel('CASCADE_DEALER_ACCESS_GROUPS.cascade_dealer_access_groups',
        this.offerDto.channel.data)
      .pipe(first())
      .subscribe(value => {
        const selectionSettings = JSON.parse(value);
        const standardSettings = selectionSettings.dealerAccessGroupSelection['STANDARD'];
        const domesticSettings = selectionSettings.dealerAccessGroupSelection['DOMESTIC'];
        const individualSettings = selectionSettings.dealerAccessGroupSelection['INDIVIDUAL'];
        standardSettings.forEach((entry: any) => {
          this.standardAccessGroupSettings[entry.dealerAccessGroup] = entry['active'];
        });
        domesticSettings.forEach((entry: any) => {
          this.domesticAccessGroupSettings[entry.dealerAccessGroup] = entry['active'];
        });
        if (individualSettings) {
          individualSettings.forEach((entry: any) => {
            this.individualAccessGroupSettings[entry.dealerAccessGroup] = entry['active'];
          });
        }

        this.isStatusPreparation = ['PREPARATION'].includes(this.offerDto.status.data);
        this.isStatusCancelledOrExpired = ['CANCELLED', 'EXPIRED'].includes(this.offerDto.status.data);
        this.showNotifyDeliveryDealer = selectionSettings['showNotifyDeliveryDealer'] === 'true' && this.isStatusPreparation;
        if (this.offerDto.dealerAccessGroups.length > 0) {
          this.setDealerAccessGroupSettings();
        } else {
          if (this.offerDto.channel.data === 'VGRX' && individualSettings) {
            this.selectIndividualDefaultCascade();
          } else {
            this.selectStandardCascade();
          }
        }
      });

    // check if there is a direct sale set
    this.addedDealers = this.offerDto.directSaleDealers || [];
    this.isCascadeSelectionEnabled = this.addedDealers.length <= 0;

    this.cascadeChange.emit(true);
    this.resetCascadeEvent.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this.offerDto.dealerAccessGroups.length > 0) {
        this.setDealerAccessGroupSettings();
      } else {
        this.selectStandardCascade();
      }
      this.isCascadeSelectionEnabled = true;
    });
    this.selectedCascade = this.getSelectedCascadeType();
  }

  private setDealerAccessGroupSettings(): void {
    Object.keys(this.standardAccessGroupSettings).forEach(group => {
      this.accessGroupSettings[group] = (this.offerDto.dealerAccessGroups.find((e) => e === group) !== undefined);
    });
    this.offerUpdateDto.dealerAccessGroups = <DealerAccessGroup[]>Object.keys(this.accessGroupSettings)
      .filter(group => this.accessGroupSettings[group] === true);
  }

  /**
   * Re-initialise access group settings on input value changes
   */
  ngOnChanges() {
    if (this.isStatusPreparation) {
      switch (this.selectedCascade) {
      case this.STANDARD_SELECTION:
        this.selectStandardCascade();
        break;
      case this.DOMESTIC_SELECTION:
        this.selectDomesticCascade();
        break;
      }
    }
    this.cascadeChange.emit(this.isCascadeSelectionEnabled);
    if (isAuctionOfferDto(this.offerDto)) {
      this.hasNonExcludedOffers = this.offerDto.bids.filter(bid => !bid.excluded && bid.type !== 'INITIAL').length > 0;
    } else {
      this.hasNonExcludedOffers = false;
    }

    this.addedDealers = this.offerDto.directSaleDealers || [];

    this.isStatusPreparation = ['PREPARATION'].includes(this.offerDto.status.data);
    this.isStatusCancelledOrExpired = ['CANCELLED', 'EXPIRED'].includes(this.offerDto.status.data);

    let roles: DealerRole[];
    switch (this.offerDto.channel.data) {
    case 'PB':
      roles = ['PBV_BUYER'];
      break;
    case 'PIA':
      roles = ['PIA_BUYER'];
      break;
    case 'ALL_UC':
      roles = ['ALL_UC_BUYER'];
      break;
    case 'DIN_BIL':
      roles = ['DIN_BIL_BUYER'];
      break;
    case 'VGRX':
      roles = ['VGRX_BUYER'];
      break;
    default:
      roles = [];
    }
    this.dealerAutoCompleteSourceFunction = (filter: string) =>
      this.dealerService.getDealers(filter, roles, this.offerDto.channel.data);
  }

  /**
   * Select standard cascade settings (originally "without cascade")
   */
  selectStandardCascade() {
    this.selectedCascade = this.STANDARD_SELECTION;
    this.addedDealers = this.offerDto.directSaleDealers || [];
    this.accessGroupSettings = {...this.standardAccessGroupSettings};
    this.offerUpdateDto.dealerAccessGroups = <DealerAccessGroup[]>Object.keys(this.accessGroupSettings)
      .filter(group => this.accessGroupSettings[group] === true);
  }

  /**
   * Select domestic cascade settings
   */
  selectDomesticCascade() {
    this.selectedCascade = this.DOMESTIC_SELECTION;
    this.accessGroupSettings = {...this.domesticAccessGroupSettings};
    this.offerUpdateDto.dealerAccessGroups = <DealerAccessGroup[]>Object.keys(this.accessGroupSettings)
      .filter(group => this.accessGroupSettings[group] === true);
  }

  /**
   * Set type to individual cascade
   */
  selectIndividualCascade() {
    this.selectedCascade = this.INDIVIDUAL_SELECTION;
  }

  /**
   * Select Individual Cascade as default cascade
   */
  selectIndividualDefaultCascade() {
    this.selectIndividualCascade();
    this.accessGroupSettings = {...this.individualAccessGroupSettings};
    this.offerUpdateDto.dealerAccessGroups = <DealerAccessGroup[]>Object.keys(this.accessGroupSettings)
      .filter(group => this.accessGroupSettings[group] === true);
  }

  /**
   * Compare the selected cascade with predefined cascade types
   */
  getSelectedCascadeType(): any {
    let standardCascade = <DealerAccessGroup[]>Object.keys(this.standardAccessGroupSettings)
      .filter(group => this.standardAccessGroupSettings[group] === true);


    let missing = standardCascade.filter(item => this.offerUpdateDto.dealerAccessGroups.indexOf(item) < 0);
    if (missing.length === 0 && standardCascade.length === this.offerUpdateDto.dealerAccessGroups.length) {
      return 'STANDARD';
    }

    let domesticCascade = <DealerAccessGroup[]>Object.keys(this.domesticAccessGroupSettings)
      .filter(group => this.domesticAccessGroupSettings[group] === true);

    missing = domesticCascade.filter(item => this.offerUpdateDto.dealerAccessGroups.indexOf(item) < 0);
    if (missing.length === 0 && domesticCascade.length === this.offerUpdateDto.dealerAccessGroups.length) {
      return 'DOMESTIC';
    }

    return 'INDIVIDUAL';
  }


  /**
   * Resets the dealer access group selection
   */
  resetAccessGroupSelection() {
    Object.keys(this.accessGroupSettings).forEach(key => this.accessGroupSettings[key] = false);
    this.offerUpdateDto.dealerAccessGroups = [];
    this.selectedCascade = this.INDIVIDUAL_SELECTION;
  }


  /**
   * Toggle a dealer access group
   * @param {DealerAccessGroup} group The dealer access group to toggle
   */
  toggleAccessGroup(group: string) {
    if (!this.isStatusCancelledOrExpired) {
      this.selectedCascade = this.INDIVIDUAL_SELECTION;
      if (this.isStatusPreparation && group === this.SCRAP_DISPOSAL && !this.accessGroupSettings[group]) {
        this.resetAccessGroupSelection();
      }
      this.accessGroupSettings[group] = !this.accessGroupSettings[group];
      if (this.accessGroupSettings[group]) {
        this.offerUpdateDto.dealerAccessGroups.push(<DealerAccessGroup>group);
        if (!this.isStatusPreparation) {
          this.updateOfferAccessGroups();
        }
      } else {
        this.offerUpdateDto.dealerAccessGroups = this.offerUpdateDto.dealerAccessGroups.filter(g => ('' + g) !== ('' + group));
      }
    }
  }


  updateOfferAccessGroups() {
    const accessGroups = Object.keys(this.accessGroupSettings).filter( key => this.accessGroupSettings[key]);
    const accessGroupsUpdate = {dealerAccessGroups: accessGroups} as OfferAccessGroupsUpdateDto;

    this.offerService.updateOfferAccessGroups(this.offerDto.id, accessGroupsUpdate).pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.alertService.success(this.translate.instant('offer-maintenance.detail.update-dealeraccessgroups.success'));
        this.updateAccessGroup.emit();
      },
      () => this.alertService.danger(this.translate.instant('offer-maintenance.detail.update-dealeraccessgroups.error')));
  }

  /**
   * Returns if the user is allowed to toggle the given access group.
   * @param group The access group to toggle.
   */
  canToggleAccessGroup(group: string): boolean {
    return !(!this.isStatusPreparation && this.accessGroupSettings[group])
      && !(this.expandingOffer && this.accessGroupSettings[group] && this.hasNonExcludedOffers)
      && !(this.accessGroupSettings[this.SCRAP_DISPOSAL] && group !== this.SCRAP_DISPOSAL);
  }

  evaluateCheckboxShape(group: string, active: boolean): string {
    if (this.accessGroupSettings[this.SCRAP_DISPOSAL] && group !== this.SCRAP_DISPOSAL) {
      return 'square-fill';
    } else if (active) {
      return 'square_checked';
    } else {
      return 'square_outline';
    }
  }

  evaluateScrapDisposalCheckboxShape(): string {
    if (!this.isStatusPreparation && Object.keys(this.accessGroupSettings).some(
      (key) => this.accessGroupSettings[key] === true && key !== this.SCRAP_DISPOSAL)
    ) {
      return 'square-fill';
    } else if (this.accessGroupSettings[this.SCRAP_DISPOSAL]) {
      return 'square_checked';
    } else {
      return 'square_outline';
    }
  }

  canToggleScrapDisposal(): boolean {
    if (!this.isStatusPreparation && Object.keys(this.accessGroupSettings).some(
      (key) => this.accessGroupSettings[key] === true && key !== this.SCRAP_DISPOSAL)
      || !this.isStatusPreparation && this.accessGroupSettings[this.SCRAP_DISPOSAL]
    ) {
      return false;
    }
    return !(this.expandingOffer && this.accessGroupSettings[this.SCRAP_DISPOSAL] && this.hasNonExcludedOffers);
  }

  /**
   * Adds a dealer to the list of dealers to receive the offer
   * @param {DealerBaseDto} $event The dealer to add
   */
  addDealer($event: DealerBaseDto) {
    if (!$event) {
      return;
    }

    this.isCascadeSelectionEnabled = false;
    this.resetAccessGroupSelection();

    if ($event && !this.addedDealers.includes($event)) {
      this.offerUpdateDto.directSaleDealerIds.push($event.id);
      this.addedDealers.push($event);
      this.cascadeChange.emit(false);
    }

  }

  /**
   * Remove a dealer from the list of dealers to receive the offer
   * @param {DealerBaseDto} $event The dealer to remove
   */
  removeDealer($event: DealerBaseDto) {
    this.offerUpdateDto.directSaleDealerIds = this.offerUpdateDto.directSaleDealerIds.filter(id => id !== $event.id);
    this.addedDealers = this.addedDealers.filter(dealer => dealer.id !== $event.id);
    if (this.addedDealers.length === 0) {
      this.isCascadeSelectionEnabled = true;
    }
    this.cascadeChange.emit(this.isCascadeSelectionEnabled);
  }

  /**
   * On destruction, all subscriptions are shut down
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
