import {Component, Input} from '@angular/core';

@Component({
  selector: 'ucs-buy-now-purchase-status',
  templateUrl: './buy-now-purchase-status.component.html',
  styleUrls: ['./buy-now-purchase-status.component.scss']
})
export class BuyNowPurchaseStatusComponent {
  @Input() offerStatus: OfferStatus;

}
