import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import * as fromRoot from '../../../store/app.reducers';
import {State} from '../../../store/user/user.reducers';
import {Store} from '@ngrx/store';
import {first, takeUntil} from 'rxjs/operators';
import {SystemSettingsService} from '../../../service/system-settings.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'ucs-tax-nova-gross-profit',
  templateUrl: './tax-nova-gross-profit.component.html',
  styleUrls: ['./tax-nova-gross-profit.component.scss']
})
export class TaxNovaGrossProfitComponent implements OnInit, OnChanges, OnDestroy {

  @Input() vatType: VatType;
  @Input() extraTax: ExtraTaxDto;
  @Input() nationalSale = false;
  @Input() grossProfitData: GrossProfitDto;
  @Input() channel: DistributionChannel;
  @Input() country: Country;
  @Input() currency: Currency;
  @Input() vehicleOfferItem: VehicleItemBaseDto;
  @Input() scopeSection: AppScope;
  @Input() isEnforceAuctionOffer = false;

  userState: State;
  isNovaInfoGrossPriceEnabled: boolean;
  showVatValue: boolean;
  vatValue: number;
  isThermalWindowAffectedEnabled: boolean;
  showThermalWindowAffected: boolean;
  showGrossProfit: boolean;

  private unsubscribe: Subject<void> = new Subject<void>();
  constructor(private store: Store<fromRoot.AppState>, private systemSettingsService: SystemSettingsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.country !== undefined) {
      this.initialize();
    }
  }

  ngOnInit(): void {
    this.initialize();

    this.systemSettingsService
      .isSystemFeatureActivatedForChannel('SHOW_NOVA_INFO_GROSS_PRICE_TO_FOREIGN_DEALERS', this.channel)
      .pipe(first())
      .subscribe(enabled => {
        this.isNovaInfoGrossPriceEnabled = enabled;
      });


    this.systemSettingsService
      .isSystemFeatureActivatedForChannel('THERMAL_WINDOW_AFFECTED', this.channel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        this.isThermalWindowAffectedEnabled = value;
        if (this.isThermalWindowAffectedEnabled) {
          this.systemSettingsService.getSystemFeatureSettingForChannel('THERMAL_WINDOW_AFFECTED.thermal_window_affected_step_one', this.channel)
            .pipe(first())
            .subscribe(stringValue => {
              this.showThermalWindowAffected = JSON.parse(stringValue);
            });
        }
      });

    this.systemSettingsService.isSystemFeatureActivatedForChannel('GROSS_PROFIT_DISPLAY', this.channel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(showGrossProfit => {
        this.showGrossProfit = showGrossProfit;
      });
  }

  private initialize() {
    this.store
      .select(fromRoot.getUserState)
      .pipe(first())
      .subscribe(userState => {
        if (userState) { // wait for user state before we process because we need the country for price calculations
          this.userState = userState;
        }
      });

    this.systemSettingsService
      .isSystemFeatureActivatedForChannel('SHOW_VAT_VALUE', this.channel)
      .subscribe(value => {
        this.showVatValue = value;
        if (this.showVatValue) {
          this.systemSettingsService.getSystemFeatureSettingForChannel('TAX_CALCULATION.allVat', 'ANY')
            .pipe(first())
            .subscribe(allVat => {
              let allVatJson = JSON.parse(allVat);

              // DE special case: show always there own tax value
              if (this.userState.isGerman && this.channel === 'VGRX') {
                this.vatValue = Number(allVatJson.countryVat.DE) * 100;
              } else {
                this.vatValue = Number(allVatJson.countryVat[this.country]) * 100;
              }
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
