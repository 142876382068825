import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {first} from 'rxjs/operators';
import {State} from '../../../store/user/user.reducers';

@Component({
  selector: 'ucs-market-value',
  templateUrl: './market-value.component.html',
  styleUrls: ['./market-value.component.scss']
})
export class MarketValueComponent implements OnInit {
  @Input() marketValue: PriceDto;
  @Input() vatType: VatType;
  userState: State;

  constructor(private store: Store<fromRoot.AppState>) {
  }

  ngOnInit(): void {
    this.store
      .select(fromRoot.getUserState)
      .pipe(first())
      .subscribe(userState => {
        if (userState) {
          this.userState = userState;
        }
      });
  }
}
