@if (offerUpdateDto && offerDto) {
  <div class="d-flex">
    <div class="col-auto col-lg">
      <strong class="text text-bright me-1">{{ 'offer-maintenance.detail.receiver' | translate }}</strong>
      @if (isCascadeSelectionEnabled) {
        <div>
          <div class="row mt-3 mb-2">
            @if (isStatusPreparation) {
              <div class="btn-group btn-group-toggle col-12">
                <label class="btn btn-sm button-toggle-options" data-cy="offer-maintenance.without-cascade" [ngClass]="{'active': selectedCascade === 'STANDARD'}">
                  <input type="radio" [value]="'STANDARD'" [(ngModel)]="selectedCascade"
                    (click)="selectStandardCascade()"> {{'offer-maintenance.detail.withoutcascade' | translate}}
                  </label>
                  <label class="btn btn-sm button-toggle-options" data-cy="offer-maintenance.domestic" [ngClass]="{'active': selectedCascade === 'DOMESTIC'}">
                    <input type="radio" [value]="'DOMESTIC'" [(ngModel)]="selectedCascade"
                      (click)="selectDomesticCascade()"> {{'offer-maintenance.detail.domestic' | translate}}
                    </label>
                    <label class="btn btn-sm button-toggle-options" data-cy="offer-maintenance.individual" [ngClass]="{'active': selectedCascade === 'INDIVIDUAL'}">
                      <input type="radio" [value]="'INDIVIDUAL'" [(ngModel)]="selectedCascade"
                        (click)="selectIndividualCascade()"> {{'offer-maintenance.detail.individual' | translate}}
                      </label>
                    </div>
                  }
                </div>
                @for (entry of accessGroupSettings | keyvalue; track entry) {
                  <div class="mt-3">
                    @if (entry.key !== SCRAP_DISPOSAL) {
                      <div class="col-12 mt-2 ps-0 access-group" data-cy="offer-maintenance.cascade">
                        <ucs-icon (click)="canToggleAccessGroup(entry.key) ? toggleAccessGroup(entry.key) : '';"
                          [shape]="evaluateCheckboxShape(entry.key, entry.value)"
                        [class]="canToggleAccessGroup(entry.key) ? (isStatusCancelledOrExpired ? 'disabled-grey' : 'blue') : 'disabled-blue'"></ucs-icon>
                        <span class="ms-2 ucs-input-label" [ngClass]="{'disabled-text':isStatusCancelledOrExpired && entry.value === false}"
                          (click)="canToggleAccessGroup(entry.key) ? toggleAccessGroup(entry.key) : '';">
                      {{'offer-maintenance.detail.access-group.' + entry.key | translate}}</span>
                    </div>
                  }
                </div>
              }
              <div class="mt-4">
                <ucs-icon (click)="canToggleScrapDisposal() ? toggleAccessGroup(SCRAP_DISPOSAL) : '';"
                  [shape]="evaluateScrapDisposalCheckboxShape()"
                [class]="canToggleScrapDisposal() ? (isStatusCancelledOrExpired ? 'disabled-grey' : 'blue') : 'disabled-blue'"></ucs-icon>
                <span class="ms-2 ucs-input-label" [ngClass]="{'disabled-text':isStatusCancelledOrExpired}"
                  (click)="canToggleScrapDisposal() ? toggleAccessGroup(SCRAP_DISPOSAL) : '';">
                {{'offer-maintenance.detail.access-group.' + SCRAP_DISPOSAL | translate}}</span>
              </div>
              @if (isStatusPreparation) {
                <button type="button" class="col-12 btn btn-secondary mt-3" (click)="resetAccessGroupSelection()">
                  {{'offer-maintenance.detail.button.deselect' | translate}}
                </button>
              }
            </div>
          }
          @if (offerDto.offerType.data === 'BUYNOW' && offerDto.status.data === 'PREPARATION') {
            <ucs-dealer-autocomplete
              class="d-inline-block custom-autocomplete-layout pt-2"
              [sourceFunction]="dealerAutoCompleteSourceFunction"
              [channel]="offerDto.channel.data"
              [sidebar]=true
              [label]="'initial-proposal.dialog.dealer' | translate"
              [placeholder]="''"
              (onSelected)="addDealer($event)">
            </ucs-dealer-autocomplete>
          }
          @if (offerDto.offerType.data === 'BUYNOW') {
            <div class="d-flex">
              <strong class="mt-3 col col-4">{{ 'offer-maintenance.detail.selected-dealer' | translate }}</strong>
              @if (addedDealers?.length < 1) {
                <div class="mt-3 col col-6">{{ 'offer-maintenance.detail.no-dealer-selection' |
                  translate }}
                </div>
              }
              @if (addedDealers?.length > 0) {
                <ul class="mt-3 col col-6">
                  @for (dealer of addedDealers; track dealer.id) {
                    <li><span
                    class="dealer-name">{{ dealerService.getCompanyNumberOrPbvCustomerNumber(dealer, offerDto?.channel?.data) + ' ' + dealer.name }}</span>
                    @if (offerDto.status.data === 'PREPARATION') {
                      <ucs-icon class="delete ps-1" [shape]="'x'" [size]="24" [class]="'black'"
                      (click)="removeDealer(dealer)"></ucs-icon>
                    }
                  </li>
                }
              </ul>
            }
          </div>
        }
        @if (offerDto.offerType.data === 'BUYNOW'  && offerDto.status.data === 'PREPARATION' && offerUpdateDto.directSaleDealerIds?.length > 0) {
          <div
            class="mt-3">
            <div class="col-12 mt-2 ps-0">
              <ucs-icon (click)="offerUpdateDto.notifyDirectSaleDealers = !offerUpdateDto.notifyDirectSaleDealers"
                [shape]="offerUpdateDto.notifyDirectSaleDealers ? 'square_checked' : 'square_outline'"
              [class]="'blue'"></ucs-icon>
              <span class="ms-2">{{'offer-maintenance.detail.notify-direct-sale-dealers' | translate}}</span>
            </div>
          </div>
        }
        @if (showNotifyDeliveryDealer) {
          <div class="col-12 mt-3 ps-0">
            <ucs-icon (click)="offerUpdateDto.notifyDeliveryDealer = !offerUpdateDto.notifyDeliveryDealer"
              [shape]="offerUpdateDto.notifyDeliveryDealer ? 'square_checked' : 'square_outline'"
            [class]="'blue'"></ucs-icon>
            <span class="ms-2">{{'offer-maintenance.detail.notify-delivery-dealer' | translate}}</span>
          </div>
        }
      </div>
    </div>
  }
