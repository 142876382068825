<div class="alert alert-info col col-12">
  <p><strong>{{ 'missing-document-message.title' | translate }}</strong></p>
  <p>{{ 'missing-document-message.text1' | translate }}</p>
  <span> {{ 'missing-document-message.under' | translate }}
    <a routerLink="/administration/settings" title="Administration"> {{ 'missing.document-message.administration' | translate }} </a>
    {{ 'missing-document-message.text2' | translate }}
    <a routerLink="/contact" title="Help & Contact"> {{ 'missing-document-message.help-and-contact' | translate }} </a>
  </span>
  <p class="mt-1">{{ 'missing-document-message.subtitle' | translate }}</p>
  <ul style="list-style-type: none;">
    <li>{{ 'missing-document-message.bulletPoint1' | translate }}</li>
    <li>{{ 'missing-document-message.bulletPoint2.part1' | translate }} <u>{{ 'missing-document-message.bulletPoint2.part2' | translate }}</u> {{ 'missing-document-message.bulletPoint2.part3' | translate }}</li>
  </ul>
  <p>{{ 'missing-document-message.text3' | translate }}</p>
</div>
