import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {OfferService} from '../../service/offer.service';
import {first, takeUntil} from 'rxjs/operators';
import {SystemSettingsService} from '../../service/system-settings.service';
import * as fromRoot from '../../store/app.reducers';
import {Store} from '@ngrx/store';
import {ucsIsNil} from '../../misc/utils';

/**
 * The footer of a tile. This is only displayed at some positions in some offer states.
 */
@Component({
  selector: 'ucs-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.scss']
})
export class CardFooterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() offerData: AuctionOfferDto & BuyNowOfferDto & EnforcedAuctionOfferDto & LatestOfferDataDto;
  @Input() channel: DistributionChannel;
  /**
   *
   */
  @Input() startPrice: PriceDto; // The start price of an auction offer
  /**
   * The current price of an auction or a buynow offer
   */
  @Input() currentPrice: PriceDto;
  /**
   * The minimum amount one has to bid on this auction offer
   */
  @Input() minimumBidPrice: PriceDto;
  @Input() vatType: VatType;
  @Input() grossProfitData: GrossProfitDto;
  @Input() showOfferLink: boolean;
  @Input() footerStatus: VehicleFooterStatus;
  @Input() hideFooterStatus = false;
  @Input() vehicle: VehicleItemBaseDto | VehicleBaseDto | VehicleDetailDto;
  @Input() country: Country;
  @Input() extraTax: ExtraTaxDto;
  @Input() scopeSection: AppScope;
  @Input() disableBidding: boolean;
  @Input() activeCategory: string;
  @Input() displayLogo = false;
  @Input() enforcedAuctionData: EnforcedAuctionVehicleDataDto;
  @Output() linkToOfferClicked = new EventEmitter<void>();

  inputPrice: number;
  userInfo: UserInfoDto;
  isThermalWindowToggleActive = false;
  approvalFromUser = false;
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private store: Store<fromRoot.AppState>,
              private offerService: OfferService,
              private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit() {

    this.store.select(fromRoot.getUserInfo)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userInfo => {
        if (userInfo) {
          this.userInfo = userInfo;
        }
      });

    if(this.scopeSection === 'SALES_STORE') {
      this.store.select(fromRoot.getOffers)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(offers => {
          if (offers) {
            const offer = offers['content'].find(offer => offer.id === (<OfferDto>this.offerData).id);

            if(offer && !ucsIsNil(offer.approvals) && offer.approvals.length > 0) {
              this.approvalFromUser =
                  offer.approvals.find(approval => 'OFFER_THERMAL_WINDOW'.includes(approval.type)).approvedByUser;
            }
          }
        });
    }

    this.systemSettingsService
      .isSystemFeatureActivatedForAnyChannel('THERMAL_WINDOW_AFFECTED')
      .subscribe(isToggleActive => {
        if(isToggleActive) {
          this.systemSettingsService.getSystemFeatureSettingForChannel('THERMAL_WINDOW_AFFECTED.thermal_window_affected_step_two', this.channel)
            .pipe(first())
            .subscribe(stringValue => {
              this.isThermalWindowToggleActive = JSON.parse(stringValue);
            });
        }
      });

    this.inputPrice = this.footerStatus.startsWith('AUCTION') ? this.minimumBidPrice?.net : this.currentPrice?.net;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'offerData') {
        if (this.isThermalWindowToggleActive && this.vehicle?.thermalWindowAffected) {
          this.approvalFromUser =
            (<OfferDto>this.offerData).approvals?.find(approval => 'OFFER_THERMAL_WINDOW'.includes(approval.type))?.approvedByUser;
        }
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
