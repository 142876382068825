import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {PageSettings} from '../model/page-settings.model';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/app.reducers';
import {takeUntil} from 'rxjs/operators';

/**
 * A service which can be used for all archive-related search requests in offer or vehicle scope
 */
@Injectable()
export class ArchiveSearchService implements OnDestroy {

  private activeCategory: Section;
  private unsubscribe: Subject<void> = new Subject<void>();
  private noCacheHeaders = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
  });

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig, private store: Store<fromRoot.AppState>) {
    this.store.select(fromRoot.getVehicleMaintenanceActiveCategory)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(activeCategory => {
        this.activeCategory = activeCategory;
      });
  }

  /**
   * Pageable vehicle|offer search by search string and limited to given direct search field.
   *
   * Note we don't pass sorting, e.g. VEHICLE|OFFER_EXPIRATION, and order, e.g. DESC, here by intent, because the database
   * cannot cope with sorting by "VEHICLE|OFFER_EXPIRATION" which is only available in our vehicle index but not in jpa vehicle.
   *
   * We thus simply always sort by MODIFIED DESC for our direct search use case.
   *
   * @returns {Observable<any>}: vehicles or offers for channel matching the search string in direct search field
   */
  getByDirectSearchField(searchString: string, directSearchField: DirectSearchField, scope: string, pageSettings: PageSettings):
    Observable<any> {
    let params = new HttpParams();
    params = params.set('channel', pageSettings.channel);
    params = params.set('size', pageSettings.size.toString());
    params = params.set('page', pageSettings.page.toString());
    params = params.set('sort', 'modified' + ',' + 'desc');
    params = params.set('text', searchString);
    params = params.set('searchField', directSearchField);

    if (scope === 'VEHICLE') {
      if (this.activeCategory === 'LONGTIME_PENDING'){
        return this.http.get<VehicleBaseDto[]>(this.config.salesApiUrl + '/archive/vehicles/enforced-auction', {
          params: params,
          headers: this.noCacheHeaders
        });
      }
      return this.http.get<VehicleBaseDto[]>(this.config.salesApiUrl + '/archive/vehicles', {
        params: params,
        headers: this.noCacheHeaders
      });
    } else if (scope === 'OFFER') {
      return this.http.get<OfferDto[]>(this.config.salesApiUrl + '/archive/offers', {
        params: params,
        headers: this.noCacheHeaders
      });
    }
  }

  /**
   * Unsubscribe from Observables when component is destroyed to prevent memory leaks
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
