<div class=" d-flex justify-content-between ms-auto shift-up ">
  <div class="d-flex">
    @for (equipment of equipmentList; track equipment) {
      <div >
        @if (hasHighlightEquipment(equipment.equipment)) {
          <ucs-icon
            [shape]="equipment.shape" [class]="'grey opacity-05 me-2'"
            [floatUi]="equipment.key | translate"
          [showTrigger]="NgxFloatUiTriggers.hover"></ucs-icon>
        }
      </div>
    }
  </div>
  <div>
    @if (showEtbLongTimePending) {
      <ucs-icon
        [shape]="'etb_logo'" [class]="'grey'"
        [floatUi]="'vehicle.etb-present' | translate"
      [showTrigger]="NgxFloatUiTriggers.hover"></ucs-icon>
    }
  </div>
</div>
