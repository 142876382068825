<div class="modal" id="calendarModal" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ 'ucs.calendar' | translate }}</h5>
        <button aria-label="Close" class="btn-close btn-close-white" data-bs-dismiss="modal" type="button">
        </button>
      </div>
      <div class="modal-body text-center">
        <ngb-datepicker [dayTemplate]="dayTemplate" class="calendar-view"></ngb-datepicker>
        <ng-template #dayTemplate let-currentMonth="currentMonth" let-date>
          <div [class.out-of-month]="date.month !== currentMonth" class="custom-day">
            <div>{{date.day}}</div>
            <div [class.hidden]="!getNumOfExpiringOffers(date)"
            class="ms-4 circle-background">{{getNumOfExpiringOffers(date)}}</div>
          </div>
        </ng-template>
        @if (currentChannel === 'PB') {
          <div class="text-start ms-3 mt-2">
            <ucs-icon [class]="'blue'" [shape]="'info'" [size]="20"></ucs-icon>
            <span class="mt-1 footer-text"> {{ 'expiring-offer-calendar.info-pb' | translate}}</span>
          </div>
        }
      </div>
      <div class="modal-footer">
        <div class="col"><span class="rectangle-footer">{{ activeAuctionOffers ? activeAuctionOffers : 0 }}</span><span class="footer-text"><strong> {{ 'expiring-offer-calendar.active-acutions' | translate }} </strong></span></div>
        <div class="col"><span class="rectangle-footer">{{ activeBuyNowOffers ? activeBuyNowOffers : 0 }}</span><span class="footer-text"><strong> {{ 'expiring-offer-calendar.active-buynow' | translate }} </strong></span></div>
        <div class="col-2 text-end"><button aria-label="Close" class="btn btn-primary" data-bs-dismiss="modal" type="button">{{ 'ucs.close' | translate }}</button></div>
      </div>
    </div>
  </div>
</div>



