import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import * as SearchActions from '../../store/search/search.actions';
import {PricePipe} from '../../pipe/price.pipe';
import {SystemSettingsService} from '../../service/system-settings.service';
import {Subject} from 'rxjs';
import {SearchMultiSelectComponent} from './search-multi-select/search-multi-select.component';
import {takeUntil} from 'rxjs/operators';
import {SalesScope} from '../../model/sales-scope';
import * as EnforcedAuctionOfferAction from '../../store/enforced-auction-offer/enforced-auction-offer.actions';
import {compareSets} from '../../misc/utils';
import {SearchRangeDropdownComponent} from './search-range-dropdown/search-range-dropdown.component';
import {SearchLocationComponent} from './search-location/search-location.component';
import {SearchEquipmentComponent} from './search-equipment/search-equipment.component';
import {SearchDropdownComponent} from './search-dropdown/search-dropdown.component';
import {SearchToggleButtonComponent} from './search-toggle-button/search-toggle-button.component';
import {
  SearchToggleAuctionBuynowComponent
} from './search-toggle-auction-buynow/search-toggle-auction-buynow.component';
import {SearchSimpleInputComponent} from './search-simple-input/search-simple-input.component';
import {NgxFloatUiTriggers} from 'ngx-float-ui';

/**
 * The search filter sidebar component indirectly (via redux-store) orchestrates the result list of offers
 */
@Component({
  selector: 'ucs-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit, AfterViewInit, OnDestroy {
  searchAggregation$ = this.store.select(fromRoot.getSearchAggregation);
  searchState$ = this.store.select(fromRoot.getSearchState);
  enforcedAuctionOfferState$ = this.store.select(fromRoot.getEnforcedAuctionOfferState);
  enforcedAuctionSearchAggregations$ = this.store.select(fromRoot.getEnforcedAuctionOfferSearchAggregation);
  pageSettingsDistributionChannel$ = this.store.select(fromRoot.getPageSettingsDistributionChannel);
  userState$ = this.store.select(fromRoot.getUserState);

  @Output() hideSidebar = new EventEmitter();
  @ViewChild('dropdownRangePrice') dropdownRangePrice: SearchRangeDropdownComponent;
  @ViewChild('dropdownRangePower') dropdownRangePower: SearchRangeDropdownComponent;
  @ViewChild('dropdownRangeMileage') dropdownRangeMileage: SearchRangeDropdownComponent;
  @ViewChild('dropdownRangeInitialRegistration') dropdownRangeInitialRegistration: SearchRangeDropdownComponent;
  @ViewChild('searchLocation') searchLocation: SearchLocationComponent;
  @ViewChild('searchCombinedDealer') searchCombinedDealer: SearchMultiSelectComponent;
  @ViewChild('dropdownCountry') dropdownCountry: SearchMultiSelectComponent;
  @ViewChild('searchEquipment') searchEquipment: SearchEquipmentComponent;
  @ViewChild('dropDownChannel') dropDownChannel: SearchMultiSelectComponent;

  // detail view
  @ViewChild('dropdownChassis') dropdownChassis: SearchMultiSelectComponent;
  @ViewChild('dropdownSeats') dropdownSeats: SearchMultiSelectComponent;
  @ViewChild('dropdownColor') dropdownColor: SearchMultiSelectComponent;
  @ViewChild('dropdownGear') dropdownGear: SearchMultiSelectComponent;
  @ViewChild('dropdownRangeMotorCapacity') dropdownMotorCapacity;
  @ViewChild('dropdownFuel') dropdownFuel: SearchMultiSelectComponent;
  @ViewChild('dropdownConsumption') dropdownConsumption: SearchRangeDropdownComponent;
  @ViewChild('dropdownCo2Emission') dropdownCo2Emission: SearchDropdownComponent;
  @ViewChild('dropdownEmissionClass') dropdownEmissionClass: SearchMultiSelectComponent;
  @ViewChild('dropdownExploitationTypes') dropdownExploitationTypes: SearchMultiSelectComponent;
  @ViewChild('dropdownSalesTax') dropdownSalesTax: SearchDropdownComponent;
  @ViewChild('toggleButtonSingleBundle') toggleButtonSingleBundle: SearchToggleButtonComponent;
  @ViewChild('toggleAuctionBuyNow') toggleAuctionBuyNow: SearchToggleAuctionBuynowComponent;
  @ViewChild('inputExtDealerId') inputExtDealerId: SearchSimpleInputComponent;
  @ViewChild('inputExtVehicleId') inputExtVehicleId: SearchSimpleInputComponent;
  @ViewChild('inputVin') inputVin: SearchSimpleInputComponent;

  // create an instance of VehicleOfferSearchParams and set all values to undefined initially
  selectedDistributionChannel: DistributionChannel;
  searchParams = {} as VehicleOfferSearchParams;
  searchAggregation: VehicleOfferSearchAggregation;
  detailedSearchOptions = false; // indicates whether detailed search options are displayed
  currentScope: SalesScope;

  private unsubscribe: Subject<void> = new Subject<void>();
  private allButtonOfferTypes: OfferType[] = ['AUCTION', 'BUYNOW'];

  protected readonly UserVehicleSearchParamsRequestDto: UserVehicleSearchParamsRequestDto;
  /**
   * Instantiates the component
   * @param {Store<AppState>} store: allows access to store
   */
  constructor(private store: Store<fromRoot.AppState>,
              private pricePipe: PricePipe,
              private systemSettingsService: SystemSettingsService) {
  }

  /**
   * When initializing the component, update aggregations and subscribe to changes of search params
   */
  ngOnInit() {
    this.store.select(fromRoot.getSalesScope).pipe(takeUntil(this.unsubscribe))
      .subscribe(scope => {
        this.currentScope = scope;
      });

    if (this.isPiaAuctionScope()) {
      this.initPiaAuctionsSearchFilters();
    } else {
      this.initDefaultSearchFilters();
    }
  }

  private initDefaultSearchFilters() {

    this.searchState$
      .subscribe(searchState => {
        this.searchParams = searchState.searchParams;

        if (!this.searchParams.bundling) {
          this.searchParams.bundling = 'SINGLE';
        }
        this.updateSelections(); // update search filter selections with the values received from store
      });

    // select searchAggregation from store and subscribe to future updates
    this.searchAggregation$.pipe(takeUntil(this.unsubscribe))
      .subscribe(searchAggregation => {
        this.preparePricesForSearchAggregations(searchAggregation);
      });
  }

  private preparePricesForSearchAggregations(searchAggregation: VehicleOfferSearchAggregation) {
    if (searchAggregation) {
      searchAggregation.pricesFrom = this.divideByHundred(searchAggregation.pricesFrom);
      searchAggregation.pricesTo = this.divideByHundred(searchAggregation.pricesTo);
      this.searchAggregation = searchAggregation;
    }
  }

  private initPiaAuctionsSearchFilters() {
    this.enforcedAuctionOfferState$.pipe(takeUntil(this.unsubscribe))
      .subscribe(enforcedState => {
        this.searchParams = enforcedState.searchParams;
        this.searchParams.bundling = 'SINGLE';
        this.searchParams.channels = ['PIA'];
        this.searchParams.types = ['ENFORCED_AUCTION'];
        this.updateSelections();
      });

    this.store.dispatch(new EnforcedAuctionOfferAction.UpdateSearchAggregationAction());

    this.enforcedAuctionSearchAggregations$.pipe(takeUntil(this.unsubscribe))
      .subscribe(searchAggregation => {
        this.preparePricesForSearchAggregations(searchAggregation);
      });
  }


  ngAfterViewInit() {
    //ViewChildren can only be accessed after ViewInit
    //thus dropdowns have to be updated here
    this.updateSelections(); // update search filter selections with the values received from store
  }

  /**
   * Updates all selected values in search filter with the current searchParams object
   */
  updateSelections() {
    // top-level if conditions ensure the view child is not called before it is initialized

    // prices need to be converted because they are stored in backend and redux store in subunit (e.g. cent)
    this.convertAndOverridePrices();
    this.overrideDropDownSelectionFromTo();
    this.overrideCountries();
    this.overrideDropdownSelections();
    // detailSearchOptions
    this.detailedSearchOptionsSelectionOverride();

    if (this.dropdownCo2Emission) {
      if (this.dropdownCo2Emission.selectedValue) {
        this.detailedSearchOptions = true;
      }
      this.dropdownCo2Emission.overrideSelection(this.searchParams.co2Emission);
    }

    if (this.dropdownSalesTax) {
      if (this.dropdownSalesTax.selectedValue) {
        this.detailedSearchOptions = true;
      }
      this.dropdownSalesTax.overrideSelection(this.searchParams.vatType);
    }

    this.evaluateOfferTypeToggleState();

    this.overrideChannels();
  }

  private evaluateOfferTypeToggleState() {
    if (this.toggleAuctionBuyNow) {
      // undefined means, both auction and buyNow are active
      if (this.searchParams.bundling === 'SINGLE') {
        if (compareSets(this.searchParams.types, this.allButtonOfferTypes)
          || this.searchParams.types === null
          || this.searchParams.types === undefined
          || this.searchParams.types.length === 0) {
          this.toggleAuctionBuyNow.setFilter('ALL');
        } else {
          this.toggleAuctionBuyNow.setFilter(this.searchParams.types?.toString());
        }
      } else {
        this.toggleAuctionBuyNow.toggleForBundle();
      }
    }
  }

  private overrideDropdownSelections() {
    if (this.searchEquipment) {
      this.searchEquipment.overrideSelections(this.searchParams.equipments);
    }
    if (this.inputExtDealerId) {
      this.inputExtDealerId.overrideSelection(this.searchParams.extDealerId);
    }
    if (this.inputExtVehicleId) {
      this.inputExtVehicleId.overrideSelection(this.searchParams.extVehicleId);
    }
    if (this.inputVin) {
      this.inputVin.overrideSelection(this.searchParams.vin);
    }
    if (this.toggleButtonSingleBundle) {
      this.toggleButtonSingleBundle.overrideSelection(this.searchParams.bundling);
    }
  }

  private detailedSearchOptionsSelectionOverride() {
    if (this.dropdownChassis) {
      this.hasDetailedSearchOptions(this.dropdownChassis);
      this.dropdownChassis.overrideSelection(this.searchParams.chassis);
    }
    if (this.dropdownSeats) {
      this.hasDetailedSearchOptions(this.dropdownSeats);
      this.dropdownSeats.overrideSelection(this.searchParams.seats);
    }
    if (this.dropdownColor) {
      this.hasDetailedSearchOptions(this.dropdownColor);
      this.dropdownColor.overrideSelection(this.searchParams.colors);
    }
    if (this.dropdownGear) {
      this.hasDetailedSearchOptions(this.dropdownGear);
      this.dropdownGear.overrideSelection(this.searchParams.gearTypes);
    }
    if (this.dropdownFuel) {
      this.hasDetailedSearchOptions(this.dropdownFuel);
      this.dropdownFuel.overrideSelection(this.searchParams.fuels);
    }
    if (this.dropdownEmissionClass) {
      this.hasDetailedSearchOptions(this.dropdownEmissionClass);
      this.dropdownEmissionClass.overrideSelection(this.searchParams.emissionClass);
    }
    if (this.dropdownExploitationTypes) {
      this.hasDetailedSearchOptions(this.dropdownExploitationTypes);
      this.dropdownExploitationTypes.overrideSelection(this.searchParams.exploitationType);
    }
    if (this.searchCombinedDealer) {
      this.hasDetailedSearchOptions(this.searchCombinedDealer);
      this.searchCombinedDealer.overrideSelection(this.searchParams.combinedSellingDealers);
    }
  }

  private overrideCountries() {
    if (this.dropdownCountry) {
      if (!this.searchParams.countries) { // clear selection
        this.dropdownCountry.overrideSelection(undefined);
      } else { // or set country
        this.dropdownCountry.overrideSelection(this.searchParams.countries);
      }
    }
  }

  private overrideChannels() {
    if (this.dropDownChannel) {
      if (!this.searchParams.channels) { // clear selection
        this.dropDownChannel.overrideSelection(undefined);
      } else { // or set country
        this.dropDownChannel.overrideSelection(this.searchParams.channels);
      }
    }
  }

  private hasDetailedSearchOptions(field: any) {
    if (field.dropdownComponent?.selectedValue
      || field.multiselectComponent?.selectedElements?.length > 0) {
      this.detailedSearchOptions = true;
    }
  }

  private overrideDropDownSelectionFromTo() {
    if (this.dropdownRangePower) {
      this.dropdownRangePower.overrideSelection(this.searchParams.powerPsFrom, this.searchParams.powerPsTo);
    }
    if (this.dropdownRangeMileage) {
      this.dropdownRangeMileage.overrideSelection(this.searchParams.mileageFrom, this.searchParams.mileageTo);
    }
    if (this.dropdownRangeInitialRegistration) {
      this.dropdownRangeInitialRegistration
        .overrideSelection(this.searchParams.initialRegistrationFrom, this.searchParams.initialRegistrationTo);
    }
    if (this.searchLocation && this.searchParams.zipCode && this.searchParams.city) {
      this.searchLocation.overrideSelection(this.searchParams.zipCode, this.searchParams.city);
    }
    if (this.dropdownConsumption) {
      if (this.dropdownConsumption.selectedValueFrom || this.dropdownConsumption.selectedValueTo) {
        this.detailedSearchOptions = true;
      }
      this.dropdownConsumption.overrideSelection(this.searchParams.consumptionFrom, this.searchParams.consumptionTo);
    }
    if (this.dropdownMotorCapacity) {
      if (this.dropdownMotorCapacity.selectedValue) {
        this.detailedSearchOptions = true;
      }
      this.dropdownMotorCapacity.overrideSelection(this.searchParams.motorCapacityFrom,
        this.searchParams.motorCapacityto);
    }
  }

  private convertAndOverridePrices() {
    if (this.dropdownRangePrice) {
      let convertedPriceFrom: number;
      let convertedPriceTo: number;
      if (this.searchParams.priceFrom) {
        convertedPriceFrom = Number(this.searchParams.priceFrom) / 100;
      }
      if (this.searchParams.priceTo) {
        convertedPriceTo = Number(this.searchParams.priceTo) / 100;
      }
      this.dropdownRangePrice.overrideSelection(convertedPriceFrom, convertedPriceTo);
    }
  }

  /**
   * Trigger an update in the store to fetch from backend and bind to the results of the update in the store
   */
  updateSearchAggregation() {
    // save updated search params
    if (this.isPiaAuctionScope()) {
      this.store.dispatch(new EnforcedAuctionOfferAction.UpdateSearchParamsAction(this.searchParams));
      this.store.dispatch(new EnforcedAuctionOfferAction.UpdateSearchAggregationAction());
    } else {
      this.store.dispatch(new SearchActions.UpdateSearchParamsAction(this.searchParams));
      this.store.dispatch(new SearchActions.UpdateSearchAggregationAction());
    }
  }

  /**
   * Dispatch action to delete the searchParams in the store
   */
  onClearSearchParams() {
    if (this.isPiaAuctionScope()) {
      this.store.dispatch(new EnforcedAuctionOfferAction.DeleteSearchParamsAction());
    } else {
      this.store.dispatch(new SearchActions.DeleteSearchParamsAction());
    }
    this.updateSearchAggregation();
  }

  /**
   * Emits to parent component that sidebar is to be hidden
   */
  onToggleSidebar() {
    this.hideSidebar.emit();
  }

  /**
   * Inverts detailedSearchOptions boolean which indicates whether detailed search options are visible
   */
  onToggleDetailedSearchOptions() {
    this.detailedSearchOptions = !this.detailedSearchOptions;
  }

  /**
   * Updates vehicle-related search params and dispatch update in redux store
   */
  onChangeVehiclesSearchParams(vehicleParams: VehicleOfferSearchParams) {
    this.searchParams.brand = vehicleParams.brand;
    this.searchParams.brand2 = vehicleParams.brand2;
    this.searchParams.brand3 = vehicleParams.brand3;
    this.searchParams.brand4 = vehicleParams.brand4;
    this.searchParams.brand5 = vehicleParams.brand5;

    this.searchParams.models = vehicleParams.models;
    this.searchParams.models2 = vehicleParams.models2;
    this.searchParams.models3 = vehicleParams.models3;
    this.searchParams.models4 = vehicleParams.models4;
    this.searchParams.models5 = vehicleParams.models5;
    this.searchParams.modelVariants = vehicleParams.modelVariants;
    this.searchParams.modelVariants2 = vehicleParams.modelVariants2;
    this.searchParams.modelVariants3 = vehicleParams.modelVariants3;
    this.searchParams.modelVariants4 = vehicleParams.modelVariants4;
    this.searchParams.modelVariants5 = vehicleParams.modelVariants5;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Handles checkbox for DirectSale
   */
  onChangeDirectSale() {
    this.searchParams.directSale = !this.searchParams.directSale;
    this.updateSearchAggregation();
  }

  /**
   * Updates respective search param
   * @param value: the channel or channel array that should be updated in search params
   */
  onChangeSearchParamChannels(value: DistributionChannel[]) {
    this.searchParams.channels = value;
    this.updateSearchAggregation();
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {number} value: the value that should be updated in search params
   */
  onChangeSearchParamPriceFrom(value: number) {
    if (value && value * 100 !== this.searchParams.priceFrom) { // only update params/aggregation if value has actually changed
      this.searchParams.priceFrom = value * 100; // we need to store value in subunit (in accordance with back end)
      this.updateSearchAggregation();
    } else if (!value && this.searchParams.priceFrom) { // this case defines behavior when we clear the filter value
      this.searchParams.priceFrom = value;
      this.updateSearchAggregation();
    }
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {number} value: the value that should be updated in search params
   */
  onChangeSearchParamPriceTo(value: number) {
    if (value && value * 100 !== this.searchParams.priceTo) { // only update params/aggregation if value has actually changed
      this.searchParams.priceTo = value * 100; // we need to store value in subunit (in accordance with back end)
      this.updateSearchAggregation();
    } else if (!value && this.searchParams.priceTo) { // this case defines behavior when we clear the filter value
      this.searchParams.priceTo = value;
      this.updateSearchAggregation();
    }
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {number} value: the value that should be updated in search params
   */
  onChangeSearchParamPowerFrom(value: number) {
    if (value !== this.searchParams.powerPsFrom) { // only update params/aggregation if value has actually changed
      this.searchParams.powerPsFrom = value;
      this.updateSearchAggregation();
    }
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {number} value: the value that should be updated in search params
   */
  onChangeSearchParamPowerTo(value: number) {
    if (value !== this.searchParams.powerPsTo) { // only update params/aggregation if value has actually changed
      this.searchParams.powerPsTo = value;
      this.updateSearchAggregation();
    }
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {number} value: the value that should be updated in search params
   */
  onChangeSearchParamMileageFrom(value: number) {
    if (value !== this.searchParams.mileageFrom) { // only update params/aggregation if value has actually changed
      this.searchParams.mileageFrom = value;
      this.updateSearchAggregation();
    }
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {number} value: the value that should be updated in search params
   */
  onChangeSearchParamMileageTo(value: number) {
    if (value !== this.searchParams.mileageTo) { // only update params/aggregation if value has actually changed
      this.searchParams.mileageTo = value;
      this.updateSearchAggregation();
    }
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {number} value: the value that should be updated in search params
   */
  onChangeSearchParamRegistrationFrom(value: number) {
    if (value !== this.searchParams.initialRegistrationFrom) { // only update params/aggregation if value has actually changed
      this.searchParams.initialRegistrationFrom = value;
      this.updateSearchAggregation();
    }
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {number} value: the value that should be updated in search params
   */
  onChangeSearchParamRegistrationTo(value: number) {
    if (value !== this.searchParams.initialRegistrationTo) { // only update params/aggregation if value has actually changed
      this.searchParams.initialRegistrationTo = value;
      this.updateSearchAggregation();
    }
  }


  /**
   * Updates respective search param and dispatch update in redux store
   * @param {LocationDto} location: the location that should be updated in search params
   */
  onChangeSearchParamPostcodeCity(location: LocationDto) {
    if (location) {
      this.searchParams.zipCode = location.zipCode;
      this.searchParams.city = location.city;
    } else {
      this.searchParams.zipCode = undefined;
      this.searchParams.city = undefined;
    }

    // we also need to override country selection
    this.searchParams.countries = [];
    this.searchParams.countries.push(location.country);

    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param value: the country or country array that should be updated in search params
   */
  onChangeSearchParamCountry(value: Country[]) {
    this.searchParams.countries = value;
    // when a country is selected, zipcode & city needs to be reset
    this.searchParams.zipCode = undefined;
    this.searchParams.city = undefined;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param value: chassis that should be updated in search params
   */
  onChangeSearchParamChassis(value: string[]) {
    this.searchParams.chassis = value;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param value: seats that should be updated in search params
   */
  onChangeSearchParamSeats(value: number[]) {
    this.searchParams.seats = value;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param value: color that should be updated in search params
   */
  onChangeSearchParamColor(value: string[]) {
    this.searchParams.colors = value;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param value: gear that should be updated in search params
   */
  onChangeSearchParamGear(value: string[]) {
    this.searchParams.gearTypes = value;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {number} value: "from capacity" that should be updated in search params
   */
  onChangeSearchParamMotorCapacityFrom(value: number) {
    if (value !== this.searchParams.motorCapacityFrom) { // only update params/aggregation if value has actually changed
      this.searchParams.motorCapacityFrom = value;
      this.updateSearchAggregation();
    }
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {number} value: "to capacity" that should be updated in search params
   */
  onChangeSearchParamMotorCapacityTo(value: number) {
    if (value !== this.searchParams.motorCapacityto) { // only update params/aggregation if value has actually changed
      this.searchParams.motorCapacityto = value;
      this.updateSearchAggregation();
    }
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param value: fuel that should be updated in search params
   */
  onChangeSearchParamFuel(value: any) {
    this.searchParams.fuels = value;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {number} value: "from consumption" that should be updated in search params
   */
  onChangeSearchParamConsumptionFrom(value: number) {
    if (value !== this.searchParams.consumptionFrom) { // only update params/aggregation if value has actually changed
      this.searchParams.consumptionFrom = value;
      this.updateSearchAggregation();
    }
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {number} value: "to consumption" that should be updated in search params
   */
  onChangeSearchParamConsumptionTo(value: number) {
    if (value !== this.searchParams.consumptionTo) { // only update params/aggregation if value has actually changed
      this.searchParams.consumptionTo = value;
      this.updateSearchAggregation();
    }
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {number} value: co2 emission that should be updated in search params
   */
  onChangeSearchParamCo2Emission(value: number) {
    this.searchParams.co2Emission = value;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param value: co2 emission class that should be updated in search params
   */
  onChangeSearchParamEmissionClass(value: string[]) {
    this.searchParams.emissionClass = value;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param value: exploitation types that should be updated in search params
   */
  onChangeSearchParamExploitationTypes(value: ExploitationType[]) {
    this.searchParams.exploitationType = value;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {VatType} value: vat type that should be updated in search params
   */
  onChangeSearchParamVatType(value: VatType) {
    this.searchParams.vatType = value;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {string} value: expiration that should be updated in search params
   */
  onChangeSearchParamEndOfAuction(value: string) {
    this.searchParams.expiration = value;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Updates respective search param and dispatch update in redux store
   * @param {string[]} equipments: the equipments that should be updated in search params
   */
  onEquipmentChanged(equipments: string[]) {
    this.searchParams.equipments = equipments;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Inverts respective search param and dispatch update in redux store
   */
  onChangeSearchParamXenonLedLight() {
    this.searchParams.xenonLedLight = !this.searchParams.xenonLedLight;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Inverts respective search param and dispatch update in redux store
   */
  onChangeSearchParamFourWD() {
    this.searchParams.fourWD = !this.searchParams.fourWD;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Inverts respective search param and dispatch update in redux store
   */
  onChangeSearchParamTrailerHitch() {
    this.searchParams.trailerHitch = !this.searchParams.trailerHitch;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Inverts respective search param and dispatch update in redux store
   */
  onChangeSearchParamAutomaticTransmission() {
    this.searchParams.automaticTransmission = !this.searchParams.automaticTransmission;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Inverts respective search param and dispatch update in redux store
   */
  onChangeSearchParamHeatedSeats() {
    this.searchParams.heatedSeats = !this.searchParams.heatedSeats;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Inverts respective search param and dispatch update in redux store
   */
  onChangeSearchParamLeatherSeats() {
    this.searchParams.leatherSeats = !this.searchParams.leatherSeats;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * Inverts respective search param and dispatch update in redux store
   */
  onChangeSearchParamNavigation() {
    this.searchParams.navigation = !this.searchParams.navigation;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  /**
   * updates search param and dispatch update in redux store
   */
  onChangeSingleBundle() {
    this.searchParams.bundling = this.toggleButtonSingleBundle.activeLeft ? 'SINGLE' : 'BUNDLE';
    if (this.toggleButtonSingleBundle.activeLeft) {
      this.searchParams.types = this.allButtonOfferTypes;
    } else {
      this.searchParams.types = ['BUYNOW'];
    }
    this.updateSearchAggregation();
  }

  /**
   * set search params for auction or buy now toggle
   */
  onChangeAuctionBuyNow($event: any) {
    if ($event === 'ALL' && this.searchParams.bundling === 'SINGLE') {
      this.searchParams.types = this.allButtonOfferTypes;
    } else {
      this.searchParams.types = [$event];
    }
    this.updateSearchAggregation();
  }

  /**
   * updates search params and dispatch update in redux store
   * @param {string} extDealerId
   */
  onChangeInputExtDealerId(extDealerId: string) {
    /**
     * The if check has been introduced because angular seems to call (onInput) on init
     * and thus triggers unnecessary calls to redux store and back end
     */
    if (extDealerId !== this.searchParams.extDealerId) {
      this.searchParams.extDealerId = extDealerId;
      this.updateSearchAggregation();
    }
  }

  /**
   * updates search params and dispatch update in redux store
   * @param {string} extVehicleId: the external vehicle id
   */
  onChangeInputExtVehicleId(extVehicleId: string) {
    /**
     * The if check has been introduced because angular seems to call (onInput) on init
     * and thus triggers unnecessary calls to redux store and back end
     */
    if (extVehicleId !== this.searchParams.extVehicleId) {
      this.searchParams.extVehicleId = extVehicleId;
      this.updateSearchAggregation();
    }
  }

  /**
   * updates search params and dispatch update in redux store
   * @param {string} vin: the vehicle identification number (vin)
   */
  onChangeInputVin(vin: string) {
    /**
     * The if check has been introduced because angular seems to call (onInput) on init
     * and thus triggers unnecessary calls to redux store and back end
     */
    if (vin !== this.searchParams.vin) {
      this.searchParams.vin = vin;
      this.updateSearchAggregation();
    }
  }

  /**
   * This is a helper method to deal with currency, which is received from backend in subunit (e.g. cents for EUR)
   *
   * @param {ValueAggregationEntry[]} pricesInSubunit: the prices in their subunit (e.g. cents)
   * @returns {ValueAggregationEntry[]}: a new array with prices in their regular currency (e.g. EUR)
   */
  divideByHundred(pricesInSubunit: ValueAggregationEntry[]): ValueAggregationEntry[] {
    const convertedPrices: ValueAggregationEntry[] = [];
    for (const price of pricesInSubunit) {
      const convertedPrice: ValueAggregationEntry = {} as any;
      if (price.value !== null) {
        convertedPrice.value = this.pricePipe.transform(Number(price.value));
      } else { // we make sure to keep choice 'any' here
        convertedPrice.text = price.text;
      }
      convertedPrices.push(convertedPrice);
    }
    return convertedPrices;
  }

  isPiaAuctionScope() {
    return this.currentScope === SalesScope.PIA_AUCTION;
  }

  /**
   * Unsubscribe from Observables when component is destroyed to prevent memory leaks
   */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onChangeSearchParamCombinedDealer($event: any) {
    this.searchParams.combinedSellingDealers = $event;
    this.updateSearchAggregation(); // trigger update in redux store
  }

  protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
