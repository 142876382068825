import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'ucs-search-toggle-auction-buynow',
  templateUrl: './search-toggle-auction-buynow.component.html',
  styleUrls: ['./search-toggle-auction-buynow.component.scss']
})
export class SearchToggleAuctionBuynowComponent {
  filter = 'ALL';
  disabled = false;
  @Output() toggleChanged = new EventEmitter<any>();

  /**
   * Clears the selection for Bundle
   */
  toggleForBundle(){
    this.filter = 'BUYNOW';
    this.disabled = true;
  }

  /**
   * Clears the selection for Single
   */
  clear() {
    this.filter = 'ALL';
    this.disabled = false;
  }

  setFilter(searchParamType: string){
    this.filter = searchParamType;
  }
}
