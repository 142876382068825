import {Component, Input} from '@angular/core';

/**
 * The componenten holding the photo collage for packages in the offer result list
 */
@Component({
  selector: 'ucs-bundle-collage',
  templateUrl: './bundle-collage.component.html',
  styleUrls: ['./bundle-collage.component.scss']
})
export class BundleCollageComponent {

  @Input() images: string[];
  @Input() amount: number;
  @Input() iconSize: number; // needed, because SVG can't be styled directly via CSS without shadow-piercing operators
}
