<ucs-simple-input
  class="input-with-image-input-form"
  [inputWithSearchImage]=true
  [inputWithInfoImage]=true
  [allowResetOnEmptyInput]="true"
  [infoImageText]="'vehicle-maintenance.search.infoicon.text'| translate"
  [placeholder]="'vehicle-maintenance.search.identification' | translate"
  (onInput)="onChangeSearchString($event);"
  (onKeyUpEnter)="onKeyUpEnter($event);">
</ucs-simple-input>
