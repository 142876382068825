import {Component, Input} from '@angular/core';
import {NgxFloatUiPlacements, NgxFloatUiTriggers} from 'ngx-float-ui';

@Component({
  selector: 'ucs-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss']
})
export class InfoIconComponent {
  @Input() infoText: string;
  @Input() labelVisible = true;
  @Input() placement = NgxFloatUiPlacements.LEFT;
  protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
