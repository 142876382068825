@if (label) {
  <div class="row mt-3 mb-2">
    <div [ngClass]="{'col-12 text': true,'ps-0':showUntil}">
      <strong>{{label}}</strong>
    </div>
  </div>
}

<div class="row d-flex">
  <div [ngClass]="{'col-sm px-0': showUntil, 'pe-0': !showUntil}" class="wrapper col" data-cy="range-select-from">
    <div class="text">
      <input [id]="id + '.from'"
        [(ngModel)]="selectedValueFrom"
        (ngModelChange)="onSelectFrom($event)"
        data-cy="input-field-from"
        placeholder="{{ 'search-range-dropdown.from' | translate }}"
        [value]="selectedValueFrom"
        class="form-control text">
    </div>
    <button type="button" class="btn dropdown-button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
      data-cy="from">
    </button>
    <div class="dropdown-menu dropdown-menu-right" >
      <a class="dropdown-item" (click)="onSelectFrom('')">{{'search-range-dropdown.any' | translate}}</a>
      @for (item of aggregatedStatesFrom; track item) {
        <a class="dropdown-item" (click)="onSelectFrom(item.value)"
        >{{item.value}}</a>
      }
    </div>
  </div>

  @if (showUntil) {
    <div class="col-sm-1 text text-until d-none d-sm-block">-</div>
  }
  <div [ngClass]="{'col-sm px-0': showUntil}" class="wrapper col" data-cy="range-select-to">
    <div class="text">
      <input [id]="id + '.to'"
        [(ngModel)]="selectedValueTo"
        (ngModelChange)="onSelectTo($event)"
        data-cy="input-field-to"
        placeholder="{{ 'search-range-dropdown.to' | translate }}"
        [value]="selectedValueTo"
        class="form-control text">
    </div>
    <button type="button" class="btn dropdown-button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
      data-cy="to">
    </button>
    <div class="dropdown-menu dropdown-menu-right">
      <a class="dropdown-item" (click)="onSelectTo('')">{{'search-range-dropdown.any' | translate}}</a>
      @for (item of aggregatedStatesTo; track item) {
        <a class="dropdown-item" (click)="onSelectTo(item.value)"
        >{{item.value}}</a>
      }
    </div>
  </div>
</div>
