import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ucs-channel-selection',
  templateUrl: './channel-selection.component.html',
  styleUrls: ['./channel-selection.component.scss']
})
export class ChannelSelectionComponent {
  @Input() elementsCountALLUC: number;
  @Input() elementsCountPB: number;
  @Input() elementsCountPIA: number;
  @Input() elementsCountDINBIL: number;
  @Input() elementsCountVGRX: number;
  @Input() canViewALLUC: boolean;
  @Input() canViewPB: boolean;
  @Input() canViewPIA: boolean;
  @Input() canViewDINBIL: boolean;
  @Input() canViewVGRX: boolean;
  @Input() platformListClass = 'col d-none d-lg-block platform-list'; // default class
  @Input() hideDropDown: boolean;
  @Input() selectedChannel: DistributionChannel;
  @Input() showSidebar: boolean;
  @Output() channelChangedEmitter = new EventEmitter();
}
