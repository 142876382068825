import {Observable} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';

@Injectable()
export class JobMonitoringService {
  private noCacheHeaders = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
  });

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) {
  }

  getTasks(jobType: MonitorableBatchJobType, pageable: Pageable, stateFilter: MonitorableBatchTaskStatus[],
    startDate: Date, endDate: Date)
    : Observable<Page<MonitorableBatchTaskDto[]>> {

    let params = new HttpParams();
    params = params.set('jobType', jobType);
    params = params.set('page', pageable.pageNumber.toString());
    params = params.set('size', pageable.pageSize.toString());
    params = params.set('sort', 'created' + ',' + 'DESC');


    if (startDate) {
      params = params.set('startDate', startDate.toISOString().replace('Z', ''));
    }
    if (endDate) {
      params = params.set('endDate', endDate.toISOString().replace('Z', ''));
    }

    if (stateFilter) {
      stateFilter.forEach(filter => params = params.append('stateFilter', filter));
    }

    return this.http.get<Page<MonitorableBatchTaskDto[]>>(this.config.salesApiUrl + '/job/tasks',
      {params: params, headers: this.noCacheHeaders});
  }

  getData(id:number, jobType:MonitorableBatchJobType) :Observable<string> {
    let params = new HttpParams();
    params = params.set('jobType', jobType);

    return this.http.get<string>(this.config.salesApiUrl + '/job/tasks/' + id + '/info',
      {params: params, headers: this.noCacheHeaders});
  }

  searchTasks(jobType: MonitorableBatchJobType, pageable: Pageable, stateFilter: MonitorableBatchTaskStatus[],
    searchString: string)
    : Observable<Page<MonitorableBatchTaskDto[]>> {
    let params = new HttpParams();
    params = params.set('jobType', jobType);
    params = params.set('page', pageable.pageNumber.toString());
    params = params.set('size', pageable.pageSize.toString());
    params = params.set('searchString', searchString);

    if (stateFilter) {
      stateFilter.forEach(filter => params = params.append('stateFilter', filter));
    }

    return this.http.get<Page<MonitorableBatchTaskDto[]>>(this.config.salesApiUrl + '/job/tasks',
      {params: params, headers: this.noCacheHeaders});
  }

  /**
   * Gets errors for the provided task id and job type.
   *
   * @param id the task id
   * @param jobType the batch job type
   */
  getErrorsOfTask(id: number, jobType: MonitorableBatchJobType): Observable<BatchTaskErrorDto[]> {
    let params = new HttpParams();
    params = params.set('jobType', jobType);
    params = params.set('taskId', id.toString());

    return this.http.get<BatchTaskErrorDto[]>(this.config.salesApiUrl + '/job/errors',
      {params: params, headers: this.noCacheHeaders});
  }

  /**
   * Deletes a job task.
   *
   * @param id the task id
   * @param jobType the batch job type
   */
  deleteTask(id: number, jobType: MonitorableBatchJobType): Observable<any> {
    let params = new HttpParams();
    params = params.set('jobType', jobType);
    return this.http.delete(this.config.salesApiUrl + '/job/tasks/' + id, {params: params});
  }

  /**
   * Retries a job task.
   *
   * @param id the task id
   * @param jobType the batch job type
   */
  retryTask(id: number, jobType: MonitorableBatchJobType): Observable<any> {
    let params = new HttpParams();
    params = params.set('jobType', jobType);
    return this.http.put(this.config.salesApiUrl + '/job/tasks/' + id, null, {params: params});
  }

}
