import {ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Subject, timer} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';
import {SystemSettingsService} from '../../service/system-settings.service';
import {NgxFloatUiPlacements, NgxFloatUiTriggers} from 'ngx-float-ui';

/**
 * Component to count the time till an offer expires
 * @Input string expirationDate
 */
@Component({
  selector: 'ucs-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnDestroy {

  @Input() expirationDate;
  @Input() small: boolean;
  @Input() isOffer: boolean;
  @Input() isOfferDetail: boolean;
  @Input() isBidDialog: boolean;
  @Input() isExternalDealerBidDialog: boolean;
  showDays: boolean;
  showHours: boolean;
  showMinutes: boolean;
  isFutureDate: boolean;
  remainingDays: string;
  remainingHours: string;
  remainingMinutes: string;
  remainingSeconds: string;
  countdownClass: string;
  color: string;
  redSize: number;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private cdRef: ChangeDetectorRef, private ngZone: NgZone, private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit() {
    let expirationDate = new Date(this.expirationDate);
    const pad = '00';

    this.resetStyle();

    let now = new Date(Date.now());
    let diffMillSeconds = expirationDate.getTime() - now.getTime();

    this.ngZone.runOutsideAngular(() => {
      // We still use timer directly here, as it is outside Angular's zone and will not affect app stability
      timer(0, 1000).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
        expirationDate = new Date(this.expirationDate);
        now = new Date(Date.now());
        diffMillSeconds = expirationDate.getTime() - now.getTime();
        const diffDate = new Date(diffMillSeconds);
        const localizedDiffDate = new Date(diffDate.toString());

        // checks if date is in past. If so, don't calculate remainingDateValues
        if (diffMillSeconds < 0) {
          this.isFutureDate = false;
          this.showDays = false;
          this.showMinutes = false;
          this.showHours = false;
        } else {
          const tRemainingSeconds = '' + ((Math.floor(localizedDiffDate.getTime() / 1000)) % 60);
          this.remainingSeconds = pad.substr(0, pad.length - tRemainingSeconds.length) + tRemainingSeconds;
          const tRemainingMinutes = '' + (Math.floor(localizedDiffDate.getTime() / 1000 / 60)) % 60;
          this.remainingMinutes = pad.substr(0, pad.length - tRemainingMinutes.length) + tRemainingMinutes;
          const tRemainingHours = '' + (Math.floor(localizedDiffDate.getTime() / 1000 / 60 / 60)) % 24;
          this.remainingHours = pad.substr(0, pad.length - tRemainingHours.length) + tRemainingHours;
          this.remainingDays = '' + (Math.floor(localizedDiffDate.getTime() / 1000 / 60 / 60 / 24));

          this.resetStyle();

          this.showDays = parseInt(this.remainingDays, 10) >= 1;
          this.showHours = this.showDays ? true : parseInt(this.remainingHours, 10) >= 1;
          if (!this.showDays && !this.showHours && parseInt(this.remainingMinutes, 10) < 10) {
            this.countdownClass = 'countdown-component-last-minutes';
            this.color = 'red';
            this.redSize = 18;
            this.showMinutes = parseInt(this.remainingMinutes, 10) >= 1;
          }

          this.cdRef.detectChanges();
        }
      });
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * reset countdown to standard style
   */
  private resetStyle() {
    this.countdownClass = 'countdown-component';
    this.color = '#45494f';
    this.redSize = 0;
    this.showDays = true;
    this.showHours = true;
    this.showMinutes = true;
    this.isFutureDate = true;
  }

  protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
  protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}

