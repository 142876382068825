import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/app.reducers';

/*
 * Returns the formatted number and appends the 'ps' (horsepower) unit
 * Usage:
 *   value | ps
 * Example:
 *   {{ vehicle.powerPs | ps }}
 *   Let vehicle.powerPs be 239, then the returned text will be "239 PS" (dots or commas depending on localisation)
*/
@Pipe({
  name: 'ps'
})
@Injectable()
export class PsPipe implements PipeTransform {

  private local: string;

  constructor(private store: Store<fromRoot.AppState>) {
  }

  transform(value: number): string {

    this.store.select(fromRoot.getUserState)
      .pipe()
      .subscribe(
        s => {
          this.local = s.local;
        }
      );
    // format number based on local
    const formattedValue = new DecimalPipe(this.local).transform(value);
    // append unit
    return formattedValue + ' PS';
  }

}
