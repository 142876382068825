import {Injectable} from '@angular/core';

@Injectable()
export class HtmlSanitizerService {

  static sanitize(text: string): string {
    const RexStr = /\<|\>|\"|\&/g;
    return text.replace(RexStr,
      function(MatchStr) {
        switch (MatchStr) {
        case '<':
          return '%3C';
        case '>':
          return '%3E';
        case '\"':
          return '%22';
        case '&':
          return '%26';
        default :
          return text;
        }
      }
    );
  }
}
