@if (['GAIN', 'LOSS'].includes(grossProfitData.type)) {
  <div class="row gx-1 w-100 d-inline-block text-nowrap gross-profit amount">
    @if (grossProfitData.type === 'GAIN') {
      <ucs-icon [shape]="'arrow-up-circle'" [size]="18" class="green"></ucs-icon>
    }
    @if (grossProfitData.type === 'LOSS') {
      <ucs-icon [shape]="'arrow-down-circle'" [size]="18" class="red"></ucs-icon>
    }
    <span class="ms-1 text-nowrap">{{ ('vehicle-footer.gross-profit.' + grossProfitData.type) | translate }}
    : {{ grossProfitData.price.gross | price }} {{ grossProfitData.price.currency.data }}</span>
  </div>
}
@if (grossProfitData.type === 'ERROR') {
  <div class="row gx-1 w-100 d-inline-block text-nowrap gross-profit">
    <ucs-icon [shape]="'x-circle'" [size]="18" class="grey"></ucs-icon>
    <span class="ms-1 text-nowrap">{{ (grossProfitData.errorCode) | translate }}</span>
  </div>
}
