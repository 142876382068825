<div class="mt-4 mb-2 text">
  <strong>{{'search-filter.all-equipment' | translate}}</strong>
</div>

<!-- selected equipments are displayed above the equipment search field -->
@for (equipment of aggregatedStates; track equipment) {
  @if (equipment.selected) {
    <div class="mb-3">
      <a>
        <ucs-icon (click)="onToggleCheckbox(equipment.id)"
          [shape]="'square_checked'"
        [class]="'blue me-1'"></ucs-icon>
      </a>
      <span class="text text-bright">{{ equipment.text }} ({{equipment.count}})</span>
    </div>
  }
}

<!-- equipment search field -->
<div class="row mb-3">
  <div [ngClass]="{'col-12':true, 'col-lg-5 col-md-6':quickSearch}">
    <input [(ngModel)]="selectedOption"
      [typeahead]="aggregatedStates"
      [typeaheadMinLength]=3
      [typeaheadOptionsLimit]="999"
      typeaheadOptionField="option"
      (typeaheadOnSelect)="onSelect($event)"
      placeholder="{{'search-filter.search-equipment' | translate}}"
      class="form-control text">
  </div>
</div>

<!-- unselected equipments are displayed below the equipment search field -->
@for (equipments of searchAggregation.equipments; track equipments; let i = $index) {
  <button class="button-borderless w-100 d-flex justify-content-between icon-height mb-3"
    (click)="onToggleCategory(i)">
    {{ equipments.category }}
    @if (isCategoryExpanded(i)) {
      <ucs-icon [shape]="'angle_up'" [class]="'blue'"></ucs-icon>
    } @else {
      <ucs-icon [shape]="'angle_down'" [class]="'blue'"></ucs-icon>
    }
  </button>
  @if (isCategoryExpanded(i)) {
    @for (equipment of aggregatedStates; track equipment) {
      @if (equipments.category === equipment.category && !equipment.selected) {
        <div class="mb-3">
          <a>
            <ucs-icon (click)="onToggleCheckbox(equipment.id)"
              [shape]="'square_outline'"
            [class]="'blue me-1'"></ucs-icon>
          </a>
          <span class="text text-bright">{{ equipment.text }} ({{equipment.count}})</span>
        </div>
      }
    }
  }
}
