<span class="d-inline-block pe-md-3">
  <button class="icon-btn px-0" type="button" [floatUi]="infoPopover"
    [applyClass]="'info-popover-size'"
    [placement]="placement" [showTrigger]="NgxFloatUiTriggers.click">
    <ucs-icon class="pe-1" [shape]="'info'" [size]="24" [class]="'blue'"></ucs-icon>
    @if (labelVisible) {
      <strong class="display-icon-label">{{ 'offer-item.info' | translate }}</strong>
    }
  </button>
  <float-ui-content #infoPopover>
    <div class="offer-popover-body">
      {{infoText}}
    </div>
  </float-ui-content>
</span>
