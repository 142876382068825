<div class="modal" tabindex="-1" role="dialog" id="openTasksModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ 'vehicle-maintenance.open-tasks.title' | translate }}</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        @if (openTasks && selectedTasks) {
          <div class="row">
            <div class="col-4 col-md-3 open-task-sidebar">
              <div>
                <input type="checkbox" id="select-all" [checked]="filterCategories.length === selectedCategories.length"
                  (click)="toggleSelectAll()"/>
                <label for="select-all">{{ 'vehicle-maintenance.open-tasks.filter-category.select-all' | translate }}
                </label>
              </div>
              <hr/>
              @for (category of filterCategories; track category) {
                <div class="mt-2">
                  <input type="checkbox" [id]="'filter-' + category" [checked]="selectedCategories.includes(category)"
                    (click)="toggleFilterCategory(category)"/>
                  <label
                    [for]="'filter-' + category">{{ ('vehicle-maintenance.open-tasks.filter-category.' + category) | translate }}
                  </label>
                </div>
              }
            </div>
            <div class="col-8 col-md-9 open-task-list">
              @if (openTasks.length < 1) {
                <p>{{ 'vehicle-maintenance.open-tasks.no-open-tasks' | translate }}</p>
              }
              @for (openTask of openTasks; track openTask; let i = $index; let last = $last) {
                <div class="task row col-12">
                  @if (isTaskVisible(openTask)) {
                    <div class="row col-12">
                      <span class="index me-4">{{ i + 1 }}.</span>
                      <input type="checkbox" [id]="'task' + i" [checked]="isSelected(openTask.tasks)"
                        (click)="toggleTask(openTask.tasks)">
                      <label class="dealer-name" [for]="'task'+i">{{ openTask.dealerNumber + ' ' + openTask.dealerName }}</label>
                      @if (openTask.note) {
                        <button type="button" class="btn-popover" [floatUi]="openTask.note">
                          <ucs-icon class="ms-2" [shape]="'info'" [class]="'blue'"></ucs-icon>
                        </button>
                      }
                    </div>
                    <div class="row col-12 indent">
                      <div class="col contract-partner">{{ openTask.contractPartner }}</div>
                    </div>
                    <div class="row col-12 indent">
                      <div class="col col-6">
                        {{ openTask.modelVariant }}
                      </div>
                      <div class="col col-6">
                        <div class="row">
                          <div class="col-4">
                            {{ 'vehicle-maintenance.open-tasks.urgency' | translate }}:
                          </div>
                          <div class="col-4">
                            {{ 'vehicle-maintenance.open-tasks.urged-on' | translate }}
                          </div>
                          <div class="col-4">
                            {{ 'vehicle-maintenance.open-tasks.urged-count' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row col-12 indent">
                      <div class="col col-6">
                        {{ openTask.vin }}
                      </div>
                      <div class="col col-6">
                        @for (task of openTask.tasks; track task) {
                          <div class="row">
                            <float-ui-content #taskTemplate>
                              {{ 'vehicle-maintenance.open-tasks.created-on' | translate }}
                              : {{ task.created | date: ('ucs.date' | translate) }}<br>
                              @if (task.lastUrgeDate) {
                                <span>{{ 'vehicle-maintenance.open-tasks.urged-on' | translate }}
                                : {{ task.lastUrgeDate | date: ('ucs.date' | translate) }}</span>
                              }
                            </float-ui-content>
                            <div class="col-4">
                              <button type="button" class="btn-popover" [floatUi]="taskTemplate">
                                <span class="badge badge-secondary me-2">
                                  {{ 'vehicle-maintenance.open-tasks.type.' + task.name | translate }}
                                </span>
                              </button>
                            </div>
                            <div class="col-4">
                              {{ task.lastUrgeDate | date: ('ucs.date' | translate) }}
                            </div>
                            <div class="col-4">
                              {{ task.urgeCount }}
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    @if (!last) {
                      <hr/>
                    }
                  }
                </div>
              }
            </div>
          </div>
        }
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" [disabled]="urgeButtonDisabled || openTasks?.length < 1"
          (click)="urgeOpenTasks()">
        {{ 'vehicle-maintenance.open-tasks.contact-partner' | translate }}</button>
      </div>
    </div>
  </div>
</div>
