import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {debounceTime, filter, first, tap} from 'rxjs/operators';
import {TaxService} from '../../service/tax.service';
import {State} from '../../store/user/user.reducers';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import {SystemSettingsService} from '../../service/system-settings.service';
import {combineLatest} from 'rxjs';
import {isAuctionOfferDto, isBuyNowOfferDto, isVehicleDetailDto} from '../../misc/typeguard';

/**
 * Component that encapsulates the net/gross input.
 */
@Component({
  selector: 'ucs-net-gross-input',
  templateUrl: './net-gross-input.component.html',
  styleUrls: ['./net-gross-input.component.scss']
})
export class NetGrossInputComponent implements OnInit, OnChanges {
  @Input() vehicleOrOfferDto: VehicleDetailDto | OfferDto;
  @Input() currency: Currency;
  @Input() isUAOffer = false;
  @Input() netPriceValidationError: string;
  @Input() grossPriceValidationError: string;
  @Input() priceInputDisabled = false;
  @Input() hideGrossPrice = false;
  @Input() id: string;
  @Input() scope: 'VEHICLE' | 'OFFER';
  @Input() sectionScope: AppScope;
  @Input() headingTranslationKey: string;
  @Input() showVatNovaIcons: boolean;
  @Input() grossPriceInput: number;
  @Output() grossPriceChange = new EventEmitter();
  @Output() netPriceChange = new EventEmitter();
  // Use different eventEmitter for other components in order to avoid redundant price calculation
  @Output() backGrossPriceChange = new EventEmitter();
  @Output() backNetPriceChange = new EventEmitter();
  @Output() netOrGross = new EventEmitter<string>();
  @Output() inputErrorOutput = new EventEmitter<boolean>();
  @Output() enterOnInputElement = new EventEmitter();
  netPriceValue: number;
  grossPriceValue: number;
  userState: State;
  isNovaInfoGrossPriceEnabled: boolean;
  isMinimumPurchasePriceCalculationFeatureActive: boolean;
  currentOffer: OfferDto;
  // Temp toggle
  showPPMV = false;
  extraTaxAmount: number;
  inputError = false;
  isEnforcedAuction = false;
  private touched: boolean;

  userFocus($event) {
    this.netOrGross.emit($event);
    this.touched = true;
  }

  @Input()
  get netPrice() {
    return this.netPriceValue;
  }

  set netPrice(val) {
    if (this.netPriceValue !== val) {
      this.netPriceValue = val;
      if (this.touched){
        this.netPriceChange.emit(this.netPrice);
      }
    }
  }

  @Input()
  get grossPrice() {
    return this.grossPriceValue;
  }

  set grossPrice(val) {
    if (this.grossPriceValue !== val) {
      this.grossPriceValue = val;

      if(('' + this.grossPriceValue).match('^[0-9]{1,12}$') === null) {
        this.inputErrorOutput.emit(true);
      }

      this.inputError = !!this.extraTaxAmount && !(this.grossPriceValue >= this.extraTaxAmount);
      this.inputErrorOutput.emit(this.inputError);

      if(this.touched){
        this.grossPriceChange.emit(this.grossPrice);
      }
    }
  }

  constructor(private taxService: TaxService, private store: Store<fromRoot.AppState>,
              private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit() {
    // check if extraTaxAmount is necessary
    this.checkExtraTaxAmount();

    this.checkMinimumPurchasePriceCalculationFeatureActive();
    this.grossPriceChange
      .pipe(
        debounceTime(200),
        filter(() => this.grossPrice === 0 || !!this.grossPriceValue),
      )
      .subscribe(() => {
        this.backGrossPriceChange.emit(this.grossPrice); // update net price in other components
        this.taxService.getNetPriceWithoutExtraTax(this.grossPriceValue, this.getVatTypeFromVehicleOrOffer(),
          this.getExtraTaxFromVehicleOrOffer()?.paid ? 0 : this.getExtraTaxFromVehicleOrOffer().rate, this.extraTaxAmount, this.vehicleOrOfferDto.country, this.getChannelFromVehicleOrOffer())
          .subscribe(netValue => {
            this.netPriceValue = netValue;
            this.backNetPriceChange.emit(this.netPriceValue);
          });
      });

    this.netPriceChange
      .pipe(
        debounceTime(200),
        filter(() => this.netPriceValue === 0 || !!this.netPriceValue),
      )
      .subscribe(() => {
        this.backNetPriceChange.emit(this.netPrice); // update net price in other components

        // in case of OFFER we have a performance optimization to check
        // if backend calculation is necessary
        if (this.scope === 'OFFER') {
          this.calculateAndSetNewGrossPrice();
        } else {
          this.sendBackendGrossPriceCall();
        }
      });

    const featureObservable = this.systemSettingsService
      .isSystemFeatureActivatedForChannel('SHOW_NOVA_INFO_GROSS_PRICE_TO_FOREIGN_DEALERS', this.getChannelFromVehicleOrOffer())
      .pipe(tap(isFeatureEnabled => this.isNovaInfoGrossPriceEnabled = isFeatureEnabled));
    const userStoreObservable = this.store.select(fromRoot.getUserState)
      .pipe(tap(userState => this.userState = userState));


    combineLatest([featureObservable, userStoreObservable])
      .subscribe(([isFeatureEnabled, userState]) => {
        this.isNovaInfoGrossPriceEnabled = isFeatureEnabled;
        this.userState = userState;
        this.checkAndSetHideGrossPrice();
        // Calculate gross price only if not contained and gross input is visible
        if (!this.grossPrice && this.grossPrice !== 0 && !this.hideGrossPrice) {
          // trigger initial calculation of grossPrice
          this.netPriceChange.emit(this.netPrice);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkExtraTaxAmount();
    this.checkAndSetHideGrossPrice();
  }

  checkExtraTaxAmount() {
    if (isVehicleDetailDto(this.vehicleOrOfferDto)) {
      this.showPPMV = this.vehicleOrOfferDto.country === 'HR' && this.vehicleOrOfferDto.extraTax.paid;
      this.extraTaxAmount = this.vehicleOrOfferDto.extraTax.amount;
    }

    if (isAuctionOfferDto(this.vehicleOrOfferDto) || isBuyNowOfferDto(this.vehicleOrOfferDto)) {
      this.showPPMV = this.vehicleOrOfferDto.country === 'HR' && (<VehicleItemBaseDto>this.vehicleOrOfferDto.items[0]).extraTax.paid;
      this.extraTaxAmount = (<VehicleItemBaseDto>this.vehicleOrOfferDto.items[0]).extraTax.amount;
      this.isEnforcedAuction = this.vehicleOrOfferDto.offerType.data === 'ENFORCED_AUCTION';
    }
  }

  calculateAndSetNewGrossPrice() {
    this.store.select(fromRoot.getOffers)
      .pipe(first())
      .subscribe(offers => {
        if (!!offers) {
          this.currentOffer = offers['content'].find(offer => offer.id === this.vehicleOrOfferDto.id);
        }
      });

    if (!!this.currentOffer && isAuctionOfferDto(this.currentOffer) && !!this.currentOffer.minimumBid) {
      // calculate new gross price if netPrice != minimumBid (e.g.: user input)
      if (this.netPrice !== this.currentOffer.minimumBid.net) {
        this.sendBackendGrossPriceCall();
        // set correct backend price at frontend
      } else if (this.currentOffer.minimumBid.gross !== this.grossPriceValue) {
        this.grossPriceValue = this.currentOffer.minimumBid.gross;
      }
    } else {
      this.sendBackendGrossPriceCall();
    }
  }

  sendBackendGrossPriceCall() {
    this.taxService.getGrossPriceWithExtraTax(this.netPrice, this.getVatTypeFromVehicleOrOffer(),
      this.getExtraTaxFromVehicleOrOffer()?.paid ? 0 : this.getExtraTaxFromVehicleOrOffer().rate, this.extraTaxAmount, this.vehicleOrOfferDto.country, this.getChannelFromVehicleOrOffer()
    ).subscribe(grossValue => {
      this.grossPriceValue = grossValue;
      this.backGrossPriceChange.emit(this.grossPriceValue);
    });
  }

  checkAndSetHideGrossPrice(): void {
    /**
     * 1. called from maintenance area -> show gross
     * 2. nationalSale AT <-> AT | SI <-> SI -> show gross
     * 3. isNovaInfoGrossPriceEnabled (VGRD DE see at gross price)
     */
    if (this.sectionScope === 'SALES_MAINTENANCE') {
      this.hideGrossPrice = false;
    } else if (this.vehicleOrOfferDto.country === 'AT') {
      this.hideGrossPrice = [undefined, null].includes(this.getExtraTaxFromVehicleOrOffer()?.paid) ||
          // if novaPaid is not set or novaPaid is false and extraTaxRate is not set, hide gross price field
          (this.getExtraTaxFromVehicleOrOffer()?.paid === false && [undefined, null].includes(this.getExtraTaxFromVehicleOrOffer()?.rate)) ||
          // Hide gross price for foreign users (if respective feature enabled)
          (!this.isNovaInfoGrossPriceEnabled);
    }
  }

  checkMinimumPurchasePriceCalculationFeatureActive(): void {
    this.systemSettingsService
      .isSystemFeatureActivatedForChannel('MINIMUM_PURCHASE_PRICE_CALCULATION', this.getChannelFromVehicleOrOffer())
      .subscribe(isFeatureActive => this.isMinimumPurchasePriceCalculationFeatureActive = isFeatureActive);

  }

  getChannelFromVehicleOrOffer(): DistributionChannel {
    if(isVehicleDetailDto(this.vehicleOrOfferDto)) {
      return (this.vehicleOrOfferDto as VehicleDetailDto).channel;
    }
    return (this.vehicleOrOfferDto as OfferDto).channel.data;
  }

  getVatTypeFromVehicleOrOffer(): VatType {
    if(isVehicleDetailDto(this.vehicleOrOfferDto)) {
      return (this.vehicleOrOfferDto as VehicleDetailDto).vatType.data;
    }
    return ((this.vehicleOrOfferDto as OfferDto).items[0] as VehicleItemBaseDto).vatType.data;
  }

  getExtraTaxFromVehicleOrOffer(): ExtraTaxDto {
    if(isVehicleDetailDto(this.vehicleOrOfferDto)) {
      return (this.vehicleOrOfferDto as VehicleDetailDto).extraTax;
    }
    return ((this.vehicleOrOfferDto as OfferDto).items[0]as VehicleItemBaseDto).extraTax;
  }
}
