import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'ucs-page-size',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './page-size.component.html',
  styleUrl: './page-size.component.scss'
})
export class PageSizeComponent {
  @Input() pageable: Pageable;
  @Output() paginationRefresh = new EventEmitter();

  paginationDropdownStates = [25, 50, 75, 100];

  /**
   * selects state of dropdown to manage max number of vehicles per page
   * @param {number} state
   */
  selectPaginationDropdownState(state: number) {
    this.pageable.pageSize = state;
    this.pageable.pageNumber = 0;

    this.paginationRefresh.emit();
  }
}
