import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

/**
 * This component encapsulates a ngx-bootstrap typehead-toggle-button with a label
 */
@Component({
  selector: 'ucs-search-toggle-button',
  templateUrl: './search-toggle-button.component.html',
  styleUrls: ['./search-toggle-button.component.scss']
})
export class SearchToggleButtonComponent implements OnInit {

  @Input() label: string;
  // left label must always be either 'SINGLE' or 'AUCTIONS'
  @Input() labelLeft: string;
  // right label must always be either 'BUNDLE' or 'BUYNOW'
  @Input() labelRight: string;
  @Input() activeLeft: boolean;
  @Input() activeRight: boolean;
  @Input() disabled: boolean;
  @Output() leftClicked = new EventEmitter<any>();
  @Output() rightClicked = new EventEmitter<any>();

  disabledLeft: boolean;
  disabledRight: boolean;

  ngOnInit(): void {
    this.disabledLeft = this.activeLeft;
    this.disabledRight = this.activeRight;
  }

  /**
   * handles click-events of the left button
   * @param {string} label
   */
  clickLeft(label: string) {
    switch (label) {
    case 'SingleBundle':
      this.activeLeft = true;
      this.activeRight = false;
      this.disabledLeft = true;
      this.disabledRight = false;
      break;
    case 'AuctionBuyNow':
      if (!this.disabledLeft) {
        this.activeLeft = !this.activeLeft;
        this.disabledRight = !this.activeLeft;
      }
      this.disabledLeft = false;
      break;
    case 'Typ':
      this.activeLeft = true;
      this.activeRight = false;
      break;
    default:
    }
    this.leftClicked.emit(this.activeLeft);
  }

  /**
   * handles click-events of the right button
   * @param {string} label
   */
  clickRight(label: string) {
    switch (label) {
    case 'SingleBundle':
      this.activeLeft = false;
      this.activeRight = true;
      this.disabledLeft = false;
      this.disabledRight = true;
      break;
    case 'AuctionBuyNow':
      if (!this.disabledRight) {
        this.activeRight = !this.activeRight;
      }
      this.disabledLeft = !this.activeRight;
      this.disabledRight = false;
      break;
    case 'Typ':
      this.activeRight = true;
      this.activeLeft = false;
      break;
    default:
    }
    this.rightClicked.emit(this.activeRight);
  }

  /**
   * This function is only used for 'AuctionBuyNow'
   * Clears the selection for Bundle
   */
  selectForBundle() {
    this.activeLeft = false;
    this.activeRight = true;
    this.disabledLeft = true;
    this.disabledRight = true;
  }

  /**
   * This function is only used for 'AuctionBuyNow'
   * Clears the selection for Single
   */
  selectForSingle() {
    this.activeLeft = true;
    this.activeRight = true;
    this.disabledLeft = false;
    this.disabledRight = false;
  }

  /**
   * resets this component to initial values
   */
  clear() {
    switch (this.label) {
    case 'SingleBundle':
      this.activeLeft = true;
      this.activeRight = false;
      this.disabledLeft = true;
      this.disabledRight = false;
      break;
    case 'AuctionBuyNow':
      this.activeLeft = true;
      this.activeRight = true;
      this.disabledLeft = false;
      this.disabledRight = false;
      break;
    default:
      console.error('toggle label unknown', this.label);
    }
  }


  /**
   * Allows overriding the current in toggle buttons
   * (intended to be used by parent component)
   */
  overrideSelection(object: any) {
    if (!object) {
      return;
    }
    switch (object) {
    case 'SINGLE':
      this.activeLeft = true;
      this.activeRight = false;
      this.disabledLeft = true;
      this.disabledRight = false;
      break;
    case 'BUNDLE':
      this.activeLeft = false;
      this.activeRight = true;
      this.disabledLeft = false;
      this.disabledRight = true;
      break;
    case 'BUYNOW':
      this.activeLeft = false;
      this.activeRight = true;
      this.disabledLeft = false;
      this.disabledRight = true;
      break;
    case 'AUCTION':
      this.activeLeft = true;
      this.activeRight = false;
      this.disabledLeft = true;
      this.disabledRight = false;
      break;
    case 'UNDEFINED':
      this.activeLeft = true;
      this.activeRight = true;
      this.disabledLeft = false;
      this.disabledRight = false;
      break;
    default:
      console.error('unknown value', object);
    }
  }
}
