import {Component, EventEmitter, HostBinding, Output} from '@angular/core';
import {ToastService} from '../../service/toast.service';

@Component({
  selector: 'ucs-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})

export class ToastComponent {
  @HostBinding('class.ngb-toasts') toast = true;

  @Output() saveBeforeUpdate = new EventEmitter<void>();
  @Output() continueWithoutSave = new EventEmitter<void>();

  constructor(public toastService: ToastService) {}

  public save(toast) {
    this.toastService.remove(toast);
    this.saveBeforeUpdate.emit();
  }

  public dismiss(toast) {
    this.toastService.remove(toast);
    this.continueWithoutSave.emit();
  }
}
