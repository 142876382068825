import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';

/**
 * Service to handle approval from user
 */
@Injectable()
export class ApprovalService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) {  }

  approve(objectReferenceId: number, channel: DistributionChannel): Observable<ApprovalDto> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put<ApprovalDto>(
      this.config.salesApiUrl + '/approvals/OFFER_THERMAL_WINDOW/' + objectReferenceId, JSON.stringify(channel),
      httpOptions);
  }
}
