import {Observable, Subject} from 'rxjs';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/app.reducers';
import {takeUntil} from 'rxjs/operators';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';

/**
 * This service handles user-related http calls to the respective REST endpoints
 */
@Injectable()
export class UserService implements OnDestroy {
  static readonly CATEGORY_FILTER = 'ucs-category-filter-';
  static readonly CURRENT_CATEGORY = 'ucs-current-category-';
  static readonly CURRENT_LANGUAGE = 'ucs-language-';
  static readonly MAINTENANCE_VEHICLE_SORTING = 'ucs-maintenance-vehicle-sorting-';
  userId: number;
  private unsubscribe: Subject<void> = new Subject<void>();
  private noCacheHeaders = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
  });

  constructor(private http: HttpClient, private store: Store<fromRoot.AppState>,
              @Inject(APP_CONFIG) private config: AppConfig) {
    this.store.select(fromRoot.getUserInfo)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userInfo => {
        if (userInfo) {
          this.userId = userInfo.id;
        }
      });
  }

  /**
   * Fetches all saved searches for the given user
   * @returns {Observable<any>}: saved searches in a Page object
   */
  getSavedSearches(): Observable<any> {
    return this.http.get<any>(this.config.salesApiUrl + '/users/' + this.userId + '/searches', {headers: this.noCacheHeaders});
  }

  /**
   * Add a saved search for the given user
   * @returns {Observable<number>}: the id of the saved search
   */
  addSavedSearch(search: UserVehicleSearchParamsRequestDto): void {
    this.http.post<number>(this.config.salesApiUrl + '/users/' + this.userId + '/searches', search).subscribe();
  }

  /**
   * Update a user's saved search
   */
  updateSavedSearch(search: UserVehicleSearchParamsRequestDto, searchId: number): void {
    this.http.put<void>(this.config.salesApiUrl + '/users/' + this.userId + '/searches/' + searchId, search).subscribe();
  }

  /**
   * Update the General Terms & Conditions approval
   */
  updateGtc(gtcId: number): Observable<UserInfoDto> {
    return this.http.put<UserInfoDto>(this.config.salesApiUrl + '/users/' + this.userId + '/gtcs/' + gtcId, null);
  }

  /**
   * Delete a saved search for a user
   */
  deleteSavedSearch(searchId: number): void {
    this.http.delete<void>(this.config.salesApiUrl + '/users/' + this.userId + '/searches/' + searchId).subscribe();
  }

  /**
   * Get all available countries
   */
  getCountries() {
    return this.http.get<CountriesDto>(this.config.salesApiUrl + '/countries');
  }

  /**
   * Return a list of substitutions for this user.
   */
  getSubstitutions(): Observable<UserSubstituteInformationDto[]> {
    return this.http.get<UserSubstituteInformationDto[]>(this.config.salesApiUrl + '/users/substitutions');
  }

  /**
   * Activates the current user in substitutions list per default and returns the full list of substitutions
   * including the activated current user substitution.
   */
  activateDefaultSubstitutionBehavior(): Observable<UserSubstituteInformationDto[]> {
    return this.http.post<UserSubstituteInformationDto[]>(this.config.salesApiUrl + '/users/substitutions', null);
  }

  /**
   * Update the list of substitutions with newly selected substitution IDs and get a new list of substitution user IDs.
   * @param substitutionUserIDs The IDs of the selected substitution users.
   */
  updateSubstitutions(substitutionUserIDs: number[]): Observable<UserSubstituteInformationDto[]> {
    return this.http.put<UserSubstituteInformationDto[]>(this.config.salesApiUrl + '/users/substitutions', substitutionUserIDs);
  }

  /**
   * Store the list of category filter combination in the local storage
   * @param categoryFilter The category and filter to store in the local storage
   */
  storeCategoryFilter(categoryFilter: CategoryFilterDto[], channel: string): void {
    localStorage.setItem(UserService.CATEGORY_FILTER + this.userId + '-' + channel, JSON.stringify(categoryFilter));
  }

  /**
   * Store the current selected category in the local storage
   * @param currentCategory The category to store in the local storage
   */
  storeCurrentCategory(currentCategory: Section): void {
    localStorage.setItem(UserService.CURRENT_CATEGORY + this.userId, JSON.stringify(currentCategory));
  }

  /**
   * Store the selected vehicle sorting from the categories in the local storage
   * @param maintenanceSorting The vehicle sorting to store in the local storage
   */
  storeMaintenanceVehicleSorting(maintenanceSorting: MaintenanceSortingDto[], channel: string): void {
    localStorage.setItem(UserService.MAINTENANCE_VEHICLE_SORTING + this.userId + '-' + channel,
      JSON.stringify(maintenanceSorting));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Read the substitutions from the local storage. On error, it returns an empty array.
   */
}
