import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import * as OfferActions from '../../store/offer/offer.actions';

/**
 * The buy button and bid input field and button
 * @Input offerType OfferType indicating if the buy-now button or the bid button should be displayed
 */
@Component({
  selector: 'ucs-bid-buy',
  templateUrl: './bid-buy.component.html',
  styleUrls: ['./bid-buy.component.scss']
})
export class BidBuyComponent implements OnDestroy, OnChanges {

  @Input() offerId: number;
  @Input() offerType: OfferType;
  @Input() inputPrice: number;
  @Input() disabled: boolean;
  @Input() thermalWindowAffected = false;

  modalTarget: string;
  thermalWindowModalTarget: string;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private store: Store<fromRoot.AppState>) {
  }

  buttonClicked() {
    this.store.dispatch(new OfferActions.UpdateAuctionBidAction(this.inputPrice / 100));
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'offerId' || propName === 'thermalWindowModalTarget') {
        this.modalTarget = ['AUCTION', 'ENFORCED_AUCTION'].includes(this.offerType)
          ? '#confirmBidModal' + this.offerId : '#confirmBuyNowModal' + this.offerId;
        this.thermalWindowModalTarget = '#confirmThermalWindowModal' + this.offerId;
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}

