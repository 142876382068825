import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import * as VehicleMaintenanceSearchReducers from '../../store/vehicle-maintenance/vehicle-maintenance.reducers';
import {
  UpdatedVehicleMaintenanceSearchSelectedFiltersAction,
  UpdatePageSettingsPageChannel
} from '../../store/vehicle-maintenance/vehicle-maintenance.actions';

@Component({
  selector: 'ucs-maintenance-search-filter',
  templateUrl: './maintenance-search-filter.component.html',
  styleUrls: ['./maintenance-search-filter.component.scss']
})
export class MaintenanceSearchFilterComponent implements OnInit, OnDestroy {
  filters: SearchFilter[];
  vehicleMaintenanceSearchState: VehicleMaintenanceSearchReducers.State;
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private store: Store<fromRoot.AppState>) {
  }

  /**
   * On init check for local storage support and selected filter from local storage if present.
   */
  ngOnInit(): void {
    this.store.select(fromRoot.getVehicleMaintenanceState)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(vehicleMaintenanceSearchState => {
        this.vehicleMaintenanceSearchState = vehicleMaintenanceSearchState;
        this.filters = [];
        if (this.vehicleMaintenanceSearchState.searchFilterConfig) {
          this.filters = vehicleMaintenanceSearchState.searchFilterConfig.filterConfig[vehicleMaintenanceSearchState.activeCategory];
        }
      }
      );
  }

  /**
   * Toggles the selectedFilter and triggers a store update.
   * @param selectedFilter The filter to toggle.
   */
  toggleSelectedFilters(selectedFilter: SearchFilter) {
    const index = this.vehicleMaintenanceSearchState.selectedFilters.indexOf(selectedFilter);
    if (index === -1) {
      this.vehicleMaintenanceSearchState.selectedFilters.push(selectedFilter);
    } else {
      this.vehicleMaintenanceSearchState.selectedFilters.splice(index, 1);
    }

    // we need to reset page when changing filters (as new results will always start with page 0)
    if (this.vehicleMaintenanceSearchState.pageSettings.page !== 0) {
      this.store.dispatch(new UpdatePageSettingsPageChannel(0, this.vehicleMaintenanceSearchState.pageSettings.channel));
    }

    this.store.dispatch(
      new UpdatedVehicleMaintenanceSearchSelectedFiltersAction(
        this.vehicleMaintenanceSearchState.selectedFilters,
        this.vehicleMaintenanceSearchState.pageSettings.channel));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
