import {Directive, ElementRef, Inject, Renderer2} from '@angular/core';
import {APP_CONFIG} from '../inject-tokens';
import {AppConfig} from '../../model/app-config.model';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[data-cy]'
})
export class DataCyDirective {
  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(APP_CONFIG) private config: AppConfig) {
    if (this.config.production) {
      renderer.removeAttribute(el.nativeElement, 'data-cy');
    }
  }
}
