import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FilterBullet} from '../../../model/filter-bullet.model';
import {TranslateService} from '@ngx-translate/core';

/**
 * This component hosts one filter bullet
 */
@Component({
  selector: 'ucs-filter-bullet',
  templateUrl: './filter-bullet.component.html',
  styleUrls: ['./filter-bullet.component.scss']
})
export class FilterBulletComponent {
    @Input() bullet: FilterBullet;
    @Output() clickDelete = new EventEmitter<FilterBullet>();

    protected readonly Number: number;

    constructor(private translation: TranslateService) {}

    /**
     * Event emitter for Parent, when deleting Bullet
     * @param bullet: FilterBullet
     */
    delete(bullet: FilterBullet): void {
      this.clickDelete.next(bullet);
    }
}
