import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../shared/store/app.reducers';
import {NotificationService} from '../../../../shared/service/notification.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {UpdateUserNotificationAction} from '../../../../shared/store/notification/notification.actions';
import {Location} from '@angular/common';
import {SystemSettingsService} from '../../../../shared/service/system-settings.service';

@Component({
  selector: 'ucs-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  @ViewChild('notificationModalTemplate', { static: true }) notificationModal: TemplateRef<any>;
  activeModalRef: NgbModalRef;
  allNotifications: NotificationDto[];
  notificationApproved = false;
  currentNotification: NotificationDto;
  currentNotificationIndex = 0;
  appScope: AppScope;

  private unsubscribe: Subject<void> = new Subject<void>();


  constructor(private store: Store<fromRoot.AppState>,
              private notificationService: NotificationService,
              private modalService: NgbModal,
              private location: Location,
              private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit(): void {

    //Init user notifications
    this.store.select(fromRoot.getUserInfo)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userInfo => {
        this.defineAppScope(userInfo);
        if (this.location.path().startsWith('/maintenance')) {
          this.appScope = 'SALES_MAINTENANCE';
        }

        this.store.dispatch(new UpdateUserNotificationAction(this.appScope));
      });

    this.store.select(fromRoot.getUserNotifications)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(notification => {
        this.allNotifications = notification;
        if (!this.allNotifications || this.allNotifications.length === 0) {
          return;
        }
        this.currentNotification = this.allNotifications[this.currentNotificationIndex];
        this.displayCurrentNotification();
      });
  }


  private defineAppScope(userInfo: UserInfoDto) {
    let seller = false;
    let buyer = false;
    for (let element of userInfo.roles) {
      if (element.includes('SELLER')) {
        seller = true;
      }
      if (element.includes('BUYER')) {
        buyer = true;
      }
    }

    if (!buyer && seller) {
      this.appScope = 'SALES_MAINTENANCE';
    } else {
      this.appScope = 'SALES_STORE';
    }
  }

  toggleNotificationApproved() {
    this.notificationApproved = !this.notificationApproved;
  }

  approveNotification() {
    this.notificationService.approveUserNotification(this.currentNotification.id)
      .subscribe(() => {
        this.activeModalRef.close();
        this.showNextNotification();
      });
    this.notificationApproved = false;
  }

  showNextNotification() {
    this.currentNotificationIndex += 1;
    if (this.allNotifications?.length > 0 && this.currentNotificationIndex < this.allNotifications.length) {
      this.currentNotification = this.allNotifications[this.currentNotificationIndex];
      this.displayCurrentNotification();
    } else {
      this.currentNotificationIndex = 0;
    }
  }

  displayCurrentNotification() {
    this.activeModalRef = this.modalService.open(this.notificationModal, {
      backdrop: 'static',
      keyboard: false
    });
  }

  /**
   * Unsubscribe from Observables when component is destroyed to prevent memory leaks
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
