<!-- Create templates for models and variants multiselect to be used in different layouts
by this there is only one instance per multiselect and they reference the same data -->
<ng-template #brandMultiselect>
  <ucs-search-multi-select
    data-cy="search-filter-dropdown-brand"
    [ngClass]="{'horizontal-select': horizontal}"
    [id]="'search-filter.brand'"
    [placeholder]="'search-dropdown.placeholder' | translate"
    [label]="'search-filter.brand' | translate"
    [selectionDisabled]="false"
    (onSelected)="onSelectBrand($event)"
    (onInput)="onSelectBrand($event)"
    [selectedElement]="selectedAggregatedBrand"
    [states]="getModelSelection()?.brands"
    [multiselect]="false">
  </ucs-search-multi-select>
</ng-template>

<ng-template #modelMultiselect>
  <ucs-search-multi-select #modelSelectRef class="model-multiselect"
    data-cy="search-filter-dropdown-model"
    [ngClass]="{'horizontal-select': horizontal}"
    [id]="'search-filter.model'"
    [placeholder]="'search-dropdown.placeholder' | translate"
    [label]="'search-filter.model' | translate"
    [states]="getModelSelection()?.models"
    [selectionDisabled]="disableModelSelection"
    [modelOrVariantSearchParams]="getModelsFromSearchParams()"
    (onSelected)="onSelectModel($event)"
    [selectedModel]="aggregatedModelStates[0]?.text"
    [floatUi]="modelPopover"
    [placement]="NgxFloatUiPlacements.BOTTOM"
    [showTrigger]="NgxFloatUiTriggers.hover"
    [disabled]="currentModelsValue?.at(0).length < 25 || currentModelsValue.length > 1">

  </ucs-search-multi-select>
  <float-ui-content #modelPopover>
    {{currentModelsValue}}
  </float-ui-content>
</ng-template>

<ng-template #variantMultiselect>
  <ucs-search-multi-select #variantSelectRef class="model-multiselect"
    data-cy="search-filter-dropdown-variant"
    [ngClass]="{'horizontal-select': horizontal}"
    [id]="'search-filter.modelVariant'"
    [placeholder]="'search-dropdown.placeholder' | translate"
    [label]="'search-filter.variant' | translate"
    [states]="getModelSelection()?.modelVariants"
    [selectionDisabled]="disableModelVariantSelection"
    [modelOrVariantSearchParams]="getVariantsFromSearchParams()"
    (onSelected)="onSelectModelVariant($event)"
    [selectedModelVariant]="aggregatedModelVariantStates[0]?.text"
    [floatUi]="modelVariantPopover"
    [placement]="NgxFloatUiPlacements.BOTTOM"
    [showTrigger]="NgxFloatUiTriggers.hover"
    [disabled]="currentModelVariantsValue?.at(0).length < 25 || currentModelVariantsValue.length > 1">>
  </ucs-search-multi-select>
  <float-ui-content #modelVariantPopover>
    {{currentModelVariantsValue}}
  </float-ui-content>
</ng-template>
<!-- END templates -->

@if (currentOfferVariantIndex > 1) {
  <div class="row mt-3">
    <div [ngClass]="{'col-9': !horizontal, 'col-10': horizontal, 'text icon-height':true}">
      <strong>{{'search-filter.vehicle' | translate}}</strong>
    </div>
  </div>
}

<!-- numberOfOfferVariants array serves as an iterator to be used with *ngFor,
it does not contain data, vehicle search information is only stored using VehicleOfferSearchParams -->
@if (numberOfOfferVariants.length > 1) {
  @for (unused of numberOfOfferVariants; track unused; let i = $index; let isFirst = $first; let isLast = $last) {
    <div>
      @if (!isFirst) {
        <hr class="hr">
      }
      <div class="row mt-1">
        @if (!isLast) {
        <div [ngClass]="{'col-9':!horizontal,
                         'col-10': horizontal,
                         'text': currentOfferVariantIndex === (i+1),
                         'text-alternate': currentOfferVariantIndex !== (i+1),
                         'text-truncate icon-height': true}">
            <a (click)="onSwitchVehicle(i+1)">{{ vehicleSummaries[i] }}</a>
          </div>
          <div [ngClass]="{'col-3': !horizontal, 'col-2 text-end': horizontal}">
            <a (click)="onClearVehicle(i+1)">
              <ucs-icon [shape]="'cancel'" [class]="'blue'"></ucs-icon>
            </a>
          </div>
        }
      </div>
    </div>
  }
}

<!-- MULTISELECT initial set of dropdowns for a multi offer variant (vehicle) selection -->
@if (numberOfOfferVariants.length <= maxOfferVariants) {
  <div [ngClass]="{'row mt-3': horizontal}">
    <div [ngClass]="{'col-md-4 col-sm-12 d-md-block': horizontal}">
      <ng-container *ngTemplateOutlet="brandMultiselect"></ng-container>
    </div>
    <div [ngClass]="{'col-md-4 col-sm-12 d-md-block': horizontal}">
      <ng-container *ngTemplateOutlet="modelMultiselect"></ng-container>
    </div>
    <div [ngClass]="{'col-md-4 col-sm-12 d-md-block': horizontal}">
      <ng-container *ngTemplateOutlet="variantMultiselect"></ng-container>
    </div>
  </div>
}

<!-- do not allow adding further vehicles to the search
WHEN maxOfferVariants is reached OR
WHEN no value of brand has been selected yet -->
@if (numberOfOfferVariants.length < maxOfferVariants && currentBrandValue) {
  <div class="row mt-3">
    <div class="col-12">
      <button class="button-borderless text-start" (click)="onAddVehicle()">
        <span>
          <ucs-icon [shape]="'plus'" [class]="'blue'"></ucs-icon>
          {{'search-filter.further-vehicles' | translate}}
        </span>
      </button>
    </div>
  </div>
}
