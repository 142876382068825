<div class="test">
  @for (toast of toastService.toasts; track toast) {
    <ngb-toast
      [header]="toast.text"
      [autohide]="false"
      (hide)="toastService.remove(toast)">
      <div>
        <strong>{{'vehicle-maintenance.detail.toast.unsaved-data-info' | translate}}</strong>
      </div>
      <button class="btn btn-secondary"
      (click)="save(toast)">{{'vehicle-maintenance.detail.toast.save-button' | translate}}</button>
      <button class="btn btn-secondary"
        (click)="dismiss(toast)">{{'vehicle-maintenance.detail.toast.continue-without-save-button' | translate}}
      </button>
    </ngb-toast>
  }
</div>
