import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SystemSettingsService} from '../../../service/system-settings.service';
import * as fromRoot from '../../../store/app.reducers';
import {Store} from '@ngrx/store';
import {MaintenanceScope} from '../../../model/maintenance-scope';

/**
 * The footer of a tile. This is only displayed at some positions in some offer states.
 */
@Component({
  selector: 'ucs-enforced-auction-footer',
  templateUrl: './enforced-auction-footer.component.html',
  styleUrls: ['./enforced-auction-footer.component.scss']
})
export class EnforcedAuctionFooterComponent implements OnInit, OnDestroy {
  @Input() vehicle: VehicleBaseDto | VehicleDetailDto;
  @Input() displayLogo = false;
  @Input() enforcedAuctionData: EnforcedAuctionVehicleDataDto;
  isAdmin: boolean = false;
  piaAdminOnlyEnforcedItemStatus: EnforcedAuctionItemStatus[] = ['LOCKED','MANUALLY_SELECTED','MANUALLY_REMOVED','AUTO_SELECTED', 'NOT_SELECTED', 'ERROR','VALIDATION_ERROR'];
  userInfo: UserInfoDto;
  currentScope: MaintenanceScope;
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private store: Store<fromRoot.AppState>,
              private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit() {
    this.store.select(fromRoot.getMaintenanceScope).pipe(takeUntil(this.unsubscribe))
      .subscribe(scope => {
        this.currentScope = scope;
      });

    this.systemSettingsService.getSystemFeatureSettingForChannel(
      'ENFORCED_AUCTION.maintenance', this.vehicle.channel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        if (value && this.vehicle.channel === 'PIA') {
          let parsedEnforcedAuctionMaintenanceSettings: EnforcedAuctionMaintenanceSettings;
          parsedEnforcedAuctionMaintenanceSettings = JSON.parse(value);
          this.isAdmin = parsedEnforcedAuctionMaintenanceSettings.enabled
            && parsedEnforcedAuctionMaintenanceSettings.canAdminister;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getCssLogoClasses(channel: DistributionChannel): string {
    switch (channel) {
    case 'ALL_UC':
      return 'all ms-4';
    case 'DIN_BIL':
      return 'dinbil ms-4';
    case 'PB':
      return 'pb';
    case 'PIA':
      return 'pia ms-4';
    case 'VGRX':
      return 'vgrx ms-4';
    default:
      return '';
    }
  }
}
