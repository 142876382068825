<!-- model, color and upholstery prices -->

@if (showGeneralPrice && vehicleDetails) {
  <div class="font-heading font-weight-bold mb-2 mt-2">
    {{ 'offer-detail.general-price' | translate }}
  </div>
  <div class="row justify-content-between">
    <div class="col-12 ms-2">
      @if (showModelPrice) {
        <ucs-label-price [label]="'offer-detail.model-price' | translate"
          [currency]="vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text"
        [price]="vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.net"></ucs-label-price>
      }
      @if (showColorPrice) {
        <ucs-label-price [label]="'offer-detail.color-price' | translate"
          [currency]="vehicleDetails.priceInformation['NEW_COLOR_PRICE']?.currency?.text"
        [price]="vehicleDetails.priceInformation['NEW_COLOR_PRICE']?.net"></ucs-label-price>
      }
      @if (showUpholsteryPrice) {
        <ucs-label-price [label]="'offer-detail.upholstery-price' | translate"
          [currency]="vehicleDetails.priceInformation['NEW_UPHOLSTERY_PRICE']?.currency?.text"
        [price]="vehicleDetails.priceInformation['NEW_UPHOLSTERY_PRICE']?.net"></ucs-label-price>
      }
    </div>
  </div>
}

<!-- additional equipment prices (de: Mehrausstattungen) -->

@if (equipmentWithPrice && equipmentWithPrice.length > 0) {
  <div class="font-heading font-weight-bold mb-2">
    {{ 'offer-detail.equipment-price' | translate }}
  </div>
  @for (equipment of equipmentWithPrice; track equipment; let i = $index) {
    <div class="row justify-content-between">
      <div class="col-12 ms-2">
        @if ((equipment.price.net !== null && equipment.price.gross !== null &&
          equipment.price.currency !== null )) {
          <div>
            <ucs-label-price [label]="equipment.code + ' - ' + equipment.text" [currency]="equipment.price.currency.text"
            [price]="equipment.price.net" [tooltipOverLabel]="equipment.detailText ? tooltipEquip : undefined"></ucs-label-price>
          </div>
        } @else {
          <ucs-label-price [label]="equipment.code + ' - ' + equipment.text" [currency]="equipment.price.currency.text"
            [price]="equipment.price.net" [tooltipOverLabel]="equipment.detailText ? tooltipEquip : undefined"
          [showNetPriceUnavailableInfo]="true"></ucs-label-price>
        }
        <ng-template #showNetPriceUnavailableInfo>
          <ucs-label-price [label]="equipment.code + ' - ' + equipment.text" [currency]="equipment.price.currency.text"
            [price]="equipment.price.net" [tooltipOverLabel]="equipment.detailText ? tooltipEquip : undefined"
          [showNetPriceUnavailableInfo]="true"></ucs-label-price>
        </ng-template>
        <float-ui-content #tooltipEquip>
          @if (equipment.detailText) {
            <div>
              @for (equip of equipment.detailText.split('/#'); track equip) {
                <div class="tooltip-styling">
                  {{equip}}<br>
                </div>
              }
            </div>
          }
        </float-ui-content>
      </div>
    </div>
  }
  <div class="row justify-content-between font-heading font-weight-bold">
    <div class="col-12 ms-2">
      <ucs-label-price [label]="'offer-detail.equipment-sum' | translate"
        [currency]="equipmentWithPrice[0].price.currency.text"
      [price]="sumEquipmentWithNetPrice" [sumIncalculable]="sumIncalculable"></ucs-label-price>
    </div>
  </div>
}

<!-- Total price net -->

@if (showGeneralPrice || equipmentWithPrice && equipmentWithPrice.length > 0) {
  <hr class="mt-0"/>
  <div class="row justify-content-between font-heading font-weight-bold">
    <div class="col-12 ms-2">
      <ucs-label-price [label]="'offer-detail.total-price-net' | translate"
                       [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text)"
      [price]="totalPriceNet"></ucs-label-price>
    </div>
  </div>
}

<!-- NoVA rate & amount -->
<ng-container>
  @if (vehicleDetails.country === 'AT') {
    @if ((novaAmount >= 0 && !userState.isGerman)) {
      <hr class="mt-0"/>
      <div class="row justify-content-between">
        <div class="col-12 ms-2">
          <ucs-label-price
            [label]="(vehicleDetails.extraTax?.rate/10000 | percent:'1.2') + ' ' + ('offer-detail.nova' | translate)"
            [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text)"
          [price]="novaAmount"></ucs-label-price>
        </div>
      </div>
    }
    <!-- NoVA discount surcharge (de: NoVA Zu-/Abschlag) -->
    @if (!ucsIsNil(vehicleDetails?.novaDiscountSurcharge)) {
      <div class="row justify-content-between">
        <div class="col-12 ms-2">
          <ucs-label-price [label]="'offer-detail.nova-discount-surcharge' | translate"
                           [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text)"
          [price]="vehicleDetails?.novaDiscountSurcharge"></ucs-label-price>
        </div>
      </div>
    }
    <!-- NoVA subtotal (de: Zwischensumme) -->
    @if (novaSubtotal) {
      <div class="row justify-content-between font-heading font-weight-bold">
        <div class="col-12 ms-2">
          <ucs-label-price [label]="('offer-detail.nova-subtotal' | translate) + ' ' +
          (vehicleDetails.extraTax?.rate/10000 | percent:'1.2') + ' ' + ('offer-detail.nova' | translate)"
                           [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text)"
          [price]="novaSubtotal"></ucs-label-price>
        </div>
      </div>
    }
  }
</ng-container>

<!-- VAT (de: USt.) -->

@if (vatAmount) {
  <hr class="mt-0"/>
  <div class="row justify-content-between">
    <div class="col-12 ms-2">
      <ucs-label-price [label]="('offer-detail.vat' | translate) + ' ' + (vatFactor | percent)"
                       [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text)"
      [price]="vatAmount"></ucs-label-price>
    </div>
  </div>
}

<!-- price total (incl. NoVA & VAT) -->

@if (totalPrice) {
  <hr class="mt-0"/>
  <ng-container>
    @if (vehicleDetails.country === 'AT') {
      <div class="row justify-content-between font-heading font-weight-bold">
        <div class="col-12 ms-2">
          <ucs-label-price [label]="('offer-detail.total' | translate) + ' ' +
          (vehicleDetails.extraTax?.rate/10000 | percent:'1.2') + ' ' + ('offer-detail.nova' | translate) +  ' & ' +
          ('offer-detail.vat' | translate) + ' ' + (vatFactor | percent)"
                           [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          ('offer-detail.vat' | translate) + ' ' + (vatFactor | percent))"
                       [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text)"
          [price]="totalPrice" [sumIncalculable]="sumIncalculable"></ucs-label-price>
        </div>
      </div>
    } @else {
      <div class="row justify-content-between font-heading font-weight-bold">
        <div class="col-12 ms-2">
          <ucs-label-price [label]="('offer-detail.total' | translate) + ' ' +
          ('offer-detail.vat' | translate) + ' ' + (vatFactor | percent)"
                           [currency]="(equipmentWithPrice[0]?.price?.currency?.text ? equipmentWithPrice[0].price.currency.text :
          vehicleDetails.priceInformation['NEW_MODEL_PRICE']?.currency?.text)"
          [price]="totalPrice" [sumIncalculable]="sumIncalculable"></ucs-label-price>
        </div>
      </div>
    }
    <!--    do not show nova for vehicles that are not from at-->
  </ng-container>
}
