import {Inject, Injectable, OnDestroy} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/app.reducers';
import {Observable, Subject} from 'rxjs';
import {first, map, takeUntil} from 'rxjs/operators';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';

@Injectable()
export class UserSettingsService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject<void>();
  private userId: number;

  constructor(private http: HttpClient, private store: Store<fromRoot.AppState>,
              @Inject(APP_CONFIG) private config: AppConfig) {
    this.store.select(fromRoot.getUserInfo)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userInfo => {
        if (userInfo) {
          this.userId = userInfo.id;
        }
      });
  }

  /**
   * Should be used only for initializing the store with the UserSettings
   * @param userId of the user
   */
  getAllUserSettingsFromBackend(userId: number): Observable<GlobalUserSettingDto[]> {
    return this.http.get<GlobalUserSettingDto[]>(this.config.salesApiUrl + '/user-settings/' + userId);
  }

  getUserSetting(userSettingKey: string): Observable<GlobalUserSettingDto> {
    return this.store.select(fromRoot.getUserSettings).pipe(first(),
      map((settings: GlobalUserSettingDto[]) => {
        return settings.find(setting => setting.propertyKey === userSettingKey);
      }
      ));
  }

  saveUserSetting(userSetting: GlobalUserSettingDto, settingName): Observable<any> {
    return this.http.put<GlobalUserSettingDto>(
      this.config.salesApiUrl + '/user-settings/' + this.userId + '/' + settingName, userSetting);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
