<div class="container-fluid search-filter-container sidebar pb-2 pt-2">

  <ucs-simple-input
    class="input-with-image-input-form"
    [inputWithSearchImage]=true
    [inputWithInfoImage]=true
    [infoImageText]="'search-filter-finished.search-info'| translate"
    [placeholder]="'search-filter-finished.search-placeholder' | translate"
    (onInput)="onChangeSearchString($event);"
    (onKeyUpEnter)="onKeyUpEnter($event);">
  </ucs-simple-input>

  <div class="row mt-0"></div>

  <!-- Offertyp -->
  <div class="mt-3 mb-2 text">
    <strong>{{'search-filter-finished.offertyp' | translate}}</strong>
  </div>

  <!-- Single - Bundle -->
  <ucs-search-toggle-button #toggleButtonSingleBundle
                            [label]="'SingleBundle'"
                            [activeLeft]=true
                            [activeRight]=false
                            [labelLeft]="'quick-search.onlySingleVehicle' | translate"
                            [labelRight]="'quick-search.onlyPackets' | translate"
                            (rightClicked)="toggleAuctionBuyNow.toggleForBundle(); onChangeSingleBundle();"
                            (leftClicked)="toggleAuctionBuyNow.clear(); onChangeSingleBundle();">
  </ucs-search-toggle-button>

  <!-- Auction - Buy now -->
  <ucs-search-toggle-auction-buynow #toggleAuctionBuyNow
                                    (toggleChanged)="onChangeAuctionBuyNow($event)">
  </ucs-search-toggle-auction-buynow>

  <div class="row mt-3">
    <div class="col-12">
      <hr class="hr mb-0">
    </div>
  </div>

  <!-- status -->
  <div class="mt-3 mb-2 text">
    <strong>{{'search-filter-finished.status' | translate}}</strong>
  </div>

  <!-- All Purchases -->
  <div class="mt-3" disabled="true">
    <a>
      <ucs-icon (click)="allPurchasesDisabled === true || onChangeAllPurchasesSale()"
                [shape]="finishedSearchParams.allPurchases ? 'square_checked' : 'square_outline'"
                [class]="allPurchasesColor+' me-1'"></ucs-icon>
    </a>
    <span class="text text-bright me-1 ucs-input-label"
          (click)="allPurchasesDisabled === true || onChangeAllPurchasesSale()">
      {{ 'search-filter-finished.allpurchases' | translate }}</span>
  </div>

  <!-- Highest bid -->
  <div class="mt-3">
    <a>
      <ucs-icon (click)="highestBidDisabled === true || onChangeHighestBidSale()"
                [shape]="finishedSearchParams.highestBid ? 'square_checked' : 'square_outline'"
                [class]="highestBidColor+' me-1'"></ucs-icon>
    </a>
    <span class="text text-bright me-1 ucs-input-label"
          (click)="highestBidDisabled === true || onChangeHighestBidSale()">
      {{ 'search-filter-finished.highestBid' | translate }}</span>
  </div>

  <!-- Only offered -->
  <div class="mt-3 mb-3">
    <a>
      <ucs-icon (click)="onlyOfferedDisabled === true || onChangeOnlyOfferedSale()"
                [shape]="finishedSearchParams.onlyOffered ? 'square_checked' : 'square_outline'"
                [class]="onlyOfferedColor+' me-1'"></ucs-icon>
    </a>
    <span class="text text-bright me-1 ucs-input-label"
          (click)="onlyOfferedDisabled === true || onChangeOnlyOfferedSale()">
      {{ 'search-filter-finished.onlyOffered' | translate }}</span>
  </div>

  <!-- Only cancelled and copied -->
  <ng-container>
    <div class="mt-3 mb-3">
      <a>
        <ucs-icon (click)="purchaseNotReceievedDisabled === true || onChangePurchaseNotReceieved()"
                  [shape]="finishedSearchParams.purchaseNotReceived ? 'square_checked' : 'square_outline'"
                  [class]="purchaseNotReceivedColor+' me-1'"></ucs-icon>
      </a>
      <span class="text text-bright me-1 ucs-input-label"
            (click)="purchaseNotReceievedDisabled === true || onChangePurchaseNotReceieved()">
      {{ 'search-filter-finished.purchaseNotReceived' | translate }}</span>
    </div>
  </ng-container>

</div>
