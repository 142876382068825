@if (logs.length > 0) {
  <table class="table table-responsive text mt-2" aria-describedby="Audit log">
    <thead>
      <tr>
        <th scope="col"><span>{{ 'audit-log.table.date' | translate }}
        </span></th>
        <th scope="col"><span>{{ 'audit-log.table.actor' | translate }}
        </span></th>
        <th scope="col"><span>{{ 'audit-log.table.action' | translate }}
        </span></th>
        <th scope="col"><span>{{ 'audit-log.table.comment' | translate }}
        </span></th>
        <th scope="col"><span>{{ 'audit-log.table.diff' | translate }}
        </span></th>
      </tr>
    </thead>
    <tbody>
      @for (log of logs; track log; let i = $index) {
        <tr class="mt-3">
          <!-- date -->
          <td>{{log.timestamp | date : 'dd.MM.yyyy HH:mm:ss'}}</td>
          <!-- actor -->
          <td>
            @if (log.dealer) {
              <span>{{log.actor + " / " + log.dealer}}</span>
            }
            @if (!log.dealer) {
              <span>{{log.actor}}</span>
            }
          </td>
          <!-- action -->
          <td>
            @if (!log.oldValue && log.action === 'OFFER_EDITABLE_FIELD_MODIFICATION') {
              <span>
              {{'audit-log.action.OFFER_EDITABLE_FIELDS_SET' | translate }} </span>
            }
            @if (!log.oldValue && log.action === 'VEHICLE_EDITABLE_FIELDS_MODIFICATION') {
              <span>
              {{'audit-log.action.VEHICLE_EDITABLE_FIELDS_SET' | translate }}</span>
            }
            @if (!(log.action === 'OFFER_EDITABLE_FIELD_MODIFICATION' ||
              log.action === 'VEHICLE_EDITABLE_FIELDS_MODIFICATION') || (log.oldValue && log.newValue) ||
              (log.oldValue && !log.newValue)) {
              <span>
              {{'audit-log.action.' + log.action | translate }}</span>
            }
          </td>
          <!-- comment -->
          <td>
            @if (!(log.action === 'VEHICLE_DOCUMENT_ADD' || log.action === 'VEHICLE_DOCUMENT_DELETE')
              && !isStatusChangeOrCreation(log)) {
              <span>{{log.comment}}</span>
            }
            @if (isStatusChangeOrCreation(log)) {
              <span>{{log.comment.split('§')[1]}}</span>
            }
          </td>
          <!-- diff -->
          <td>
            @if (isStatusChangeOrCreation(log)) {
              <span>{{log.comment.split('§')[0]}}</span>
            }
            @if (log.action === 'VEHICLE_EXTRA_TAX_PAID_CHANGE') {
              <span>
                {{'audit-log.value.VEHICLE_EXTRA_TAX_PAID_CHANGE.' + log.oldValue | translate}} ->
              {{'audit-log.value.VEHICLE_EXTRA_TAX_PAID_CHANGE.' + log.newValue | translate}}</span>
            }
            @if (log.property && log.oldValue.length === 0) {
              <span>{{'(' + log.property + ') ' + log.newValue}}</span>
            }
            @if (log.property && log.oldValue.length > 0) {
              <span>{{'(' + log.property + ') ' + log.oldValue + ' -> ' +
              log.newValue}}</span>
            }
          </td>
        </tr>
      }
    </tbody>
  </table>
} @else {
  <strong class="row ms-auto">{{'gdpr.noData' | translate}}</strong>
}

