import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TaxService} from '../../service/tax.service';
import {State} from '../../store/user/user.reducers';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import {first} from 'rxjs/operators';
import {ucsIsNil} from '../../misc/utils';

/**
 * This component contains the price information from the car (model, equipment,...)
 */
@Component({
  selector: 'ucs-price-detail',
  templateUrl: './price-detail.component.html',
  styleUrls: ['./price-detail.component.scss']
})
export class PriceDetailComponent implements OnChanges {
  @Input() vehicleDetails: VehicleItemDetailDto & VehicleDetailDto;

  // visibility flags
  showModelPrice = false;
  showColorPrice = false;
  showUpholsteryPrice = false;
  showGeneralPrice = false;

  equipmentWithPrice: EquipmentNewDto[];
  sumIncalculable = false;
  sumEquipmentWithNetPrice: number;
  totalPriceNet: number;
  novaAmount: number;
  novaSubtotal: number;
  vatAmount: number;
  totalPrice: number;
  userState: State;
  vatFactor: number;

  protected readonly ucsIsNil = ucsIsNil;

  constructor(private taxService: TaxService, private store: Store<fromRoot.AppState>) {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'vehicleDetails') {
        this.store
          .select(fromRoot.getUserState)
          .pipe(first())
          .subscribe(userState => {
            if (userState) { // wait for user state before we process because we need the country for price calculations
              this.userState = userState;
              this.resetPrices();
              this.generalPrice();
              this.filterNewEquipmentExtraWithNetPrice();
              this.updatePriceCalculations();
            }
          });
      }
    }
  }

  /**
   * Gets the VAT factor.
   */
  getVatFactor() {
    this.taxService.getVatFactorFromBackend(this.vehicleDetails.country)
      .pipe(first())
      .subscribe(vatFactor => {
        this.vatFactor = vatFactor;
      });
  }

  /**
   * Resets prices to allow new calculations.
   */
  resetPrices() {
    this.equipmentWithPrice = undefined;
    this.sumEquipmentWithNetPrice = undefined;
    this.totalPriceNet = undefined;
    this.novaAmount = undefined;
    this.novaSubtotal = undefined;
    this.vatAmount = undefined;
    this.totalPrice = undefined;
  }

  /*
   * check if there is a price (model,color,upholstery) and set the visibility flag
   */
  generalPrice() {
    if (this.vehicleDetails) {
      const priceInfo = this.vehicleDetails.priceInformation;

      this.showModelPrice = !!priceInfo['NEW_MODEL_PRICE']?.net;
      this.showColorPrice = !!priceInfo['NEW_COLOR_PRICE']?.net;
      this.showUpholsteryPrice = !!priceInfo['NEW_UPHOLSTERY_PRICE']?.net;

      this.showGeneralPrice = this.showModelPrice || this.showColorPrice || this.showUpholsteryPrice;
    } else {
      console.error('Vehicle does not exist');
    }
  }

  /*
   * filters the new equipments which are having a net price and are of any EXTRA* type
   */
  filterNewEquipmentExtraWithNetPrice(): void {
    this.sumIncalculable = false;
    if (this.vehicleDetails.newCarEquipment) {
      this.equipmentWithPrice = this.vehicleDetails.newCarEquipment
        .filter(equipment => !ucsIsNil(equipment.price)
            && (equipment.type === 'EXTRA' || equipment.type === 'BUNDLE_EXTRA'))
        .map(e => {
          if (ucsIsNil(e.price.net)) {
            this.sumIncalculable = true;
          }
          return e;
        });
    }
  }

  /*
   * Update price calculations such as e.g. sum of additional equipments and total net price
   */
  updatePriceCalculations() {
    // calculate net sum of additional equipments (de: Summe Mehrausstattungen) of filtered new equipments
    if (this.equipmentWithPrice.length > 0) {
      this.sumEquipmentWithNetPrice = this.equipmentWithPrice
        .map(equipment => equipment.price.net)
        .reduce((priceSum, current) => priceSum + current);
    }

    // calculate total net price
    this.calculateTotalNetPrice();

    // get the VAT factor (de: USt.)
    this.getVatFactor();

    // calculate NoVA amount
    if (this.totalPriceNet > 0 && this.vehicleDetails?.extraTax.rate >= 0) {
      this.novaAmount = (this.totalPriceNet * this.vehicleDetails.extraTax.rate) / 10000;
    }

    // calculate NoVA subtotal
    if (this.totalPriceNet > 0 && this.novaAmount >= 0 && !ucsIsNil(this.vehicleDetails?.novaDiscountSurcharge)) {
      this.novaSubtotal = this.novaAmount + this.vehicleDetails.novaDiscountSurcharge;
    }


    // get calculated vat amount
    this.getVatAmount();
  }

  calculateTotalNetPrice() {
    const priceInfo = this.vehicleDetails.priceInformation;
    this.totalPriceNet =
        (priceInfo['NEW_MODEL_PRICE']?.net ?? 0) +
        (priceInfo['NEW_COLOR_PRICE']?.net ?? 0) +
        (priceInfo['NEW_UPHOLSTERY_PRICE']?.net ?? 0) +
        (this.sumEquipmentWithNetPrice ?? 0);
  }

  getVatAmount() {
    if (this.totalPriceNet > 0) {
      this.taxService.getVatAmountFromBackend(this.totalPriceNet, this.vehicleDetails.country)
        .pipe(first())
        .subscribe(vatAmount => {
          this.vatAmount = vatAmount;
          this.totalPrice = this.totalPriceNet + this.vatAmount;
          if (this.vehicleDetails.country === 'AT') {
            this.totalPrice += (this.novaSubtotal || this.novaAmount);
          }
        });
    }
  }
}
