/**
 *  Dialog for PDF print options
 */
import {Component, Input, OnInit} from '@angular/core';
import {VehicleMaintenanceService} from '../../service/vehicle-maintenance.service';

declare let $: any;

@Component({
  selector: 'ucs-print-dialog',
  templateUrl: './print-dialog.component.html',
  styleUrls: ['./print-dialog.component.scss']
})

export class PrintDialogComponent implements OnInit{

  @Input() vehicleId: number;

  isSimpleSelected: boolean = false;
  isExtendedSelected: boolean = true;
  sendEmail: boolean = false;

  emailValidationError = '';
  createPDFDto: CreatePdfDto;

  constructor(
    private vehicleService: VehicleMaintenanceService) {
  }

  ngOnInit(): void {
    this.resetSelection();
  }

  resetSelection() {
    this.isSimpleSelected = false;
    this.isExtendedSelected = true;
    this.sendEmail = false;
    this.emailValidationError = '';
    this.createPDFDto = {
      vehicleId: undefined,
      includeVehicleData: false,
      includeStandardEquipment: false,
      includeStandardEquipmentExtendingInformation: false,
      includeNewCarPriceInformation: false,
      includeNewCarPriceInformationExtendingInformation: false,
      includePicture: false,
      includeSurvey: false,
      includeEtb: false,
      includeEtbWithPicture: false,
      sendToEmailAddress: undefined,
      emailComment: undefined,
    };
  }


  togglePrintSelection() {
    this.isSimpleSelected = !this.isSimpleSelected;
    this.isExtendedSelected = !this.isExtendedSelected;
  }

  toggleVehicleData() {
    this.createPDFDto.includeVehicleData = !this.createPDFDto.includeVehicleData;
  }

  toggleEquipmentSelection() {
    this.createPDFDto.includeStandardEquipment = !this.createPDFDto.includeStandardEquipment;
    if (!this.createPDFDto.includeStandardEquipment) {
      this.createPDFDto.includeStandardEquipmentExtendingInformation = false;
    }
  }

  toggleEquipmentWithDetailsSelection() {
    if (this.createPDFDto.includeStandardEquipment) {
      this.createPDFDto.includeStandardEquipmentExtendingInformation
        = !this.createPDFDto.includeStandardEquipmentExtendingInformation;
    }
  }

  toggleNewCarPriceInfoSelection() {
    this.createPDFDto.includeNewCarPriceInformation = !this.createPDFDto.includeNewCarPriceInformation;
    if (!this.createPDFDto.includeNewCarPriceInformation) {
      this.createPDFDto.includeNewCarPriceInformationExtendingInformation = false;
    }
  }

  toggleNewCarPriceInfoDetailsSelection() {
    if (this.createPDFDto.includeNewCarPriceInformation) {
      this.createPDFDto.includeNewCarPriceInformationExtendingInformation
        = !this.createPDFDto.includeNewCarPriceInformationExtendingInformation;
    }
  }

  togglePicturesSelection() {
    this.createPDFDto.includePicture = !this.createPDFDto.includePicture;
  }

  toggleSurveySelection() {
    this.createPDFDto.includeSurvey = !this.createPDFDto.includeSurvey;
  }

  toggleEtbSelection() {
    this.createPDFDto.includeEtb = !this.createPDFDto.includeEtb;
    if (!this.createPDFDto.includeEtb) {
      this.createPDFDto.includeEtbWithPicture = false;
    }
  }

  toggleEtbWithPicturesSelection() {
    if (this.createPDFDto.includeEtb) {
      this.createPDFDto.includeEtbWithPicture = !this.createPDFDto.includeEtbWithPicture;
    }
  }

  toggleSendEmail() {
    this.sendEmail = !this.sendEmail;
    if (!this.sendEmail) {
      this.createPDFDto.sendToEmailAddress = undefined;
      this.createPDFDto.emailComment = undefined;
      this.emailValidationError = '';
    }
  }

  checkEmailPatternValidity() {
    const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (!regex.test(this.createPDFDto.sendToEmailAddress) && this.createPDFDto.sendToEmailAddress.length > 0) {
      this.emailValidationError = 'print-dialog.wrong-mail-format';
    } else {
      this.emailValidationError = '';
    }
  }

  createPDF() {
    if(this.sendEmail && (!this.createPDFDto.sendToEmailAddress || this.createPDFDto.sendToEmailAddress.length === 0)) {
      this.emailValidationError = 'print-dialog.error.no-email-entered';
      return;
    }
    this.emailValidationError = '';
    this.createPDFDto.vehicleId = this.vehicleId;
    this.vehicleService.printVehicle(this.createPDFDto).subscribe();

    $('#printModal').modal('hide');
    this.resetSelection();
  }

  openPrintView() {
    $('#printModal').modal('hide');
    //wait for modal to completely close before printing window
    setTimeout(() => window.print(), 1000);
  }
}
