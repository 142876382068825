import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {OfferListOptions} from '../../../model/offer-list-options.model';
import {takeUntil} from 'rxjs/operators';
import {SystemSettingsService} from '../../../service/system-settings.service';
import {Subject} from 'rxjs';

/**
 * Either hosts a list of offers (OfferComponent) for the offer results
 * or a list of smaller versions of the offers (OfferSmallComponent) for the carousel image sliders
 */
@Component({
  selector: 'ucs-offer-list-core',
  templateUrl: './offer-list-core.component.html',
  styleUrls: ['./offer-list-core.component.scss']
})
export class OfferListCoreComponent implements OnInit, OnDestroy {
  optionsList: OfferListOptions = {
    'type': 'offer',
    'direction': 'row'
  };

  @Input() options: OfferListOptions;
  @Input() offers: OfferDto[];

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private systemSettingService: SystemSettingsService) {
  }

  ngOnInit() {
    Object.assign(this.optionsList, this.options);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
