import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/app.reducers';
import * as SpinnerActions from '../store/spinner/spinner.actions';
import {ConnectableObservable, Observable} from 'rxjs';
import {publish, refCount} from 'rxjs/operators';

/**
 * This service handles the global spinner, which is visible as a loading indicator
 */
@Injectable()
export class SpinnerService {

  constructor(private store: Store<fromRoot.AppState>) {
  }

  /**
   * Gets a ready prepared Observable (e.g. a configure HTTP call), transforms it into a ConnectableObservable and
   * before subscribing to it, it sets the spinner's loading indicator to true.
   * On success and error, it sets the spinner's loading indicator to false.
   * @param o The observable of which to create a ConnectableObservable from.
   * @return co The created ConnectableObservable
   */
  spinner<T>(o: Observable<T>): Observable<T> {
    const co = o.pipe(publish(), refCount()) as ConnectableObservable<T>;
    this.store.dispatch(new SpinnerActions.UpdateLoadingAction(true));
    co.subscribe(() => {
      this.store.dispatch(new SpinnerActions.UpdateLoadingAction(false));
    }, () => {
      this.store.dispatch(new SpinnerActions.UpdateLoadingAction(false));
    }, () => {
      // do nothing
    });
    return co;
  }

  /**
   * manually activates the spinner without any extra logic. In order to stopp the spinner deactivateSpinner() needs
   * to be called.
   * use with caution, the spinner will not deactivate in case of an error!
   */
  activateSpinner(){
    this.store.dispatch(new SpinnerActions.UpdateLoadingAction(true));
  }

  /**
   * manually deactivates the spinner without any extra logic
   */
  deactivateSpinner() {
    this.store.dispatch(new SpinnerActions.UpdateLoadingAction(false));
  }
}
