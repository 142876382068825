<div [ngClass]="{ 'row':true, 'mb-3':hint === undefined, 'mb-2':hint !== undefined}">
  <div class="col-12 col-md-5" [ngClass]="{'tooltip-label': tooltipOverLabel}">
    <span [floatUi]="tooltipOverLabel" [showTrigger]="NgxFloatUiTriggers.hover" [disabled]="!tooltipOverLabel">
      {{label}}
    </span>
  </div>
  <div class="col-12 col-md-2 price">
    @if (showNetPriceUnavailableInfo) {
      <div>
        <span class="no-net-price">
          <ucs-icon [shape]="'alert-triangle'" [size]="18"
          [class]="'grey'" class="warning-no-net-price"></ucs-icon>
          {{'label-price.price-unknown' | translate}}
        </span>
      </div>
    } @else {
      <span class="float-left">{{ currency }}</span>
      <span class="float-right">
        {{ price | price: undefined : undefined :  '1.2-2' }}
      </span>
    }
  </div>
  @if (sumIncalculable) {
    <div>
      <ucs-icon [shape]="'alert-triangle'" [size]="18"
      [class]="'grey'"></ucs-icon>
      {{ 'label-price.sum-incalculable' | translate}}
    </div>
  }
</div>
@if (hint !== undefined) {
  <div class="row mb-3 hint">
    <div class="col-12">
      {{hint}}
    </div>
  </div>
}

