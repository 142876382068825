<form class="row">
  <div class="col">
    <label for="fromDate"><strong>{{ "ucs.date.from" | translate }}</strong></label>
    <div class="dp-hidden position-absolute">
      <div class="input-group mt-1">
        <input #datepicker="ngbDatepicker"
               (dateSelect)="onDateSelection($event)"
               [autoClose]="'inside'"
               [dayTemplate]="t"
               [displayMonths]="2"
               [maxDate]="maxDate!"
               [minDate]="minDate!"
               [startDate]="fromDate!"
               class="form-control"
               name="datepicker"
               ngbDatepicker
               outsideDays="hidden"
               tabindex="-1">
        <ng-template #t let-date let-focused="focused">
          <span (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
                [class.faded]="isHovered(date) || isInside(date)"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                class="custom-day">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
    <div class="input-group mt-1">
      <input #dpFromDate
             (input)="fromDate = validateFromDateInput(fromDate, dpFromDate.value)" [value]="formatter.format(fromDate)"
             class="form-control"
             id="fromDate"
             name="dpFromDate"
             placeholder="yyyy-mm-dd">
      <button (click)="datepicker.toggle()" class="btn btn-outline-secondary py-0 btn-datepicker" type="button">
        <ucs-icon [class]="'blue'" [shape]="'calendar'"></ucs-icon>
      </button>
    </div>
  </div>
  <div class="col-12 col-md col-xl col-l">
    <label for="toDate"><strong>{{ "ucs.date.to" | translate }}</strong></label>
    <div class="input-group mt-1">
      <input #dpToDate
             (input)="toDate = validateToDateInput(toDate, dpToDate.value)" [value]="formatter.format(toDate)"
             class="form-control"
             id="toDate"
             name="dpToDate"
             placeholder="yyyy-mm-dd">
      <button (click)="datepicker.toggle()" class="btn btn-outline-secondary py-0 btn-datepicker" type="button">
        <ucs-icon [class]="'blue'" [shape]="'calendar'"></ucs-icon>
      </button>
    </div>
  </div>
</form>
