import {Component, OnDestroy, OnInit} from '@angular/core';
import {State} from '../../../store/user/user.reducers';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ucs-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit, OnDestroy {
  userState: State;
  private unsubscribe: Subject<void> = new Subject<void>();

  /**
   * Instantiates the component.
   *
   * @param {Store<AppState>} store: allows access to the store
   */
  constructor(private store: Store<fromRoot.AppState>) {
  }

  /**
   * Called when component is being initialized.
   */
  ngOnInit(): void {
    // subscribe to user state to access the user's roles
    this.store.select(fromRoot.getUserState)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userState => {
        if (userState) {
          this.userState = userState;
        }
      });
  }

  /**
   * PBV support, PBV admin, PBV seller, PBV clerk see all manuals
   */
  isAllowedToSeeAllManualsOrVideosFromPB(): boolean {
    return this.userState.isSupportPoi || this.userState.isDevPoi
      || this.userState.isSupportPb
      || this.userState.isAdminPb
      || this.userState.isAuctionSellerPb
      || this.userState.isBuyNowSellerPb
      || this.userState.isClerkPb;
  }

  /**
   * PBV support, PBV admin, PBV seller, PBV clerk see all manuals
   */
  isAllowedToSeeAllManualsOrVideosFromPBHR(): boolean {
    return this.userState.isSupportPoi || this.userState.isDevPoi
      || this.userState.isSupportPb
      || ((this.userState.isAdminPb
      || this.userState.isAuctionSellerPb
      || this.userState.isBuyNowSellerPb
      || this.userState.isClerkPb) && this.userState.userInfo.country === 'HR');
  }

  hasMoreThanOneChannel(): boolean {
    return this.userState.hasAnyRoleForPb && this.userState.hasAnyRoleForPia
      || this.userState.hasAnyRoleForPb && this.userState.hasAnyRoleForAllUc
      || this.userState.hasAnyRoleForPb && this.userState.hasAnyRoleForDinBil
      || this.userState.hasAnyRoleForPia && this.userState.hasAnyRoleForAllUc
      || this.userState.hasAnyRoleForAllUc && this.userState.hasAnyRoleForDinBil
      || this.userState.hasAnyRoleForPia && this.userState.hasAnyRoleForDinBil;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
