@if (label !== undefined) {
  <div class="row mt-3 mb-2">
    <div class="col-12 text">
      <strong>{{label}}</strong>
    </div>
  </div>
}

<div [ngClass]="{
  'row':true,
  'mt-2':label === undefined,
  'mb-2':label === undefined}">
  <div class="col-12">
    <input [id]="id"
      [(ngModel)]="selectedValue"
      [typeahead]="dataSource$"
      (typeaheadOnSelect)="onSelect($event)"
      [typeaheadOptionsLimit]="7"
      [typeaheadItemTemplate]="customItemTemplate"
      [placeholder]="placeholder"
      [typeaheadMinLength]=3
      typeaheadOptionField="zipCode"
      (input)="onInput($event)"
      class="form-control text">
    <ng-template #customItemTemplate let-model="item">
      {{model.zipCode + ' ' + model.city}}
    </ng-template>

  </div>
</div>
