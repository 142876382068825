<!-- PICTURES -->
<a [floatUi]="popPicture" [showTrigger]="NgxFloatUiTriggers.hover" [placement]="NgxFloatUiPlacements.BOTTOM" [applyClass]="'float-ui-content-display'" role="button" tabindex="0">
  <ucs-icon [shape]="vehicle.missingPicture ? 'camera' : 'camera_checked'"></ucs-icon>
</a>
<float-ui-content class="float-ui-content-display" #popPicture>
  @if (vehicle.missingPicture && missingImagesCount > 1) {
    <div>
      {{ 'vehicle.missing-picture' | translate }}
      ({{ 'vehicle.missing-picture.prefix' | translate }}
      {{ (missingImagesCount ) }} {{ 'vehicle.missing-picture-count' | translate }})
    </div>
  }
  @if (vehicle.missingPicture && missingImagesCount === 1) {
    <div>
      {{ 'vehicle.missing-picture' | translate }}
      ({{ 'vehicle.missing-picture.prefix' | translate }}
      {{ (missingImagesCount ) }} {{ 'vehicle.missing-single-picture-count' | translate }})
    </div>
  }
  @if (!vehicle.missingPicture) {
    <div>
      {{ 'vehicle.enough-picture' | translate }}
    </div>
  }
</float-ui-content>

<!-- DOCUMENTS -->
@if (showMissingDocumentIcon()) {
  <a [floatUi]="popDocument" [showTrigger]="NgxFloatUiTriggers.hover" [placement]="NgxFloatUiPlacements.BOTTOM" [applyClass]="'float-ui-content-display'" role="button" tabindex="0">
    <ucs-icon [shape]="vehicle.missingDocument ? 'document' : 'document_checked'"></ucs-icon>
  </a>
  <float-ui-content class="float-ui-content-display" #popDocument>
    <div>
      <div>
        {{ (vehicle.missingEtb ? 'vehicle.missing-etb' : 'vehicle.complete-etb') | translate }}
      </div>
      @if (vehicle.channel === 'PB') {
        <div>
          {{ (vehicle.missingCertificate ? 'vehicle.missing-certificate' : 'vehicle.complete-certificate') | translate}}
        </div>
      }
    </div>
  </float-ui-content>
}

<!-- PERSON -->
<a [floatUi]="floatUiPerson" [showTrigger]="NgxFloatUiTriggers.hover" [placement]="NgxFloatUiPlacements.BOTTOM" [applyClass]="'float-ui-content-display'" role="button" tabindex="0">
  <ucs-icon [shape]="vehicle.missingContactPerson ? 'user' : 'user_checked'"></ucs-icon>
</a>
<float-ui-content class="float-ui-content-display" #floatUiPerson>
  <div>
    {{ (vehicle.missingContactPerson ? 'vehicle.missing-person' : 'vehicle.set-person') | translate }}
  </div>
</float-ui-content>

<!-- VEHICLE DETAIL -->
<a [floatUi]="popVehicleDetail" [showTrigger]="NgxFloatUiTriggers.hover" [placement]="NgxFloatUiPlacements.BOTTOM" [applyClass]="'float-ui-content-display'" role="button" tabindex="0">
  <ucs-icon data-cy="vehicle-detail.pencil-icon" [shape]="vehicle.missingVehicleDetail ? 'edit-3' : 'edit-3_checked'"></ucs-icon>
</a>
<float-ui-content class="float-ui-content-display"#popVehicleDetail>
  <div>
    {{ (vehicle.missingVehicleDetail ? 'vehicle.missing-vehicleDetail' : 'vehicle.set-vehicleDetail') | translate }}
  </div>
</float-ui-content>

<!-- INITIAL PROPOSAL -->
@if (showInitialProposalIcon) {
  <a [floatUi]="popInitialProposal" [showTrigger]="NgxFloatUiTriggers.hover" [placement]="NgxFloatUiPlacements.BOTTOM" [applyClass]="'float-ui-content-display'" role="button" tabindex="0">
    <ucs-icon [shape]="vehicle.missingInitialProposalPrice ? 'plus-square' : 'plus-square_checked'"></ucs-icon>
  </a>
  <float-ui-content class="float-ui-content-display" #popInitialProposal>
    <div>
      {{ (vehicle.missingInitialProposalPrice ? 'vehicle.missing-initialProposalPrice' : 'vehicle.set-initialProposalPrice') | translate }}
    </div>
  </float-ui-content>
}
<!-- SELLER CALCULATIONS -->
@if (isAllowedToSeeSellerCalculations) {
  <a [floatUi]="popInitialProposal" [showTrigger]="NgxFloatUiTriggers.hover" [placement]="NgxFloatUiPlacements.BOTTOM" [applyClass]="'float-ui-content-display'" role="button" tabindex="0">
    <ucs-icon [shape]="vehicle.sellerCalculationsCompleted ? 'calculations_checked' : 'calculations'"></ucs-icon>
  </a>
  <float-ui-content class="float-ui-content-display" #popInitialProposal>
    <div>
      {{
      (vehicle.sellerCalculationsCompleted ? 'vehicle.set-sellerCalculations' :
      'vehicle.missing-sellerCalculations') |
      translate }}
    </div>
  </float-ui-content>
}
