import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../service/user.service';
import {takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import {
  InitUserSubstitutionsAction,
  UpdateOpenTasksAction,
  UpdateUserSubstitutionsAction
} from '../../store/vehicle-maintenance/vehicle-maintenance.actions';
import {Subject} from 'rxjs';

@Component({
  selector: 'ucs-substitutions-list',
  templateUrl: './substitutions-list.component.html',
  styleUrls: ['./substitutions-list.component.scss']
})
export class SubstitutionsListComponent implements OnInit, OnDestroy {

  substitutions: UserSubstituteInformationDto[];

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private userService: UserService, private store: Store<fromRoot.AppState>) {
  }

  ngOnInit(): void {
    //init substitutions
    this.store.dispatch(new InitUserSubstitutionsAction());

    this.store.select(fromRoot.getUserSubstitutions)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userSubstitutions => {
        this.substitutions = userSubstitutions;
        this.store.dispatch(new UpdateOpenTasksAction());
      });
  }

  toggleSubstitution(userId: number): void {
    if (!this.substitutions) {
      return;
    }
    const substitution = this.substitutions.find((sub) => sub.userId === userId);
    substitution.substituteActive = !substitution.substituteActive;
    this.store.dispatch(new UpdateUserSubstitutionsAction(
      this.substitutions.filter(sub => sub.substituteActive)
        .map(filteredSub => filteredSub.userId)));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
