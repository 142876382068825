import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {isLocationDto, isValueAggregationEntry} from '../../../misc/typeguard';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead/typeahead-match.class';
import {NgxFloatUiPlacements} from 'ngx-float-ui';

/**
 * This component encapsulates a ngx-bootstrap typehead-dropdown with a label
 */
@Component({
  selector: 'ucs-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss']
})
export class SearchDropdownComponent implements OnInit, OnChanges {
  @Input() id: string;
  @Input() label: string;
  @Input() states: any[];
  @Input() placeholder: string;
  @Input() popoverText: string;
  @Output() onSelected = new EventEmitter<any>();
  selectedValue: string;
  selectedCode: string;
  aggregatedStates: any[] = [];

  ngOnInit() {
    this.initAggregatedStates(this.states, this.aggregatedStates);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateAggregatedStates();
  }

  /**
   * On selecting a value, find the original value from the input states using the id and emit value to parent
   */
  onSelect(event: TypeaheadMatch) {
    // check whether entry is of state ValueAggregationEntry
    if (isValueAggregationEntry(this.states[0])) {
      const item = this.aggregatedStates.find(x => x.option === this.selectedValue);
      this.onSelected.emit((<ValueAggregationEntry[]>this.states)[item.id].value);
      this.selectedCode = item.code;
    }
  }

  /**
   * Called whenever input value of dropdown changes
   * When empty, i.e. selection cleared, emit to parent to clear the search param
   */
  onInput(event) {
    if (this.selectedValue === '') {
      this.selectedValue = undefined;
      this.onSelected.emit(undefined);
    }
  }

  /**
   * Clears the selection of the dropdown
   */
  clear() {
    this.selectedValue = undefined;
  }

  /**
   * Allows overriding the current selection (intended to be used by parent component)
   */
  overrideSelection(object: any) {
    if (!object || object.length === 0) { // if undefined, clear the selection
      this.clear();
    } else {

      if (isLocationDto(object)) {
        // find the matching item to the object value
        const item = this.states.find(x => x.value === object.country);

        // override the selected value with the correct item
        // also check if backend offers an aggregation count and append it, if so
        this.checkAggregationCount(item);

      } else { // our object is a string value
        const item = this.states.find(x => x.value === object.toString());

        if (item) {
          if (item.count) {
            this.selectedValue = item.text + ' (' + item.count + ')';
          } else {
            this.selectedValue = item.text;
          }
        }
      }
    }
  }

  private checkAggregationCount(item) {
    if (item) {
      if (item.count) {
        this.selectedValue = item.text + ' (' + item.count + ')';
      } else {
        this.selectedValue = item.text;
      }
    }
  }

  /** IE11 Fix, so you don't need to double click the menu-item -> does not work if selected value is unselected
   /* https://github.com/valor-software/ngx-bootstrap/issues/3124
   */
  onFocus() {
    if ((document['documentMode'] && !this.selectedValue) ||
      (this.selectedValue && this.selectedValue.length === 0)) {
      this.selectedValue = ' ';
    }
  }

  /**
   * Initializes the aggregated states for optimized display, also adding an id for easier handling
   * @param {any[]} inputStates: the states, i.e. dropdown options, passed to the component
   * @param {any[]} aggregStates: optimized display options with an id for each entry
   */
  private initAggregatedStates(inputStates: any[], aggregStates: any[]): void {
    const myAggregatedStates = [];
    let id = 0;
    if (inputStates) {
      for (const entry of inputStates) {
        if (isValueAggregationEntry(entry)) {

          let aggregatedValue;
          // check if backend offers an aggregation count and append it, if so
          if (entry.count) {
            aggregatedValue = entry.text + ' (' + entry.count + ')';
          } else {
            aggregatedValue = entry.text;
          }

          myAggregatedStates.push({id: id, option: aggregatedValue, code: entry.value});
          id++;
        } else {
          console.error('Unknown input state');
        }
        this.aggregatedStates = myAggregatedStates;
      }
    }
  }

  private updateAggregatedStates(): void {
    // re-initialize aggregation states
    this.initAggregatedStates(this.states, this.aggregatedStates);

    // if we have a value selected, we need to update the number of found items in the dropdown
    if (this.selectedCode !== undefined && this.selectedValue !== undefined
      && isValueAggregationEntry(this.states[0])) {

      // find the item in our aggregation states
      // if the item is present, then there are results with this setting and we update the label with the new number
      // otherwise we just set the aggregation value to 0
      const item = this.aggregatedStates.find(x => x.code === this.selectedCode);

      if (item !== undefined) {
        this.selectedValue = item.option;
      } else {
        const regEx = /\([0-9]+\)/;
        this.selectedValue = this.selectedValue.replace(regEx, '(0)');
      }
    }
  }

  protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
