import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/app.reducers';

/*
 * Returns the formatted number and appends the 'kW' (kilowatt) unit
 * Usage:
 *   value | kw
 * Example:
 *   {{ vehicle.powerKw | kw }}
 *   Let vehicle.powerKw be 176, then the returned text will be "176 kW" (dots or commas depending on localisation)
*/
@Pipe({
  name: 'kw'
})
@Injectable()
export class KwPipe implements PipeTransform {

  private local: string;

  constructor(private store: Store<fromRoot.AppState>) {
  }

  transform(value: number): string {

    this.store.select(fromRoot.getUserState)
      .pipe()
      .subscribe(
        s => {
          this.local = s.local;
        }
      );
    // format number based on local
    const formattedValue = new DecimalPipe(this.local).transform(value);
    // append unit
    return formattedValue + ' kW';
  }

}
