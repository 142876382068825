/**
 * Component for editing the mail supplementary text
 */

import {Component, Input} from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'ucs-back-navigation',
  templateUrl: './back-navigation.component.html',
  styleUrls: ['./back-navigation.component.scss']
})
export class BackNavigationComponent {

  @Input() navigateBack: boolean;

  constructor(private location: Location) {
  }

  goBack() {
    if (this.navigateBack) {
      this.location.back();
    }
  }
}
