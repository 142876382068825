/**
 * This component displays the convenience email subject
 */
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SystemSettingsService} from '../../service/system-settings.service';
import {HtmlSanitizerService} from '../../service/html-sanitizer.service';
import {NgxFloatUiTriggers} from 'ngx-float-ui';

@Component({
  selector: 'ucs-email-subject',
  templateUrl: './email-subject.component.html'
})
export class EmailSubjectComponent implements OnChanges {

  @Input() contractPartner: string;
  @Input() modelVariant: string;
  @Input() vin: string;
  @Input() contractId: string;
  @Input() externalId: string;
  @Input() channel: DistributionChannel;

  showEmailSubjectToggle: boolean;

  constructor(private systemSettingsService: SystemSettingsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'channel') {
        this.systemSettingsService
          .isSystemFeatureActivatedForChannel('ADD_CONVENIENCE_EMAIL_SUBJECT_TO_VEHICLE_DETAIL', this.channel)
          .subscribe(value => {
            this.showEmailSubjectToggle = value;
          });
      }
    }
  }

  getMailSubject(): string {
    let subject = '';
    if (this.contractPartner) {
      subject = this.contractPartner + ' - ';
    }
    if (this.modelVariant) {
      subject += this.modelVariant + ' - ';
    }
    if (this.vin) {
      subject += this.vin + ' - ';
    }
    if (this.contractId || this.externalId) {
      subject += this.contractId && this.channel === 'PB' ? this.contractId : this.externalId;
    }
    return subject;
  }

  validateEmailSubject(text: string) {
    return HtmlSanitizerService.sanitize(text);
  }

  protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
