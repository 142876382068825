/**
 * This component displays the current bid status
 */
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {SystemSettingsService} from '../../service/system-settings.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'ucs-bid-status',
  templateUrl: './bid-status.component.html',
  styleUrls: ['./bid-status.component.scss']
})
export class BidStatusComponent implements OnInit, OnChanges, OnDestroy {
  @Input() status: BidStatus;
  @Input() maximumBid: PriceDto;
  @Input() myLastHighestBid: PriceDto;
  @Input() myLastMaximumBid: PriceDto;
  @Input() purchasePrice: PriceDto;
  @Input() highestBid: PriceDto;
  @Input() purchaseReceived: boolean;
  @Input() tooLateBid: PriceDto;
  @Input() offerStatus: OfferStatus;
  @Input() nationalSale: boolean;
  @Input() isUAOffer = false;
  displayMaximumBid = false;
  displayOutbidMaximumBid = false;
  displayOutbidHighestBid = false;
  displayTooLate = false;
  displaySpecialBid = false;

  messageInCaseOfOutbid: string;
  bidTypeMsgToDisplay: string;
  userMaxOrLatestBidToDisplay: PriceDto;

  isFeatureShowGrossPriceEnabled: boolean = false;

  showGrossPriceInStatus: boolean = false;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit() {
    this.systemSettingsService
      .isSystemFeatureActivatedForAnyChannel('SHOW_GROSS_PRICE_IN_BID_STATUS')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isFeatureEnabled => {
        this.isFeatureShowGrossPriceEnabled = isFeatureEnabled;
        this.showGrossPriceInStatus = isFeatureEnabled && this.nationalSale;
      });

    this.renderBidStatus(); // render initially
  }

  /**
   * When we receive an update on one of the input properties of this component, make sure to re-render the bid status.
   *
   * @param changes the changes that occurred
   */
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'highestBid' || propName === 'maximumBid'
        || propName === 'status' || propName === 'purchasePrice'
        || propName === 'myLastHighestBid' || propName === 'myLastMaximumBid'
        || propName === 'offerStatus') {
        this.renderBidStatus();
        break; //it is enough to render once even if more than one property has changed
      }
    }
  }

  /**
   * Renders the bid status for display.
   */
  private renderBidStatus() {

    this.showGrossPriceInStatus = this.isFeatureShowGrossPriceEnabled && this.nationalSale;

    this.displayMaximumBid = this.maximumBid !== null && this.highestBid.net <= this.maximumBid.net;

    this.displayMaximumBid = this.maximumBid !== null && this.highestBid.net <= this.maximumBid.net;

    if (this.status === 'OUTBID') {
      if (!!this.tooLateBid) {
        this.displayTooLate = true;
      }

      this.displayOutbidBidIfAvailable();
      this.displayOutbidBidBasedOnNetPriceComparison();
      this.displaySpecialBidOutbidMessage();
      this.setOutbidInfoText();
    }
  }

  private displayOutbidBidIfAvailable() {
    if (this.myLastMaximumBid !== null && this.myLastHighestBid === null) {
      this.displayOutbidMaximumBid = true;
      this.displayOutbidHighestBid = false;
    }

    if (this.myLastHighestBid !== null && this.myLastMaximumBid === null) {
      this.displayOutbidMaximumBid = false;
      this.displayOutbidHighestBid = true;
    }
  }

  private displayOutbidBidBasedOnNetPriceComparison() {
    if (this.myLastMaximumBid !== null && this.myLastHighestBid !== null) {
      if (this.myLastMaximumBid.net >= this.myLastHighestBid.net) {
        this.displayOutbidMaximumBid = true;
        this.displayOutbidHighestBid = false;
      } else {
        this.displayOutbidMaximumBid = false;
        this.displayOutbidHighestBid = true;
      }
    }
  }

  private displaySpecialBidOutbidMessage() {
    if((this.myLastMaximumBid !== null && this.maximumBid !== null)
      && (this.myLastMaximumBid.net === this.maximumBid.net)
      && (this.offerStatus === 'EXPIRED')) {
      this.displaySpecialBid = true;
    }
  }

  private setOutbidInfoText(){
    if (!this.displaySpecialBid) {
      if (this.displayOutbidHighestBid) {
        this.userMaxOrLatestBidToDisplay = this.myLastHighestBid;
        this.bidTypeMsgToDisplay = 'offer-item.your-last-bid';
      } else if (this.displayOutbidMaximumBid) {
        this.userMaxOrLatestBidToDisplay = this.myLastMaximumBid;
        this.bidTypeMsgToDisplay = 'offer-item.your-last-maximum-bid';
      }
      this.messageInCaseOfOutbid = this.displayTooLate ? 'offer-item.bid-not-accepted' : 'offer-item.you-were-outbid';
    } else {
      this.userMaxOrLatestBidToDisplay = this.maximumBid;
      this.messageInCaseOfOutbid = 'offer-item.your-highest-bid';
      this.bidTypeMsgToDisplay = 'offer-item.your-maximum';
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
