import {Component, Input} from '@angular/core';

@Component({
  selector: 'ucs-price-display',
  templateUrl: './price-display.component.html',
  styleUrls: ['./price-display.component.scss']
})
export class PriceDisplayComponent {
  @Input() heading: string;
  @Input() priceDto: PriceDto;
}
