@if (!hideDropDown) {
  <div class="col dropdown d-lg-none platform" [ngClass]="{'center-btn': !showSidebar}">
    <button class="btn button dropdown-toggle channel-button" type="button" data-bs-toggle="dropdown">
      @if ((selectedChannel === 'ALL_UC' && canViewALLUC)) {
        <span>
        {{ 'ucs.all_uc' | translate }} {{ elementsCountALLUC || elementsCountALLUC === 0 ? '(' + elementsCountALLUC + ')' : '' }}</span>
      }
      @if ((selectedChannel === 'PB' && canViewPB)) {
        <span>
        {{ 'ucs.pb' | translate }} {{ elementsCountPB || elementsCountPB === 0 ? '(' + elementsCountPB + ')' : ''}}</span>
      }
      @if ((selectedChannel === 'PIA' && canViewPIA)) {
        <span>
        {{ 'ucs.pia' | translate }} {{ elementsCountPIA || elementsCountPIA === 0 ? '(' + elementsCountPIA + ')' : ''}}</span>
      }
      @if ((selectedChannel === 'DIN_BIL' && canViewDINBIL)) {
        <span>
        {{ 'ucs.din_bil' | translate }} {{ elementsCountDINBIL || elementsCountDINBIL === 0 ? '(' + elementsCountDINBIL + ')' : ''}}</span>
      }
      @if ((selectedChannel === 'VGRX' && canViewVGRX)) {
        <span>
        {{ 'ucs.vgrx' | translate }} {{ elementsCountVGRX || elementsCountVGRX === 0 ? '(' + elementsCountVGRX + ')' : ''}}</span>
      }
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      @if (canViewALLUC) {
        <span
          (click)="this.channelChangedEmitter.emit('ALL_UC')" class="dropdown-item">
        {{ 'ucs.all_uc' | translate }} {{ elementsCountALLUC || elementsCountALLUC === 0 ? '(' + elementsCountALLUC + ')' : '' }} </span>
      }
      @if (canViewPB) {
        <span
          (click)="this.channelChangedEmitter.emit('PB')" class="dropdown-item">
        {{ 'ucs.pb' | translate }} {{ elementsCountPB || elementsCountPB === 0 ? '(' + elementsCountPB + ')' : ''}} </span>
      }
      @if (canViewPIA) {
        <span
          (click)="this.channelChangedEmitter.emit('PIA')" class="dropdown-item">
        {{ 'ucs.pia' | translate }} {{ elementsCountPIA || elementsCountPIA === 0? '(' + elementsCountPIA + ')' : ''}} </span>
      }
      @if (canViewDINBIL) {
        <span
          (click)="this.channelChangedEmitter.emit('DIN_BIL')" class="dropdown-item">
        {{ 'ucs.din_bil' | translate }} {{ elementsCountDINBIL || elementsCountDINBIL === 0? '(' + elementsCountDINBIL + ')' : ''}} </span>
      }
      @if (canViewVGRX) {
        <span
          (click)="this.channelChangedEmitter.emit('VGRX')" class="dropdown-item">
        {{ 'ucs.vgrx' | translate }} {{ elementsCountVGRX || elementsCountVGRX === 0? '(' + elementsCountVGRX + ')' : ''}} </span>
      }
    </div>
  </div>
}

<div [ngClass]="platformListClass">
  <ul>
    @if (canViewALLUC) {
      <li
        (click)="this.channelChangedEmitter.emit('ALL_UC')" class="link"
        [ngClass]="{'underline': selectedChannel === 'ALL_UC'}">
        {{ 'ucs.all_uc' | translate }} {{ elementsCountALLUC || elementsCountALLUC === 0 ? '(' + elementsCountALLUC + ')' : '' }}
      </li>
    }
    @if (canViewPB) {
      <li
        (click)="this.channelChangedEmitter.emit('PB')" class="link"
        [ngClass]="{'underline': selectedChannel === 'PB'}">
        {{ 'ucs.pb' | translate }} {{ elementsCountPB || elementsCountPB === 0 ? '(' + elementsCountPB + ')' : ''}}
      </li>
    }
    @if (canViewPIA) {
      <li
        (click)="this.channelChangedEmitter.emit('PIA')" class="link" data-cy="PIA-channel"
        [ngClass]="{'underline': selectedChannel === 'PIA'}">
        {{ 'ucs.pia' | translate}} {{ elementsCountPIA || elementsCountPIA === 0 ? '(' + elementsCountPIA + ')' : ''}}
      </li>
    }
    @if (canViewDINBIL) {
      <li
        (click)="this.channelChangedEmitter.emit('DIN_BIL')" class="link"
        [ngClass]="{'underline': selectedChannel === 'DIN_BIL'}">
        {{ 'ucs.din_bil' | translate}} {{ elementsCountDINBIL || elementsCountDINBIL === 0 ? '(' + elementsCountDINBIL + ')' : ''}}
      </li>
    }
    @if (canViewVGRX) {
      <li
        (click)="this.channelChangedEmitter.emit('VGRX')" class="link"
        [ngClass]="{'underline': selectedChannel === 'VGRX'}">
        {{ 'ucs.vgrx' | translate}} {{ elementsCountVGRX || elementsCountVGRX === 0 ? '(' + elementsCountVGRX + ')' : ''}}
      </li>
    }
  </ul>
</div>
