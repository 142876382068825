import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {PageSettings} from '../../model/page-settings.model';
import * as fromRoot from '../../store/app.reducers';
import {map, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {
  ResetVehicleAction,
  UpdatedMaintenanceVehiclesAction,
  UpdatedVehicleMaintenanceSearchPageSettings
} from '../../store/vehicle-maintenance/vehicle-maintenance.actions';
import {SpinnerService} from '../../service/spinner.service';
import * as fromVehicle from '../../store/vehicle-maintenance/vehicle-maintenance.reducers';
import {VehicleMaintenanceSearchService} from '../../service/vehicle-maintenance-search.service';
import {ArchiveSearchService} from '../../service/archive-search.service';

@Component({
  selector: 'ucs-vehicle-identification-full-text-search',
  templateUrl: './vehicle-identification-full-text-search.component.html'
})
export class VehicleIdentificationFullTextSearchComponent implements OnInit, OnDestroy {
  @Output() fulltextSearch = new EventEmitter();
  pageSettings: PageSettings;
  private vehicleBaseDtoReturnValue: any;
  private unsubscribe: Subject<void> = new Subject<void>();
  private searchString: string;

  constructor(
    private store: Store<fromRoot.AppState>,
    private vehicleStore: Store<fromVehicle.State>,
    private vehicleMaintenanceSearchService: VehicleMaintenanceSearchService,
    private spinnerService: SpinnerService,
    private archiveSearchService: ArchiveSearchService) {
  }

  ngOnInit() {
    this.store.select(fromRoot.getVehicleMaintenanceSearchPageSettings)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(pageSettings => {
        this.pageSettings = pageSettings;
      });
  }

  /**
   * When input field for is changed, after string length of >5 the list is updated
   * @param $event the change value
   */
  onChangeSearchString($event) {
    this.searchString = $event;
    this.search();
  }

  /**
   * When the enter key was released a search is started on any three digits long string
   * @param $event the change value
   */
  onKeyUpEnter($event) {
    if ($event && $event.toString().trim().length >= 3) {
      this.searchString = $event;
      this.search();
    }
  }

  search(): void {
    if (this.searchString === '') {
      this.fulltextSearch.emit(this.searchString);
      return;
    }
    // whenever requirements are fulfilled to conduct a search, expose our search string to parent component
    if (this.pageSettings.page !== 0) {
      this.pageSettings.page = 0;
      this.store.dispatch(new UpdatedVehicleMaintenanceSearchPageSettings(this.pageSettings));
    }
    this.fulltextSearch.emit(this.searchString);

    this.spinnerService.spinner(
      this.vehicleMaintenanceSearchService.getVehiclesByIdentificationMatch(this.searchString)
        .pipe(
          map(resultVehicleBaseDto => {
            this.vehicleBaseDtoReturnValue = resultVehicleBaseDto;
            if (this.vehicleBaseDtoReturnValue.content.length === 0) {
              // directSearchField 'VIN' will be ignored in backend
              this.spinnerService.spinner(
                this.archiveSearchService.getByDirectSearchField(this.searchString, 'VIN', 'VEHICLE', this.pageSettings)
              ).subscribe(resultArchiveSearch => {
                this.vehicleStore.dispatch(new UpdatedMaintenanceVehiclesAction(resultArchiveSearch));
              });
            } else {
              this.vehicleStore.dispatch(new UpdatedMaintenanceVehiclesAction(resultVehicleBaseDto));
            }
          })
        )
    ).subscribe();
  }

  /**
   * Unsubscribe from Observables when component is destroyed to prevent memory leaks
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.store.dispatch(new ResetVehicleAction());
  }
}
