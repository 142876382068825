import {Inject, Injectable, OnDestroy} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as fromRoot from '../store/app.reducers';
import {Store} from '@ngrx/store';
import {EMPTY, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';

/**
 * This service handles search-related http calls to the respective REST endpoints
 */
@Injectable()
export class SearchService implements OnDestroy {
  searchParams: VehicleOfferSearchParams;
  searchScopeParams: VehicleOfferSearchParams;
  enforcedAuctionScopeParams: VehicleOfferSearchParams;
  finishedSearchParams: VehicleOfferSearchParams;
  private unsubscribe: Subject<void> = new Subject<void>();
  private userInfo: UserInfoDto;

  constructor(private http: HttpClient, private store: Store<fromRoot.AppState>,
              @Inject(APP_CONFIG) private config: AppConfig) {
    this.onInit(); // life cycle hooks do not get called within @Injectable() except for OnDestroy
  }

  /**
   * Initialize the service
   */
  onInit() {
    // select searchParams from store and subscribe to future updates
    this.store.select(fromRoot.getSearchParams)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(searchParams => {
        this.searchScopeParams = searchParams;
      });

    // select finishedSearchParams from store and subscribe to future updates
    this.store.select(fromRoot.getFinishedSearchParams)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(searchParams => {
        this.finishedSearchParams = searchParams;
      });

    this.store.select(fromRoot.getUserInfo)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userInfo => {
        if (userInfo) {
          this.userInfo = userInfo;
        }
      });

    this.store.select(fromRoot.getEnforcedAuctionOfferSearchParams)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(searchParams => {
        this.enforcedAuctionScopeParams = searchParams;
      });
  }

  /**
   * Unsubscribe from Observables when service is destroyed to prevent memory leaks
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Fetches search aggregations based on search parameters
   * @returns {Observable<VehicleOfferSearchAggregation>}: aggregated search values
   */
  getSearchAggregations(): Observable<VehicleOfferSearchAggregation> {
    this.searchParams = this.searchScopeParams;
    // set http params based on search params from store
    if (AuthenticationService.isOnlyAllowedToSeeFinishedOffersReasonGtc(this.userInfo)) {
      return EMPTY;
    }
    let params = this.setHttpParams();
    return this.http.get<VehicleOfferSearchAggregation>(this.config.salesApiUrl + '/offers/aggregations',
      {params: params});
  }

  /**
   * Fetches search aggregations based on search parameters
   * @returns {Observable<VehicleOfferSearchAggregation>}: aggregated search values
   */
  getEnforcedAuctionOfferSearchAggregations(): Observable<VehicleOfferSearchAggregation> {
    this.searchParams = this.enforcedAuctionScopeParams ;
    // set http params based on search params from store
    if (AuthenticationService.isOnlyAllowedToSeeFinishedOffersReasonGtc(this.userInfo)) {
      return EMPTY;
    }
    let params = this.setHttpParams();
    return this.http.get<VehicleOfferSearchAggregation>(this.config.salesApiUrl + '/offers/aggregations',
      {params: params});
  }

  private setHttpParams() {
    let params = new HttpParams();
    if (this.searchParams) {
      if (this.searchParams.channels && this.searchParams.channels.length > 0) {
        params = params.set('channels', String(this.searchParams.channels));
      }
      params = this.addHttpParam(this.searchParams.brand, 'brand', params);
      params = this.addHttpParam(this.searchParams.brand2, 'brand2', params);
      params = this.addHttpParam(this.searchParams.brand3, 'brand3', params);
      params = this.addHttpParam(this.searchParams.brand4, 'brand4', params);
      params = this.addHttpParam(this.searchParams.brand5, 'brand5', params);

      params = this.getModelsSearchParams(params);
      params = this.getModelVariantsSearchParams(params);

      params = this.addHttpParam(this.searchParams.priceFrom, 'priceFrom', params);
      params = this.addHttpParam(this.searchParams.priceTo, 'priceTo', params);
      params = this.addHttpParam(this.searchParams.powerPsFrom, 'powerPsFrom', params);
      params = this.addHttpParam(this.searchParams.powerPsTo, 'powerPsTo', params);
      params = this.addHttpParam(this.searchParams.mileageFrom, 'mileageFrom', params);
      params = this.addHttpParam(this.searchParams.mileageTo, 'mileageTo', params);

      params = this.addHttpParam(this.searchParams.motorCapacityFrom, 'motorCapacityFrom', params);
      params = this.addHttpParam(this.searchParams.motorCapacityto, 'motorCapacityto', params);
      params = this.addHttpParam(this.searchParams.consumptionFrom, 'consumptionFrom', params);
      params = this.addHttpParam(this.searchParams.consumptionTo, 'consumptionTo', params);
      params = this.addHttpParam(this.searchParams.initialRegistrationFrom, 'initialRegistrationFrom', params);
      params = this.addHttpParam(this.searchParams.initialRegistrationTo, 'initialRegistrationTo', params);
      params = this.addHttpParam(this.searchParams.zipCode, 'zipCode', params);
      params = this.addHttpParam(this.searchParams.city, 'city', params);

      params = this.addHttpParam(this.searchParams.countries, 'countries', params);
      params = this.addHttpParam(this.searchParams.seats, 'seats', params);
      params = this.addHttpParam(this.searchParams.chassis, 'chassis', params);
      params = this.addHttpParam(this.searchParams.colors, 'colors', params);
      params = this.addHttpParam(this.searchParams.gearTypes, 'gearTypes', params);
      params = this.addHttpParam(this.searchParams.fuels, 'fuels', params);
      params = this.addHttpParam(this.searchParams.equipments, 'equipments', params);
      params = this.addHttpParam(this.searchParams.co2Emission, 'co2Emission', params);
      params = this.addHttpParam(this.searchParams.emissionClass, 'emissionClass', params);
      params = this.addHttpParam(this.searchParams.combinedSellingDealers, 'combinedSellingDealers', params);
      params = this.addHttpParam(this.searchParams.exploitationType, 'exploitationType', params);

      params = this.addHttpParam(this.searchParams.vatType, 'vatType', params);
      params = this.addHttpParam(this.searchParams.expiration, 'expiration', params);
      params = this.addHttpParam(this.searchParams.automaticTransmission, 'automaticTransmission', params);
      params = this.addHttpParam(this.searchParams.fourWD, 'fourWD', params);
      params = this.addHttpParam(this.searchParams.heatedSeats, 'heatedSeats', params);
      params = this.addHttpParam(this.searchParams.leatherSeats, 'leatherSeats', params);
      params = this.addHttpParam(this.searchParams.trailerHitch, 'trailerHitch', params);
      params = this.addHttpParam(this.searchParams.navigation, 'navigation', params);
      params = this.addHttpParam(this.searchParams.xenonLedLight, 'xenonLedLight', params);
      params = this.addHttpParam(this.searchParams.types, 'types', params);
      params = this.addHttpParam(this.searchParams.bundling, 'bundling', params);
      params = this.addHttpParam(this.searchParams.vin, 'vin', params);
      params = this.addHttpParam(this.searchParams.extDealerId, 'extDealerId', params);
      params = this.addHttpParam(this.searchParams.extVehicleId, 'extVehicleId', params);
    }
    return params;
  }

  private getModelVariantsSearchParams(params: HttpParams) {
    if (this.searchParams.modelVariants?.length > 0) {
      params = params.set('modelVariants', String(this.searchParams.modelVariants));
    }
    if (this.searchParams.modelVariants2?.length > 0) {
      params = params.set('modelVariants2', String(this.searchParams.modelVariants2));
    }
    if (this.searchParams.modelVariants3?.length > 0) {
      params = params.set('modelVariants3', String(this.searchParams.modelVariants3));
    }
    if (this.searchParams.modelVariants4?.length > 0) {
      params = params.set('modelVariants4', String(this.searchParams.modelVariants4));
    }
    if (this.searchParams.modelVariants5?.length > 0) {
      params = params.set('modelVariants5', String(this.searchParams.modelVariants5));
    }
    return params;
  }

  private getModelsSearchParams(params: HttpParams) {
    if (this.searchParams.models?.length > 0) {
      params = params.set('models', String(this.searchParams.models));
    }
    if (this.searchParams.models2?.length > 0) {
      params = params.set('models2', String(this.searchParams.models2));
    }
    if (this.searchParams.models3?.length > 0) {
      params = params.set('models3', String(this.searchParams.models3));
    }
    if (this.searchParams.models4?.length > 0) {
      params = params.set('models4', String(this.searchParams.models4));
    }
    if (this.searchParams.models5?.length > 0) {
      params = params.set('models5', String(this.searchParams.models5));
    }
    return params;
  }

  private addHttpParam(field: any, name: string, params: HttpParams) {
    if (field) {
      if(field instanceof Array) {
        params = params.set(name, field.toString());
      } else {
        params = params.set(name, String(field));
      }
    }
    return params;
  }

  /**
   * Fetches locations based on filter
   * @returns {Observable<LocationDto>}: locations matching the filter
   */
  getLocations(filter: string): Observable<LocationDto> {
    const params = new HttpParams().set('filter', filter);
    return this.http.get<LocationDto>(this.config.salesApiUrl + '/offers/locations', {params: params});
  }
}
