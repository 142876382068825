import {Component, Input, OnDestroy, OnInit, SimpleChanges, OnChanges} from '@angular/core';
import {HtmlSanitizerService} from '../../../service/html-sanitizer.service';
import {Subject} from 'rxjs';

/**
 * This component encapsulates information on the dealer
 */
@Component({
  selector: 'ucs-offer-seller',
  templateUrl: './offer-seller.component.html',
  styleUrls: ['./offer-seller.component.scss']
})
export class OfferSellerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isTop: boolean;
  @Input() offer: OfferDto;
  @Input() showTop: boolean;
  @Input() isAccountingClerk: boolean;
  user: UserDto;
  private unsubscribe: Subject<void> = new Subject<void>();
  protected readonly VehicleItemBaseDto: VehicleItemBaseDto;

  ngOnInit(): void {
    this.setSellerInformation();
  }

  validateEmailSubject(text: string) {
    return HtmlSanitizerService.sanitize(text);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setSellerInformation();
  }

  private setSellerInformation() {
    if (this.isAccountingClerk) {
      this.user = this.offer.sellerInformation.accountingClerkUser;
    } else {
      this.user = this.offer.sellerInformation.contactPerson;
    }
  }
}
