<!-- type default => value[] has max length 1 -->
@if (bullet && bullet.type === 'default' && bullet.value[0] !== '') {
  <div class="bullet">
    <div class="text px-3">
      @if (bullet['key'].startsWith('channels')) {
        <span>{{ 'ucs.' + bullet.value[0].toLowerCase() | translate }}</span>
      }
      @if (bullet['key'].startsWith('combinedSellingDealers')) {
        <span>{{ bullet.value[0] }}</span>
      }
      @if (bullet['key'].startsWith('countries')) {
        <span>{{ 'api.type.country.' + bullet.value[0] | translate }}</span>
      }
      @if (bullet['key'] === 'vatType') {
        <span>{{ 'api.type.vat.' + bullet.value[0] | translate }}</span>
      }
      @if (bullet['key'].startsWith('exploitationType')) {
        <span
        >{{ 'api.type.exploitation.' + bullet.value[0] | translate }}</span>
      }
      @if (bullet['key'].startsWith('colors')) {
        <span>{{ 'api.type.basecolor.' + bullet.value[0] | translate }}</span>
      }
      @if (bullet['key'].startsWith('chassis')) {
        <span>{{  bullet.value[0] }}</span>
      }
      @if (bullet['key'].startsWith('seats')) {
        <span><span
        class="me-1">{{  bullet.value[0] }}</span>{{ 'search-filter.seats' | translate }}</span>
      }
      @if (bullet['key'].startsWith('gears')) {
        <span>{{  bullet.value[0] }}</span>
      }
      @if (bullet['key'].startsWith('gearTypes')) {
        <span>{{  bullet.value[0] }}</span>
      }
      @if (bullet['key'] === 'co2Emission') {
        <span>{{  (bullet.value[0] | cast : Number) | gramsPerHundredKm }}</span>
      }
      @if (bullet['key'].startsWith('emissionClass')) {
        <span>{{  bullet.value[0] }}</span>
      }
      @if (bullet['key'].startsWith('fuels')) {
        <span>{{ bullet.value[0] }}</span>
      }
      @if (bullet['key'] === 'bundling') {
        <span>{{ 'api.type.offerbundling.' + bullet.value[0] | translate }}</span>
      }
      @if (bullet['key'] === 'type') {
        <span>{{ 'api.type.offertype.' + bullet.value[0] | translate }}</span>
      }
      @if (bullet['key'].startsWith('brand')) {
        <span>{{ bullet.value[0] }}</span>
      }
      @if (bullet['key'].startsWith('models')) {
        <span>{{ bullet.value[0] }}</span>
      }
      @if (bullet['key'].startsWith('modelVariants')) {
        <span>{{ bullet.value[0] }}</span>
      }
      @if (bullet['key'] === 'extDealerId') {
        <span>{{'search-filter.extDealerId' | translate}}
        : {{ bullet.value[0] }}</span>
      }
      @if (bullet['key'] === 'extVehicleId') {
        <span>{{'search-filter.extVehicleId' | translate}}
        : {{ bullet.value[0] }}</span>
      }
      @if (bullet['key'] === 'vin') {
        <span>{{'search-filter.vin' | translate}}: {{ bullet.value[0] }}</span>
      }
    </div>
    <ucs-icon class="icon" (click)="delete(bullet)" [shape]="'cancel'" [style]="'grey'"></ucs-icon>
  </div>
}

<!-- type default => value[] has max length 1 -->
@if (bullet && bullet.type === 'equipmentHighlight' && bullet.value[0] !== '') {
  <div class="bullet">
    <div class="text px-3">
      <span>{{ 'equipment.' + bullet.value[0] | translate }}</span>
    </div>
    <ucs-icon class="icon" (click)="delete(bullet)" [shape]="'cancel'" [style]="'grey'"></ucs-icon>
  </div>
}

<!-- type range => value[] has max length 2 -->
@if (bullet && bullet.type === 'range' && (bullet.value[0] !== '' || bullet.value[1] !== '')) {
  <div class="bullet">
    <div class="text px-3">
      @if (bullet.value[0] !== '') {
        <span>
          @if (!bullet['key'].startsWith('price')) {
            <span class="px-1">{{ 'search-range-dropdown.from' | translate }}</span>
          }
          @if (bullet['key'].startsWith('price')) {
            <span>{{ 'search-filter.price.from' | translate }} <span
            class="pe-1">{{ (bullet.value[0] | cast : Number) | price }}</span></span>
          }
          @if (bullet['key'].startsWith('powerPs')) {
            <span>{{ (bullet.value[0] | cast : Number) | ps }} </span>
          }
          @if (bullet['key'].startsWith('mileage')) {
            <span>{{ (bullet.value[0] | cast : Number) | km }} </span>
          }
          @if (bullet['key'].startsWith('initialRegistration')) {
            <span>{{ bullet.value[0] }} </span>
          }
          @if (bullet['key'].startsWith('consumption')) {
            <span>
              @if (bullet.value[1] !== '') {
                <span>{{ bullet.value[0] }} </span>
              }
              @if (bullet.value[1] === '') {
                <span>{{ (bullet.value[0] | cast : Number) | litersPerHundredKm }} </span>
              }
            </span>
          }
          @if (bullet['key'].startsWith('motorCapacity')) {
            <span>
              @if (bullet.value[1] !== '') {
                <span>{{ bullet.value[0] }} </span>
              }
              @if (bullet.value[1] === '') {
                <span>{{ (bullet.value[0] | cast : Number) | ccm }} </span>
              }
            </span>
          }
        </span>
      }
      @if (bullet.value[1] !== '') {
        <span>
          @if (!bullet['key'].startsWith('price')) {
            <span>{{ 'search-filter.until' | translate }} </span>
          }
          @if (bullet['key'].startsWith('price')) {
            <span>{{ 'search-filter.price.to' | translate }} </span>
          }
          @if (bullet['key'].startsWith('price')) {
            <span><span
            class="pe-1">{{ (bullet.value[1] | cast : Number) | price }}</span></span>
          }
          @if (bullet['key'].startsWith('powerPs')) {
            <span>{{ (bullet.value[1] | cast : Number) | ps }}</span>
          }
          @if (bullet['key'].startsWith('mileage')) {
            <span>{{ (bullet.value[1] | cast : Number) | km }}</span>
          }
          @if (bullet['key'].startsWith('initialRegistration')) {
            <span>{{ bullet.value[1] }}</span>
          }
          @if (bullet['key'].startsWith('consumption')) {
            <span
            >{{ (bullet.value[1] | cast : Number) | litersPerHundredKm }}</span>
          }
          @if (bullet['key'].startsWith('motorCapacity')) {
            <span>{{ (bullet.value[1] | cast : Number) | ccm }}</span>
          }
        </span>
      }
    </div>
    <ucs-icon class="icon" (click)="delete(bullet)" [shape]="'cancel'" [style]="'grey'"></ucs-icon>
  </div>
}

<!-- type city => value[] has max length 2 -->
@if (bullet && bullet.type === 'city' && (bullet.value[0] !== '' || bullet.value[1] !== '')) {
  <div class="bullet">
    <div class="text px-3">
      @if (bullet.value[0] !== '') {
        <span class="pe-1">{{ bullet.value[0] }}</span>
      }
      @if (bullet.value[1] !== '') {
        <span>{{ bullet.value[1] }}</span>
      }
    </div>
    <ucs-icon class="icon" (click)="delete(bullet)" [shape]="'cancel'" [style]="'grey'"></ucs-icon>
  </div>
}

<!-- type equipment -->
@if (bullet && bullet.type === 'equipment') {
  <div class="bullet">
    <div class="text px-3">
      <span>{{ 'api.type.usedcarequipment.' + bullet.value[0] | translate }}</span>
    </div>
    <ucs-icon class="icon" (click)="delete(bullet)" [shape]="'cancel'" [style]="'grey'"></ucs-icon>
  </div>
}

