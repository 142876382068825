<div class="detail-container row">
  <nav class="col-auto mx-auto col-lg mx-lg-0">

    @if (paginationPages?.length > 0) {
      <ul class="pagination">
        <li class="page-item">
          <button (click)="selectPage(pageable.pageNumber - 1)"
            [disabled]="pageable.pageNumber === 0"
            [ngClass]="{'disabled': pageable.pageNumber === 0}"
            class="page-link">
            <ucs-icon [shape]="'angle_left'" [class]="'blue'" [size]="24"></ucs-icon>
            <span class="sr-only">{{'ucs.pagination.previous' | translate}}</span>
          </button>
        </li>
        @if (!getPagesSubSelection().includes(getFirstPage())) {
          <li>
            <button (click)="selectPage(0)" [ngClass]="{'selected': 0 === pageable.pageNumber}"
              class="page-link mx-1" href="#">
              <strong> 1 </strong>
            </button>
          </li>
        }
        @if (!getPagesSubSelection().includes(getFirstPage())) {
          <li class="align-items-center d-flex">
            <strong class="mx-1 align-bottom dots">...</strong>
          </li>
        }
        @for (page of paginationPages.length > 5 ? getPagesSubSelection(): paginationPages; track page) {
          <li class="page-item">
            <button (click)="selectPage(page)" [ngClass]="{'selected': page === pageable.pageNumber}"
              class="page-link mx-1" href="#"><strong>{{ page + 1 }}</strong></button>
            </li>
          }
          @if (!getPagesSubSelection().includes(getLastPage())) {
            <li class="align-items-center d-flex">
              <strong class="mx-1 dots">...</strong>
            </li>
          }
          @if (!getPagesSubSelection().includes(getLastPage())) {
            <li>
              <button (click)="selectPage(paginationPages.length - 1)" [ngClass]="{'selected': paginationPages.length - 1 === pageable.pageNumber}"
                class="page-link mx-1" href="#">
                <strong>{{ paginationPages.length }}</strong>
              </button>
            </li>
          }
          @if (paginationPages) {
            <li class="page-item">
              <button (click)="selectPage(pageable.pageNumber + 1)"
                [disabled]="pageable.pageNumber === paginationPages[paginationPages.length - 1]"
                [ngClass]="{'disabled': pageable.pageNumber === paginationPages[paginationPages.length - 1]}"
                class="page-link">
                <span class="sr-only">{{'ucs.pagination.next' | translate}}</span>
                <ucs-icon [shape]="'angle_right'" [class]="'blue'" [size]="24"></ucs-icon>
              </button>
            </li>
          }
        </ul>
      }
    </nav>
      <ucs-page-size class="text-lg-right m-1 col-auto mx-auto"
                     [pageable]="pageable"
                     (paginationRefresh)="paginationRefresh.emit()">
      </ucs-page-size>
  </div>
