import {Component, Input} from '@angular/core';
import {NgxFloatUiTriggers} from 'ngx-float-ui';

/**
 * A simple component with a label, a price and an optional hint
 */
@Component({
  selector: 'ucs-label-price',
  templateUrl: './label-price.component.html',
  styleUrls: ['./label-price.component.scss']
})
export class LabelPriceComponent {
  @Input() label: string;
  @Input() currency: string;
  @Input() price: number;
  @Input() hint: string;
  @Input() tooltipOverLabel: string;
  @Input() showNetPriceUnavailableInfo: boolean;
  @Input() sumIncalculable: boolean;
  protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
