/**
 * Dialog for bidding while and after ACTIVE auction phase for internal and external dealers
 */
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {OfferService} from '../../service/offer.service';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import {filter, first, takeUntil} from 'rxjs/operators';
import {isAuctionOfferDto, isBuyNowOfferDto, isVehicleItemBaseDto} from '../../misc/typeguard';
import {Observable, Subject} from 'rxjs';
import {SystemSettingsService} from '../../service/system-settings.service';
import {DealerService} from '../../service/dealer.service';
import {addPrices, subtractPrices, ucsDeepCopy} from '../../misc/utils';
import {ToastAlertService} from '../../service/toast-alert.service';
import {HttpErrorResponse} from '@angular/common/http';

declare let $: any;

@Component({
  selector: 'ucs-external-dealer-bid-dialog',
  templateUrl: './external-dealer-bid-dialog.component.html',
  styleUrls: ['./external-dealer-bid-dialog.component.scss']
})
export class ExternalDealerBidDialogComponent implements OnInit, OnChanges, OnDestroy {
  @Input() offer: AuctionOfferDto | BuyNowOfferDto;
  @Output() update = new EventEmitter<any>();
  bidRequest = {} as BidRequestDto;
  validationError: string;
  generalValidationError: string;
  grossPriceValidationError: string;
  currentPrice: PriceDto;
  comment: string;
  dealer: DealerDetailDto;
  vehicleItem: VehicleItemBaseDto;
  isPiaOrAllAuctionSeller: boolean;
  currentDealerId: number;
  manualInput: boolean;
  manualInputValid: boolean;
  allowedToSeeManualInput = false;
  isBuyNowOffer = false;
  isAuctionOffer = false;
  dealerAutoCompleteSourceFunction: (string) => Observable<DealerDetailDto[]>;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private offerService: OfferService,
              private alertService: ToastAlertService,
              private dealerService: DealerService,
              private translate: TranslateService,
              private store: Store<fromRoot.AppState>,
              private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit() {
    this.isAuctionOffer = isAuctionOfferDto(this.offer);
    this.isBuyNowOffer = isBuyNowOfferDto(this.offer);

    let buyerRoleForChannel: { [p in DistributionChannel]: DealerRole };
    buyerRoleForChannel = {'PB': 'PBV_BUYER', 'PIA': 'PIA_BUYER', 'ALL_UC': 'ALL_UC_BUYER',
      'DIN_BIL': 'DIN_BIL_BUYER', 'VGRX': 'VGRX_BUYER',  'ANY': undefined};
    this.dealerAutoCompleteSourceFunction = (searchFilter: string) =>
      this.dealerService
        .getDealers(searchFilter, [buyerRoleForChannel[this.offer.channel.data]], this.offer.channel.data);
    this.manualInput = false;
    this.manualInputValid = false;
    if (isVehicleItemBaseDto(this.offer.items[0])) {
      this.vehicleItem = <VehicleItemBaseDto>this.offer.items[0];
    }
    this.store.select(fromRoot.getAuctionBid)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(bid => {
        if (bid) {
          this.currentPrice.net = bid * 100;
          this.initData();
        }
      });
    this.store
      .select(fromRoot.getUserState)
      .pipe(takeUntil(this.unsubscribe),
        filter(userState => !!userState.userInfo)
      )
      .subscribe(userState => {
        this.isPiaOrAllAuctionSeller = userState.isAuctionSellerPia || userState.isAuctionSellerAllUc;
        this.currentDealerId = userState.userInfo.currentDealerId;
      });
    this.systemSettingsService
      .isSystemFeatureActivatedForChannel('EXTERNAL_DEALER_MANUAL_ENTRY', this.offer.channel.data)
      .pipe(first()).subscribe(activated => this.allowedToSeeManualInput = activated);

    if(this.isBuyNowOffer) {
      this.currentPrice = (this.offer as BuyNowOfferDto).price;
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initData();
  }

  /**
   * Add selected dealer to local variable
   * @param $event The selected dealer
   */
  dealerSelected($event) {
    if ($event) {
      this.dealer = $event;
    } else {
      this.dealer = {} as DealerDetailDto;
    }
    this.bidRequest.dealerId = this.dealer.id;
  }

  /**
   * Dealer has been entered manually
   * @param $event The entered dealer
   */
  dealerEntered($event: ExternalDealerInformationDto) {
    this.bidRequest.externalCustomerDealer = $event;
    this.bidRequest.dealerId = undefined;
  }

  /**
   * Change from manual dealer input to dealer auto complete search and back
   */
  switchDealerEntryType() {
    this.manualInput = !this.manualInput;
    this.dealer = undefined;
    this.bidRequest.externalCustomerDealer = undefined;
    this.bidRequest.dealerId = undefined;
  }

  purchase() {
    this.offerService.purchaseOfferForDealer(this.offer.id, this.dealer.id, this.comment, false)
      .subscribe({
        next: (offer) => { // Success
          if (offer.status.data === 'FINISHED') {
            this.alertService.showAlerts('success', this.translate.instant('buy-now.dialog.purchase.successful'));
            $('#confirmBidModal' + this.offer.id).modal('hide');
            this.offer = offer as BuyNowOfferDto;
            this.currentPrice = this.offer.purchase.price;
            this.update.emit(this.offer);
          }
        }, error: (err) => {
          if ((err as HttpErrorResponse).error.code === 'error.mail.recipients') {
            this.alertService.info(this.translate.instant('mail.error.no-recipients'));
            this.alertService.showAlerts('success', this.translate.instant('buy-now.dialog.purchase.successful'));
            $('#confirmBidModal' + this.offer.id).modal('hide');
          }
        }
      });
  }

  /**
   * Bid on the offer
   */
  bid() {
    this.validationError = undefined;
    this.grossPriceValidationError = undefined;
    this.generalValidationError = undefined;
    if (!this.currentPrice.net) {
      this.validationError = 'bid-dialog.validation.missing-bid';
    } else {
      this.checkAndSetValidationErrorText();
      this.checkAndSetGeneralValidationErrorText();
    }
    this.checkAndSetGrossPriceValidationErrorText();

    if (this.manualInput && !this.manualInputValid) { // check if manual input is valid and set validation error, so bid does not work
      this.generalValidationError = 'bid-dialog.validation.fill-in-dealer';
    }

    if (!this.validationError && !this.generalValidationError) {
      this.bidRequest.netPrice = this.currentPrice.net;
      this.bidRequest.grossPrice = this.currentPrice.gross;
      this.bidRequest.comment = this.comment.trim();

      this.offerService.bidOnOffer(this.offer.id, this.bidRequest)
        .subscribe(res => { // Success
          let updatedOffer = this.offer as AuctionOfferDto;
          updatedOffer.highestBid = (<BidResponseDto>res).highestBid;
          updatedOffer.bids = res.bids;
          updatedOffer.followUpPossible = res.followUpPossible;
          updatedOffer.bidStatus = res.bidStatus;
          this.offer = updatedOffer;
          this.update.emit(this.offer);
          this.alertService.success(this.translate.instant('bid.dialog.bid.successful'));
          $('#confirmBidModal' + this.offer.id).modal('hide');
        });
    }
  }

  private checkAndSetGrossPriceValidationErrorText() {
    // error handling for gross price input field
    if (('' + this.currentPrice.gross).match('^[0-9]+$') === null) {
      this.grossPriceValidationError = 'bid-dialog.validation.bid-no-whole-number';
    }
    if (this.offer.status.data !== 'EXPIRED' && this.currentPrice.gross < ((this.offer as AuctionOfferDto).minimumBid.net / 100)) {
      this.grossPriceValidationError = 'bid-dialog.validation.bid-lower-than-minimum';
    }
    if ((this.offer as AuctionOfferDto).maximumBid !== null) {
      if (this.currentPrice.gross < ((this.offer as AuctionOfferDto).maximumBid.net / 100)) {
        this.grossPriceValidationError = 'bid-dialog.validation.bid-lower-than-bidagent';
      }
    }
    if (this.grossPriceValidationError === undefined && ('' + this.currentPrice.gross).match('^[0-9]{1,12}$') === null) {
      this.grossPriceValidationError = 'bid-dialog.validation.figure-to-high';
    }
  }

  private checkAndSetGeneralValidationErrorText() {
    if (!this.comment || this.comment.trim().length < 1) {
      this.generalValidationError = 'bid-dialog.validation.missingComment';
    }
    if (!this.bidRequest.dealerId && !this.manualInput) {
      this.generalValidationError = 'bid-dialog.validation.missingDealer';
    }
    if (this.isPiaOrAllAuctionSeller && this.dealer && (this.currentDealerId === this.dealer.id)) {
      this.generalValidationError = 'bid-dialog.validation.bid-as-own-dealer';
    }
  }

  private checkAndSetValidationErrorText() {
    if (('' + this.currentPrice.net).match('^[0-9]+$') === null) {
      this.validationError = 'bid-dialog.validation.bid-no-whole-number';
    }
    if (this.offer.status.data !== 'EXPIRED' && this.currentPrice.net < ((this.offer as AuctionOfferDto).minimumBid.net / 100)) {
      this.validationError = 'bid-dialog.validation.bid-lower-than-minimum';
    }
    if ((this.offer as AuctionOfferDto).maximumBid !== null) {
      if (this.currentPrice.net < ((this.offer as AuctionOfferDto).maximumBid.net / 100)) {
        this.validationError = 'bid-dialog.validation.bid-lower-than-bidagent';
      }
    }
    if (this.validationError === undefined && ('' + this.currentPrice.net).match('^[0-9]{1,12}$') === null) {
      this.validationError = 'bid-dialog.validation.figure-to-high';
    }
  }

  /**
   * On cancel clear error message and entered data
   */
  cancel() {
    this.validationError = undefined;
    this.generalValidationError = undefined;
    this.store.select(fromRoot.getAuctionBid)
      .pipe(first())
      .subscribe(bid => {
        if (bid) {
          this.currentPrice.net = bid * 100;
        }
      });
  }

  private initData(): void {
    if (this.offer.status.data === 'EXPIRED') {
      this.bidRequest.bidType = 'ON_BEHALF_RENEGOTIATED';
    } else {
      this.bidRequest.bidType = 'ON_BEHALF_REGULAR';
    }

    if (this.currentPrice === undefined && (this.offer as AuctionOfferDto).minimumBid !== undefined) {
      // if a maximum bid is defined, propose the new one with a minimumStep
      if ((this.offer as AuctionOfferDto).maximumBid !== null && (this.offer as AuctionOfferDto).maximumBid !== undefined) {
        // if there has been a bid on this offer (and thus there is a highestBid), we can calculate minimumStep range
        // e.g. 2000 (minimumStep) = 25000 (minimumBid) - 23000 (highestBid)
        if ((this.offer as AuctionOfferDto).highestBid){
          const minimumStep = subtractPrices((this.offer as AuctionOfferDto).minimumBid, (this.offer as AuctionOfferDto).highestBid);
          this.currentPrice = addPrices((this.offer as AuctionOfferDto).maximumBid,minimumStep);
        } else {
          this.currentPrice = ucsDeepCopy((this.offer as AuctionOfferDto).maximumBid);
        }
      } else { // if no maximum bid is defined, the maximum bid is the same as the next regular minimumBid
        this.currentPrice = ucsDeepCopy((this.offer as AuctionOfferDto).minimumBid);
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected readonly BuyNowOfferDto: BuyNowOfferDto;
  protected readonly AuctionOfferDto: AuctionOfferDto;
}
