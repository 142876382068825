@if (userState.isAustrian || isNovaInfoGrossPriceEnabled ||
  ((this.nationalSale || this.scopeSection === 'SALES_MAINTENANCE'))) {
  <div>
    <div class="gross-price-container" [ngClass]="{'mb-2 not-calculable': !grossPrice}">
      @if (!grossPrice) {
        <span>{{ 'tile-footer.gross-price.not-calculable' | translate }}</span>
      }
      @else {
          @if (isEnforceAuction) {
              <div class="highest-bid-heading">
                  {{ (startPrice?.gross === currentPrice?.gross ? 'vehicle-footer.starting-price' : 'vehicle-footer.highest-bid') | translate }}
              </div>
          }
        <span [ngClass]="{'highest-bid' : isEnforceAuction}" [ngStyle]="{'font-size': (grossPrice?.toString().length >= 9) ? '18px' : '22px' }">
        {{ grossPrice | price }} {{ currentPrice.currency.data }}</span>
        <span class="gross-net ms-1">{{ 'api.type.pricetype.GROSS' | translate }}</span>
      }
    </div>
    @if (grossPrice) {
      <div class="row mb-2 icons">
        @if (this.isEnforceAuction && vatType === 'DIFFERENTIAL') {
          <div class="col-auto mt-3"></div>
        }
        @if (vatType === 'STANDARD' && (userState.isAustrian || channel === 'ALL_UC')) {
          <ucs-calc-icon class="col-auto" [type]="'OK'" [text]="'icon.vat.DIFFERENTIAL'"></ucs-calc-icon>
        }
        @if ((userState.isAustrian || isNovaInfoGrossPriceEnabled) && (offerType !== 'ENFORCED_AUCTION')) {
          <ucs-calc-icon class="col-auto"
            [type]="extraTax?.paid ? 'OK' : 'NOK'"
          [text]="'icon.nova.' + (extraTax?.paid ? 'OK' : 'NOK')"></ucs-calc-icon>
        }
      </div>
    }
  </div>
}
