@if (grossProfitData) {
  <ucs-gross-profit [grossProfitData]="grossProfitData"
    class="ucs-gross-profit w-100 d-inline-block text-nowrap {{showGrossProfit === true ? '' : ' invisible'}}">
  </ucs-gross-profit>
}

<div class="row gx-1 w-100 d-inline-block text-nowrap tax">
  <ucs-icon [shape]="vatType === 'STANDARD' ? 'info' : 'alert-triangle'" [size]="18"
  [class]="'grey'"></ucs-icon>
  <span class="ms-1 text-nowrap">{{ ('tile-footer.tax-nova.tax.' + vatType) | translate }}</span>
  @if (showVatValue && vatType === 'STANDARD') {
    <span class="ms-1 text-nowrap">({{vatValue}}%)</span>
  }
</div>

@if ((nationalSale || isNovaInfoGrossPriceEnabled) && !isEnforceAuctionOffer) {
  <ng-container>
    @if (country !== 'HR' || (country === 'HR' && nationalSale && extraTax?.paid)) {
      <div
        class="row gx-1 w-100 d-inline-block text-nowrap nova">
        @if (country !== 'HR') {
          <ucs-icon [shape]="extraTax?.paid ? 'info' : 'alert-triangle'" [size]="18" [class]="'grey'"></ucs-icon>
          <span class="ms-1 text-nowrap">{{ (extraTax?.paid ? 'tile-footer.tax-nova.nova.nova-paid' : 'tile-footer.tax-nova.nova.nova-not-paid') | translate }}</span>
        }
        @if (country === 'HR' && nationalSale && extraTax?.paid) {
          <ucs-icon [shape]="'info'" [size]="18" [class]="'grey'"></ucs-icon>
          <span class="ms-1 text-nowrap">{{ 'vehicle-detail.extra-tax-label.PPMV' | translate }} {{extraTax?.amount | price}} {{currency}}</span>
        }
      </div>
    }
  </ng-container>
}

@if (vehicleOfferItem.thermalWindowAffected && showThermalWindowAffected && isThermalWindowAffectedEnabled && scopeSection === 'SALES_STORE') {
  <div class="row gx-1 w-100 d-inline-block text-nowrap tax">
    <ucs-icon [shape]="'alert-triangle'" [size]="18" [class]="'grey'"></ucs-icon>
    <span class="ms-1 text-nowrap">{{ 'vehicle-maintenance.detail.thermal-window' | translate }}</span>
  </div>
}
