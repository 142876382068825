import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';

@Injectable()
export class OfferExpirationLockTimeService {

  private noCacheHeaders = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
  });

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) {
  }

  /**
   * Retrieve all offer expiration lock times for a given channel
   * @returns {Observable<OfferExpirationLockTimeDto[]>}:
   */
  getOfferExpirationLockTimesForChannel(channel: DistributionChannel): Observable<OfferExpirationLockTimeDto[]> {
    let params = new HttpParams().set('channel', channel);

    return this.http.get<OfferExpirationLockTimeDto[]>(this.config.salesApiUrl + '/maintenance/offer/locktime',
      {params: params});
  }

  /**
   * Create an offer expiration lock time
   * @param lockTime DTO containing all necessary information for lock time creation
   */
  createOfferExpirationLockTime(lockTime: OfferExpirationLockTimeDto): Observable<any> {
    return this.http.post(this.config.salesApiUrl + '/maintenance/offer/locktime', lockTime);
  }

  /**
   * Delete an offer expiration lock time
   * @param lockTimeId of the lock time that should be deleted
   */
  deleteOfferExpirationLockTime(lockTimeId: number) {
    return this.http.delete(this.config.salesApiUrl + '/maintenance/offer/locktime/' + lockTimeId);
  }
}
