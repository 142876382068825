import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import * as OfferActions from '../../store/offer/offer.actions';

@Component({
  selector: 'ucs-view-settings',
  templateUrl: './view-settings.component.html',
  styleUrls: ['./view-settings.component.scss']
})
export class ViewSettingsComponent implements OnInit {

  tags = <ViewTag[]>['MILEAGE', 'INITIAL-REGISTRATION', 'FUEL', 'POWER', 'COLOR', 'FUEL-CONSUMPTION', 'TAX',
    'EMISSION', 'EXPLOITATION-TYPE'];
  defaultActiveTags = <ViewTag[]>['MILEAGE', 'INITIAL-REGISTRATION', 'FUEL', 'POWER'];
  activeTags = <ViewTag[]>['MILEAGE', 'INITIAL-REGISTRATION', 'FUEL', 'POWER'];
  support = true;
  errorMessage = '';

  constructor(private store: Store<fromRoot.AppState>) {
  }

  /**
   * On init check for local storage support and get view settings from local storage if present.
   */
  ngOnInit(): void {
    this.support = this.supportsLocalStorage();
    if (this.support) {
      const items = localStorage.getItem('ucs-view-settings');
      if (items !== null) {
        this.activeTags = JSON.parse(items);
      }
    }
  }

  /**
   * Toggles a view tag: remove if present, add if not present.
   * @param {ViewTag} tag Indicates which tag is toggled
   */
  toggleTag(tag: ViewTag) {
    if (this.activeTags.includes(tag)) {
      this.activeTags = this.activeTags.filter(entry => entry !== tag);
    } else {
      this.activeTags.push(tag);
    }
    this.saveSettings();
  }

  /**
   * Save settings to local storage and redux store.
   */
  saveSettings() {
    try {
      localStorage.setItem('ucs-view-settings', JSON.stringify(this.activeTags));
      this.store.dispatch(new OfferActions.UpdateViewSettings(this.activeTags));
    } catch (e) {
      this.errorMessage = 'view-settings.save-error';
    }
  }

  /**
   * Reset view settings to default set of view settings.
   */
  resetToDefaults() {
    this.activeTags = [...this.defaultActiveTags];
    this.saveSettings();
  }

  /**
   * Check if the current device supports local storage.
   * @returns {boolean} If the current device supports local storage.
   */
  private supportsLocalStorage() {
    try {
      return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
      return false;
    }
  }
}
