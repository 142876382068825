<div class="row">
  <span [ngClass]="{'col-9' : displayLogo, 'col-12' : !displayLogo}">
    <div class="row footer rounded-bottom">
      <div class="d-flex justify-content-between">
        <div class="col-auto buy ps-2 pt-2 pb-2">
          @if (vehicle.offerDataDto && currentScope.valueOf() === 'MAINTENANCE_VEHICLE_DETAIL') {
            <button
              class="btn btn-primary" routerLink="/maintenance/offer/{{vehicle.offerDataDto.offerId}}">
              <strong>{{ 'vehicle.link-to-offer' | translate }}</strong>
            </button>
          }
        </div>
        @if (enforcedAuctionData?.status) {
          <div class="mt-2 text-end footer-font" data-cy="enforced-auction.vehicle-status">
            @if (isAdmin && piaAdminOnlyEnforcedItemStatus.includes(enforcedAuctionData?.status)) {
              <span>
                {{ 'vehicle-footer.enforced-auction-item-status.' + enforcedAuctionData?.status | translate }}
              </span>
            }
            @if (!piaAdminOnlyEnforcedItemStatus.includes(enforcedAuctionData?.status)) {
              <span>
                {{ 'vehicle-footer.enforced-auction-item-status.' + enforcedAuctionData?.status | translate }}
                @if (enforcedAuctionData?.status === 'OFFER_FINISHED') {
                  <span
                  >{{ vehicle.offerDataDto.purchaseDate | date : 'dd.MM.yyyy HH:mm:ss' }} </span>
                }
              </span>
            }
          </div>
        }
      </div>
      <div class="d-lg-flex d-grid justify-content-lg-between">
        <div class="col-lg-4">
          <ucs-starting-or-offer-price
            [isAdmin]="isAdmin"
            [itemStatus]="enforcedAuctionData?.status"
            [startingPrice]="enforcedAuctionData?.startingPrice"
            [catalogItemId]="enforcedAuctionData?.catalogItemId"
            [vin]="vehicle.vin"
            [vatType]="vehicle.vatType.data"
            [offerData]="vehicle.offerDataDto"
            data-cy="enforced-auction.starting-price">
          </ucs-starting-or-offer-price>
        </div>
        <div class="col-lg-3">
          <ucs-market-value
            [marketValue]="enforcedAuctionData?.marketValue">
          </ucs-market-value>
        </div>
        <div class="col-lg-3 text-lg-end pe-2">
          <ucs-e-auction-information
            [stationaryDays]="enforcedAuctionData?.stationaryDays"
            [numOfSaleAttempts]="enforcedAuctionData?.numOfSaleAttempts">
          </ucs-e-auction-information>
        </div>
      </div>
    </div>
  </span>
  @if (displayLogo) {
    <span [ngClass]="{'col-3' : displayLogo, 'col-0' : !displayLogo}" class="">
      @if (displayLogo) {
        <div [ngClass]="{'col-auto' : displayLogo, 'col-0' : !displayLogo}"
          class="logo col d-none d-md-block">
          <ucs-logo-icon [class]="getCssLogoClasses(vehicle.channel)"
            [country]="vehicle.country"
            [distributionChannel]="vehicle.channel">
          </ucs-logo-icon>
        </div>
      }
    </span>
  }
</div>
