@if (support) {
  @for (tag of tags; track tag) {
    <div class="mb-3">
      <input type="checkbox"
        [checked]="activeTags.includes(tag)"
        [id]="'view-settings-' + tag"
        (click)="toggleTag(tag)"
        [class]="'blue me-1'"/>
      <label [for]="'view-settings-' + tag">{{ 'view-settings.' + tag | translate }}</label>
    </div>
  }
  <p><button tabindex="1" class="btn btn-tertiary" (click)="resetToDefaults()">{{ 'view-settings.reset-to-defaults' | translate }}</button></p>
  <p>{{ errorMessage | translate }}</p>
}
@if (!support) {
  <p>{{ 'view-settings.no-support' | translate }}</p>
}
