import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/app.reducers';

/*
 * Returns the formatted number and appends the 'km' (kilometers) unit
 * Usage:
 *   value | km
 * Example:
 *   {{ vehicle.mileage | km }}
 *   Let vehicle.mileage be 130500, then the returned text will be "130,500 km" (dots or commas depending on localisation)
*/
@Pipe({
  name: 'km'
})
@Injectable()
export class KmPipe implements PipeTransform {

  private local: string;

  constructor(private store: Store<fromRoot.AppState>) {
  }

  transform(value: number): string {

    this.store.select(fromRoot.getUserState)
      .pipe()
      .subscribe(
        s => {
          this.local = s.local;
        }
      );
    // format number based on local
    const formattedValue = new DecimalPipe(this.local).transform(value);
    // append unit
    return formattedValue + ' km';
  }

}
