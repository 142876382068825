import {Component, Input} from '@angular/core';

@Component({
  selector: 'ucs-gross-profit',
  templateUrl: './gross-profit.component.html',
  styleUrls: ['./gross-profit.component.scss']
})
export class GrossProfitComponent {
  @Input() grossProfitData: GrossProfitDto;
}
