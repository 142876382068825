<input #priceInput=ngModel
  (focus)="onFocus()"
  type="number"
  lang="de"
  step="{{ step }}"
  min="0"
  pattern="^\d*(\.\d{0,2})?$"
  class="{{ class }}"
  name="{{ name }}"
  id="{{ id }}"
  [(ngModel)]="innerModel"
  [disabled]="disabled"
  [ngClass]="{'no-valid-ppmv' : inputError}"
  (ngModelChange)="inputChange($event)"
  [required]="required" max="9999999999"
  (keyup.enter)="enterOnInputElement.emit()">
@if (innerModel > 9999999999) {
  <div class="error">{{ 'bid-dialog.validation.figure-to-high' | translate }}</div>
}
@if (priceInput.invalid && priceInput.dirty && priceInput?.errors?.pattern) {
  <div
  class="error">{{ 'bid-dialog.validation.pattern' | translate }}</div>
}
@if (inputError) {
  <div
  class="error mt-1">{{ 'error.gross-smaller-ppmv' | translate }}</div>
}
