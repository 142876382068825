import {Component, Input} from '@angular/core';

@Component({
  selector: 'ucs-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  @Input() shape: string;
  @Input() class: string;
  @Input() size: number;

  handleSVG(svg: SVGElement): SVGElement {
    if (this.size) {
      svg.setAttribute('width', this.size.toString());
      svg.setAttribute('height', this.size.toString());
    }
    return svg;
  }
}
