import {Observable, throwError} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

/**
 * This service handles dealer-related http calls to the respective REST endpoints
 */
@Injectable()
export class DealerService {

  private allowedMimeTypes = ['application/pdf', 'image/jpeg', 'image/png'];

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig, private translateService: TranslateService) {
  }

  getDealer(dealerId: number){
    return this.http.get<DealerDetailDto>(this.config.salesApiUrl + '/dealer/' + dealerId);
  }

  getDealers(filter: string, roles: DealerRole[], channel: DistributionChannel): Observable<DealerDetailDto[]> {
    let params = new HttpParams().set('filter', filter);
    roles.forEach(dealerRole => {
      params = params.append('dealerRoles', dealerRole);
    });
    params = params.append('channel', channel);
    return this.http.get<DealerDetailDto[]>(this.config.salesApiUrl + '/dealers', { params: params }).pipe(
      map((dealers: DealerDetailDto[]) => {
        return dealers.map(dealer => {
          dealer.companyNumber = this.getCompanyNumberOrPbvCustomerNumber(dealer, channel);
          return dealer;
        });
      })
    );
  }

  /**
   * Get foreign dealers according to filter string.
   *
   * @param filter The string to filter for.
   */
  getForeignDealers(filter: string): Observable<DealerDetailDto[]> {
    const params = new HttpParams().set('filter', filter);
    return this.http.get<DealerDetailDto[]>(this.config.salesApiUrl + '/dealers/foreign', {params: params});
  }

  /**
   * Get the given dealer's settings.
   */
  getDealerSettings(dealerId: number): Observable<DealerSettingsDto> {
    return this.http.get<DealerSettingsDto>(this.config.salesApiUrl + '/dealers/' + dealerId + '/settings');
  }

  /**
   * Sets this dealer's contact settings
   * @param contactSettings The contact settings to set
   */
  setContactSettings(dealerId: number, contactSettings: ContactSettingsDto<ContactBaseSettingEntryDto>) {
    return this.http.put(this.config.salesApiUrl + '/dealers/' + dealerId + '/settings/contact', contactSettings);
  }


  /**
   * Get the given dealer's mail notification settings.
   */
  getDealerMailNotificationSettings(dealerId: number): Observable<DealerMailNotificationSettingsDto> {
    return this.http.get<DealerMailNotificationSettingsDto>(this.config.salesApiUrl + '/dealers/' + dealerId + '/mailnotificationsettings');
  }

  /**
   * Get the enforced auction default contact persons useris for a given dealer.
   */
  getEnforcedAuctionDefaultContactPersons(dealerId: number) {
    return this.http.get<number[]>(this.config.salesApiUrl + '/dealer/' + dealerId + '/enforcedAuctionDefaultContactPersons');
  }

  /**
   * Sets this dealer's mail notification settings
   * @param mailNotificationSettings The mail notification settings to set
   */
  setMailNotificationSettings(dealerId: number, mailNotificationSettings: DealerMailNotificationSettingsDto) {
    return this.http.post(this.config.salesApiUrl + '/dealers/' + dealerId + '/mailnotificationsettings', mailNotificationSettings);
  }

  /**
   * Update dealer data for external dealer
   *
   * @param dealerId The id from the dealer
   * @param dealerData The dealer data to set
   */
  setDealerData(dealerId: number, dealerData: DealerDataDto) {
    return this.http.put(this.config.salesApiUrl + '/dealers/' + dealerId + '/settings/dealerData', dealerData);
  }

  /**
   * Update mail supplementary text from the given id
   *
   * @param dealerId The id from the dealer
   * @param mailText The mail text to set
   */
  setMailtext(dealerId: number, mailText: MailSupplementaryTextDto) {
    return this.http.put(this.config.salesApiUrl + '/dealers/' + dealerId + '/settings/mailtext', mailText);
  }

  /**
   * Upload a dealer document of the given type for the given dealer.
   * @param dealerId The ID of the dealer for whom to upload a document.
   * @param documentType The type of document to upload.
   * @param document The document to upload itself.
   */
  uploadDealerDocument(dealerId: number, documentType: DocflowDocumentType, document: File):
    Observable<HttpEvent<DealerSettingsDto>> {
    if (!this.allowedMimeTypes.includes(document.type)) {
      return throwError(() => new Error(this.translateService.instant('settings.documents.upload.error')));
    }
    const formData = new FormData();
    formData.append('file', document);
    return this.http.post<DealerSettingsDto>(this.config.salesApiUrl + '/dealers/' + dealerId + '/accreditationdocuments/' +
      documentType, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  createDealerLock(dealerLock: DealerLockDto): Observable<any> {
    return this.http.post(this.config.salesApiUrl + '/dealers/' + dealerLock.dealerId + '/lock', dealerLock);
  }

  /**
   * Sets the unlock date to now for an existing dealer lock
   */
  prepareDealerUnlock(dealerId: number, unlockDate: Date): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.patch(this.config.salesApiUrl + '/dealers/' + dealerId + '/lock/unlockDate' +
      '?unlockDate=' + unlockDate.toISOString(), {}, httpOptions);
  }


  /**
   * Get all locked dealers.
   */
  getLockedDealers(filter: string, active: boolean, pageRequest: Pageable): Observable<Page<LockedDealerDto>> {
    let params = new HttpParams().set('filter', filter);
    params = params.append('active', String(active));
    params = params.append('size', pageRequest.pageSize.toString());
    params = params.append('page', pageRequest.pageNumber.toString());
    return this.http.get<Page<LockedDealerDto>>(this.config.salesApiUrl + '/dealers/locked', {params: params});
  }

  /**
   * Returns the company number or PBV customer number based on channel and what is available.
   *
   * It thus encapsulates our company number vs PBV customer number logic (so it can be used across components).
   *
   * @param dealer whose company number or PBV customer number to return
   * @param channel the channel as context
   */
  getCompanyNumberOrPbvCustomerNumber(dealer: DealerBaseDto | DealerDetailDto, channel: DistributionChannel): string {
    // case 1: the dealer has a regular company number (not just the country code, e.g. 'AT')
    if (('country' in dealer) && (dealer?.companyNumber !== dealer?.country?.data)) {
      return dealer.companyNumber;

      // case 2: the dealer has a PBV customer number AND the context is channel PB
    } else if (channel && channel === 'PB' && dealer?.pbvCustomerNumber) {
      return dealer.pbvCustomerNumber;
    }

    // case 3: the dealer has neither or its not PB channel and dealerBaseDto has no country defined -> return companyNumber as is (will be just the country code, e.g. 'AT')
    return dealer?.companyNumber ? dealer?.companyNumber : '';

  }
}
