import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[ucsNumberFormat]'
})
export class NumberFormatDirective {
  private numberFormat = new Intl.NumberFormat('de-DE');

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const cleanedValue = value.replace(/[^\d]/g, '');
    const wholeNumber: number = Number(cleanedValue.slice(0, -3));
    const formattedWholeNumber = this.numberFormat.format(wholeNumber);
    this.el.nativeElement.value = formattedWholeNumber + (formattedWholeNumber.length > 0 ? '.' : '') + cleanedValue.slice(-3);
  }
}
