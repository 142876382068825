<div [floatUi]="expirationDate | date : 'dd.MM.yyyy HH:mm (z)' "
  [showTrigger]="NgxFloatUiTriggers.hover"
  [placement]="NgxFloatUiPlacements.TOP"
  [applyClass]="'countdown-float-ui-size'"
  class="d-flex justify-content-md-end justify-content-center">
  <ucs-icon class="icon-small-height" [class]="color" [shape]="'time_clock'" [size]="small ? 13 : 14"></ucs-icon>
  @if (remainingSeconds && isFutureDate) {
<span [class]="countdownClass + (small ? ' small-font' : '')
    + (isOffer ? ' is-offer-style' : '')
    + (isOfferDetail ? ' is-offer-detail-style' : '')
    + (isExternalDealerBidDialog ? ' is-external-dealer-bid-dialog' : '')
    + (isBidDialog ? ' is-bid-dialog-style' : '')">
      <span class="d-inline-flex px-1">{{ 'offer-item.remaining' | translate }}</span>
      <div class="d-inline-flex">
        <span>
          @if (showDays) {
            <span>
              {{ remainingDays }}d
            </span>
          }
          @if (showHours) {
            <span>
              {{ remainingHours }}h
            </span>
          }
          @if (showMinutes) {
            <span>
              {{ remainingMinutes }}min
            </span>
          }
          @if (!showHours) {
            <span>
              {{ remainingSeconds }}s
            </span>
          }
        </span>
      </div>
    </span>
  }
  @if (isFutureDate === false) {
    <span class="me-2" [class]="countdownClass + (small ? ' small-font' : '')">
      {{ 'api.type.offerstatus.EXPIRED' | translate }}
    </span>
  }
</div>

