/**
 * Dialog for saving the searchparams
 */
import {Component, Input, ViewChild} from '@angular/core';
import {ToastAlertService} from '../../service/toast-alert.service';
import {UserService} from '../../service/user.service';
import {TranslateService} from '@ngx-translate/core';
import {NgModel} from '@angular/forms';

@Component({
  selector: 'ucs-save-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: ['./save-dialog.component.scss']
})

export class SaveDialogComponent {
  inputText: string;
  @Input() parent: string;
  @Input() search: UserVehicleSearchParamsRequestDto;
  @ViewChild('descriptionInput', {static: true}) descriptionInput: NgModel;

  constructor(private userService: UserService,
              private myAlert: ToastAlertService,
              private translate: TranslateService) {
  }

  // save search
  saveSettings() {
    if (this.inputText !== undefined && this.inputText.length !== 0) {
      this.search.searchName = this.inputText;
      this.search.notificationEnabled = true;
      this.userService.addSavedSearch(this.search);
      this.myAlert.showAlerts(
        'info',
        this.translate.instant('save-search.success') + ' ("' + this.inputText + '")');
      this.inputText = '';
    }
  }

  cancelSettings() {
    this.inputText = '';
    this.descriptionInput.reset();
  }

  private ucsAlert(typ: string, msg: string) {
    this.myAlert.showAlerts(typ, msg);
  }
}
