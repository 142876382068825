import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'ucs-browser-alert-dialog',
  templateUrl: './browser-alert-dialog.component.html',
  styleUrls: ['./browser-alert-dialog.component.scss']
})
export class BrowserAlertDialogComponent implements OnInit {

  @ViewChild('browserAlertDialog', { static: true }) browserAlertDialog: TemplateRef<any>;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    this.modalService.open(this.browserAlertDialog, {size: 'lg'});
  }
}
