<div class="alert alert-info col col-12">
  @if (gtcs?.length > 0) {
    <form [formGroup]="form" #gtcAcceptanceForm="ngForm">
      <p><strong>{{ 'gtc.title' | translate }} {{ 'gtc.channel.' + gtcs[0].channel | translate }}</strong></p>
      @for (gtc of gtcs; track gtc.id) {
        <div>
          <p><strong class="text-dark">{{ 'api.type.country.' + gtc.country | translate }} ({{gtc.country}})</strong></p>
          @if (gtc.channel === 'PIA' && gtc.country === 'AT') {
            <div>
                <p><strong>{{ 'gtc-change-history-PIA-v4.row1' | translate }}</strong></p>
              <p>{{ 'gtc.version' | translate }} {{ gtc.name }} {{ 'gtc-change-history-PIA-v4.row2' | translate }}</p>
              <ul>
                  <div>
                      <p><strong>{{ 'gtc-change-history-PIA-v4.row3' | translate }}</strong></p>
                      <p>{{ 'gtc-change-history-PIA-v4.row4' | translate }}</p>
                      <p>{{ 'gtc-change-history-PIA-v4.row5' | translate }}</p>
                      <p><strong>{{ 'gtc-change-history-PIA-v4.row6' | translate }}</strong></p>
                      <p>{{ 'gtc-change-history-PIA-v4.row7' | translate }}</p>
                  </div>
              </ul>
                <p>{{ 'gtc-change-history-PIA-v4.row8' | translate }}</p>
                <p><strong>{{ 'gtc-change-history-PIA-v4.row9' | translate }}</strong></p>
            </div>
          }
          @if (gtc.channel === 'PIA' && gtc.country === 'IT') {
                <p>
                    <input type="checkbox" id="{{gtc.channel}}-{{gtc.country}}-PTU" formControlName="{{gtc.channel}}-{{gtc.country}}-PTU" required>
                    <label class="mb-2" for="{{gtc.channel}}-{{gtc.country}}-PTU"> {{ 'gtc.checkBox.pre' | translate }}
                        <strong><a href="assets/gtc/{{ getGTCFile(gtc.channel, gtc.country)[1] }}" target="_blank" rel="noopener">{{ 'terms.ptu' | translate }}</a></strong>
                        {{ 'gtc.checkBox.post' | translate }}
                    </label>
                </p>
                <p>
                    <input type="checkbox" id="{{gtc.channel}}-{{gtc.country}}-GTC" formControlName="{{gtc.channel}}-{{gtc.country}}-GTC" required>
                    <label class="mb-2" for="{{gtc.channel}}-{{gtc.country}}-GTC"> {{ 'gtc.checkBox.alternativ' | translate }}
                        <strong><a href="assets/gtc/{{ getGTCFile(gtc.channel, gtc.country)[0] }}" target="_blank" rel="noopener">{{ 'terms.pia' | translate }}</a></strong>
                        {{ 'gtc.checkBox.post' | translate }}
                    </label>
                </p>
                <div>
                    <ul>
                    <p>{{ 'gtc-change-history-PIA-IT-v1.row1' | translate }}</p>
                    <p class="mb-0">
                        <input type="checkbox" id="{{gtc.channel}}-{{gtc.country}}-LIST" formControlName="{{gtc.channel}}-{{gtc.country}}-LIST" required>
                        <label class="mb-0" for="{{gtc.channel}}-{{gtc.country}}-LIST"> {{ 'gtc-change-history-PIA-IT-v1.row2' | translate }}</label>
                    </p>
                        <ul>
                            <div>
                                <li>{{ 'gtc-change-history-PIA-IT-v1.list1' | translate }}</li>
                                <li>{{ 'gtc-change-history-PIA-IT-v1.list2' | translate }}</li>
                                <li>{{ 'gtc-change-history-PIA-IT-v1.list3' | translate }}</li>
                                <li>{{ 'gtc-change-history-PIA-IT-v1.list4' | translate }}</li>
                                <li>{{ 'gtc-change-history-PIA-IT-v1.list5' | translate }}</li>
                                <li>{{ 'gtc-change-history-PIA-IT-v1.list6' | translate }}</li>
                                <li>{{ 'gtc-change-history-PIA-IT-v1.list7' | translate }}</li>
                                <li>{{ 'gtc-change-history-PIA-IT-v1.list8' | translate }}</li>
                                <li>{{ 'gtc-change-history-PIA-IT-v1.list9' | translate }}</li>
                                <li>{{ 'gtc-change-history-PIA-IT-v1.list10' | translate }}</li>
                                <li>{{ 'gtc-change-history-PIA-IT-v1.list11' | translate }}</li>
                                <li>{{ 'gtc-change-history-PIA-IT-v1.list12' | translate }}</li>
                                <li>{{ 'gtc-change-history-PIA-IT-v1.list13' | translate }}</li>
                                <li>{{ 'gtc-change-history-PIA-IT-v1.list14' | translate }}</li>
                                <li>{{ 'gtc-change-history-PIA-IT-v1.list15' | translate }}</li>
                            </div>
                        </ul>
                    </ul>
                </div>
          }
          @if (gtc.channel === 'PB' && gtc.country === 'AT') {
            <div>
              <p>{{ 'gtc-change-history-PB-v4.row1' | translate }}</p>
              <br>
                <p>{{ 'gtc-change-history-PB-v4.row2' | translate }}</p>
                <p>{{ 'gtc-change-history-PB-v4.row3' | translate }}</p>
                <ul>
                  <li>{{ 'gtc-change-history-PB-v4.row4' | translate }}</li>
                  <li>{{ 'gtc-change-history-PB-v4.row5' | translate }}</li>
                  <p>{{ 'gtc-change-history-PB-v4.row6' | translate }}<br>
                  {{ 'gtc-change-history-PB-v4.row7' | translate }}</p>
                </ul>
                <p>{{ 'gtc-change-history-PB-v4.row8' | translate }}</p>
                <p>{{ 'gtc-change-history-PB-v4.row9' | translate }}</p>
                <br>
                  <p>{{ 'gtc-change-history-PB-v4.row10' | translate }}</p>
                  <p>{{ 'gtc-change-history-PB-v4.row11' | translate }}</p>
                </div>
          }
            @if (gtc.country !== 'IT') {
              <p>
                <input type="checkbox" id="{{gtc.channel}}-{{gtc.country}}" formControlName="{{gtc.channel}}-{{gtc.country}}" required>
                <label class="mb-2" for="{{gtc.channel}}-{{gtc.country}}"> {{ 'gtc.checkBox.pre' | translate }}
                  <strong><a href="assets/gtc/{{ getGTCFile(gtc.channel, gtc.country)[0] }}" target="_blank" rel="noopener">{{ (gtc.channel === 'PB' && gtc.country === 'AT') ? ('terms.pb.AT.v4' | translate) : ('terms.' + gtc.channel.toLowerCase() | translate) }}</a></strong>
                  {{ 'gtc.checkBox.post' | translate }}
                  @if (getGTCFile(gtc.channel, gtc.country).length > 1) {
                    (<strong><a href="../../assets/gtc/{{ getGTCFile(gtc.channel, gtc.country)[1] }}" target="_blank" rel="noopener">{{ 'terms.english-version' | translate }}</a></strong>)
                  }
                </label>
              </p>
            }
          @if (gtc.channel === 'PB' && gtc.country === 'AT') {
                <p>
                  <strong><a href="assets/gtc/PP_PB_AT_de_2024_04.pdf" target="_blank" rel="noopener">{{ 'gtc-change-history-PB-v2.row12' | translate }}</a>
                  <br>
                    <a href="assets/gtc/PP_PB_AT_en_2024_04.pdf" target="_blank" rel="noopener">{{ 'gtc-change-history-PB-v2.row13' | translate }}</a></strong>
                  </p>
          }
          <br/>
        </div>
      }
      <button class="btn accept-button" [disabled]="gtcAcceptanceForm.form.invalid" (click)="acceptAll()">
        <strong>{{'gtc.accept' | translate }}</strong>
      </button>
    </form>
  }
</div>