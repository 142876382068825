import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/app.reducers';

/*
 * Returns the formatted number and appends the 'l / 100km' (liters per hundred kilometers) unit
 * Usage:
 *   value | litersPerHundredKm
 * Example:
 *   {{ vehicle.consumption | litersPerHundredKm }}
 *   Let vehicle.consumption be 7.0, then the returned text will be "7.0l / 100km" (dots or commas depending on localisation)
*/
@Pipe({
  name: 'litersPerHundredKm'
})
@Injectable()
export class LitersPerHundredKmPipe implements PipeTransform {

  private local: string;

  constructor(private store: Store<fromRoot.AppState>) {
  }

  transform(value: number): string {

    this.store.select(fromRoot.getUserState)
      .pipe()
      .subscribe(
        s => {
          this.local = s.local;
        }
      );

    // format number based on locale
    const formattedValue = value === null ? '-' : new DecimalPipe(this.local).transform(value);
    // append unit
    return formattedValue + ' l / 100km';
  }

}
