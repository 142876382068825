import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, filter, Subject} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';
import * as fromRoot from '../../store/app.reducers';
import * as VehicleMaintenanceSearchReducers from '../../store/vehicle-maintenance/vehicle-maintenance.reducers';
import {PageSettings} from '../../model/page-settings.model';
import {VehicleMaintenanceSearchService} from '../../service/vehicle-maintenance-search.service';
import {SystemSettingsService} from '../../service/system-settings.service';

/**
 * The search filter maintenance sidebar component indirectly (via redux-store) orchestrates the result list of vehicles
 */
@Component({
  selector: 'ucs-vehicle-search-filter-sidebar',
  templateUrl: './vehicle-search-filter-sidebar.component.html',
  styleUrls: ['./vehicle-search-filter-sidebar.component.scss']
})
export class VehicleSearchFilterSidebarComponent implements OnInit, OnDestroy {
  @Output() hideSidebar = new EventEmitter();
  @Output() categoryChanged = new EventEmitter<Section>();
  @Output() directSearch = new EventEmitter();
  categories: String[];
  vehicleMaintenanceState: VehicleMaintenanceSearchReducers.State;
  private unsubscribe: Subject<void> = new Subject<void>();
  private pageSettings: PageSettings;
  private selectedFilters: SearchFilter[];

  isEnforcedMaintenanceEnabled: boolean;

  /**
   * Instantiates the component
   * @param {Store<AppState>} store: allows access to store
   * @param vehicleMaintenanceSearchService
   */
  constructor(private store: Store<fromRoot.AppState>,
              private vehicleMaintenanceSearchService: VehicleMaintenanceSearchService,
              private systemSettingsService: SystemSettingsService) {
  }

  /**
   * When initializing the component and subscribe to changes of sections
   */
  ngOnInit() {
    this.store.select(fromRoot.getVehicleMaintenanceState)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(vehicleMaintenanceState => {
        this.vehicleMaintenanceState = vehicleMaintenanceState;
        this.selectedFilters = vehicleMaintenanceState.selectedFilters;
        this.pageSettings = vehicleMaintenanceState.pageSettings;
        this.categories = [];
        if (this.vehicleMaintenanceState.searchFilterConfig) {
          this.categories = Object.keys(this.vehicleMaintenanceState.searchFilterConfig.filterConfig);
        }
      });

    combineLatest([
      this.systemSettingsService.isSystemFeatureActivatedForChannel('ENFORCED_AUCTION', 'PIA').pipe(first()),
      this.systemSettingsService.getSystemFeatureSettingForChannel('ENFORCED_AUCTION.maintenance', 'PIA').pipe(first(),
        filter(string => !!string))
    ]).pipe(takeUntil(this.unsubscribe))
      .subscribe(([featureToggleEnabled, settingString]) => {
        let parsedEnforcedAuctionMaintenanceSettings: EnforcedAuctionMaintenanceSettings = JSON.parse(settingString);
        this.isEnforcedMaintenanceEnabled = featureToggleEnabled && parsedEnforcedAuctionMaintenanceSettings.enabled;
      });
  }

  /**
   * Change the category
   * @param category
   */
  changeCategory(category) {
    this.categoryChanged.emit(category);
  }

  /**
   * Unsubscribe from Observables when component is destroyed to prevent memory leaks
   */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Emits to parent component that sidebar is to be hidden
   */
  onToggleSidebar() {
    this.hideSidebar.emit();
  }

  /**
   * Exposes the fulltext search received by child component vehicle-identification-full-text-search.component.ts
   * to parent component
   * .
   * @param term: the fulltext search string
   */
  emitFulltextSearch(term: string) {
    this.directSearch.emit(term);
  }
}
