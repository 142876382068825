import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {isEquipmentAggregationEntry, isValueAggregationEntry} from '../../../misc/typeguard';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead/typeahead-match.class';

/**
 * Handles the equipment selection, both via search field or checkbox selections
 */
@Component({
  selector: 'ucs-search-equipment',
  templateUrl: './search-equipment.component.html',
  styleUrls: ['./search-equipment.component.scss']
})
export class SearchEquipmentComponent implements OnInit, OnChanges {
  @Input() quickSearch: boolean;
  @Input() searchAggregation: VehicleOfferSearchAggregation;
  @Output() onEquipmentChanged = new EventEmitter<string[]>();
  // the aggregated states allow convenient handling of the UI behavior and are used by both search field and checkboxes
  aggregatedStates: {
    id: number, option: string, value: string, text: string, category: string, count: number, selected: boolean
  }[] = [];
  categories: { category: string, expanded: boolean }[] = [];
  selectedOption: string; // the option selected in search field

  /**
   * Initializes the component
   */
  ngOnInit() {
    // initialize aggregatedStates and categories properties
    this.updateStates(this.searchAggregation.equipments, this.aggregatedStates);
  }

  /**
   * Handle changes of search aggregations.
   *
   * @param {SimpleChanges} changes the changes that occurred in the application
   */
  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'searchAggregation') {
        this.updateStates(this.searchAggregation.equipments, this.aggregatedStates);
      }
    }
  }

  /**
   * On clear reset the component
   */
  clear() {
    this.aggregatedStates = [];
    this.categories = [];
    this.selectedOption = undefined;
    this.updateStates(this.searchAggregation.equipments, this.aggregatedStates);
  }

  /**
   * Called when an option is selected in the equipment search field
   */
  onSelect(event: TypeaheadMatch) {
    const item = this.aggregatedStates.find(x => x.option === this.selectedOption);
    item.selected = true;
    this.selectedOption = undefined;
    this.emitSelectedEquipments();
  }

  /**
   * Called when a checkbox is toggled
   * @param {number} id: the id of the checkbox toggled in aggregatedStates
   */
  onToggleCheckbox(id: number) {
    const item = this.aggregatedStates.find(x => x.id === id);
    item.selected = !item.selected;
    this.emitSelectedEquipments();
  }

  /**
   * Called when a category button is toggled
   * @param {number} index: the index of the toggled category in categories
   */
  onToggleCategory(index: number) {
    this.categories[index].expanded = !this.categories[index].expanded;
  }

  /**
   * Shows whether a category is expanded
   * @param {number} index: the index of the category in categories
   * @returns {boolean} whether the category is expanded
   */
  isCategoryExpanded(index: number): boolean {
    if (this.categories && this.categories[index]) {
      return this.categories[index].expanded;
    } else {
      return false;
    }
  }

  /**
   * Allows overriding the current selections (intended to be used by parent component)
   */
  overrideSelections(equipments: string[]) {
    // first clear all equipment selections
    this.clear();

    // then select passed equipments, if available
    if (equipments) {
      for (const equipment of equipments) {
        const item = this.aggregatedStates.find(x => x.value === equipment);
        item.selected = true;
      }
    }
  }

  /**
   * Updates aggregated states with provided inputStates.
   * @param {any[]} inputStates: the states array passed to this component
   * @param aggregStates  {any[]} aggregStates: the states array to be updated
   */
  private updateStates(inputStates: any[], aggregStates: any[]) {
    let id = 0;
    const selectedEquipment = aggregStates.filter(x => x.selected);

    aggregStates = [];
    for (const equipmentEntry of inputStates) {
      if (isEquipmentAggregationEntry(equipmentEntry)) {
        this.categories.push({category: equipmentEntry.category, expanded: false});
        for (const entry of equipmentEntry.equipment) {
          if (isValueAggregationEntry(entry)) {
            const aggregatedValue = entry.text + ' (' + entry.count + ')';
            const selected = selectedEquipment.find(x => x.value === entry.value) !== undefined;
            aggregStates.push({
              id: id, option: aggregatedValue, value: entry.value, text: entry.text, category: equipmentEntry.category,
              count: entry.count, selected: selected
            });
            id++;
          } else {
            console.error('unknown inner input states');
          }
        }
      } else {
        console.error('unknown input states');
      }

    }
    this.aggregatedStates = aggregStates;
  }

  /**
   * Emits selected equipments to parent
   */
  private emitSelectedEquipments() {
    const selectedEquipments = this.aggregatedStates.filter(x => x.selected);
    const equipmentsToEmit: string[] = [];
    for (const entry of selectedEquipments) {
      equipmentsToEmit.push(entry.value);
    }
    this.onEquipmentChanged.emit(equipmentsToEmit);
  }
}
