<div class="row">
  <div class="d-inline-block market-value-heading ms-2 mt-2">
    <span>{{ 'vehicle-footer.market-value' | translate }}</span>
  </div>
  <div class="market-price mb-2 ms-2">
    @if (marketValue?.gross) {
      <span>{{ marketValue?.gross | price}} {{ marketValue?.currency.data }}
        <span class="font-regular">{{ 'api.type.pricetype.GROSS' | translate }}</span>
      </span>
    }
    @if (!marketValue?.gross) {
      <span>{{ 'vehicle-footer.NOT_AVAILABLE' | translate }}</span>
    }
  </div>
</div>
