import { Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: any[] = [];

  // Push new Toasts to array with content and options
  show(text: string) {
    this.toasts.push({ text});
  }

  // Callback method to remove Toast DOM element from view
  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  saveData(toast) {
    // do nothing
  }

  dismiss(toast) {
    // do nothing
  }
}
