import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';


/**
 * Spinner component showing a spinner in the upper center of the screen, including a semitransparent white
 * layover. It's appearance is controlled by a loading boolean in the NGRX-store.
 */
@Component({
  selector: 'ucs-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {

  loading: boolean;
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private store: Store<fromRoot.AppState>) {
  }

  /**
   * Load the spinner loading status on init
   */
  ngOnInit() {
    this.store.select(fromRoot.getLoading)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

  /**
   * Dispose of subscription on destroy
   */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
