import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {SystemSettingsService} from './system-settings.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/app.reducers';
import {PageSettings} from '../model/page-settings.model';
import * as VehicleMaintenanceReducers from '../store/vehicle-maintenance/vehicle-maintenance.reducers';
import {filter, first, mergeMap, takeUntil} from 'rxjs/operators';
import {UserService} from './user.service';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';

/**
 * A service which can be used for all vehicle maintenance related search requests returning
 * to be used as search results and not for vehicle detail maintenance
 */
@Injectable()
export class VehicleMaintenanceSearchService implements OnDestroy {
  private vehicleMaintenanceState: VehicleMaintenanceReducers.State;
  private pageSettings: PageSettings;
  private activeCategory: Section;
  private selectedFilters: SearchFilter[];
  private activeSubstitutionUserIds: number[];
  private unsubscribe: Subject<void> = new Subject<void>();
  private noCacheHeaders = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
  });

  constructor(private http: HttpClient,
              private systemSettingsService: SystemSettingsService,
              private store: Store<fromRoot.AppState>,
              private userService: UserService,
              @Inject(APP_CONFIG) private config: AppConfig) {

    this.store.select(fromRoot.getVehicleMaintenanceState)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(vehicleMaintenanceState => {
        this.vehicleMaintenanceState = vehicleMaintenanceState;
        this.pageSettings = vehicleMaintenanceState.pageSettings;
        this.activeCategory = vehicleMaintenanceState.activeCategory;
        this.selectedFilters = vehicleMaintenanceState.selectedFilters;
      });

    this.store.select((fromRoot.getUserSubstitutions))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userSubstitutions => {
        this.activeSubstitutionUserIds = userSubstitutions.filter(sub => sub.substituteActive)
          .map(filteredSub => filteredSub.userId);
      });
  }

  /**
   * Pageable vehicle full text identification search.
   * Fetches all vehicles for a given distribution channel and a search string
   *
   * @returns {Observable<VehicleBaseDto[]>}: vehicles for distribution channel matching the search string
   */
  getVehiclesByIdentificationMatch(searchString: string): Observable<VehicleBaseDto[]> {
    let params = this.getHttpParams('modified' + ',' + 'desc');
    params = params.set('searchString', searchString);

    if (this.activeCategory === 'LONGTIME_PENDING') {
      return this.http.get<VehicleBaseDto[]>(this.config.salesApiUrl + '/maintenance/vehicles/identification/enforced-auction', {
        params: params,
        headers: this.noCacheHeaders
      });
    }
    return this.http.get<VehicleBaseDto[]>(this.config.salesApiUrl + '/maintenance/vehicles/identification', {
      params: params,
      headers: this.noCacheHeaders
    });
  }

  /**
   * Pageable vehicle search.
   * Fetches all vehicles for the current vehicle maintenance distribution channel
   *
   * @returns {Observable<VehicleBaseDto[]>}: offers for distribution channel
   */
  getVehiclesByFilter(): Observable<VehicleBaseDto[]> {
    let params = this.getHttpParams();
    if (this.activeCategory) {
      params = params.set('section', this.activeCategory);
    }
    if (this.selectedFilters) {
      params = params.set('filters', String(this.selectedFilters));
    }
    if (this.activeCategory === 'LONGTIME_PENDING') {
      return this.getEnforcedAuctionVehicles();
    }
    return this.systemSettingsService
      .isSystemFeatureActivatedForChannel('SUBSTITUTION_RULE', this.pageSettings.channel)
      .pipe(
        first(),
        mergeMap(substitutionActive => {
          if (substitutionActive && this.activeSubstitutionUserIds && this.activeSubstitutionUserIds.length > 0) {
            params = params.set('substitutionUserIds', String(this.activeSubstitutionUserIds));
          }
          return this.http.get<VehicleBaseDto[]>(this.config.salesApiUrl + '/maintenance/vehicles', {
            params: params,
            headers: this.noCacheHeaders
          });
        }));
  }

  getEnforcedAuctionVehicles(): Observable<EnforcedAuctionVehicleBaseDto[]> {
    let isEnforcedAuctionMaintenanceFeatureEnabled: boolean;

    //TODO use combineLatest or similar instead of chaining observables
    this.systemSettingsService.isSystemFeatureActivatedForChannel('ENFORCED_AUCTION', this.pageSettings.channel)
      .pipe(first())
      .subscribe(featureToggleEnabled => {
        this.systemSettingsService.getSystemFeatureSettingForChannel(
          'ENFORCED_AUCTION.maintenance', this.pageSettings.channel)
          .pipe(first(), filter(setting => !!setting))
          .subscribe(settingString => {
            let parsedEnforcedAuctionMaintenanceSettings: EnforcedAuctionMaintenanceSettings = JSON.parse(settingString);
            isEnforcedAuctionMaintenanceFeatureEnabled = featureToggleEnabled && parsedEnforcedAuctionMaintenanceSettings.enabled;
          });
      });

    let params = this.getHttpParams();
    if (this.selectedFilters) {
      params = params.set('filters', String(this.selectedFilters));
    }

    if(isEnforcedAuctionMaintenanceFeatureEnabled && this.activeCategory === 'LONGTIME_PENDING') {
      return this.http.get<EnforcedAuctionVehicleBaseDto[]>(this.config.salesApiUrl + '/enforced-auction/vehicles/base',
        {
          params: params,
          headers: this.noCacheHeaders
        });
    } else {
      return of([]);
    }
  }

  getHttpParams(sort?: string): HttpParams {
    let params = new HttpParams();
    params = params.set('channel', this.pageSettings.channel);
    params = params.set('size', this.pageSettings.size.toString());
    params = params.set('page', this.pageSettings.page.toString());
    if (sort) {
      params = params.set('sort', sort);
    } else {
      params = params.set('sort', this.pageSettings.sort + ',' + this.pageSettings.order);
    }
    return params;
  }

  /**
   * Unsubscribe from Observables when component is destroyed to prevent memory leaks
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
