import {Component, Input, OnInit} from '@angular/core';
import {isAuctionOfferDto, isBuyNowOfferDto, isVehicleItemBaseDto} from '../../../misc/typeguard';

@Component({
  selector: 'ucs-offer-small',
  templateUrl: './offer-small.component.html',
  styleUrls: ['./offer-small.component.scss']
})
export class OfferSmallComponent implements OnInit {
  @Input() index: number;
  @Input() offer: OfferDto;
  auctionOffer: AuctionOfferDto | EnforcedAuctionOfferDto;
  buyNowOffer: BuyNowOfferDto;
  imageUrl: string;

  ngOnInit() {
    // cast input offer dto to either auction or buynow
    this.castOffer();
    this.imageUrl = '';
    if (this.offer.items[0] && isVehicleItemBaseDto(this.offer.items[0])) {
      const vehicleItemBase = <VehicleItemBaseDto>this.offer.items[0];
      if (vehicleItemBase?.images[0]?.url) {
        this.imageUrl = (<VehicleItemBaseDto>this.offer.items[0]).images[0].url + '/120';
      }
    }
  }

  /**
   * Casts the input OfferDto to the correct subtype
   */
  castOffer() {
    // initialize AuctionOfferDto or BuyNowOfferDto depending on what has been passed
    if (isAuctionOfferDto(this.offer)) {
      this.auctionOffer = this.offer;
    } else if (isBuyNowOfferDto(this.offer)) {
      this.buyNowOffer = this.offer;
    } else {
      console.error('Unknown input DTO');
    }
  }
}
