import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead/typeahead-match.class';
import {SearchService} from '../../../service/search.service';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

/**
 * This component searches for a location (zipcode + city) based on the user's input
 */
@Component({
  selector: 'ucs-search-location',
  templateUrl: './search-location.component.html',
  styleUrls: ['./search-location.component.scss']
})
export class SearchLocationComponent {
  @Input() id: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Output() onSelected = new EventEmitter<any>();
  selectedValue: string;
  dataSource$: Observable<any>;

  /**
   * Bind our async datasource to the observable returned by getLocations
   */
  constructor(private searchService: SearchService) {
    this.dataSource$ = new Observable((observer: any) => {
      // Runs on every search
      observer.next(this.selectedValue);
    }).pipe(mergeMap((filter: string) => this.searchService.getLocations(filter)));
  }

  /**
   * Emit the selected item to the parent
   */
  onSelect(event: TypeaheadMatch): void {
    this.selectedValue = event.item.zipCode + ' ' + event.item.city;
    this.onSelected.emit(event.item);
  }

  /**
   * Called whenever input value of dropdown changes
   * When empty, i.e. selection cleared, emit to parent to clear the search param
   */
  onInput(event) {
    if (this.selectedValue === '') {
      this.selectedValue = undefined;
      this.onSelected.emit(undefined);
    }
  }

  /**
   * Clears the selection of the dropdown
   */
  clear() {
    this.selectedValue = undefined;
  }

  /**
   * Allows overriding the current selection (intended to be used by parent component)
   */
  overrideSelection(zipCode: string, city: string) {
    if (zipCode === undefined && city === undefined) { // clear the selection
      this.clear();
    } else { // or set the selected value if not undefined
      this.selectedValue = zipCode + ' ' + city;
    }
  }

}
