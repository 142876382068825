<div *ngIf="isTop" [ngClass]="{'row':showTop, 'mb-3': showTop}">
  <div *ngIf="showTop" class="col-12 filler-height">
    <!--this filler allows correct horizontal alignment with the other component -->
  </div>
</div>
<div *ngIf="!isAccountingClerk; else accountingClerkName" class="row font-weight-bold mb-1">
  <div class="col-12 ps-0">
    {{'offer-detail.seller' | translate}}
  </div>
</div>
<ng-template #accountingClerkName>
  <div class="row font-weight-bold mb-1 col-12 ps-0">
    {{'offer-detail.accounting-clerk' | translate }}
  </div>
</ng-template>
<div *ngIf="!isAccountingClerk" class="row font-heading font-weight-bold" [ngClass]="{
      'mb-1':isTop,
      'mt-3': !isTop}">
  <div class="col-12" [ngClass]="{'text-truncate ps-0': isTop}">
    {{ vehicle?.seller?.name }}
  </div>
</div>
<div class="row font-regular" [ngClass]="{'mb-2': isTop, 'mb-4': !isTop}">
  <div [ngClass]="{'col-12 ps-0': isTop, 'col-md-5 col-sm-6 d-none d-sm-block': !isTop}">
    <ng-container *ngIf="isTop; else onBottom">
      <div *ngIf="!isAccountingClerk" class="row mb-2">
        <span class="col-auto pe-0">
          <ucs-icon [shape]="'location_pin'" [class]="'black me-2'" [size]="16"></ucs-icon>
        </span>
        <span [ngClass]="{'col-10':showTop, 'col-9':!showTop, 'ps-0':true}">
          {{ vehicle?.seller?.address }}
          <br>
          {{ vehicle?.seller?.zipCode }} {{ vehicle?.seller?.city }}
        </span>
      </div>
      <div *ngIf="!isAccountingClerk; else accountingClerk" class="d-inline-flex col-12 mt-2">
        <span class="me-2 mt-2">
          <ucs-icon [shape]="'user'" [class]="'black'" [size]="16"></ucs-icon>
        </span>
        <select name="employee" class="form-select form-select-sm"
                [class]="ucsIsNil(vehicleDetailUpdate.contactPersonId) || ucsIsNil(selectedEmployee) ? 'not-filled' : ''"
                *ngIf="vehicle.canEdit ; else contactPersonLabel" [ngModel]="vehicleDetailUpdate.contactPersonId"
                (ngModelChange)="onEmployeeSelect($event)">
            <option *ngFor="let employee of employees" [value]="employee.id">
                {{ employee.lastName + ' ' + employee.firstName }}
                <span *ngIf="displayPnetSellerNrEnabled && employee?.partnerNetSellerNr">
                            {{ ' (VK-NR. ' + employee.partnerNetSellerNr + ')' }}
                </span>
            </option>
        </select>
      </div>
    </ng-container>
    <ng-template #onBottom>
      <div class="row">
        <span class="col-auto pe-0">
          <ucs-icon [shape]="'location_pin'" [class]="'black me-2'" [size]="16"></ucs-icon>
        </span>
        <span class="col-10 ps-0">
          {{ vehicle?.seller?.address }}
          <br>
          {{ vehicle?.seller?.zipCode }} {{ vehicle?.seller?.city }}
        </span>
      </div>
    </ng-template>
  </div>
<!--  here beginns small screen html part-->
  <div *ngIf="!isTop" class="col-md-7 col-sm-6 col-12 ps-sm-2">
    <div *ngIf="!isAccountingClerk" class="row mb-3 d-sm-none">
      <span class="col-auto pe-0">
        <ucs-icon [shape]="'location_pin'" [class]="'black me-2'" [size]="16"></ucs-icon>
      </span>
      <span class="col-10 ps-0">
        {{ vehicle?.seller?.address }}
        <br>
        {{ vehicle?.seller?.zipCode }} {{ vehicle?.seller?.city }}
      </span>
    </div>
    <div *ngIf="!isAccountingClerk; else accountingClerk" class="d-inline-flex col-12 mt-1 mb-2">
      <span class="me-2 mt-2">
        <ucs-icon [shape]="'user'" [class]="'black'" [size]="16"></ucs-icon>
      </span>
      <select name="employee" class="form-select form-select-sm" data-cy="vehicle-detail.seller-employee" *ngIf="vehicle.canEdit ; else contactPersonLabel"
              [class]="ucsIsNil(vehicleDetailUpdate.contactPersonId) ? 'not-filled' : ''"
              [ngModel]="vehicleDetailUpdate.contactPersonId" (ngModelChange)="onEmployeeSelect($event)">
          <option *ngFor="let employee of employees" [value]="employee.id">
              {{ employee.lastName + ' ' + employee.firstName }}
              <span *ngIf="displayPnetSellerNrEnabled && employee?.partnerNetSellerNr">
                            {{ ' (VK-NR. ' + employee.partnerNetSellerNr + ')' }}
                </span>
          </option>
      </select>
    </div>
    <div *ngIf="(selectedEmployee?.email && selectedEmployee?.email !== '') || (vehicle?.seller?.email && vehicle.seller.email !== '')" class="row mb-3">
      <div class="col-12">
        <a *ngIf="employees" href="mailto:{{ selectedEmployee?.email ? selectedEmployee.email : vehicle.seller.email }}?subject={{validateEmailSubject('vehicle-maintenance.detail.email-subject'
          | translate : {vehicleId: vehicle?.id, modelVariant: vehicle?.modelVariant, vinNumber: vehicle?.vin}) }}" class="link">
          <ucs-icon [shape]="'mail'" [class]="'blue me-2'" [size]="16"></ucs-icon>
          <span>{{'offer-detail.contact' | translate}}</span>
        </a>
      </div>
    </div>
    <div *ngIf="(selectedEmployee?.phoneNumber && selectedEmployee?.phoneNumber !== '') ||  (vehicle?.seller?.phoneNumber && vehicle.seller.phoneNumber !== '')" class="row mb-1">
      <div class="col-12">
        <a href="tel:{{ selectedEmployee?.phoneNumber ? selectedEmployee.phoneNumber : vehicle.seller.phoneNumber }}" class="link">
          <ucs-icon [shape]="'phone'" [class]="'blue me-2'" [size]="16"></ucs-icon>
          <span>{{ selectedEmployee?.phoneNumber ? selectedEmployee.phoneNumber : vehicle.seller.phoneNumber }}</span>
        </a>
      </div>
    </div>
    <div class="py-3"></div>
  </div>
</div>
<!--  end of small screen html part-->
<div *ngIf="isTop && ((selectedEmployee?.email && selectedEmployee?.email !== '') || (vehicle?.seller?.email && vehicle.seller.email !== ''))" class="row mb-2">
  <div class="col-12 ps-0">
    <a *ngIf="employees" href="mailto:{{ selectedEmployee?.email ? selectedEmployee.email : vehicle.seller.email }}?subject={{validateEmailSubject('vehicle-maintenance.detail.email-subject'
          | translate : {vehicleId: vehicle?.id, modelVariant: vehicle?.modelVariant, vinNumber: vehicle?.vin}) }}" class="link">
      <ucs-icon [shape]="'mail'" [class]="'blue me-2'" [size]="16"></ucs-icon>
      <span>{{'offer-detail.contact' | translate}}</span>
    </a>
  </div>
</div>
<div *ngIf="isTop && selectedEmployee?.phoneNumber && selectedEmployee?.phoneNumber !== ''" class="row">
  <div class="col-12 ps-0 text-truncate">
    <a href="tel:{{ selectedEmployee?.phoneNumber ? selectedEmployee.phoneNumber : vehicle.seller.phoneNumber }}" class="link">
      <ucs-icon [shape]="'phone'" [class]="'blue me-2'" [size]="16"></ucs-icon>
      <span>{{ selectedEmployee?.phoneNumber ? selectedEmployee.phoneNumber : vehicle.seller.phoneNumber }}</span>
    </a>
  </div>
</div>

<ng-template #contactPersonLabel>
  <span class="mt-2">
    {{vehicle?.seller?.contactPerson?.firstName + " " + vehicle?.seller?.contactPerson?.lastName}}
  </span>
</ng-template>
<ng-template #accountingClerk>
  <div class="d-inline-flex col-12 mt-2 accountingClerk">
          <span class="me-2 mt-2">
            <ucs-icon [shape]="'user'" [class]="'black'" [size]="16"></ucs-icon>
          </span>
    <input *ngIf="vehicle.canEdit ; else accountingClerkUserLabel"
           [id]="'id'"
           [(ngModel)]="selectedValue"
           [typeahead]="employeesName"
           typeaheadOptionField="fullname"
           typeaheadOptionsInScrollableView="10"
           [typeaheadScrollable]="true"
           (focus)="onFocus()"
           [typeaheadMinLength]=0
           [typeaheadOptionsLimit]="999"
           (typeaheadOnSelect)="onSelect($event)"
           autocomplete="off"
           class="form-control search-dropdown">
    <ng-template #accountingClerkUserLabel>
      <span class="mt-2">
        {{vehicle?.seller?.accountingClerkUser?.firstName + " " + vehicle?.seller?.accountingClerkUser?.lastName}}
      </span>
    </ng-template>
  </div>
</ng-template>
