<div class="row footer rounded-bottom">
  @if (!isEnforcedAuction) {
    <div class="col-auto col-lg-3 d-flex align-items-end">
      <ucs-net-price [currentPrice]="currentPrice" [startPrice]="startPrice" [vatType]="vatType"
      [novaPaid]="extraTax?.paid" [offerType]="offerType" [channel]="channel"></ucs-net-price>
    </div>
  }
  <div class="col-auto col-lg-3 d-flex align-items-end">
    <ucs-gross-price [currentPrice]="currentPrice" [vatType]="vatType" [channel]="channel" [offerType]="offerType"
      [extraTax]="extraTax" [nationalSale]="nationalSale" [scopeSection]="scopeSection" [startPrice]="startPrice"
    [isEnforceAuction]="isEnforcedAuction"></ucs-gross-price>
  </div>

    <!-- For offer scope mobile view tax info is hidden and displayed in the sales-single-offer-card instead -->
  <div class="col-auto col-lg-3 ms-lg-0 mt-2 mt-lg-0 align-items-center"
    [ngClass]="{'d-md-flex d-none': !!salesScope, 'd-flex': !salesScope}">
    <ucs-tax-nova-gross-profit [vatType]="vatType" [grossProfitData]="grossProfitData"
      [channel]="channel" [country]="country" [isEnforceAuctionOffer]="isEnforcedAuction"
      [nationalSale]="nationalSale" [currency]="currentPrice?.currency?.data"
      [extraTax]="extraTax" [vehicleOfferItem]="vehicle" [scopeSection]="scopeSection">
    </ucs-tax-nova-gross-profit>
  </div>
  @if (['AUCTION','ENFORCED_AUCTION'].includes(offerType) && offer?.eligible === true) {
    <div class="col-12 d-md-none d-flex my-2 my-md-0 justify-content-center"
      >
      <ucs-bid-status [status]="offer?.bidStatus?.data"
        [offerStatus]="offerStatus"
        [maximumBid]="offer?.maximumBid"
        [myLastHighestBid]="offer?.myLastHighestBid"
        [myLastMaximumBid]="offer?.myLastMaximumBid"
        [tooLateBid]="offer?.tooLateBid"
        [purchasePrice]="offer?.purchase?.price"
        [highestBid]="offer?.highestBid"
        [purchaseReceived]="offer?.purchase?.purchaseReceived"
        [nationalSale]="offer?.nationalSale">
      </ucs-bid-status>
    </div>
  }
  @if (offerType === 'BUYNOW' && buyingDealerId === userState.userInfo.currentDealerId) {
    <div class="ms-auto my-auto col-12 buy pe-2 d-md-none d-flex justify-content-center"
      >
      <ucs-buy-now-purchase-status [offerStatus]="offerStatus">
      </ucs-buy-now-purchase-status>
    </div>
  }
  <div
    class="col-12 col-md-auto col-lg-3 mb-2 ms-auto d-flex align-items-end justify-content-center justify-content-lg-end"
    [ngClass]="{'bottom-empty': !showOfferLink && !['ACTIVE', 'EXTENDED'].includes(offerStatus)}">
    <ucs-offer-status-buy-bid [expirationDate]="expirationDate"
      [expectedExpirationDate]="expectedExpirationDate"
      [purchaseDate]="purchaseDate"
      [footerStatus]="footerStatus"
      [offerId]="offerId"
      [offerType]="offerType"
      [showOfferLink]="showOfferLink"
      [showBidBuyButtons]="['ACTIVE', 'EXTENDED'].includes(offerStatus)"
      [inputPrice]="inputPrice"
      [channel]="channel"
      [offerStatus]="offerStatus"
      [vehicle]="vehicle"
      [disableBidding]="disableBidding"
      [hideFooterStatus]="hideFooterStatus"
      [thermalWindowAffected]="thermalWindowAffected"
      (linkToOfferClicked)="linkToOfferClicked.emit()"
      class="w-100">
    </ucs-offer-status-buy-bid>
  </div>
</div>
