@if (isTop) {
  <div [ngClass]="{'row':showTop, 'mb-3': showTop}">
    @if (showTop) {
      <div class="col-12 filler-height">
        <!--this filler allows correct horizontal alignment with the other component -->
      </div>
    }
  </div>
}

@if (isTop && !isAccountingClerk) {
  <div class="row font-small mb-1">
    <div class="col-12 ps-0">
      <h4>  {{'offer-detail.seller' | translate}}  </h4>
    </div>
  </div>
} @else {
  @if (isTop) {
    <div class="row font-weight-bold mt-4 mb-2 col-12 ps-0">
      {{'offer-detail.accounting-clerk' | translate}}
    </div>
  }
}

@if (!isAccountingClerk) {
  <div class="row font-heading font-weight-bold" [ngClass]="{ 'mb-1':isTop, 'mt-3': !isTop}">
    <div class="col-12" [ngClass]="{'text-truncate ps-0': isTop}">
      {{ offer.sellerInformation.name }}
    </div>
  </div>
}

<div class="row font-regular" [ngClass]="{'mb-2': isTop, 'mb-3 pb-md-3': !isTop}">

  <div [ngClass]="{'col-12 ps-0': isTop, 'col-md-5 col-sm-6 d-none d-sm-block': !isTop}">

    @if (isTop) {
      @if (!isAccountingClerk) {
        <div class="row mb-2">
          <span class="col-auto pe-0">
            <ucs-icon [shape]="'location_pin'" [class]="'black me-2'" [size]="16"></ucs-icon>
          </span>
          @if (offer?.sellerInformation) {
            <span [ngClass]="{'col-10':showTop, 'col-9':!showTop, 'ps-0':true}">
              {{ offer.sellerInformation.address }}<br>
              {{ offer.sellerInformation.zipCode }} {{ offer.sellerInformation.city }} - {{offer.sellerInformation.country.data}}
              <ucs-icon class="pb-1" [shape]="'flags/' + offer.sellerInformation.country.data.toLocaleLowerCase()" [size]="16"></ucs-icon>
            </span>
          }
        </div>
      }
      @if (user) {
        <div class="row">
          <span class="col-auto pe-0">
            <ucs-icon [shape]="'user'" [class]="'black me-2'" [size]="16"></ucs-icon>
          </span>
          <span [ngClass]="{'col-10':showTop, 'col-9':!showTop, 'ps-0':true}" class="mt-1">
            {{user.firstName + " " + user.lastName}}
          </span>
        </div>
      }
    } @else {
      @if (!isAccountingClerk) {
        <div class="row">
          <span class="col-auto pe-0">
            <ucs-icon [shape]="'location_pin'" [class]="'black me-2'" [size]="16"></ucs-icon>
          </span>
          @if (offer?.sellerInformation) {
            <span class="col-10 ps-0">
              {{ offer.sellerInformation.address }}<br>
              {{ offer.sellerInformation.zipCode }} {{ offer.sellerInformation.city }} - {{offer.sellerInformation.country.data}}
              <ucs-icon class="pb-1" [shape]="'flags/' + offer.sellerInformation.country.data.toLocaleLowerCase()" [size]="16"></ucs-icon>
            </span>
          }
        </div>
      }
    }

  </div>

  @if (!isTop) {
    <div class="col-md-7 col-sm-6 col-12 ps-sm-2">
      @if (!isAccountingClerk) {
        <div class="row mb-3 d-sm-none">
          <span class="col-auto pe-0">
            <ucs-icon [shape]="'location_pin'" [class]="'black me-2'" [size]="16"></ucs-icon>
          </span>
          @if ((offer.items[0] | cast : VehicleItemBaseDto)?.custodyDealer) {
            <span class="col-10 ps-0">
              {{ (offer.items[0] | cast : VehicleItemBaseDto)?.custodyDealer?.address }}<br>
              {{ (offer.items[0] | cast : VehicleItemBaseDto)?.custodyDealer?.zipCode }} {{
              (offer.items[0] | cast : VehicleItemBaseDto)?.custodyDealer?.city }} - {{
              (offer.items[0] | cast : VehicleItemBaseDto)?.custodyDealer?.country.data }}
              <ucs-icon class="pb-1" [shape]="'flags/' + offer.sellerInformation.country.data.toLocaleLowerCase()" [size]="16"></ucs-icon>
            </span>
          } @else {
            <span class="col-10 ps-0">
              {{ offer.sellerInformation.address }}<br>
              {{ offer.sellerInformation.zipCode }} {{ offer.sellerInformation.city }} - {{offer.sellerInformation.country.data}}
              <ucs-icon class="pb-1" [shape]="'flags/' + offer.sellerInformation.country.data.toLocaleLowerCase()" [size]="16"></ucs-icon>
            </span>
          }
        </div>
      }
      @if (isAccountingClerk) {
        <div class="row font-weight-bold mb-2 ps-2">
          {{'offer-detail.accounting-clerk' | translate }}
        </div>
      }
      @if (user) {
        <div class="row mb-3">
          <span class="col-auto pe-0">
            <ucs-icon [shape]="'user'" [class]="'black me-2'" [size]="16"></ucs-icon>
          </span>
          <span class="col-10 ps-0">
            {{user.firstName + " " + user.lastName}}
          </span>
        </div>
      }
      @if (user?.email && user.email !== '') {
        <div
          class="row mb-3">
          <div class="col-12">
            <!--This part is for single offers-->
            @if (offer.items.length === 1) {
<a href="mailto:{{user.email}}?subject={{validateEmailSubject('offer-detail.email-subject'
      | translate: {offerTitle: offer.title, offerId: offer.id, vinNumber: (offer.items[0] | cast : VehicleItemBaseDto).vin}) }}" class="link">
                <ucs-icon [shape]="'mail'" [class]="'blue me-2'" [size]="16"></ucs-icon>
                <span>{{'offer-detail.contact' | translate}}</span>
              </a>
            }
            <!--This part is for bundles-->
            @if (offer.items.length > 1) {
<a href="mailto:{{user.email}}?subject={{validateEmailSubject('offer-detail.email-subject-bundle'
      | translate: {offerTitle: offer.title, offerId: offer.id}) }}" class="link">
                <ucs-icon [shape]="'mail'" [class]="'blue me-2'" [size]="16"></ucs-icon>
                <span>{{'offer-detail.contact' | translate}}</span>
              </a>
            }
          </div>
        </div>
      }
      @if (user?.phoneNumber && user.phoneNumber !== '') {
        <div
          class="row mb-1">
          <div class="col-12">
            <a href="tel:{{user.phoneNumber}}" class="link">
              <ucs-icon [shape]="'phone'" [class]="'blue me-2'" [size]="16"></ucs-icon>
              <span>{{user.phoneNumber}}</span>
            </a>
          </div>
        </div>
      }
    </div>
  }
</div>

@if (isTop && user?.email && user.email !== '') {
  <div
    class="row mb-2">
    <div class="col-12 ps-0">
      <!--This part is for single offers-->
      @if (offer.items.length === 1) {
<a href="mailto:{{user.email}}?subject={{validateEmailSubject('offer-detail.email-subject'
      | translate: {offerTitle: offer.title, offerId: offer.id, vinNumber: (offer.items[0] | cast : VehicleItemBaseDto).vin})
       }}" class="link">
          <ucs-icon [shape]="'mail'" [class]="'blue me-2'" [size]="16"></ucs-icon>
          <span>{{'offer-detail.contact' | translate}}</span>
        </a>
      }
      <!--This part is for bundles-->
      @if (offer.items.length > 1) {
<a href="mailto:{{user.email}}?subject={{validateEmailSubject('offer-detail.email-subject-bundle'
      | translate: {offerTitle: offer.title, offerId: offer.id}) }}" class="link">
          <ucs-icon [shape]="'mail'" [class]="'blue me-2'" [size]="16"></ucs-icon>
          <span>{{'offer-detail.contact' | translate}}</span>
        </a>
      }
    </div>
  </div>
}
@if (isTop && user?.phoneNumber && user.phoneNumber !== '') {
  <div
    class="row">
    <div class="col-12 ps-0 text-truncate">
      <a href="tel:{{user.phoneNumber}}" class="link">
        <ucs-icon [shape]="'phone'" [class]="'blue me-2'" [size]="16"></ucs-icon>
        <span>{{user.phoneNumber}}</span>
      </a>
    </div>
  </div>
}
