import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {first} from 'rxjs/operators';

@Component({
  selector: 'ucs-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  error: string;
  errorCode: string;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params
      .pipe(first())
      .subscribe(params => {
        const id = params['id'];
        if (id !== undefined) {
          this.errorCode = id;
        } else {
          this.errorCode = '400';
        }
        this.updateErrorMsg();
      });
  }

  private updateErrorMsg() {
    switch (this.errorCode) {
    case 'navigation':
      this.error = 'error.navigation';
      break;
    case '404':
      this.error = 'error.404';
      break;
    default:
      this.error = 'error.400';
      break;
    }
  }
}

