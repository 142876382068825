import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../store/app.reducers';
import {first} from 'rxjs/operators';
import {State} from '../../../../store/user/user.reducers';

@Component({
  selector: 'ucs-e-auction-information',
  templateUrl: './e-auction-information.component.html',
  styleUrls: ['./e-auction-information.component.scss']
})
export class EAuctionInformationComponent implements OnInit {
  @Input() etxPrice: PriceDto;
  @Input() stationaryDays: any;
  @Input() numOfSaleAttempts: any;
  userState: State;

  constructor(private store: Store<fromRoot.AppState>) {
  }

  ngOnInit(): void {
    this.store
      .select(fromRoot.getUserState)
      .pipe(first())
      .subscribe(userState => {
        if (userState) {
          this.userState = userState;
        }
      });
  }
}
