import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../service/authentication.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ToastAlertService} from '../../service/toast-alert.service';

@Component({
  selector: 'ucs-auth',
  templateUrl: './auth.component.html'
})
export class AuthComponent implements OnInit, OnDestroy {

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private alert: ToastAlertService, private translate: TranslateService,
              private authenticationService: AuthenticationService, private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    let token: string;
    this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(param => {
      this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(queryParams => {
        if (!this.authenticationService.supportsLocalStorage()) {
          this.alert.danger(this.translate.instant('error.localstorage-not-supported'));
          return;
        }
        if (param.status) {
          if (queryParams.token) {
            token = queryParams.token;
          } else {
            token = this.getAuthTokenByCookie();
          }
          if (param.status === 'success' && token) {
            this.authenticationService.storeToken(token);
            this.router.navigate(['/']);
          } else {
            this.router.navigateByUrl('logout');
          }
        } else {
          token = this.authenticationService.getToken();
          if (token !== null) {
            this.router.navigate(['/']);
          } else {
            this.router.navigateByUrl('logout');
          }
        }
      });
    });
  }

  private getAuthTokenByCookie(): string {
    let token = this.getCookie('jwtToken');

    //delete cookie by setting expiration to past date
    document.cookie = 'jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    return token;
  }

  private getCookie(cookieKey: string) {
    let key = cookieKey + '=';
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let c = cookies[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(key) === 0) {
        return c.substring(key.length, c.length);
      }
    }
    return '';
  }

  /**
   * Unsubscribe from Observables when component is destroyed to prevent memory leaks
   */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
