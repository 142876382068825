import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';
import {SystemSettingsService} from '../../../service/system-settings.service';
import * as fromRoot from '../../../store/app.reducers';
import {Store} from '@ngrx/store';
import {SalesScope} from '../../../model/sales-scope';
import {State} from '../../../store/user/user.reducers';
import {isEnforcedAuctionOfferDto} from '../../../misc/typeguard';

/**
 * The footer of a tile. This is only displayed at some positions in some offer states.
 */
@Component({
  selector: 'ucs-default-footer',
  templateUrl: './default-footer.component.html',
  styleUrls: ['./default-footer.component.scss']
})
export class DefaultFooterComponent implements OnInit, OnChanges, OnDestroy {
  //Offer data coming from OfferItemDto or LatestOfferDataDto
  @Input() offer: AuctionOfferDto & BuyNowOfferDto & EnforcedAuctionOfferDto & LatestOfferDataDto;
  @Input() offerId: number;
  @Input() offerStatus: OfferStatus;
  @Input() offerType: OfferType;
  @Input() vehicle: any;
  @Input() channel: DistributionChannel;
  @Input() startPrice: PriceDto;
  @Input() currentPrice: PriceDto;
  @Input() minimumBidPrice: PriceDto;
  @Input() vatType: VatType;
  @Input() grossProfitData: GrossProfitDto;
  @Input() expirationDate: any;
  @Input() expectedExpirationDate: any;
  @Input() purchaseDate: any;
  @Input() showOfferLink: boolean;
  @Input() footerStatus: VehicleFooterStatus;
  @Input() hideFooterStatus = false;
  @Input() country: Country;
  @Input() buyingDealerId: number;
  @Input() extraTax: ExtraTaxDto;
  @Input() nationalSale: boolean;
  @Input() scopeSection: AppScope;
  @Input() disableBidding: boolean;
  @Input() activeCategory: string;
  @Input() displayLogo = false;
  @Input() thermalWindowAffected = false;
  @Output() linkToOfferClicked = new EventEmitter<void>();
  showGrossProfit = false;
  inputPrice: number;
  userInfo: UserInfoDto;
  salesScope: SalesScope;
  userState: State;
  isEnforcedAuction = false;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private store: Store<fromRoot.AppState>,
              private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit() {
    this.store.select(fromRoot.getUserInfo)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userInfo => {
        if (userInfo) {
          this.userInfo = userInfo;
        }
      });

    this.systemSettingsService.isSystemFeatureActivatedForChannel('GROSS_PROFIT_DISPLAY', this.channel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(showGrossProfit => {
        this.showGrossProfit = showGrossProfit;
      });
    this.store
      .select(fromRoot.getUserState)
      .pipe(first())
      .subscribe(userState => {
        if (userState) {
          this.userState = userState;
        }
      });

    this.inputPrice = this.footerStatus.startsWith('AUCTION') ? this.minimumBidPrice?.net : this.currentPrice?.net;
    this.nationalSale = !this.nationalSale ? this.scopeSection === 'SALES_MAINTENANCE' : this.nationalSale;

    if (this.scopeSection === 'SALES_STORE') {
      this.store.select(fromRoot.getSalesScope)
        .subscribe(scope => {
          this.salesScope = scope;
        });
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'offer') {
        this.isEnforcedAuction = isEnforcedAuctionOfferDto(this.offer);
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
