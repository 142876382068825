<h1>{{ 'legal-notice.legal-notice' | translate}}</h1>

@if (userState.hasAnyRoleForPia) {
  <h3>{{ 'ucs.pia' | translate }}</h3>
  Porsche Inter Auto GmbH & CO KG<br/>
  Louise-Piëch-Straße 2<br/>
  AT-5020 Salzburg<br/>
  <br/>
  {{ 'legal-notice.phone' | translate }}: +43 662 46 81 0<br/>
  {{ 'legal-notice.fax' | translate }}: +43 662 46 81 12547<br/>
  {{ 'legal-notice.e-mail' | translate }}: <a href="mailto:porscheinterauto@porsche.co.at">porscheinterauto&#64;porsche.co.at</a><br/>
  <br/>
  {{ 'legal-notice.chamber-affiliation' | translate }}: {{ 'legal-notice.economic-chamber-salzburg' | translate }}<br/>
  {{ 'legal-notice.trade-regulations' | translate }}: GewO <a rel="noopener" href="http://www.ris.bka.gv.at" target="_blank">www.ris.bka.gv.at</a><br/>
  <br/>
  {{ 'legal-notice.regulating-authority' | translate }}: {{ 'legal-notice.district-commission-salzburg' | translate }}<br/>
  FN 175466p/{{ 'legal-notice.state-court' | translate }} Salzburg<br/>
  {{ 'legal-notice.vat-no' | translate}}: ATU 45189200
}

@if (userState.hasAnyRoleForPb) {
  <h3>{{ 'ucs.pb' | translate }}</h3>
  Porsche Bank Aktiengesellschaft<br/>
  <br/>
  Vogelweiderstraße 75<br/>
  AT-5020 Salzburg<br/>
  <br/>
  {{ 'legal-notice.phone' | translate }}: +43 (0)662 46 83 - 0<br/>
  {{ 'legal-notice.fax' | translate }}: +43 (0)662 46 83 - 2900<br/>
  {{ 'legal-notice.e-mail' | translate }}: <a href="mailto:kontakt@porschebank.at">kontakt&#64;porschebank.at</a><br/>
  <br/>
  {{ 'legal-notice.pb-member-economic-chamber' | translate }}<br/>
  {{ 'legal-notice.pb-member-leasing' | translate }}<br/>
  <br/>
  FN 58517f/{{ 'legal-notice.state-court' | translate }} Salzburg<br/>
  {{ 'legal-notice.vat-no' | translate}}: ATU 338 33 607<br/>
}

@if (userState.hasAnyRoleForAllUc) {
  <h3>{{ 'ucs.all_uc' | translate }}</h3>
  Porsche Austria GmbH & Co OG<br/>
  {{ 'legal-notice.automobile-distribution' | translate }} / {{ 'legal-notice.wholesale' | translate }}<br/>
  <br/>
  A-5020 Salzburg<br/>
  Louise-Piëch-Straße 2<br/>
  {{ 'legal-notice.po-box' | translate }} 164<br/>
  <br/>
  {{ 'legal-notice.phone' | translate }}: +43/662/4681-0<br/>
  {{ 'legal-notice.e-mail' | translate }}: <a href="mailto:office@dasweltauto.at">office&#64;dasweltauto.at</a><br/>
  <br/>
  {{ 'legal-notice.legal-form' | translate }}: {{ 'legal-notice.legal-form.og' | translate }}<br/>
  {{ 'legal-notice.residence' | translate}}: Salzburg<br/>
  FN 27015 d / {{ 'legal-notice.state-court' | translate }} Salzburg<br/>
  <br/>
  {{ 'legal-notice.vat-no' | translate}}: ATU 34242904<br/>
  <br/>
  WKO - {{ 'legal-notice.wko-description' |translate }}<br/>
  {{ 'legal-notice.commercial-and-professional-regulations' | translate}}: GewO
  <a rel="noopener" href="http://www.ris.bka.gv.at" target="_blank">www.ris.bka.gv.at</a><br/>
  <br/>
  {{ 'legal-notice.regulating-authority' | translate}}: {{ 'legal-notice.district-commission-salzburg' | translate}}
}

<div class="spacer"></div>
