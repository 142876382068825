import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead/typeahead-match.class';
import {Observable, Observer} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {DealerService} from '../../service/dealer.service';

/**
 */
@Component({
  selector: 'ucs-dealer-autocomplete',
  templateUrl: './dealer-autocomplete.component.html',
  styleUrls: ['./dealer-autocomplete.component.scss']
})
export class DealerAutocompleteComponent {
  // the function for mergeMap from which to get the dealers
  @Input() sourceFunction: (string) => Observable<DealerDetailDto[]>;
  @Input() channel: DistributionChannel;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() sidebar: boolean;
  @Input() smallerHeading: boolean;
  @Input() class: string;
  @Input() clearOnSelect = true;
  @Input() showCompanyNumberOrPbvCustomerNumber = true;
  @Output() onSelected = new EventEmitter<any>();
  @Output() onBlur = new EventEmitter<void>();
  selectedValue: string;
  dataSource$: Observable<any>;

  /**
   * Bind our async datasource to the observable returned by the source function
   */
  constructor(protected dealerService: DealerService) {
    this.dataSource$ = new Observable((observer: Observer<any>) => {
      // Runs on every search
      observer.next(this.selectedValue);
    }).pipe(mergeMap((filter: string) => this.sourceFunction(filter)));
  }

  /**
   * Emit the selected item to the parent
   */
  onSelect(event: TypeaheadMatch): void {
    this.selectedValue = this.dealerService.getCompanyNumberOrPbvCustomerNumber(event.item, this.channel) + ' ' + event.item.name;
    this.onSelected.emit(event.item);
    if (this.clearOnSelect){
      this.selectedValue = undefined;
    }
  }

  /**
   * Called whenever input value of dropdown changes
   * When empty, i.e. selection cleared, emit to parent to clear the search param
   */
  onInput(event) {
    if (this.selectedValue === '') {
      this.selectedValue = undefined;
      this.onSelected.emit(undefined);
    }
  }

  /**
   * Allows overriding the current selection (intended to be used by parent component)
   */
  overrideSelection(companyNumberOrPbvCustomerNumber: string, name: string) {
    if (companyNumberOrPbvCustomerNumber === undefined && name === undefined) {
      this.selectedValue = undefined;
    } else { // or set the selected value if not undefined
      this.selectedValue = companyNumberOrPbvCustomerNumber + ' ' + name;
    }
  }
}
