<div class="row mt-2 mb-2">
  <div class="btn-group col-12">
    <input type="button" role="button" tabindex="0" value="{{labelLeft}}"
           data-cy="left"
           class="btn btn-sm button-toggle-options"
           [disabled]="disabledLeft"
           [ngClass]="{'disabled': disabledLeft, 'active': activeLeft}"
           (click)="clickLeft(label)">
    <input type="button" role="button" tabindex="0" value="{{labelRight}}"
           data-cy="right"
           class="btn btn-sm button-toggle-options"
           [ngClass]="{'disabled': disabledRight, 'active': activeRight}"
           [disabled]="disabledRight"
           (click)="clickRight(label)">
  </div>
</div>
