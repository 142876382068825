@if (filterBullets) {
  <div class="scrollable-group">
    <div class="row">
      @for (bullet of filterBullets; track bullet) {
        <div class="bullet mt-2 col-auto"
          [ngClass]="{'px-0': (bullet.value[0] === '' || bullet.value[1] === ''), 'px-1': (bullet.value[0] !== '' || bullet.value[1] !== '')}">
          <ucs-filter-bullet (clickDelete)="onDeleteBullet($event)" [bullet]="bullet"></ucs-filter-bullet>
        </div>
      }
    </div>
  </div>
}
