@if (activeCategory !== 'LONGTIME_PENDING' && (userInfo?.accredited !== false || channel !== 'PIA')) {
  <ucs-default-footer
    [offer]="offerData"
    [offerId]="offerData.offerId ? offerData.offerId : offerData.id"
    [offerStatus]="offerData.status?.data ? offerData.status.data : offerData.offerStatus"
    [offerType]="offerData.offerType?.data ? offerData.offerType.data : offerData.offerType"
    [expectedExpirationDate]="offerData.expectedExpiration"
    [expirationDate]="offerData.expiration"
    [channel]="channel"
    [vehicle]="vehicle"
    [displayLogo]="displayLogo"
    [nationalSale]="offerData.nationalSale"
    [extraTax]="extraTax"
    [activeCategory]="activeCategory"
    [country]="country"
    [buyingDealerId]="offerData?.purchase?.buyingDealer?.id"
    [currentPrice]="currentPrice"
    [disableBidding]="disableBidding"
    [footerStatus]="footerStatus"
    [grossProfitData]="grossProfitData"
    [hideFooterStatus]="hideFooterStatus"
    [minimumBidPrice]="minimumBidPrice"
    [purchaseDate]="offerData?.purchase?.dateOfPurchase ?  offerData.purchase.dateOfPurchase : offerData.purchaseDate"
    [scopeSection]="scopeSection"
    [showOfferLink]="showOfferLink"
    [startPrice]="startPrice"
    [vatType]="vatType"
    [thermalWindowAffected]="isThermalWindowToggleActive && vehicle?.thermalWindowAffected && !approvalFromUser"
    (linkToOfferClicked)="linkToOfferClicked.emit()">
  </ucs-default-footer>
}

@if (activeCategory === 'LONGTIME_PENDING') {
  <ucs-enforced-auction-footer
    [vehicle]="vehicle"
    [enforcedAuctionData]="enforcedAuctionData"
  [displayLogo]="displayLogo"></ucs-enforced-auction-footer>
}


@if (userInfo?.accredited === false && channel==='PIA') {
  <ucs-missing-documents-footer>
  </ucs-missing-documents-footer>
}
