import {Pipe, PipeTransform} from '@angular/core';

/**
 * Truncates a string
 *
 * Examples:
 *   'Porsche Holding' | truncate:3           -> Por...
 *   'Porsche Holding' | truncate:3:' [..]'   -> Por [..]
 */
@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, length: number, mode: string = 'suffix', ellipsis: string = '...'): string {
    if(mode === 'suffix') {
      return value?.length > length ? value.substring(0, length) + ellipsis : value;
    } else if(mode === 'prefix') {
      return value?.length > length ? ellipsis + value.substring(length) : value;
    } else {
      return value;
    }
  }

}
