// This file exports 'user-defined typeguards' to check whether an object's instance is of a given interface

/**
 * Checks whether a given object is of type ValueAggregationEntry
 * @param arg: the instance to be checked whether of type ValueAggregationEntry
 * @returns {arg is ValueAggregationEntry} true if of type ValueAggregationEntry, else false
 */
export function isValueAggregationEntry(arg: any): arg is ValueAggregationEntry {
  return (<ValueAggregationEntry>arg).value !== undefined;
}

/**
 * Checks whether a given object is of type EquipmentAggregationEntry
 * @param arg: the instance to be checked whether of type EquipmentAggregationEntry
 * @returns {arg is EquipmentAggregationEntry} true if of type EquipmentAggregationEntry, else false
 */
export function isEquipmentAggregationEntry(arg: any): arg is EquipmentAggregationEntry {
  return (<EquipmentAggregationEntry>arg).category !== undefined;
}

/**
 * Checks whether a given object is of type LocationDto
 * @param arg: the instance to be checked whether of type LocationDto
 * @returns {arg is LocationDto} true if of type LocationDto, else false
 */
export function isLocationDto(arg: any): arg is LocationDto {
  return (<LocationDto>arg).zipCode !== undefined;
}

/**
 * Checks whether a given object is of type EquipmentDto
 * @param arg: the instance to be checked whether of type EquipmentDto
 * @returns {arg is EquipmentDto} true if of type EquipmentDto, else false
 */
export function isEquipmentDto(arg: any): arg is EquipmentDto {
  return (<EquipmentDto>arg).text !== undefined;
}

/**
 * Checks whether a given object is of type AuctionOfferDto
 * @param arg: the instance to be checked whether of type AuctionOfferDto
 * @returns {arg is AuctionOfferDto} true if of type AuctionOfferDto, else false
 */
export function isAuctionOfferDto(arg: any): arg is AuctionOfferDto {
  return (<AuctionOfferDto>arg).bidStatus !== undefined;
}

/**
 * Checks whether a given object is of type AuctionOfferDto
 * @param arg: the instance to be checked whether of type AuctionOfferDto
 * @returns {arg is EnforcedAuctionOfferDto} true if of type AuctionOfferDto, else false
 */
export function isEnforcedAuctionOfferDto(arg: any): arg is EnforcedAuctionOfferDto {
  return (<EnforcedAuctionOfferDto>arg).offerType.data === 'ENFORCED_AUCTION';
}

/**
 * Checks whether a given object is of type BuyNowOfferDto
 * @param arg: the instance to be checked whether of type BuyNowOfferDto
 * @returns {arg is BuyNowOfferDto} true if of type BuyNowOfferDto, else false
 */
export function isBuyNowOfferDto(arg: any): arg is BuyNowOfferDto {
  return (<BuyNowOfferDto>arg).price !== undefined;
}

/**
 * Checks whether a given object is of type AuctionOfferUpdateDto
 * @param arg: the instance to be checked whether of type AuctionOfferUpdateDto
 * @returns {arg is BuyNowOfferDto} true if of type AuctionOfferUpdateDto, else false
 */
export function isAuctionOfferUpdateDto(arg: any): arg is AuctionOfferUpdateDto {
  return (<AuctionOfferUpdateDto>arg).minimumPurchasePrice !== undefined;
}

/**
 * Checks whether a given object is of type BuyNowOfferUpdateDto
 * @param arg: the instance to be checked whether of type BuyNowOfferUpdateDto
 * @returns {arg is BuyNowOfferDto} true if of type BuyNowOfferUpdateDto, else false
 */
export function isBuyNowOfferUpdateDto(arg: any): arg is BuyNowOfferUpdateDto {
  return (<BuyNowOfferUpdateDto>arg).title !== undefined;
}

/**
 * Checks whether a given object is of type VehicleItemBaseDto
 * @param arg: the instance to be checked whether of type VehicleItemBaseDto
 * @returns {arg is VehicleItemBaseDto} true if of type VehicleItemBaseDto, else false
 */
export function isVehicleItemBaseDto(arg: any): arg is VehicleItemBaseDto {
  return (<VehicleItemBaseDto>arg).vehicleId !== undefined;
}

/**
 * Checks whether a given object is of type VehilceBaseDto
 * @param arg: the instance to be checked whether of type VehilceBaseDto
 * @returns {arg is VehilceBaseDto} true if of type VehilceBaseDto, else false
 */
export function isVehicleBaseDto(arg: any): arg is VehicleBaseDto {
  return (<VehicleBaseDto>arg).fuelTypes !== undefined;
}

/**
 * Checks whether a given object is of type VehicleDetailDto
 * @param arg: the instance to be checked whether of type VehicleDetailDto
 * @returns {arg is VehicleDetailDto} true if of type VehicleDetailDto, else false
 */
export function isVehicleDetailDto(arg: any): arg is VehicleDetailDto {
  return (<VehicleDetailDto>arg).vin !== undefined;
}

/**
 * Checks whether a given object is of type VehicleItemDetailDto
 * @param arg: the instance to be checked whether of type VehicleItemDetailDto
 * @returns {arg is VehicleItemDetailDto} true if of type VehicleItemDetailDto, else false
 */
export function isVehicleItemDetailDto(arg: any): arg is VehicleItemDetailDto {
  return (<VehicleItemDetailDto>arg).vin !== undefined;
}

/**
 * Checks whether a given object is of type OfferDto
 * @param arg: the instance to be checked whether of type OfferDto
 * @returns {arg is OfferDto} true if of type OfferDto, else false
 */
export function isOfferDto(arg: any): arg is OfferDto {
  return (<OfferDto>arg).channel.data !== undefined;
}
