import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';

@Component({
  selector: 'ucs-price-input',
  templateUrl: './price-input.component.html',
  styleUrls: ['./price-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: PriceInputComponent,
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useValue: Validators.max(999999999999),
    multi: true
  }]
})
export class PriceInputComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() class: string;
  @Input() name: string;
  @Input() id: string;
  @Input() inputError: boolean;
  @Input() required = true;
  @Input() step: string = '.01';
  innerModel: number;
  @Output() userFocus = new EventEmitter<string>();
  @Output() enterOnInputElement = new EventEmitter();
  private onChangedCallback = new Array<(value: number) => void>();
  private onTouchedCallback = new Array<() => void>();



  inputChange(newPrice: number) {
    this.innerModel = newPrice;
    this.executeCallbacks(newPrice);
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if(this.innerModel > 99999999999){
      event.preventDefault();
    }
  }

  private executeCallbacks(newPrice: number): void {
    const newPriceAsSubunit: number = this.convertToSubunit(newPrice);
    this.onChangedCallback.forEach(callback => callback(newPriceAsSubunit));
  }

  private convertToSubunit(value: number): number {
    if (value !== 0 && !value) {
      return null;
    }
    return Math.round(value * 100);
  }

  onFocus(): void {
    let focusParsed = this.id.replace(/.*_/, '');
    this.userFocus.emit(focusParsed);
  }

  writeValue(value: number): void {
    this.innerModel = value / 100;
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChangedCallback.push(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback.push(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
