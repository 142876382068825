<h2>{{ 'terms.terms' | translate }}</h2>

<!-- we remove DIN_BIL and VGRX because we actually dont have buyer users for this two channel so we dont need
the AGBs -->
@for (distributionChannel of ['PIA', 'PB', 'ALL_UC']; track distributionChannel) {
  @if (userState.userInfo?.currentDealerChannelCountries[distributionChannel]?.length > 0) {
    <h3>{{ 'ucs.' + distributionChannel.toLowerCase() | translate }}</h3>
    @for (country of userState.userInfo.currentDealerChannelCountries[distributionChannel]; track country) {
      <strong>{{ 'api.type.country.' + country | translate }} ({{ country }})</strong><br>
        @if (country !== 'IT'){
            <a href="../../assets/gtc/{{ getGTCFile(distributionChannel, country)[0] }}" target="_blank">{{ 'terms.' + distributionChannel.toLowerCase() | translate }}</a>
        }
        @if (getGTCFile(distributionChannel, country).length > 1) {
          @if (country === 'IT'){
              <a href="../../assets/gtc/{{ getGTCFile(distributionChannel, country)[1] }}" target="_blank">{{ 'terms.ptu' | translate }}</a>
              <br>
              <a href="../../assets/gtc/{{ getGTCFile(distributionChannel, country)[0] }}" target="_blank">{{ 'terms.' + distributionChannel.toLowerCase() | translate }} ({{ 'terms.english-version' | translate }})</a>
          }
          @else {
              <br>
              <a href="../../assets/gtc/{{ getGTCFile(distributionChannel, country)[1] }}" target="_blank">{{ 'terms.' + distributionChannel.toLowerCase() | translate }} ({{ 'terms.english-version' | translate }})</a>
          }
        }
        @if (distributionChannel === 'PB' && country === 'AT') {
          <br>
            <a href="../../assets/gtc/PP_PB_AT_de_2024_04.pdf" target="_blank">{{ 'gtc-change-history-PB-v2.row12' | translate }}</a>
            <br>
              <a href="../../assets/gtc/PP_PB_AT_en_2024_04.pdf" target="_blank">{{ 'gtc-change-history-PB-v2.row13' | translate }}</a>
            }
            @if (distributionChannel === 'PB' && country === 'SI') {
              <br>
                <a href="../../assets/gtc/PP_PB_SI_sl_2024_05.pdf" target="_blank">{{ 'gtc-change-history-PB-v2.row12' | translate }}</a>
              }
              @if (distributionChannel === 'PB' && country === 'HR') {
                <br>
                  <a href="../../assets/gtc/PP_PB_HR_hr_2024_09.pdf" target="_blank">{{ 'gtc-change-history-PB-v2.row12' | translate }}</a>
                }
                <br><br>
              }
            }
          }

          <div class="spacer"></div>
