/**
 * Table for audit log
 */
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {State} from '../../store/user/user.reducers';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ucs-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})

export class LogComponent implements OnInit, OnDestroy {
  @Input() logs: LogDto[];
  userState: State;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private store: Store<fromRoot.AppState>) {
  }


  ngOnInit() {
    this.store
      .select(fromRoot.getUserState)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userState => {
        if (userState) {
          this.userState = userState;
        }
      });
  }

  isStatusChangeOrCreation(log: LogDto):boolean{
    return log.action === 'OFFER_STATUS_CHANGE' || log.action === 'VEHICLE_STATUS_CHANGE'
      || log.action === 'OFFER_INITIAL_CREATION';
  }

  /**
   * On desctruction, all subscriptions are shut down
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
