import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {SystemSettingsService} from '../service/system-settings.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Directive({
  selector: '[ucsHideableData]'
})
/**
 * directive for hiding data which is configured to be hidden in the corresponding feature settings
 */
export class HideableDataDirective implements OnInit, OnDestroy {

  @Input() dataType: string;

  @Input() channel: DistributionChannel;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private elementRef: ElementRef,
              private systemSettingsService: SystemSettingsService) { }

  ngOnInit(): void {
    this.elementRef.nativeElement.style.display = 'none';
    this.view();
  }

  view(): void {
    if (this.dataType && this.channel) {
      let hiddenFields: string[];
      this.systemSettingsService.getHiddenVehicleData(this.channel)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(hiddenData => {
          hiddenFields = hiddenData;
        });
      this.elementRef.nativeElement.style.display = !hiddenFields.includes(this.dataType) ? 'block' : 'none';
      return;
    }
    this.elementRef.nativeElement.style.display = 'block';
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
