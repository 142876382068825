<div class="row">
  <div class="d-inline-block starting-price-heading ms-2 mt-2">
    @if (!ucsIsNil(this.offerData) && !ucsIsNil(this.offerData.initialBid) && !ucsIsNil(this.offerData.highestBid)
      && this.offerData.initialBid.gross !== this.offerData.highestBid.gross) { <!-- offer price -->
      <span>{{ 'vehicle-footer.highest-bid' | translate }}</span>
    } @else { <!-- starting price -->
      <span>{{ 'vehicle-footer.starting-price' | translate }}</span>
    }
  </div>
  <div class="starting-price mb-2 ms-2">
    @if (offerData?.purchasePrice?.gross) { <!-- offer price -->
      <span>{{ offerData.purchasePrice.gross | price }} {{ offerData.purchasePrice.currency.data }}</span>
      <span class="font-regular">{{ 'api.type.pricetype.GROSS' | translate }}</span>
    } @else if (!ucsIsNil(this.offerData?.initialBid) && !ucsIsNil(this.offerData?.highestBid)
      && this.offerData?.initialBid?.gross !== this.offerData?.highestBid?.gross) {
      <span >{{ offerData.highestBid.gross | price }} {{ offerData.highestBid.currency.data }}</span>
      <span class="font-regular">{{ 'api.type.pricetype.GROSS' | translate }}</span>
    } @else { <!-- starting price -->
      @if (['AUTO_SELECTED', 'MANUALLY_SELECTED', 'MANUALLY_REMOVED', 'NOT_SELECTED'].includes(itemStatus)
        && isAdmin ) {
        <span>
          <input ucsNumberFormat type="text" class="small-input-form" maxlength="13"
            [value]="(startingPrice?.gross / 100) | number: '1.0-0':'de' "
            (blur)="updateStartingPrice($event)"
            (keyup.enter)="updateStartingPrice($event)">
          {{ startingPrice?.currency.data }}
          <span class="font-regular">{{ 'api.type.pricetype.GROSS' | translate }}</span>
        </span>
      } @else {
        @if (startingPrice?.gross) {
          <span>{{ startingPrice?.gross | price}} {{ startingPrice?.currency.data }}
            <span class="font-regular">{{ 'api.type.pricetype.GROSS' | translate }}</span>
          </span>
        }
        @if (!startingPrice?.gross) {
          <span>{{ 'vehicle-footer.NOT_AVAILABLE' | translate }}</span>
        }
      }
    }
  </div>
</div>
