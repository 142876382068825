/**
 * Helper Model for FilterBulletComponent and FilterBulletListComponent
 */
export class FilterBullet {
  key: string;
  value: string[];
  type: string; // default, range, city, equipment, equipmentHighlight

  constructor(key: string, value: string[], type: string) {
    this.key = key;
    this.value = value;
    this.type = type;
  }
}
