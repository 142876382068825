import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/app.reducers';

/*
 * Returns the formatted number and appends the 'kg' (kilogram) unit
 * Usage:
 *   value | kg
 * Example:
 *   {{ vehicleDetails.weightNet | kg }}
 *   Let vehicleDetails.weightNet be 1728, then the returned text will be "1,728 kg" (dots or commas depending on localisation)
*/
@Pipe({
  name: 'kg'
})
@Injectable()
export class KgPipe implements PipeTransform {

  private local: string;

  constructor(private store: Store<fromRoot.AppState>) {
  }

  transform(value: number): string {

    this.store.select(fromRoot.getUserState)
      .pipe()
      .subscribe(
        s => {
          this.local = s.local;
        }
      );
    // format number based on local
    const formattedValue = new DecimalPipe(this.local).transform(value);

    // check if formattedValue is null or undefined
    if (formattedValue === null) {
      return '-';
    } else {
      // append unit
      return formattedValue + ' kg';
    }
  }
}
