@if (showEmailSubjectToggle) {
  <div class="d-flex gap-2 ms-1">
    <span  #subject>{{getMailSubject()}}</span>
    <ucs-icon [floatUi]="'tooltip.common.copy-to-clipboard' | translate"
      [showTrigger]="NgxFloatUiTriggers.hover"
      [shape]="'copy'"
      [class]="'blue mb-2 subject-copy-icon'" [size]="16"
    [cdkCopyToClipboard]="subject.textContent"></ucs-icon>
    <a href="mailto:?subject={{validateEmailSubject(subject.textContent)}}" class="link">
      <ucs-icon [shape]="'mail'" [class]="'blue mb-1'" [size]="16"
      [floatUi]="'mail.common.open-client' | translate" [showTrigger]="NgxFloatUiTriggers.hover"></ucs-icon>
    </a>
  </div>
}
