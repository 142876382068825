<div class="modal fade" id="saveSearchModal" aria-labelledby="saveSearchModal" aria-hidden="true" tabindex="-1"
  role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ 'save-search.head' | translate }}</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body col-12">
        <p class="text-body">{{ 'save-search.label' | translate }}</p>
        <input name="descriptionInput" [(ngModel)]="inputText" #descriptionInput=ngModel class="col-12" type="text"
          placeholder="{{'save-search.placeholder' | translate}}" [autofocus]="true">
        @if (descriptionInput.dirty && !inputText) {
          <div class="error pt-1">
            {{ 'save-search.no-description' | translate }}
          </div>
        }
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="cancelSettings()">{{ 'save-search.cancel' | translate }}
        </button>
        <button type="button" class="btn btn-primary save-btn" data-bs-dismiss="modal" (click)="saveSettings()" [disabled]="!inputText">
          {{ 'save-search.save' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
