import {Component,} from '@angular/core';

/**
 * The footer of a tile. This is only displayed at some positions in some offer states.
 */
@Component({
  selector: 'ucs-missing-documents-footer',
  templateUrl: './missing-documents-footer.component.html',
  styleUrls: ['./missing-documents-footer.component.scss']
})
export class MissingDocumentsFooterComponent {


}
