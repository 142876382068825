<div [floatUi]="tooltipText" [showTrigger]="NgxFloatUiTriggers.hover" [disabled]="!tooltipText">
  <button class="btn btn-secondary" data-bs-toggle="modal" [attr.data-bs-target]="'#transportDialog' + id"
          [disabled]="disabled"
          (click)="resetTransportRequest()">
    <ucs-icon [shape]="'truck'" [class]="'blue'" [size]="18"></ucs-icon>
    {{ (id === 'Basket' ? 'transport.collective-transport-request' : 'transport.transport-request') | translate }}
  </button>
</div>

<div class="modal fade" [id]="'transportDialog' + id" aria-labelledby="transportDialog" aria-hidden="true" tabindex="-1"
  role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ 'transport.modal.title' | translate }}</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
      </div>
      @if (existingTransportRequests?.length > 0) {
        <div class="existing-transport-requests px-3 pt-3">
          <div class="warning row pb-3 justify-content-between">
            <span class="col-auto warning-text">
              <ucs-icon [shape]="'alert-triangle'" [class]="'col pe-2 red'" [size]="18"></ucs-icon>
              <strong>{{ 'transport.modal.existing-transport-requests' | translate }}</strong>
            </span>
            @if (allowedToSeeExistingTRs) {
              <div class="accordion-icon col-auto">
                @if (showExistingTransportRequests) {
                  <ucs-icon [shape]="'angle_up'" [class]="'col ms-auto black'"
                  (click)="showExistingTransportRequests = false"></ucs-icon>
                } @else {
                  <ucs-icon [shape]="'angle_down'" [class]="'col ms-auto black'"
                  (click)="showExistingTransportRequests = true"></ucs-icon>
                }
              </div>
            }
          </div>
          @if (showExistingTransportRequests && allowedToSeeExistingTRs) {
            @for (transport of existingTransportRequests; track transport; let last = $last) {
              <div class="row"
                [ngClass]="{'pb-3': last}">
                <div class="col col-2">{{ transport.date | date: ('ucs.datetime' | translate) }}</div>
                <div class="col col-3">{{ ('transport.modal.carrier.' + transport.carrier) | translate }}</div>
                <div class="col col-2">{{ ('transport.modal.type.' + transport.transportRequestType) | translate }}</div>
                <div class="col col-2">{{ transport.fromLocation + ' => ' + transport.destinationDealer.city }}</div>
                @if (transport.requestorUser) {
                  <div class="col col-3">{{ transport.requestorUser.firstName + ' ' +
                    transport.requestorUser.lastName + ' / '
                    + dealerService.getCompanyNumberOrPbvCustomerNumber(transport.requestorDealer, channel) + ' ' + transport.requestorDealer.name }}
                  </div>
                }
              </div>
            }
          }
        </div>
      }
      <div class="row pt-3 selected-vehicles-label">
        <strong>{{ 'transport.modal.selected-vehicles' | translate }}</strong>
      </div>
      @for (vehicle of selectedVehicles; track vehicle.id) {
        <div class="row selected-vehicles">
          <div class="col col-12 ms-3">
            <ucs-icon (click)="toggleVehicle(vehicle.id)" class="ms-2"
              [shape]="isVehicleSelected( vehicle.id) ? 'square_checked' : 'square_outline'"
            [class]="'blue'" [ngClass]="{'d-none': selectedVehicles.length === 1}"></ucs-icon>
            <span class="ms-2">{{ vehicle.externalId + ' - ' + vehicle.modelVariant + ' - ' + vehicle.vin}}</span>
          </div>
        </div>
      }
      <div class="row">
        <ul class="list-group col col-6 ps-3 ms-3 mt-3">
          <li class="list-group-item list-group-item-action"
            [ngClass]="{'active': transportRequestCreation.carrier === 'FRIKUS'}"
            (click)="transportRequestCreation.carrier = 'FRIKUS'">
            {{ 'transport.modal.carrier.FRIKUS' | translate }}
            <button class="ms-auto" type="button" [floatUi]="popFRIKUS" [placement]="NgxFloatUiPlacements.TOP"
              (click)="$event.stopPropagation()">
              <ucs-icon [shape]="'info'"
              [class]="transportRequestCreation.carrier === 'FRIKUS' ? 'white' : 'blue'"></ucs-icon>
            </button>
            <float-ui-content #popFRIKUS>
              <div>{{ 'transport.modal.carrier.FRIKUS' | translate }}</div>
              <div>Industriegasse 30</div>
              <div>8141 Zettling</div>
              <div><a href="mailto:manfred.poelzl@frikus.com">manfred.poelzl&#64;frikus.com</a></div>
              <div><a href="mailto:carina.pointner@frikus.com">carina.pointner&#64;frikus.com</a></div>
              <div>+43 (0) 3135 500 DW 8161</div>
            </float-ui-content>
          </li>
          <li class="list-group-item list-group-item-action"
            [ngClass]="{'active': transportRequestCreation.carrier === 'HOEDLMAYR'}"
            (click)="transportRequestCreation.carrier = 'HOEDLMAYR'">
            {{ 'transport.modal.carrier.HOEDLMAYR' | translate }}
            <button class="ms-auto" type="button" [floatUi]="popHOEDLMAYR" [placement]="NgxFloatUiPlacements.TOP"
              (click)="$event.stopPropagation()">
              <ucs-icon [shape]="'info'"
              [class]="transportRequestCreation.carrier === 'HOEDLMAYR' ? 'white' : 'blue'"></ucs-icon>
            </button>
            <float-ui-content #popHOEDLMAYR>
              <div>{{ 'transport.modal.carrier.HOEDLMAYR' | translate }}</div>
              <div>Aisting 3</div>
              <div>4311 Schwertberg</div>
              <div><a href="mailto:helmut.knoll@hoedlmayr.com">helmut.knoll&#64;hoedlmayr.com</a></div>
              <div>+43 (0) 7262 660</div>
            </float-ui-content>
          </li>
          <li class="list-group-item list-group-item-action"
            [ngClass]="{'active': transportRequestCreation.carrier === 'LAGERMAX'}"
            (click)="transportRequestCreation.carrier = 'LAGERMAX'">
            {{ 'transport.modal.carrier.LAGERMAX' | translate }}
            <button class="ms-auto" type="button" [floatUi]="popLAGERMAX" [placement]="NgxFloatUiPlacements.TOP"
              (click)="$event.stopPropagation()">
              <ucs-icon [shape]="'info'"
              [class]="transportRequestCreation.carrier === 'LAGERMAX' ? 'white' : 'blue'"></ucs-icon>
            </button>
            <float-ui-content #popLAGERMAX>
              <div>{{ 'transport.modal.carrier.LAGERMAX' | translate }}</div>
              <div>Feldstraße 8</div>
              <div>5204 Straßwalchen</div>
              <div><a href="mailto:stefanie.staudinger@lagermax.com">stefanie.staudinger&#64;lagermax.com</a></div>
              <div>+43 (0) 6215 8485</div>
            </float-ui-content>
          </li>
        </ul>
        <div class="col col-5">
          <div class="col col-12 ms-3"><strong>{{ 'transport.modal.type' | translate }}</strong></div>
          <select [(ngModel)]="transportRequestCreation.transportRequestType"
            class="col col-6 form-select ms-3 request-type">
            <option [value]="'ORDER'">{{ 'transport.modal.type.ORDER' | translate }}</option>
            <option [value]="'INQUIRY'">{{ 'transport.modal.type.INQUIRY' | translate }}</option>
          </select>
          @if (isSellerPb) {
            <div class="payed-by-pb col-12 mt-3 mb-3">
              <ucs-icon (click)="transportRequestCreation.paidByPB = !transportRequestCreation.paidByPB"
                [shape]="transportRequestCreation.paidByPB ? 'square_checked' : 'square_outline'"
              [class]="'blue'"></ucs-icon>
              <strong class="ms-2">{{'transport.modal.payed-by-PB' | translate}}</strong>
            </div>
          }
        </div>
      </div>
      <div class="row pt-3">
        <div class="col col-6 ps-3 ms-3 pe-3">
          <div class="col-6 d-inline-block text">
            <strong>{{'transport.modal.destination-dealer' | translate}}</strong>
          </div>
          <div class="col col-6 d-inline-block pe-0 text-end">
            @if (!manualDestinationInput) {
              <button type="button" class="btn-popover mb-1" (click)="changeToInput()">
                <ucs-icon [shape]="'edit-2'"></ucs-icon>
                <strong>{{'initial-proposal.dialog.manual-input' | translate}}</strong>
              </button>
            }
            @if (manualDestinationInput) {
              <button type="button" class="btn-popover mb-1" (click)="changeToInput()">
                <ucs-icon [shape]="'search'" [class]="'blue'"></ucs-icon>
                <strong>{{'initial-proposal.dialog.search-dealer' | translate}}</strong>
              </button>
            }
          </div>
          @if (!manualDestinationInput) {
            @if (!transportRequestForOwnPurchase) {
              <div class="col col-8 d-inline-block">
                <ucs-dealer-autocomplete [sidebar]=true
                  [channel]="channel"
                  [sourceFunction]="dealerAutoCompleteSourceFunction"
                  [placeholder]="''"
                  [label]="''"
                  (onSelected)="dealerSelected($event)">
                </ucs-dealer-autocomplete>
              </div>
            }
            @if (destinationDealer?.name) {
              <div class="col-12" [ngClass]="{'mt-3': !transportRequestForOwnPurchase}"
                >
                {{ dealerService.getCompanyNumberOrPbvCustomerNumber(destinationDealer, channel) + ' ' + destinationDealer?.name }}<br/>
                {{ destinationDealer?.address }}<br/>
                {{ destinationDealer?.zipCode + ' ' + destinationDealer?.city }}
              </div>
            }
          }

          @if (manualDestinationInput) {
            <ucs-manual-dealer-input [vehicleId]="selectedVehicles[0]?.id"
              (validityChanged)="isManualDealerFormValid = $event"
            (externalDealerChanged)="transportRequestCreation.destinationDealer = $event"></ucs-manual-dealer-input>
          }
        </div>
        <div class="col col-5 ms-3">
          <div class="row">
            <div class="col col-12 mt-2">
              <strong>{{ 'transport.modal.pickup-dealer' | translate }}</strong>
            </div>
          </div>
          @if (fromDealer?.id) {
            <div class="row">
              <div class="col col-12">
                {{ dealerService.getCompanyNumberOrPbvCustomerNumber(fromDealer, channel) + ' ' + fromDealer?.name }}
              </div>
            </div>
          } @else {
            <div class="row">
              <div class="col col-12">
                {{ custodyDealerExternalId ? custodyDealerExternalId + ' ' : '' }}{{ custodyDealerCompanyName ? custodyDealerCompanyName : '' }}
              </div>
            </div>
          }
          @if (fromDealer?.address) {
            <div class="row">
              <div class="col col-12">
                {{ fromDealer?.address }}
              </div>
            </div>
          }
            <div class="row">
              <div class="col col-12">
                {{ fromDealer?.zipCode && fromDealer?.city ? fromDealer?.zipCode + ' ' + fromDealer?.city :
                  ((fromDealer?.zipCode ?? fromDealer?.city) ?? '')
                }}
              </div>
            </div>
            <div class="row">
              <div class="col col-12">
                {{ fromDealer?.country?.text }}
              </div>
            </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col col-5 ps-3 ms-3 me-3">
          <strong>{{ 'transport.modal.delivery-date-info' | translate }}</strong>
          <textarea class="form-control"
          [(ngModel)]="transportRequestCreation.toLocationInfo"></textarea>
        </div>
        @if (!transportRequestForOwnPurchase) {
          <div class="col col-5 offset-1">
            <strong>{{ 'transport.modal.pickup-date-info' | translate }}</strong>
            <textarea class="form-control"
            [(ngModel)]="transportRequestCreation.fromLocationInfo"></textarea>
          </div>
        }
      </div>
      <div class="row mt-3">
        <div class="col col-5 ps-3 ms-3 me-3">
          <strong>{{ 'transport.modal.further-info' | translate }}</strong>
          <textarea class="form-control" [(ngModel)]="transportRequestCreation.furtherInfo"></textarea>
        </div>
      </div>
      <div class="row ps-3">
        <div class="email-copy col-12 mt-3 mb-3">
          <ucs-icon (click)="transportRequestCreation.emailCopy = !transportRequestCreation.emailCopy"
            [shape]="transportRequestCreation.emailCopy ? 'square_checked' : 'square_outline'"
          [class]="'blue'"></ucs-icon>
          <strong class="ms-2">{{'transport.modal.email-copy' | translate}}</strong>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="resetTransportRequest()">{{
          'transport.modal.cancel' | translate }}
        </button>
        <button
          class="btn btn-primary align-items-center no-radius-right pt-2 pe-3 pb-2 ps-3 me-0"
          (click)="createTransportRequest()" data-bs-dismiss="modal"
          [disabled]="isCreateButtonDisabled()">
          <strong>{{'transport.modal.send' | translate }}</strong>
        </button>
      </div>
    </div>
  </div>
</div>
