<button id="bid-buy.bid-buy-button"
        [ngClass]="{'button-bid': ['AUCTION','ENFORCED_AUCTION'].includes(offerType), 'button-buy': offerType === 'BUYNOW'}"
        (click)="buttonClicked()"
        class="btn btn-primary align-items-center px-3 py-2 mb-2 bid-buy-button"
        data-bs-toggle="modal"
        [disabled]="disabled"
        [attr.data-bs-target]="thermalWindowAffected ? thermalWindowModalTarget : modalTarget"
        data-cy="bid-buy-component.bid-buy-btn">
  <strong>{{ ['AUCTION','ENFORCED_AUCTION'].includes(offerType) ? ('offer-item.bid' | translate) : ('offer-item.buy-now' | translate) }}</strong>
</button>
