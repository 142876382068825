import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {VehicleMaintenanceService} from '../../service/vehicle-maintenance.service';
import {takeUntil} from 'rxjs/operators';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'ucs-manual-dealer-input',
  templateUrl: './manual-dealer-input.component.html',
  styleUrls: ['./manual-dealer-input.component.scss']
})

/**
 * Component for manual input of external dealers that do not exist in Partner.Net
 */
export class ManualDealerInputComponent implements OnInit, OnDestroy {
  @Input() vehicleId: number;
  @Output() externalDealerChanged = new EventEmitter<ExternalDealerInformationDto>();
  @Output() validityChanged = new EventEmitter<boolean>();

  @ViewChild('manualInputDealer', {static: true}) manualInputDealer: NgForm;

  externalDealer: ExternalDealerInformationDto;
  masterData: VehicleDetailMasterDataDto;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private vehicleService: VehicleMaintenanceService) {
  }

  /**
   * Fetches master data for list of countries
   */
  ngOnInit() {
    this.externalDealer = {} as ExternalDealerInformationDto;
    this.externalDealer.type = 'EXTERNAL_DEALER';
    this.vehicleService
      .getVehicleDetailsMasterData(this.vehicleId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(masterData => {
        this.masterData = masterData;
      });
    this.externalDealer.country = 'AT';
  }

  formChanged() {
    this.externalDealerChanged.emit(this.externalDealer);
    this.validityChanged.emit(this.manualInputDealer.valid);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
