@if (label) {
  <div class="row mt-3 mb-2 multiselect-label-row">
    <div class="col-12 text">
      <strong>{{label}}</strong>
      @if (popoverText) {
        <button
          type="button"
          class="button-borderless"
          [floatUi]="popoverText"
          [placement]="NgxFloatUiPlacements.TOP">
          <ucs-icon [shape]="'info'" [size]="16" [class]="'black me-1'"></ucs-icon>
        </button>
      }
    </div>
  </div>
}

<ng-select
  [items]="aggregatedStates"
  [multiple]="multiselect"
  [searchable]="true"
  (search)="onSearch($event)"
  (add)="updateAggregatedStates($event)"
  (remove)="updateAggregatedStates($event.value)"
  (clear)="deselectAll()"
  [closeOnSelect]="false"
  [clearSearchOnAdd]="!multiselect"
  bindLabel="option"
  placeholder="{{placeholder}}"
  [(ngModel)]="selectedElements"
  (change)="onInput.emit(selectedElements)"
  [readonly]="selectionDisabled">

  @if (showSelectButtons && multiselect) {
    <ng-template ng-header-tmp>
      <button (click)="selectAll()" class="btn btn-sm btn-link">{{'search-filter.select-all' | translate}}</button>
      <button (click)="deselectAll()" class="btn btn-sm btn-link">{{'search-filter.deselect-all' | translate}}</button>
    </ng-template>
  }

  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    @if (items.length === 1) {
      <div class="multi-item-label">
        <span class="ng-value-label">{{items[0].option}}</span>
      </div>
    }
    @if (items.length > 1) {
      <div class="multi-item-label">
        <span class="ng-value-label">{{items.length}} {{'search-filter.selected' | translate}} </span>
      </div>
    }
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{index}}" type="checkbox" class="checkbox-primary checkbox-item"
      [(ngModel)]="item$.selected"/> {{item.option}}
    </ng-template>
  </ng-select>

