<div class="dropdown">
  <span>{{ 'ucs.pagination.items-per-page' | translate }}</span>
  <button class="btn btn-light dropdown-toggle small ms-2" type="button" data-bs-toggle="dropdown">
    <span class="me-1">{{ pageable.pageSize }}</span>
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    @for (state of paginationDropdownStates; track state) {
      <button (click)="selectPaginationDropdownState(state)"
              class="dropdown-item">{{ state }}
      </button>
    }
  </div>
</div>
