import {Pipe, PipeTransform} from '@angular/core';
import {TranslatePipe} from '@ngx-translate/core';

/**
 * Translates the emission class, if the value is 'UNKNOWN'
 */
@Pipe({
  name: 'emissionClass',
  pure: false
})
export class EmissionClassPipe extends TranslatePipe implements PipeTransform {
  transform(value: string): string {
    return value === 'UNKNOWN' ? super.transform('api.type.emission-class.' + value) : value;
  }
}
