<div [routerLink]="'/offer/' + offer.id" class="container-fluid offer-expiring rounded">
  <div class="body row">
    <div class="col image-container ps-3 pe-2">
      <div class="image">
        <img src="{{ imageUrl }}" alt="" class="img-fluid img"/>
      </div>
    </div>
    <div class="col">
      <div class="row text-container px-2">
        <div class="model col-12 px-0"><strong>{{offer.title}}</strong></div>
        <div class="w-100"></div>
        <div class="col-12 px-0 align-self-end">
          <div class="current-offer-title">{{ 'offer-item.highest-bid' | translate }}</div>
          <div class="w-100"></div>
        </div>
        <div class="col-12 px-0">
          <strong>
            <div class="current-offer">{{ auctionOffer ?
              (auctionOffer.highestBid.net | price: auctionOffer.highestBid.currency.data) :
              (buyNowOffer.price.net | price: buyNowOffer.price.currency.data) }}
            </div>
          </strong>
          <div class="price-type">{{('api.type.pricetype.NET' | translate)}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
