@if (offers) {
  <!-- optionsList.type 'offer' is used to display results in offer result list -->
  @if (optionsList.type === 'offer') {
    @for (offer of offers; track offer.id) {
      <div class="mb-3" data-cy="offer-list.offer-list-core">
        <!-- show the single offer card or the offer bundle head card -->
        @if (offer.items.length === 1) {
          <ucs-sales-single-offer-card
            [offer]="offer"
            [enableNote]="false"
            data-cy="offer-list-item">
          </ucs-sales-single-offer-card>
        }
        @if (offer.items.length > 1) {
          <ucs-sales-bundle-offer-card
            [offer]="offer"
            [enableNote]="false"
            data-cy="offer-list-item">
          </ucs-sales-bundle-offer-card>
        }
      </div>
    }
  }
  <!-- optionsList.type 'offer-small' is used for display in carousel image sliders -->
  <!-- carousel image sliders are used both horizontally (direction = row) and vertically (direction = column) -->
  @if (optionsList.type === 'offer-small') {
    <div
     [ngClass]="{
     'd-flex': true,
     'flex-column': optionsList.direction === 'column',
     'flex-row': optionsList.direction === 'row'}">
      @for (offer of offers; track offer.id; let i = $index) {
        <ucs-offer-small
      [ngClass]="{
        'mb-2': optionsList.direction === 'column' && i !== 2,
        'mx-3': optionsList.direction === 'row' && i === 1
      }"
          [offer]="offer"
          [index]="i">
        </ucs-offer-small>
      }
    </div>
  }
}
