import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

/**
 * Pipe which transforms the backend price values from absolute cent values
 * to euro values with comma separated cent eg. 1234 -> 12,34
 * It takes two optional arguments:
 * currency: string - if present, the currency will be added to the result string
 * locale: string - default: 'de'; the locale to forward to the DecimalPipe
 */
@Pipe({
  name: 'price'
})
@Injectable()
export class PricePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {
  }

  transform(value: number, currency?: string, locale: string = 'de', digitsInfo = '1.0-2'): string {
    const decimalValue = value / 100 as number;
    return this.decimalPipe.transform(decimalValue, digitsInfo, locale) + (currency ? ' ' + currency : '');
  }
}
