<div class="row">
  @if (label !== undefined) {
    <div [ngClass]="{'col-md-6 text':!sidebar, 'text-sidebar': sidebar, 'smaller-heading':smallerHeading}" class="col-12 dealer-label">
      <strong>{{label}}</strong>
    </div>
  }
  <div [ngClass]="{'col-md-6': !sidebar}" class="col-12 dealer-input">
    <input [(ngModel)]="selectedValue"
      [typeahead]="dataSource$"
      (typeaheadOnSelect)="onSelect($event)"
      [typeaheadOptionsLimit]="7"
      [typeaheadItemTemplate]="customItemTemplate"
      [placeholder]="placeholder"
      [typeaheadMinLength]="3"
      typeaheadOptionField="name"
      (input)="onInput($event)"
      (blur)="onBlur.emit()"
      class="form-control search-field {{ class }}">
    <ng-template #customItemTemplate let-model="item">
      {{ dealerService.getCompanyNumberOrPbvCustomerNumber(model, channel) + ' ' + model.name }}
    </ng-template>
  </div>
</div>
