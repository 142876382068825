<div class="modal fade lineheight" id="{{'confirmThermalWindowModal' + offer.id}}" aria-labelledby="confirmThermalWindowModal"
  aria-hidden="true"
  tabindex="-1"
  role="dialog">
  <div class="modal-dialog modal-dialog-scrollable modal-size" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ 'thermal-window.dialog.head' | translate }}</h5>
      </div>

      <div class="modal-body">
        <div class="d-lg-flex d-grid justify-content-between mb-3">
          <div class="title">
            <strong>{{'offer-detail.initial-registration' | translate}}: {{ucsVehicle.initialRegistrationDate | date : 'dd.MM.yyyy' }}
              - {{'thermal-window.dialog.engine' | translate}}: {{ucsVehicle.motorCapacity | ccm}} {{ucsVehicle.powerKw | kw}} / {{ucsVehicle.powerPs | ps}}
            - {{'offer-detail.emission-class' | translate}}: {{ucsVehicle.emissionClass}}</strong>
            {{ 'thermal-window.dialog.title' | translate }}
            <strong>{{ucsVehicle.brand}}</strong>
          </div>
        </div>

        <div class="greeting">
          {{ 'thermal-window.dialog.greeting' | translate}}
        </div>
        <br>
          <div class="continuous-text">
            {{'thermal-window.dialog.part1.1' | translate}}
            <strong>{{offer.title}}</strong>
            {{'thermal-window.dialog.part1.2' | translate}}
            <strong>{{ucsVehicle.vin}}</strong> {{'thermal-window.dialog.part1.3' | translate}}
          </div>
          <br>
            @if (!isOwnBrand) {
              <div class="continuous-text">
                {{'thermal-window.dialog.part2.1' | translate}}
                <strong>{{ucsVehicle.brand}}</strong> {{'thermal-window.dialog.part2.2' | translate}}
              </div>
            }
            @if (isOwnBrand) {
              <div class="continuous-text">
                {{'thermal-window.dialog.part2.1' | translate}}
                <strong>{{'brand.fullname.'+ucsVehicle.makeId | translate}}</strong> {{'thermal-window.dialog.part2.2' | translate}}
              </div>
            }
            <br>
              <div class="continuous-text">
                {{'thermal-window.dialog.part3' | translate}}
              </div>
              <br>
                @if (!isOwnBrand) {
                  <div class="continuous-text">
                    {{'thermal-window.dialog.part4.1' | translate}}
                    {{ucsVehicle.brand}}
                    {{'thermal-window.dialog.part4.2' | translate}}
                  </div>
                }
                @if (isOwnBrand) {
                  <div class="continuous-text">
                    {{'thermal-window.dialog.part4.1' | translate}}
                    {{'brand.fullname.'+ucsVehicle.makeId | translate}}
                    {{'thermal-window.dialog.part4.2' | translate}}
                  </div>
                }
                <br>
                  <div class="continuous-text">
                    {{'thermal-window.dialog.part5' | translate}}
                  </div>
                  <br>
                    <div class="continuous-text">
                      {{'thermal-window.dialog.part6' | translate}}
                    </div>
                    <br>
                      <div class="continuous-text">
                        {{'thermal-window.dialog.part7' | translate}}
                      </div>
                      <br>
                        <div class="continuous-text">
                          {{'thermal-window.dialog.part8' | translate}}
                        </div>
                        <br>
                          <div class="continuous-text">
                            {{'thermal-window.dialog.part9' | translate}}
                          </div>
                          <br>
                            <div class="continuous-text">
                              {{'thermal-window.dialog.part10' | translate}}
                            </div>
                            <br>
                              @if (!isOwnBrand) {
                                <div class="continuous-text">
                                  {{'thermal-window.dialog.part11.1' | translate}} {{ucsVehicle.brand}} {{'thermal-window.dialog.part11.2' | translate}}
                                </div>
                              }
                              @if (isOwnBrand) {
                                <div class="continuous-text">
                                  {{'thermal-window.dialog.part11.1' | translate}} {{'brand.fullname.'+ucsVehicle.makeId | translate}} {{'thermal-window.dialog.part11.2' | translate}}
                                </div>
                              }
                              <br>
                                <div class="continuous-text">
                                  {{'thermal-window.dialog.part12' | translate}}
                                </div>

                                <br>
                                  <div>
                                    <input type="checkbox" [(ngModel)]="terms" id="{{'terms'+offer.id}}">
                                    <label class="term-label" for="{{'terms'+offer.id}}">{{'thermal-window.dialog.checkbox' | translate}}</label>
                                  </div>
                                </div>

                                <div class="modal-footer">
                                  <button type="button" class="btn btn-secondary button-cancel"
                                    [attr.data-bs-target]="overFollowupProposal ? '#rejectFollowup' : ''"
                                    [attr.data-bs-toggle]="overFollowupProposal ? 'modal' : ''"
                                    data-bs-dismiss="modal">
                                    {{'thermal-window.dialog.decline' | translate }}
                                  </button>
                                  <button type="button" class="btn btn-primary button-save" data-bs-dismiss="modal"
                                    [attr.data-bs-target]="overFollowupProposal ? '' : modalTarget"
                                    [attr.data-bs-toggle]="overFollowupProposal ? '' : 'modal'" [disabled]="!terms || clicked"
                                    (click)="acceptTerms()">
                                    {{'thermal-window.dialog.accept' | translate }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- rejcectFollowupDialog is duplicated here because of bootstrap modal and ngbModal missmatch issue. See UCSDEV-13563 -->
                          <div class="modal fade" id="rejectFollowup" aria-hidden="true" aria-labelledby="rejectFollowup" tabindex="-1">
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h1 class="modal-title fs-5" id="rejectFollowupLabel">
                                    {{ 'vehicle-maintenance.detail.followup-proposal-dialog.title' | translate }}
                                  </h1>
                                </div>
                                <div class="modal-body">
                                  {{ 'vehicle-maintenance.detail.followup-proposal-dialog.question' | translate }}
                                </div>
                                <div class="modal-footer">
                                  <button class="btn btn-secondary button-cancel button-width" type="button" data-bs-dismiss="modal"
                                    (click)="updateFollowupProposalStatus(false)">
                                    <strong> {{ "ucs.yes" | translate }} </strong>
                                  </button>
                                  <button class="btn btn-primary button-save button-width" type="button" data-bs-dismiss="modal">
                                    <strong> {{ "ucs.no" | translate }} </strong>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
