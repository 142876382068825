<div class="modal fade" id="printModal" aria-labelledby="printModal" aria-hidden="true"
  tabindex="-1"
  role="dialog">
  <div class="modal-dialog print-modal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ 'offer-item.print' | translate }}/PDF</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" (click)="resetSelection()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body" #modalBody>

        <div class="row mb-2">
          <div class="col-12">
            <input type="radio" id="printSimple" name="printOptions"
              [checked]="isSimpleSelected"
              (click)="togglePrintSelection()">
            <label for="printSimple">{{ 'print-dialog.simple' | translate }}
            ({{'print-dialog.print-current-display-content' | translate}})</label>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <input type="radio" id="printExtended" name="printOptions"
              [checked]="isExtendedSelected"
              (click)="togglePrintSelection()">
            <label for="printExtended">{{ 'print-dialog.extended' | translate }}</label>
          </div>
        </div>

        @if (isExtendedSelected) {
          <div class="ms-4 mt-2">
            <div>
              <ucs-icon id="print-option-vehicle-data" (click)="toggleVehicleData()"
                [shape]="createPDFDto.includeVehicleData ? 'square_checked' : 'square_outline'"
              [class]="'blue'"></ucs-icon>
              <span class="text text-bright ms-1 ucs-input-label"
              (click)="toggleVehicleData()">{{ 'print-dialog.vehicle-data' | translate }}</span>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <ucs-icon id="print-option-equipment" (click)="toggleEquipmentSelection()"
                  [shape]="createPDFDto.includeStandardEquipment ? 'square_checked' : 'square_outline'"
                [class]="'blue'"></ucs-icon>
                <span
                  class="text text-bright ms-1 ucs-input-label"
                (click)="toggleEquipmentSelection()">{{'print-dialog.standard-equipment' | translate}}</span>
              </div>
              <div class="col-sm-6 ps-0">
                <ucs-icon id="print-option-equipment-with-details" class="ms-4"
                  (click)="toggleEquipmentWithDetailsSelection()"
                  [shape]="createPDFDto.includeStandardEquipmentExtendingInformation ? 'square_checked' : 'square_outline'"
                [class]="createPDFDto.includeStandardEquipment ? 'blue' : 'disabled-grey'"></ucs-icon>
                <span
                  class="text text-bright ms-1 ucs-input-label {{!createPDFDto.includeStandardEquipment ? 'disabled-element' : ''}}"
                  (click)="toggleEquipmentWithDetailsSelection()">
                  {{'print-dialog.with-details' | translate}}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <ucs-icon id="print-option-new-vehicle-price" (click)="toggleNewCarPriceInfoSelection()"
                  [shape]="createPDFDto.includeNewCarPriceInformation ? 'square_checked' : 'square_outline'"
                [class]="'blue'"></ucs-icon>
                <span class="text text-bright ms-1 ucs-input-label"
                (click)="toggleNewCarPriceInfoSelection()">{{'offer-detail.price-overview' | translate}}</span>
              </div>
              <div class="col-sm-6 ps-0">
                <ucs-icon id="print-option-price-with-details" class="ms-4"
                  (click)="toggleNewCarPriceInfoDetailsSelection()"
                  [shape]="createPDFDto.includeNewCarPriceInformationExtendingInformation ? 'square_checked' : 'square_outline'"
                [class]="createPDFDto.includeNewCarPriceInformation ? 'blue' : 'disabled-grey'"></ucs-icon>
                <span
                  class="text text-bright ms-1 ucs-input-label {{!createPDFDto.includeNewCarPriceInformation ? 'disabled-element' : ''}}"
                  (click)="toggleNewCarPriceInfoDetailsSelection()">
                {{'print-dialog.with-details' | translate}}</span>
              </div>
            </div>
            <div>
              <ucs-icon id="print-option-images" (click)="togglePicturesSelection()"
                [shape]="createPDFDto.includePicture ? 'square_checked' : 'square_outline'"
              [class]="'blue'"></ucs-icon>
              <span class="text text-bright ms-1 ucs-input-label"
              (click)="togglePicturesSelection()">{{'mail.survey.images' | translate}}</span>
            </div>
            <div>
              <ucs-icon id="print-option-survey" (click)="toggleSurveySelection()"
                [shape]="createPDFDto.includeSurvey ? 'square_checked' : 'square_outline'"
              [class]="'blue'"></ucs-icon>
              <span class="text text-bright ms-1 ucs-input-label"
              (click)="toggleSurveySelection()">{{'print-dialog.survey' | translate}}</span>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <ucs-icon id="print-option-etb" (click)="toggleEtbSelection()"
                  [shape]="createPDFDto.includeEtb ? 'square_checked' : 'square_outline'"
                [class]="'blue'"></ucs-icon>
                <span
                  class="text text-bright ms-1 ucs-input-label"
                (click)="toggleEtbSelection()">{{'api.type.document.ELECTRONIC_TESTREPORT' | translate}}</span>
              </div>
              <div class="col-sm-6 ps-0">
                <ucs-icon id="print-option-etb-with-pictures" class="ms-4"
                  (click)="toggleEtbWithPicturesSelection()"
                  [shape]="createPDFDto.includeEtbWithPicture ? 'square_checked' : 'square_outline'"
                [class]="createPDFDto.includeEtb ? 'blue' : 'disabled-grey'"></ucs-icon>
                <span
                  class="text text-bright ms-1 ucs-input-label {{!createPDFDto.includeEtb ? 'disabled-element' : ''}}"
                  (click)="toggleEtbWithPicturesSelection()">
                  {{'print-dialog.with-pictures' | translate}}
                </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-5">
                <ucs-icon id="print-option-email" (click)="toggleSendEmail()"
                  [shape]="sendEmail ? 'square_checked' : 'square_outline'"
                [class]="'blue'"></ucs-icon>
                <span class="text text-bright ms-1 ucs-input-label"
                (click)="toggleSendEmail()">{{'print-dialog.send-to-email' | translate}}</span>
              </div>
              <div class="col-sm-7">
                <input placeholder="example@email.com" name="emailInput" (input)="checkEmailPatternValidity()"
                  [disabled]="!sendEmail" [(ngModel)]="createPDFDto.sendToEmailAddress"
                  class="form-control" type="email">
                @if (emailValidationError?.length > 0) {
                  <div class="ms-auto mt-1">
                    <span class="error">{{ emailValidationError | translate }}</span>
                  </div>
                }
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-sm-7 mt-1 mb-3">
                <textarea placeholder="{{'print-dialog.optional-message' | translate}}"
                  [(ngModel)]="createPDFDto.emailComment" [disabled]="!sendEmail"
                  class="form-control"
                maxlength="500"></textarea>
                @if (createPDFDto.emailComment?.length > 0) {
                  <p class="pt-0 me-5 text-secondary">
                    {{'print-dialog.remaining-characters' | translate}}: {{createPDFDto.emailComment?.length ? 500 - createPDFDto.emailComment?.length : 500}} / 500
                  </p>
                }
              </div>
            </div>
          </div>
        }
        @if (isExtendedSelected) {
          <div class="me-auto ms-1 mt-4">
            <ucs-icon [shape]="'info'" [class]="'grey'"></ucs-icon>
            {{'print-dialog.save-description.pdf-creation' | translate}}
          </div>
        }
      </div>


      <div class="modal-footer">
        <button type="button" class="btn btn-secondary"
          data-bs-dismiss="modal" (click)="resetSelection()">
          {{ 'ucs.cancel' | translate }}
        </button>
        <button
          class="btn btn-primary align-items-center no-radius-right pe-3 pb-2 ps-3 me-0"
          (click)="isSimpleSelected ? openPrintView() : createPDF()" [disabled]="sendEmail && (emailValidationError?.length > 0 || !createPDFDto.sendToEmailAddress)">
          <strong>{{ (isSimpleSelected ? 'print-dialog.open-print-view' : sendEmail ? 'print-dialog.send-pdf' : 'print-dialog.save-pdf')| translate }}</strong>
        </button>
      </div>
    </div>
  </div>
</div>
