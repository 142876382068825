<ng-template #browserAlertDialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'ucs.browser-alert.warning' | translate}}</h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="d()">
      <span aria-hidden="true"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="warning-text">
      <ucs-icon class="pe-1 warning-symbol" [shape]="'feather/alert-triangle'" [class]="'feather-black'"
                [size]="20"></ucs-icon>
      <p>
        <strong>{{'ucs.browser-alert.attention' | translate}}</strong>
        {{'ucs.browser-alert.browser-not-supported' | translate}}
      </p>
    </div>
    <div class="info-text">
      <p>{{'ucs.browser-alert.link-to' | translate}}
        <a rel="noopener" href="https://www.google.com/chrome"
           target="_blank">{{'ucs.browser-alert.google-chrome-download' | translate}}</a>
      </p>
      <p>{{'ucs.browser-alert.questions' | translate}}</p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-primary"
            data-cy="browser-alert-dialog-component.acknowledged-btn"
            (click)="c()">{{'ucs.browser-alert.acknowledged' | translate}}</button>
  </div>
</ng-template>
