import {Component, OnDestroy, OnInit} from '@angular/core';
import {State} from '../../../store/user/user.reducers';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {takeUntil} from 'rxjs/operators';
import {GTC_FILENAMES_MAP} from '../../../shared.module';

@Component({
  selector: 'ucs-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})

export class TermsComponent implements OnInit, OnDestroy {

  userState: State;
  private unsubscribe: Subject<void> = new Subject<void>();

  /**
   * Instantiates the component.
   *
   * @param {Store<AppState>} store: allows access to the store
   */
  constructor(private store: Store<fromRoot.AppState>) {
  }

  /**
   * Called when component is being initialized.
   */
  ngOnInit(): void {
    // subscribe to user state to access the user's roles
    this.store.select(fromRoot.getUserState)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userState => {
        if (userState) {
          this.userState = userState;
        }
      });
  }

  getGTCFile(distributionChannel: DistributionChannel, country: Country): string[] {
    let result = GTC_FILENAMES_MAP.get(distributionChannel).get(country);
    if(result === undefined) {
      return ['GTC_Placeholder_en.pdf'];
    }
    return result;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
