<div class="container-fluid search-filter-container sidebar pb-2 pt-2">

  <div class="row mt-0"></div>

  <!--toggle-button-sidebar-->
  <div class="row box mb-0">
    <div class="col-12 mt-2 mb-2">
      <button class="button-borderless w-100 d-flex justify-content-between icon-height"
        (click)="onToggleSidebar()">
        {{'search-filter.hide' | translate}}
        <div class="d-inline-flex">
          <ucs-icon [size]="24" [shape]="vehicleMaintenanceState.activeCategory" class="blue"></ucs-icon>
          <ucs-icon [size]="16" [shape]="'double_angle_left'" class="me-1 blue"></ucs-icon>
        </div>
      </button>
    </div>
  </div>

  <!--full-text-search-->
  <ucs-vehicle-identification-full-text-search (fulltextSearch)="emitFulltextSearch($event)">
  </ucs-vehicle-identification-full-text-search>

  <!--category-->
  <div class="mt-2 mb-2">
    @if (isEnforcedMaintenanceEnabled) {
      <div>
        @for (category of categories; track category) {
          <div>
            @if (category === 'LONGTIME_PENDING' && categories.length > 1) {
              <div class="row mt-1">
                <div class="col-12">
                  <hr class="hr mb-3">
                </div>
              </div>
            }
            <div (click)="changeCategory(category)"
              [ngClass]="{'active-category':category === vehicleMaintenanceState.activeCategory}"
              class="mt-2 category">
              <ucs-icon [size]="24" [id]="'maintenance-search-' + category" [shape]="category"
              class="ms-1 me-1 grey"></ucs-icon>
              <label [attr.data-cy]="'vehicle-maintenance.sidebar.' + category">{{ 'vehicle-maintenance.sidebar.' + category | translate }}</label>
            </div>
          </div>
        }
      </div>
    } @else {
      @for (category of categories; track category) {
        <div (click)="changeCategory(category)"
          [ngClass]="{'active-category':category === vehicleMaintenanceState.activeCategory}"
          class="mt-2 category"
          >
          @if (category !== 'LONGTIME_PENDING') {
            <div>
              <ucs-icon [size]="24" [id]="'maintenance-search-' + category" [shape]="category"
              class="ms-1 me-1 grey"></ucs-icon>
              <label [attr.data-cy]="'vehicle-maintenance.sidebar.' + category">{{ 'vehicle-maintenance.sidebar.' + category | translate }}</label>
            </div>
          }
        </div>
      }
    }

  </div>
</div>
