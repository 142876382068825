<div class="error-component row">
  <div class="col-12">
    <h1>{{ 'logged-out.logged-out-title' | translate }}</h1>
    <p class="info rounded p-3">
      <ucs-icon [shape]="'info'" [class]="'blue'" size="24"></ucs-icon>
      <span class="text ms-2">{{ 'logged-out.logged-out' | translate }}</span>
      <a href="/">
        {{ 'logged-out.log-back-in' | translate }}</a>
    </p>
  </div>
</div>
