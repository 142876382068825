import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {OfferListOptions} from '../../../model/offer-list-options.model';
import {first, takeUntil} from 'rxjs/operators';
import * as fromRoot from '../../../store/app.reducers';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {SystemSettingsService} from '../../../service/system-settings.service';

/**
 * Either hosts a list of vehicles (VehicleComponent) for the vehicle results
 */
@Component({
  selector: 'ucs-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss']
})
export class VehicleListComponent implements OnInit, OnDestroy {
  @Input() options: OfferListOptions;
  @Input() vehicles: VehicleBaseDto[] & EnforcedAuctionVehicleBaseDto[];
  @Input() activeCategory: string;
  vehiclesBasket: VehicleBaseDto[];
  optionsList: OfferListOptions = {
    'type': 'vehicle',
    'direction': 'row'
  };
  doNewScrollingBehavior: boolean = false;
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(public store: Store<fromRoot.AppState>,
              private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit() {
    Object.assign(this.optionsList, this.options);
    this.store.select(fromRoot.getMaintenanceVehicleBasket)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(vehiclesBasket => {
        if (vehiclesBasket) {
          this.vehiclesBasket = vehiclesBasket;
        }
      });

    this.systemSettingsService.isSystemFeatureActivatedForAnyChannel('UCS_602_NEW_SCROLLING_BEHAVIOR_IN_MAINTENANCE')
      .pipe(first()).subscribe(isEnabled => {
        this.doNewScrollingBehavior = isEnabled;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
