<div class="modal fade lineheight" id="{{'confirmBuyNowModal' + offer.id}}"
  aria-labelledby="confirmBuyNowModal"
  aria-hidden="true"
  tabindex="-1"
  role="dialog">
  <div class="modal-dialog modal-size" role="document">
    <div class="modal-content" data-cy="buynow-dialog-component.buynow-dialog-body">
      <div class="modal-header">
        <h5 class="modal-title">{{ 'buy-now.dialog.head' | translate }}</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div class="modal-body">
        <div class="d-lg-flex d-grid justify-content-lg-between gap-2 mb-2">
          <div class="title">
            <strong>{{offer.title}}</strong>
          </div>
          <ucs-logo-icon [distributionChannel]="offer.channel.data"
            [country]="offer.country"
            [dialog]="true">
          </ucs-logo-icon>
        </div>

        <div class="d-lg-flex d-grid justify-content-between mb-3 gap-2">
          <div>
            <ucs-icon [shape]="'location_pin'" [class]="'black'" [size]="16"></ucs-icon>
            <span class="mx-2">{{ offer.location }}</span>
            <ucs-icon [shape]="'flags/' + offer.country.toLowerCase()" [size]="18"></ucs-icon>
          </div>
          <div class="col-auto">
            <div class="tax">
              <ucs-icon [shape]="vehicleItem.vatType.data === 'STANDARD' ? 'info' : 'alert-triangle'" [size]="16"
              [class]="'grey pe-2'"></ucs-icon>
              <span>{{ ('tile-footer.tax-nova.tax.' + vehicleItem.vatType.data) | translate }}</span>
            </div>
            @if (userState.isAustrian || isNovaInfoGrossPriceEnabled) {
              <div class="nova">
                <ucs-icon [shape]="vehicleItem.extraTax?.paid ? 'info' : 'alert-triangle'" [size]="16"
                [class]="'grey pe-2'"></ucs-icon>
                <span>{{ (vehicleItem.extraTax?.paid ? 'tile-footer.tax-nova.nova.nova-paid' : 'tile-footer.tax-nova.nova.nova-not-paid') | translate }}</span>
              </div>
            }
            @if (showThermalWindowAffected && isThermalWindowAffectedEnabled && vehicleItem.thermalWindowAffected) {
              <div class="tax">
                <ucs-icon [shape]="'alert-triangle'" [size]="16" [class]="'grey pe-2'"></ucs-icon>
                <span>{{ 'vehicle-maintenance.detail.thermal-window' | translate }}</span>
              </div>
            }
          </div>
        </div>

        @if (isShowNovaRefundEnabled && (novaRefundTotalSum !== null)) {
          <div class="row col-12 mt-1 info">
            <span>
              <strong>{{('offer-detail.nova-refund' | translate) + ':'}}</strong>
              {{ (novaRefundTotalSum | price: offer?.price?.currency?.text ?
            offer.price.currency.text : ' EUR') }}</span>
            @if (offer.items.length > 1) {
              <span
              >&nbsp;{{' (' + ('offer-detail.nova-refund.total' | translate) + ')'}}</span>
            }
          </div>
        }

        @if (vehicleItem.infoText) {
          <div class="d-flex justify-content-between">
            <div class="info-overflow">
              <strong>{{'buy-now.dialog.info' | translate}}</strong>
              {{ (vehicleItem.infoText.length > 72)?
              (vehicleItem.infoText | truncate
              : 70 - ('buy-now.dialog.info'| translate)?.length - ('buy-now.dialog.show' | translate)?.length)
              : vehicleItem.infoText}}
            </div>
            @if (vehicleItem.infoText.length > 72) {
              <span
                class="overflow-link fw-bold"
                #popOver="ngbPopover"
                [ngClass]="{'overflow-link-hover': popOver.isOpen()}"
                [ngbPopover]="popOverInfo"
                autoClose="true"
                placement="bottom-right">{{ 'buy-now.dialog.show' | translate }}
              </span>
            }
          </div>
        }
        <ng-template #popOverInfo>
          <span>{{ vehicleItem.infoText | truncate
            : 70 - ('buy-now.dialog.info'| translate)?.length - ('buy-now.dialog.show' | translate)?.length
          :'prefix' }}</span>
        </ng-template>
        <div class="col-12 pb-1 pt-2">
          <div class="row">
            <strong>{{ 'buy-now.dialog.your-price' | translate }}</strong>
          </div>

          <div class="d-lg-flex d-grid net-price-container">
            <div class="col-lg-4 mx-3 my-lg-2">
              <strong>{{(offer?.price?.net | price) + ' ' + offer?.price?.currency?.data}}{{(offer?.country === 'UA')? '*':'' }}</strong>
              <span class="buy-now-gross-net">
                {{ 'api.type.pricetype.NET' | translate }}
              </span>
            </div>
            <div class="d-inline-flex mx-3 my-lg-2">
              @if (vehicleItem.vatType.data === 'STANDARD' && (userState.isAustrian || offer.channel.data === 'ALL_UC')) {
                <ucs-calc-icon
                  [type]="vehicleItem.vatType.data === 'STANDARD' ? 'NOK' : 'OK'"
                  [text]="'icon.vat.' + vehicleItem.vatType.data">
                </ucs-calc-icon>
              }
              @if (userState.isAustrian || isNovaInfoGrossPriceEnabled) {
                <ucs-calc-icon
                  [type]="vehicleItem.extraTax?.paid ? 'OK': 'NOK'"
                  [text]="'icon.nova.' + (vehicleItem.extraTax?.paid ? 'OK': 'NOK')">
                </ucs-calc-icon>
              }
            </div>
          </div>

          @if (!hideGrossPrice) {
            <div class="d-lg-flex d-grid gross-price-container">
              <div class="col-lg-4 mx-3 my-lg-2">
                <strong>{{(grossPrice| price) + ' ' + offer?.price?.currency?.data }}{{(offer?.country === 'UA')? '*':'' }}</strong>
                <span class="buy-now-gross-net">
                  {{ 'api.type.pricetype.GROSS' | translate }}
                </span>
                @if (showPPMV) {
                  ({{ 'ucs.including-short'| translate }}
                  {{ extraTaxPrice | price: undefined : undefined :  '1.2-2' }} {{ offer?.price?.currency?.data }}{{(offer?.country === 'UA')? '*':'' }}
                  {{'vehicle-detail.extra-tax-label.PPMV' | translate}})
                }
              </div>
              <div class="d-inline-flex mx-3 my-lg-2">
                @if (vehicleItem.vatType.data === 'STANDARD' && (userState.isAustrian || offer.channel.data === 'ALL_UC')) {
                  <ucs-calc-icon
                    [type]="'OK'"
                    [text]="'icon.vat.DIFFERENTIAL'">
                  </ucs-calc-icon>
                }
                @if (userState.isAustrian || isNovaInfoGrossPriceEnabled) {
                  <ucs-calc-icon
                    [type]="vehicleItem.extraTax?.paid ? 'OK' : 'NOK'"
                    [text]="'icon.nova.' + (vehicleItem.extraTax?.paid ? 'OK' : 'NOK')">
                  </ucs-calc-icon>
                }
              </div>
            </div>
          }
        </div>
        @if ((offer?.country === 'UA')) {
          <div class="d-flex justify-content-between">
            <div class="info-overflow">
              <strong>{{'buy-now.dialog.disclaimer.title'| translate}}</strong>
              {{ 'buy-now.dialog.disclaimer.text'| translate | truncate
              : 66 - ('buy-now.dialog.disclaimer.title'| translate)?.length - ('buy-now.dialog.show' | translate)?.length }}
            </div>
            <span class="overflow-link fw-bold"
              #popOver="ngbPopover"
              [ngClass]="{'overflow-link-hover': popOver.isOpen()}"
              [ngbPopover]="popOverDisclaimer"
              autoClose="true"
              placement="bottom-right">{{ 'buy-now.dialog.show' | translate }}
            </span>
          </div>
        }
        <ng-template #popOverDisclaimer>
          <span>{{ 'buy-now.dialog.disclaimer.text'| translate | truncate
            :66 - ('buy-now.dialog.disclaimer.title'| translate)?.length - ('buy-now.dialog.show' | translate)?.length
          :'prefix' }}</span>
        </ng-template>
      </div>

      <div class="modal-footer pb-1 pt-2">
        <div class="d-flex justify-content-end mb-2">
          <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">{{
            'buy-now.dialog.cancel' | translate }}
          </button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
            (click)="buynow()"
            data-cy="buynow-dialog-component.buynow-btn">{{ 'buy-now.dialog.buy' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
