import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../..//store/app.reducers';
import {State} from '../../../store/user/user.reducers';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ucs-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss']
})

export class LegalNoticeComponent implements OnInit, OnDestroy {
  userState: State;
  private unsubscribe: Subject<void> = new Subject<void>();

  /**
   * Instantiates the component.
   *
   * @param {Store<AppState>} store: allows access to the store
   * @param translateService service for translations
   */
  constructor(private store: Store<fromRoot.AppState>, private translateService: TranslateService) {
  }

  /**
   * Called when component is being initialized.
   */
  ngOnInit(): void {
    // subscribe to user state to access the user's roles
    this.store.select(fromRoot.getUserState)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userState => {
        if (userState) {
          this.userState = userState;

          // set local
          this.translateService.use(userState.local);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
