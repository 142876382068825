import {Pipe, PipeTransform} from '@angular/core';

/**
 * Calculates the difference between passed date and current time
 * Formats the result to hours and minutes in format 'x h y min', e.g. '17h 53min'
 * Usage:
 *   date | timeRemaining
 */
@Pipe({
  name: 'timeRemaining'
})
export class TimeRemainingPipe implements PipeTransform {
  transform(value: string): string {
    const now = new Date(Date.now());
    const expirationDate = new Date(value);
    // calculate the remaining time in hours
    const remainingHours = (expirationDate.getTime() - now.getTime()) / 3600000;
    // take the decimal part of remainingHours and multiply by 60 to get minutes
    const remainingMinutes = Math.abs((remainingHours % 1) * 60);

    return remainingHours.toFixed(0) + 'h ' + remainingMinutes.toFixed(0) + 'min';
  }
}
