<div class="status-container">
  @if ((!status || status === 'NONE') && (offerStatus === 'ACTIVE' || offerStatus === 'EXTENDED')) {
    <span class="no-bid">
      <ucs-icon class="pe-1" [shape]="'hammer'" [class]="'grey'" [size]="20"></ucs-icon>
      <span>{{ 'offer-item.no-bid' | translate }}</span>
    </span>
  }
  @if (status === 'LOWER_STEP_SIZE') {
    <span class="lower-step-size">
      <ucs-icon class="pe-1" [shape]="'feather/x'" [class]="'feather-red'" [size]="20"></ucs-icon>
      <strong>{{ 'offer-item.lower-step-size' | translate }}</strong>
    </span>
  }
  @if (status === 'OUTBID') {
    <span [class]="displaySpecialBid ? 'special-bid' : 'outbid'">
      @if (!displaySpecialBid) {
        <ucs-icon class="pe-1" [shape]="displayTooLate ? 'feather/x' : 'feather/alert-triangle'" [class]="'feather-red'" [size]="20"></ucs-icon>
      }
      @if (displaySpecialBid) {
        <ucs-icon class="pe-1" [shape]="'feather/check'" [class]="'feather-green'" [size]="20"></ucs-icon>
      }
      <strong>{{messageInCaseOfOutbid | translate}} <br class="d-none d-md-inline">
        ({{bidTypeMsgToDisplay | translate}}:
        {{userMaxOrLatestBidToDisplay?.net | price}} {{userMaxOrLatestBidToDisplay?.currency.text}}{{isUAOffer ? '*':''}} {{'api.type.pricetype.NET' | translate}}{{showGrossPriceInStatus ?
        ' / ' + (userMaxOrLatestBidToDisplay?.gross | price) + ' ' + userMaxOrLatestBidToDisplay?.currency.text + (isUAOffer ? '* ': ' ') + ('api.type.pricetype.GROSS' | translate)
      : ''}})</strong>
    </span>
  }
  @if (status === 'HIGHEST') {
    <span>
      @if (purchaseReceived === null || purchaseReceived === undefined || purchaseReceived === true) {
        <span class="your-highest-bid"
          data-cy="your-highest-bid"
          >
          <ucs-icon class="pe-1" [shape]="'feather/check'" [class]="'feather-green'" [size]="20"></ucs-icon>
          @if (!displayMaximumBid) {
            <strong>{{ 'offer-item.your-highest-bid' | translate }}</strong>
          }
          @if (displayMaximumBid) {
            <strong>{{'offer-item.your-highest-bid' | translate}} <br class="d-none d-md-inline">
              ({{ 'offer-item.your-maximum' | translate}}:
              {{maximumBid.net | price}} {{maximumBid.currency.text}}{{isUAOffer ? '*':''}} {{'api.type.pricetype.NET' | translate}}{{showGrossPriceInStatus ?
              ' / ' + (maximumBid.gross | price) + ' ' + maximumBid.currency.text + (isUAOffer ? '* ': ' ') + ('api.type.pricetype.GROSS' | translate)
            : ''}})</strong>
          }
        </span>
      } @else {
        <span class="outbid"
          data-cy="outbid">
          <ucs-icon class="pe-1" [shape]="'feather/alert-triangle'" [class]="'feather-red'" [size]="20"></ucs-icon>
          <strong>{{ 'offer-item.purchase-not-received' | translate }}</strong>
        </span>
      }
    </span>
  }
  @if (status === 'SUCCESSFUL' && purchasePrice) {
    <span class="success">
      <ucs-icon class="pe-1" [shape]="'feather/check'" [class]="'feather-green'" [size]="20"></ucs-icon>
      <strong>{{ 'offer-item.success' | translate }}
        ({{ purchasePrice?.net | price: purchasePrice?.currency?.data }}{{isUAOffer ? '*':''}} {{ 'api.type.pricetype.NET' | translate }})
      </strong>
    </span>
  }
  @if (status === 'MINIMUM_PURCHASE_PRIZE_NOT_REACHED') {
    <span class="minimum-purchase-price-not-reached"
      >
      <ucs-icon class="pe-1" [shape]="'alert-triangle'" [size]="20"></ucs-icon>
      <strong>{{ 'offer-item.minimum-purchase-price-not-reached' | translate }}</strong> <br class="d-none d-md-inline">
      <strong>{{ 'offer-item.minimum-purchase-price-not-reached-info' | translate }}</strong>
    </span>
  }
  @if (status === 'PURCHASE_NOT_OBTAINED') {
    <span class="purchase-not-obtained">
      <ucs-icon class="pe-1" [shape]="'feather/x'" [class]="'feather-red'" [size]="20"></ucs-icon>
      <strong>{{ 'offer-item.purchase-not-obtained' | translate }}</strong>
    </span>
  }
</div>

