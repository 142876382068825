@if (vehicle) {
  <div id="{{ 'vehicle' + vehicle.id }}" class="vehicle rounded container-fluid">
    <div class="px-2 pb-lg-2 pt-2 row">
      <!--headline-->
      <div class="col-xl-9 offset-xl-3 ps-xl-4 col-lg-8 ps-lg-0 headline">
        <div class="row">
          <!-- title vehicle.modelVariant -->
          <div class="col-auto pe-0 ps-0">
            <div class="col model-name link-title"
              data-cy="vehicle-component.vehicle-modelVariant">
              <a [routerLink]="'/maintenance/vehicle/' + vehicle.id" (click)="storeBacklink()">
                  @if (activeCategory === 'LONGTIME_PENDING') {
                      <strong>{{ vehicle.modelDesignation ?? vehicle.title }}</strong>
                  }
                  @else {
                      <strong>{{ vehicle.title ?? vehicle.modelVariant }}</strong>
                  }
              </a>
            </div>
            <!-- create-date -->
            <div class="row ps-0 mb-1 create-date">
              <span>{{ 'offer-item.create-date' | translate }} {{ vehicle.created | date : 'dd.MM.yyyy' }}</span>
            </div>
          </div>
          <!-- info sign -->
          @if (vehicle?.infoText && vehicle.infoText !== '') {
            <span class="col info">
              <button class="btn-popover px-0 ps-1" type="button"
                [floatUi]="popTemplate"
                [showTrigger]="NgxFloatUiTriggers.hover"
                [placement]="NgxFloatUiPlacements.BOTTOM"
                [applyClass]="'info-popover-size'">
                <ucs-icon class="pe-1" [shape]="'info'" [size]="24" [class]="'blue'"></ucs-icon>
              </button>
              <float-ui-content #popTemplate>
                <div>
                  {{vehicle.infoText}}
                </div>
              </float-ui-content>
            </span>
          }
          <!-- basket -->
          <div class="col ms-auto px-0 fav">
            <!--only show basket when ready and allowed to create offer-->
            @if ((mayCreateOfferBuyNow || mayCreateOfferAuction) && this.activeCategory !== 'LONGTIME_PENDING') {
              <span>
                <button class="tray-general me-2"
                  [ngClass]="checkInBasket() ? 'in-tray' : 'not-in-tray'"
                  triggers="focus"
                  (click)="toggleTray()">
                  <ucs-icon class="pe-lg-1" [shape]="'inbox'" [size]="24"></ucs-icon>
                  <strong class="d-none d-xl-inline">
                    {{ (checkInBasket() ? 'vehicle.in-tray' : 'vehicle.not-in-tray') | translate }}
                  </strong>
                </button>
              </span>
            }
            @if (this.activeCategory === 'LONGTIME_PENDING' && this.isAdminForEnforcedAuction) {
              @if (this.vehicle?.status !== 'ONHOLD'
                && this.vehicle.enforcedAuctionVehicleData?.status !== 'OFFER_FINISHED') {
                <span class="row pe-3">
                  <button class="enforced-bookmark" data-cy="enforced-bookmark" (click)="onToggleEnforcedVehicleBookmark()">
                    @if (['AUTO_SELECTED', 'MANUALLY_SELECTED', 'VALIDATION_ERROR'].includes(vehicle.enforcedAuctionVehicleData?.status)) {
                      <ucs-icon [shape]="'bookmark-fill'"></ucs-icon>
                      <strong class="d-none d-xl-inline">
                        {{ 'vehicle.enforcedAuction.bookmarked' | translate }}
                      </strong>
                    } @else {
                      <ucs-icon [shape]="'bookmark'"></ucs-icon>
                      <strong class="d-none d-xl-inline">
                        {{ 'vehicle.enforcedAuction.bookmark' | translate }}
                      </strong>
                    }
                  </button>
                </span>
              }
              @if (!['MANUALLY_SELECTED', 'AUTO_SELECTED'].includes(vehicle.enforcedAuctionVehicleData?.status)) {
                <span class="row pe-3">
                  @if (this.vehicle?.status === 'ONHOLD') {
                    <button
                      class="btn-tertiary enforced-locked"
                      data-cy="enforced-unlock"
                      (click)="lockEnforcedVehicle('ACTIVE')">
                      <ucs-icon class="mb-1" [shape]="'CANCELLED'" [size]="24"></ucs-icon>
                      {{ 'vehicle.enforcedAuction.unlock' | translate }}
                    </button>
                  }
                  @if (this.vehicle?.status !== 'ONHOLD' && ['MANUALLY_REMOVED', 'NOT_SELECTED', null].includes(vehicle.enforcedAuctionVehicleData?.status)) {
                    <button class="btn-tertiary enforced-locked" data-cy="enforced-lock"
                      (click)="lockEnforcedVehicle('ONHOLD')">
                      {{ 'vehicle.enforcedAuction.lock' | translate }}
                    </button>
                  }
                </span>
              }
            }
          </div>
        </div>
      </div>
      <!--image-->
      <div class="image">
        <div class="small-carousel-gallery-wrapper">
          @if (galleryImages.length > 0) {
            <ks-carousel [images]="galleryImages" [config]="carouselConfig" [id]="vehicle.id" class="d-block"></ks-carousel>
          }
        </div>
        @if (vehicle.carhubStatus !== 'ACTIVE') {
          <div
          class="overlay">{{ 'vehicle-maintenance.carhub-status.' + vehicle.carhubStatus | translate }}</div>
        }
      </div>
      <div class="col-7 col-md-8 col-xl-9 ms-0 ps-xl-4 pe-0 ps-2 row location-dealer-vat-container"
        [ngClass]="leasingDealer ? 'max-height-90' : 'max-height-60'">
        <!--location-->
        <div class="col ps-1 pe-1 location-contract">
          <ucs-icon [shape]="'location_pin'" [size]="12"
          [class]="'d-lg-inline-block align-top me-sm-2 grey opacity-05'" ></ucs-icon>
          @if (vehicle.dealerInformation?.id) {
            <span class="d-lg-inline-block">
              ({{ dealerService.getCompanyNumberOrPbvCustomerNumber(vehicle.dealerInformation, vehicle?.channel) }})
              <br>
              {{ vehicle.dealerInformation?.address }}
              <br>
              {{ vehicle.dealerInformation?.city }} - {{ vehicle.dealerInformation?.country?.data }}
            </span>
          } @else if (vehicle.channel === 'PB' && vehicle.externalCustodyDealerInformation) {
            @if (vehicle.externalCustodyDealerInformation.custodyDealerExternalId) {
              <span>
                ({{vehicle.externalCustodyDealerInformation.custodyDealerExternalId}})
              </span>
            }
            <div class="ps-4">
              @if (vehicle.externalCustodyDealerInformation.address) {
                {{ vehicle.externalCustodyDealerInformation.address ?? '' }}
                <br>
              }
              @if (vehicle.externalCustodyDealerInformation.zipCode || vehicle.externalCustodyDealerInformation.city) {
                {{ vehicle.externalCustodyDealerInformation.zipCode ?? '' }} {{ vehicle.externalCustodyDealerInformation.city ?? '' }}
              }
            </div>
          }
        </div>
        <!--leasing dealer-->
        @if (leasingDealer) {
          <div class="col ps-0 pe-1 pe-md-0 leasing-dealer location-contract"
            ucsHideableData [channel]="vehicle.channel" [dataType]="'contract-data'">
            <ucs-icon [shape]="'pen-tool'" [size]="12"
            [class]="'d-lg-inline-block align-top me-sm-2 grey'"></ucs-icon>
              <span [floatUi]="vehicle.contractPartner" [applyClass]="'contact-popover,text-wrap'"
                    [placement]="NgxFloatUiPlacements.BOTTOM"
                    [disabled]="vehicle.contractPartner?.length <=60"
                    [positionFixed]="true"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="d-lg-inline-block">
                {{ vehicle.contractPartner?.length <=60 ? vehicle.contractPartner : vehicle.contractPartner.substring(0, 61) + '...' }}
              </span>
          </div>
        }
        <!--vatType-->
        <div class="col ps-0 pe-1 pe-md-0 location-contract">
          <ucs-icon [shape]="'circle'" [size]="12" [class]="'me-sm-2 grey opacity-025'"></ucs-icon>
          <span>{{ vehicle.vatType.text }}</span>
        </div>
      </div>
      <!--viewSettings-->
      <div class="row d-lg-block height-40 technical view-settings">
        <div class="col-8 d-md-block d-none">
          <div class="row ps-1 ps-sm-3">
            @if (view('MILEAGE') && vehicle.mileage) {
              <span
                ucsHideableData [channel]="vehicle.channel" [dataType]="'mileage'"
              class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.mileage | km }}</span>
            }
            @if (view('INITIAL-REGISTRATION') && vehicle.initialRegistrationDate) {
              <span
                ucsHideableData [channel]="vehicle.channel" [dataType]="'initial-registration-date'"
              class="col-md-auto box-item me-1 mb-1 px-2">{{ 'offer-item.initial-registration.short' | translate }} {{ vehicle.initialRegistrationDate | date : 'MM/yyyy' }}</span>
            }
            @if (view('FUEL') && vehicle.fuelTypes[0]) {
              <span
                ucsHideableData [channel]="vehicle.channel" [dataType]="'fuel-types'"
              class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.fuelTypes[0] }}</span>
            }
            @if (view('POWER') && vehicle.powerPs) {
              <span
                ucsHideableData [channel]="vehicle.channel" [dataType]="'power'"
                class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.powerPs | ps }}
              ({{ vehicle.powerKw | kw }})</span>
            }
            @if (view('COLOR') && vehicle.color) {
              <span
                ucsHideableData [channel]="vehicle.channel" [dataType]="'color'"
              class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.color }}</span>
            }
            @if (view('FUEL-CONSUMPTION') && vehicle.consumption) {
              <span
                ucsHideableData [channel]="vehicle.channel" [dataType]="'consumption'"
                class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.combinedFuelCode === '4' ? (vehicle.consumption | kwhPerHundredKm) :
              (vehicle.consumption | litersPerHundredKm)}}</span>
            }
            @if (view('TAX') && vehicle.vatType?.text) {
              <span
                ucsHideableData [channel]="vehicle.channel" [dataType]="'tax-type'"
              class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.vatType?.text }}</span>
            }
            @if (view('EMISSION') && vehicle.emissionClass) {
              <span
                ucsHideableData [channel]="vehicle.channel" [dataType]="'emission-class'"
              class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.emissionClass | emissionClass }}</span>
            }
            @if (view('EXPLOITATION-TYPE') && vehicle.exploitationType?.text) {
              <span
                ucsHideableData [channel]="vehicle.channel" [dataType]="'exploitation-type'"
              class="col-md-auto box-item me-1 mb-1 px-2">{{ vehicle.exploitationType?.text }}</span>
            }
          </div>
        </div>
        @if (isAllowedToSeeDiscrepancies && discrepanciesAvailable) {
          <div class="d-inline-flex mt-3 ms-1 discrepancies">
            <ucs-icon [shape]="'info'" [size]="16" [class]="'orange'"></ucs-icon>
            <div class="ms-1" style="margin-top: 0.12em">
              {{ 'vehicle-maintenance.detail.discrepancies' | translate}}
              <a class="ps-1 discrepancye-link" [routerLink]="'/maintenance/vehicle/' + vehicle.id">{{
                'vehicle-maintenance.detail.changes' |
              translate }} </a>
            </div>
          </div>
        }
      </div>
      <!--VIN, leasing-contract-number (V-Nr)-->
      <div class="row justify-content-start mt-1 contract-data">
        <div class="d-inline-flex justify-content-between">
          <div class="d-inline-flex">
            <strong>{{ 'offer-item.vin' | translate }}&nbsp;</strong> {{vehicle.vin}}
          </div>
          <div class="justify-content-start row pe-0">
            <!--HighlightEquipment-->
            <ucs-highlight-equipment [ngClass]="{'highlight-equipment': leasingContractNumber || showExternalId}"
              class="d-inline-flex justify-content-start pe-0"
              [highlightEquipments]="vehicle.highlightEquipments"
              [showEtbLongTimePending]="!vehicle?.missingEtb && this.activeCategory === 'LONGTIME_PENDING'">
            </ucs-highlight-equipment>
          </div>
        </div>
        @if (leasingContractNumber) {
          <div ucsHideableData
            [channel]="vehicle.channel" [dataType]="'contract-data'"
            class="d-inline-flex ">
            <strong>{{ 'offer-item.leasingContractNumber' | translate }}</strong> {{vehicle.contractId}}
          </div>
        }
        <div class="d-flex external-id text-break justify-content-between">
          <div >
            @if (showExternalId) {
              <strong>{{ 'vehicle-maintenance.external-id' | translate }}</strong>: {{ vehicle.externalId }}
            }
          </div>
          <div>
            <!--internal note-->
            @if (vehicle?.note?.length > 0 && canSeeInternalNote && !isUpgradedInternalNotesEnabled) {
              <button
                class="col-auto justify-content-end custom-grid shift-right-Internal icon-footer-general"
                type="button"
                [ngClass]="{'pointer': !!vehicle?.note?.length}"
                [floatUi]="popTemplateNoteLg"
                [placement]="NgxFloatUiPlacements.TOP"
                [applyClass]="'float-ui-content-display'"
                [disabled]="!!!vehicle?.note?.length">
                <span class="internal-note">{{ 'offer-item.internal-note' | translate }}</span>
                <ucs-icon [shape]="'chevron-down'"></ucs-icon>
              </button>
            <float-ui-content class="float-ui-content-display" #popTemplateNoteLg>
              <div class="internal-note-content text-start">
                <p>{{ vehicle?.note }}</p>
              </div>
            </float-ui-content>
            }

            @if (vehicle?.notes?.length > 0 && canSeeInternalNote && isUpgradedInternalNotesEnabled) {
              <button
                class="col-auto justify-content-end custom-grid shift-right-Internal icon-footer-general"
                type="button"
                [ngClass]="{'pointer': !!vehicle?.notes?.length}"
                [floatUi]="popTemplateNoteLg"
                [placement]="NgxFloatUiPlacements.TOP"
                [applyClass]="'float-ui-content-display'"
                [disabled]="!!!vehicle?.notes?.length">
                <span class="internal-note">{{ 'offer-item.internal-notes' | translate }}</span>
                <ucs-icon [shape]="'chevron-down'"></ucs-icon>
              </button>
              <float-ui-content class="float-ui-content-display" #popTemplateNoteLg>
                @if (vehicle.notes.length > 1) {
                  <div class="internal-note-content text-start">
                  <span>{{ sortNotesByMostRecent(vehicle).note }}</span><br>
                  <a [routerLink]="'/maintenance/vehicle/' + vehicle.id" (click)="storeBacklink()" class="link-title">
                    {{ "vehicle-maintenance.notes.show-all" | translate }}
                  </a>
                </div>
                } @else if (vehicle.notes.length === 1) {
                  <div class="internal-note-content text-start">
                    <span>{{ vehicle.notes[0].note }}</span>
                  </div>
                }
              </float-ui-content>
            }
            <!--document-->
            @if (vehicle?.mediaInfos.length > 0 && this.activeCategory !== 'LONGTIME_PENDING') {
              <button
                class="icon-footer-general col-auto"
                [ngClass]="{'pointer': !!vehicle.mediaInfos.length, 'offset-custom': !canSeeInternalNote}" type="button"
                [floatUi]="popTemplateLg" [placement]="NgxFloatUiPlacements.TOP"
                [styles]="{'max-width': '300px'}"
                [disabled]="!!!vehicle.mediaInfos.length">
                <ucs-icon [shape]="'document'"
                [class]="vehicle.missingDocument ? 'grey' : 'blue'"></ucs-icon>
                <span class="documents">{{ 'offer-item.documents' | translate }}</span>
                <ucs-icon [shape]="'chevron-down'"></ucs-icon>
              </button>
            }
            <float-ui-content #popTemplateLg>
              @for (document of vehicle.mediaInfos; track document.id) {
                <a rel="noopener"
                  href="{{ document.externalLink ? document.url : document.url + '/original' }}"
                  target="_blank"
                  (mousedown)="$event.preventDefault()">
                  <span class="ps-1 d-inline-block text-alternate">
                    {{ document.type.text }}
                  </span><br/>
                  @if (showExternalDocumentLink && document.externalLink) {
                    <span class="ps-1 d-inline-block text-alternate"
                      >
                      {{ 'vms.media-types.external-document' | translate }}
                    </span>
                  }
                </a>
              }
            </float-ui-content>
          </div>
        </div>
      </div>
    </div>
    @if (!vehicle.offerDataDto && this.activeCategory !== 'LONGTIME_PENDING') {
      <ucs-vehicle-footer [vehicle]="vehicle"
        [mayCreateBuyNow]="mayCreateOfferBuyNow && vehicle.carhubStatus === 'ACTIVE'"
        [mayCreateAuction]="mayCreateOfferAuction && vehicle.carhubStatus === 'ACTIVE'">
      </ucs-vehicle-footer>
    }
    @if (vehicle.offerDataDto || this.activeCategory === 'LONGTIME_PENDING') {
      <ucs-card-footer
        [offerData]="vehicle.offerDataDto"
        [channel]="vehicle.channel"
        [currentPrice]="vehicle.offerDataDto?.offerType === 'BUYNOW' ? vehicle.offerDataDto?.price : vehicle.offerDataDto?.highestBid"
        [startPrice]="vehicle.offerDataDto?.initialBid"
        [vatType]="vehicle.vatType.data"
        [extraTax]="vehicle.offerDataDto?.extraTaxDto"
        [grossProfitData]="vehicle.offerDataDto?.grossProfit"
        [vehicle]="vehicle"
        [showOfferLink]="showLinkToOffer"
        [footerStatus]="footerStatus"
        (linkToOfferClicked)="storeBacklink()"
        ucsHideableData
        [dataType]="'offer-price-information'"
        [country]="vehicle.country"
        [scopeSection]="'SALES_MAINTENANCE'"
        [activeCategory]="activeCategory"
        [enforcedAuctionData]="vehicle?.enforcedAuctionVehicleData"
        [displayLogo]="false">
      </ucs-card-footer>
    }
  </div>
}
