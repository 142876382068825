import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ucs-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss']
})
export class DatepickerRangeComponent implements OnInit {

  hoveredDate: NgbDate | null = null;

  maxDate: NgbDate | null;
  minDate: NgbDate | null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  @Input() isFutureDatePicker = false;
  @Output() fromDateChange = new EventEmitter<NgbDate>();
  @Output() toDateChange = new EventEmitter<NgbDate>();

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {

  }

  ngOnInit(): void {
    if (this.isFutureDatePicker){
      this.maxDate = this.calendar.getNext(this.calendar.getToday(),'y',10);
      this.minDate = this.calendar.getToday();
      this.fromDate = this.calendar.getToday();
      this.toDate = this.calendar.getNext(this.calendar.getToday(),'d',10);
    } else {
      this.maxDate = this.calendar.getToday();
      this.minDate = this.calendar.getPrev(this.calendar.getToday(),'y',10);
      this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'm', 12);
      this.toDate = this.calendar.getToday();
    }
    this.fromDateChange.emit(this.fromDate);
    this.toDateChange.emit(this.toDate);
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.fromDateChange.emit(this.fromDate);
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.toDateChange.emit(this.toDate);
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.toDateChange.emit(this.toDate);
      this.fromDateChange.emit(this.fromDate);
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) &&
      date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) ||
      this.isHovered(date);
  }

  validateFromDateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    let val = parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    this.fromDateChange.emit(val);
    return val;
  }

  validateToDateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    let val = parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    this.toDateChange.emit(val);
    return val;
  }
}
