@if (error) {
  <div class="error-component row">
    <div class="col-12">
      <p>
        <a routerLink="/offer">
          <ucs-icon [shape]="'arrow_left'" [class]="'blue'"></ucs-icon>
          <span class="text ms-2">{{ 'offer-detail.back-to-search' | translate }}</span>
        </a>
      </p>
      @if (error !== 'error.navigation') {
        <p class="error rounded p-3">
          <ucs-icon [shape]="'info'" [class]="'blue'" [size]="24"></ucs-icon>
          <span class="text ms-2">{{ error | translate }}</span>
        </p>
      }
      @if (error === 'error.navigation') {
        <div>
          <div class="d-flex justify-content-center align-items-center errorCode">
            404
          </div>
          <div class="d-flex justify-content-center pageNotFound">
            {{ 'ucs.error.page-not-found' | translate }}
          </div>
        </div>
      }
    </div>
  </div>
}
