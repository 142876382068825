import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {isValueAggregationEntry} from '../../../misc/typeguard';
import * as fromRoot from '../../../store/app.reducers';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {SearchMultiSelectComponent} from '../search-multi-select/search-multi-select.component';
import {TranslateService} from '@ngx-translate/core';
import {SalesScope} from '../../../model/sales-scope';
import * as EnforcedAuctionOfferAction from '../../../store/enforced-auction-offer/enforced-auction-offer.actions';
import * as SearchActions from '../../../store/search/search.actions';
import {ucsIsNil} from '../../../misc/utils';
import {NgxFloatUiPlacements, NgxFloatUiTriggers} from 'ngx-float-ui';

/**
 * This component allows adding offer variants to the search
 * (currently all offers are vehicles with brand, model and modelVariant)
 */
@Component({
  selector: 'ucs-search-offer-variant',
  templateUrl: './search-offer-variant.component.html',
  styleUrls: ['./search-offer-variant.component.scss']
})
export class SearchOfferVariantComponent implements OnInit, OnDestroy {
  @Input() horizontal: boolean;
  @Output() onSearchParamsChanged = new EventEmitter<VehicleOfferSearchParams>();
  @ViewChild('modelSelectRef', {static: false}) modelSelectComponentRef: SearchMultiSelectComponent;
  @ViewChild('variantSelectRef') variantSelectComponentRef: SearchMultiSelectComponent;
  maxOfferVariants = 5; // our max allowed offer variants
  numberOfOfferVariants = []; // offer variants are counted using this array, as needed for *ngFor
  lastOfferVariantIndex = 1; // index of last vehicle (not zero-based to match naming in VehicleOfferSearchParams)
  currentOfferVariantIndex = 1; // index of current vehicle (not zero-based to match naming in VehicleOfferSearchParams)
  searchParams: VehicleOfferSearchParams = {} as any; // offer variants are stored here and emitted to parent
  searchAggregation: VehicleOfferSearchAggregation;
  // the aggregated values displayed in the dropdowns, i.e. 'text (count)'
  selectedAggregatedBrand: string;
  selectedAggregatedModel: string;
  selectedAggregatedModelVariant: string;
  // the array of aggregated values (id: entry index, option: 'text (count)')
  aggregatedBrandStates: any[] = [];
  aggregatedModelStates: any[] = [];
  aggregatedModelVariantStates: any[] = [];
  // the currently selected actual values (i.e. no aggregations)
  currentBrandValue: string;
  currentModelsValue: string[] = [];
  currentModelVariantsValue: string[] = [];
  // dropdowns for model or modelVariant selection are disabled when the previous field (brand | model) is not selected
  disableModelSelection = true;
  disableModelVariantSelection = true;
  currentScope: SalesScope;
  vehicleSummaries: string[] = [];

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private store: Store<fromRoot.AppState>,
              private translateService: TranslateService) {
  }

  /**
   * Transforms the states input array into displayable selections for the typeahead component
   */
  ngOnInit() {
    this.resetCurrentVehicle(); // reset the current offer variant variables on init
    this.numberOfOfferVariants = [0]; //start the counter

    this.store.select(fromRoot.getSalesScope)
      .subscribe(scope => {
        this.currentScope = scope;
      });

    if (this.currentScope === SalesScope.PIA_AUCTION) {
      this.store.select(fromRoot.getEnforcedAuctionOfferSearchAggregation)
        .pipe(takeUntil(this.unsubscribe), filter(searchAggregation => !!searchAggregation))
        .subscribe(searchAggregation => {
          this.searchAggregation = searchAggregation;
          this.initSearchAggregations();
          this.setDropdownSelections(this.currentOfferVariantIndex);
        });
    } else {
      // select searchAggregation from store and subscribe to future updates
      this.store.select(fromRoot.getSearchAggregation)
        .pipe(takeUntil(this.unsubscribe), filter(searchAggregation => !!searchAggregation))
        .subscribe(searchAggregation => {
          this.searchAggregation = searchAggregation;
          this.initSearchAggregations();
          this.setDropdownSelections(this.currentOfferVariantIndex);
        });

      this.store.select(fromRoot.getSearchParams)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(searchParams => {
          if (searchParams) {
            this.searchParams = searchParams;
            this.triggerChangeDetectionOfMultiselect(searchParams);
            this.setAllVehiclesOneByOne(searchParams);
          }
        });
    }
  }

  private initSearchAggregations(selectionIndex = this.currentOfferVariantIndex) {
    let modelAggregationEntry = this.getModelSelection(selectionIndex);

    this.aggregatedBrandStates = [];
    this.initAggregatedStates(modelAggregationEntry?.brands, this.aggregatedBrandStates);

    // models is only provided by backend once a brand is selected
    if (modelAggregationEntry?.models?.length > 0) {
      this.disableModelSelection = selectionIndex !== this.currentOfferVariantIndex;
      this.aggregatedModelStates = [];
      this.initAggregatedStates(modelAggregationEntry?.models, this.aggregatedModelStates);
    }

    // modelVariants is only provided by backend once a model is selected
    if (modelAggregationEntry?.modelVariants?.length > 0) {
      this.disableModelVariantSelection = selectionIndex !== this.currentOfferVariantIndex;
      this.aggregatedModelVariantStates = [];
      this.initAggregatedStates(modelAggregationEntry?.modelVariants, this.aggregatedModelVariantStates);
    }
  }

  getModelSelection(selectionIndex= this.currentOfferVariantIndex) {
    switch (selectionIndex) {
    case 1:
      return this.searchAggregation.modelSelection;
    case 2:
      return this.searchAggregation.modelSelection2;
    case 3:
      return this.searchAggregation.modelSelection3;
    case 4:
      return this.searchAggregation.modelSelection4;
    case 5:
      return this.searchAggregation.modelSelection5;
    default:
      return this.searchAggregation.modelSelection;
    }
  }

  getBrandFromSearchParams(brandIndex = this.currentOfferVariantIndex) {
    switch (brandIndex) {
    case 1:
      return this.searchParams?.brand;
    case 2:
      return this.searchParams?.brand2;
    case 3:
      return this.searchParams?.brand3;
    case 4:
      return this.searchParams?.brand4;
    case 5:
      return this.searchParams?.brand5;
    default:
      return undefined;
    }
  }

  getModelsFromSearchParams(modelsIndex = this.currentOfferVariantIndex) {
    switch (modelsIndex) {
    case 1:
      return this.searchParams?.models;
    case 2:
      return this.searchParams?.models2;
    case 3:
      return this.searchParams?.models3;
    case 4:
      return this.searchParams?.models4;
    case 5:
      return this.searchParams?.models5;
    default:
      return undefined;
    }
  }

  getVariantsFromSearchParams(modelsIndex = this.currentOfferVariantIndex) {
    switch (modelsIndex) {
    case 1:
      return this.searchParams.modelVariants;
    case 2:
      return this.searchParams.modelVariants2;
    case 3:
      return this.searchParams.modelVariants3;
    case 4:
      return this.searchParams.modelVariants4;
    case 5:
      return this.searchParams.modelVariants5;
    default:
      return undefined;
    }
  }

  /**
   * Called when a brand has been selected for current offer variant (vehicle)
   * Updates the current vehicle's property with the selected value
   */
  onSelectBrand(selectedModels: any[]) {
    // the Brand is not a multi select, that means there is only one element in the list
    if (!!selectedModels && !!selectedModels[0]?.option) {
      this.selectedAggregatedBrand = selectedModels[0]['option'];
    } else if (!!selectedModels && !!selectedModels['option']) {
      this.selectedAggregatedBrand = selectedModels['option'];
    } else {
      this.selectedAggregatedBrand = '';
      this.resetBrand();
      return;
    }

    // find the value from the states aggregation and update search parameters
    this.aggregatedBrandStates = [];
    this.initAggregatedStates(this.getModelSelection().brands, this.aggregatedBrandStates);
    let item = this.aggregatedBrandStates.find(x => x.option === this.selectedAggregatedBrand);
    this.currentBrandValue = (this.getModelSelection().brands)[item.id].value;

    this.updateCurrentVehicle(this.currentOfferVariantIndex);
  }

  /**
   * Called when a model has been selected for current offer variant (vehicle)
   * Updates the current vehicle's property with the selected value
   */
  onSelectModel(selectedModels: string[]) {
    if (!selectedModels || selectedModels.length === 0) {
      this.selectedAggregatedModel = undefined;
      this.currentModelsValue = undefined;
      this.currentModelVariantsValue = undefined;
      this.variantSelectComponentRef?.deselectAll();
      this.updateCurrentVehicle(this.currentOfferVariantIndex);
      return;
    }

    // find the value from the states aggregation and update search parameters
    this.aggregatedModelStates = [];
    this.currentModelsValue = [];

    this.updateModelData(this.getModelSelection().models, selectedModels);

    if (this.currentModelsValue.length > 1) {
      //Disable variant selection if multiple models are selected
      this.disableModelVariantSelection = true;
      //Remove already selected variants
      this.currentModelVariantsValue = undefined;
    }

    this.updateCurrentVehicle(this.currentOfferVariantIndex);
  }

  private updateModelData(modelsAggregations: ValueAggregationEntry[], selectedModels: string[]) {
    let item;
    this.initAggregatedStates(modelsAggregations, this.aggregatedModelStates);
    for (let modelIndex in selectedModels) {
      item = this.aggregatedModelStates.find(x => x.value === selectedModels[modelIndex]);
      if (item) {
        this.currentModelsValue.push(modelsAggregations[item.id].value);
      }
    }
  }

  /**
   * Called when a modelVariant has been selected for current offer variant (vehicle)
   * Updates the current vehicle's property with the selected value
   */
  onSelectModelVariant(selectedModelVariants: string[]) {

    if (!selectedModelVariants || selectedModelVariants.length === 0) {
      this.selectedAggregatedModelVariant = undefined;
      this.currentModelVariantsValue = undefined;
      return;
    }

    // find the value from the states aggregation and update search parameters
    let item;
    this.aggregatedModelVariantStates = [];
    this.currentModelVariantsValue = [];

    this.initAggregatedStates(this.getModelSelection().modelVariants, this.aggregatedModelVariantStates);
    for (let modelVariantIndex in selectedModelVariants) {
      item = this.aggregatedModelVariantStates.find(x => x.value === selectedModelVariants[modelVariantIndex]);
      if (item) {
        this.currentModelVariantsValue.push(this.getModelSelection().modelVariants[item.id].value);
      }
    }

    this.updateCurrentVehicle(this.currentOfferVariantIndex);
  }

  /**
   * Called whenever input value of dropdown for brand changes
   * When empty, i.e. selection cleared, also clear the search param
   */
  resetBrand() {
    this.selectedAggregatedBrand = undefined;
    this.currentBrandValue = undefined;
    this.modelSelectComponentRef?.deselectAll();
    this.variantSelectComponentRef?.deselectAll();
    this.updateCurrentVehicle(this.currentOfferVariantIndex);
    this.disableModelSelection = true;
    this.disableModelVariantSelection = true;
  }

  /**
   * If at least brand has been selected for current offer variant,
   * allow adding further offer variants (vehicles)
   */
  onAddVehicle() {
    if (this.currentBrandValue) {
      this.resetCurrentVehicle();
      this.numberOfOfferVariants.push(0);
      this.lastOfferVariantIndex++;
      this.currentOfferVariantIndex = this.lastOfferVariantIndex;

      // clear model and modelVariant selections
      this.aggregatedBrandStates = [];
      this.aggregatedModelStates = [];
      this.aggregatedModelVariantStates = [];

      this.numberOfOfferVariants.forEach((_, i) => {
        this.vehicleSummaries[i] = this.printVehicle(i + 1);
      });
    }
  }

  /**
   * Clears a vehicle selection from the search
   */
  onClearVehicle(vehicleToClear: number) {
    this.removeVehicleFromSearchParams(vehicleToClear); // removing the vehicle from search params
    this.lastOfferVariantIndex--;
    this.currentOfferVariantIndex = this.lastOfferVariantIndex;
    this.vehicleSummaries.splice(vehicleToClear-1, 1);
    this.numberOfOfferVariants.pop();
    this.updateSearchParamsInStore();
  }

  /**
   * Switches to a different vehicle to allow updating a former vehicle selection
   */
  onSwitchVehicle(vehicleToSwitch: number) {
    // if there are no current selections remove the last vehicle when switching
    if (!this.selectedAggregatedBrand
      && !this.selectedAggregatedModel
      && !this.selectedAggregatedModelVariant) {
      this.onClearVehicle(this.lastOfferVariantIndex);
    }

    this.resetCurrentVehicle(); // clear selections of dropdown components
    this.setDropdownSelections(vehicleToSwitch);
    this.currentOfferVariantIndex = vehicleToSwitch;
  }

  /**
   * This helper method prints the selected vehicle summary,
   * one of: modelVariant, model or brand (the first set, from left to right)
   * @param {number} vehicleNumber the vehicle selection summary to print, starting with 1
   * @returns {string} the vehicle summary string for display
   */
  printVehicle(vehicleNumber: number): string {
    let vehicleSummary: string;
    let searchParamBrand;

    this.aggregatedBrandStates = [];
    this.aggregatedModelStates = [];
    this.aggregatedModelVariantStates = [];

    /**
     * When printing vehicle, we also need to re-initialize the dropdown aggregated states to find the correct value
     */
    searchParamBrand = this.getBrandFromSearchParams(vehicleNumber);

    this.initSearchAggregations(vehicleNumber);

    if (searchParamBrand) {
      const brandState = this.aggregatedBrandStates.find(x => x.value === searchParamBrand);
      const modelVariants = this.getVariantsFromSearchParams(vehicleNumber);
      const models = this.getModelsFromSearchParams(vehicleNumber);
      if (modelVariants && modelVariants.length !== 0) {
        const modelVariantText = modelVariants?.length > 1 ?
          modelVariants?.length + ' ' +
          this.translateService.instant('search-filter.variants') + ' ' +
          this.translateService.instant('search-filter.selected') : modelVariants[0];
        vehicleSummary = modelVariantText && brandState ? '(' + brandState.text + ') ' + modelVariantText : undefined;
      } else if (models && models.length !== 0) {
        const modelText = models?.length > 1 ? models?.length + ' ' +
          this.translateService.instant('search-filter.models') + ' ' +
          this.translateService.instant('search-filter.selected') : models[0];
        vehicleSummary = modelText && brandState ? '(' + brandState.text + ') ' + modelText : undefined;
      } else {
        vehicleSummary = brandState?.text;
      }
    }
    return vehicleSummary;
  }

  /**
   * Clear all selections in component
   */
  clear() {
    this.resetCurrentVehicle();
    this.numberOfOfferVariants = [];
    this.lastOfferVariantIndex = 1;
    this.currentOfferVariantIndex = 1;
    this.searchParams = {} as any;
  }


  private setAllVehiclesOneByOne(searchParams: VehicleOfferSearchParams) {
    if (!ucsIsNil(searchParams.brand) || searchParams?.models?.length > 0 || searchParams?.modelVariants?.length > 0) {
      this.numberOfOfferVariants = [0];
      this.lastOfferVariantIndex = 1;
      this.currentOfferVariantIndex = 1;
      this.setDropdownSelections(1);

      // set vehicle 2
      if (!ucsIsNil(searchParams.brand2) || searchParams?.models2?.length > 0 || searchParams?.modelVariants2?.length > 0) {
        this.onAddVehicle();
        this.setDropdownSelections(2);

        // set vehicle 3
        if (!ucsIsNil(searchParams.brand3) || searchParams?.models3?.length > 0 || searchParams.modelVariants3?.length > 0) {
          this.onAddVehicle();
          this.setDropdownSelections(3);

          // set vehicle 4
          if (!ucsIsNil(searchParams.brand4) || searchParams?.models4?.length > 0 || searchParams?.modelVariants4?.length > 0) {
            this.onAddVehicle();
            this.setDropdownSelections(4);

            // set vehicle 5
            if (!ucsIsNil(searchParams.brand5) || searchParams?.models5?.length > 0 || searchParams?.modelVariants5?.length > 0) {
              this.onAddVehicle();
              this.setDropdownSelections(5);
            }
          }
        }
      }
    }
  }

  /**
   * Method for triggering change detection of ng-select component
   * See https://github.com/ng-select/ng-select#change-detection for reference
   * @param searchParams
   * @private
   */
  private triggerChangeDetectionOfMultiselect(searchParams: VehicleOfferSearchParams) {

    this.searchParams.models = this.searchParams.models ? [...searchParams.models] : undefined;
    this.searchParams.models2 = this.searchParams.models2 ? [...searchParams.models2] : undefined;
    this.searchParams.models3 = this.searchParams.models3 ? [...searchParams.models3] : undefined;
    this.searchParams.models4 = this.searchParams.models4 ? [...searchParams.models4] : undefined;
    this.searchParams.models5 = this.searchParams.models5 ? [...searchParams.models5] : undefined;

    this.searchParams.modelVariants = this.searchParams.modelVariants ? [...searchParams.modelVariants] : undefined;
    this.searchParams.modelVariants2 = this.searchParams.modelVariants2 ? [...searchParams.modelVariants2] : undefined;
    this.searchParams.modelVariants3 = this.searchParams.modelVariants3 ? [...searchParams.modelVariants3] : undefined;
    this.searchParams.modelVariants4 = this.searchParams.modelVariants4 ? [...searchParams.modelVariants4] : undefined;
    this.searchParams.modelVariants5 = this.searchParams.modelVariants5 ? [...searchParams.modelVariants5] : undefined;
  }

  /**
   * Initializes the aggregated states for optimized display, also adding an id for easier handling
   * @param {any[]} inputStates: the states, i.e. dropdown options, passed to the component
   * @param {any[]} aggregStates: optimized display options with an id for each entry
   */
  private initAggregatedStates(inputStates: any[], aggregStates: any[]) {
    if (inputStates) {
      let id = 0;
      for (const entry of inputStates) {
        if (isValueAggregationEntry(entry)) {
          const aggregatedValue = entry.text + ' (' + entry.count + ')';
          aggregStates.push({id: id, option: aggregatedValue, value: entry.value, text: entry.text});
          id++;
        } else {
          console.error('Unknown input state');
        }
      }
    }
  }

  /**
   * Helper method to reset the current offer variant (vehicle)
   */
  private resetCurrentVehicle() {
    this.selectedAggregatedBrand = undefined;
    this.selectedAggregatedModel = undefined;
    this.selectedAggregatedModelVariant = undefined;

    this.currentBrandValue = undefined;

    this.currentModelsValue = undefined;
    this.currentModelVariantsValue = undefined;

    this.disableModelSelection = true;
    this.disableModelVariantSelection = true;
  }

  /**
   * Adds the current vehicle selection to our VehicleOfferSearchParams instance
   */
  private updateCurrentVehicle(vehicleNumber: number) {
    switch (vehicleNumber) {
    case 1:
      this.searchParams.brand = this.currentBrandValue;
      this.searchParams.models = this.currentModelsValue;
      this.searchParams.modelVariants = this.currentModelVariantsValue;
      break;
    case 2:
      this.searchParams.brand2 = this.currentBrandValue;
      this.searchParams.models2 = this.currentModelsValue;
      this.searchParams.modelVariants2 = this.currentModelVariantsValue;
      break;
    case 3:
      this.searchParams.brand3 = this.currentBrandValue;
      this.searchParams.models3 = this.currentModelsValue;
      this.searchParams.modelVariants3 = this.currentModelVariantsValue;
      break;
    case 4:
      this.searchParams.brand4 = this.currentBrandValue;
      this.searchParams.models4 = this.currentModelsValue;
      this.searchParams.modelVariants4 = this.currentModelVariantsValue;
      break;
    case 5:
      this.searchParams.brand5 = this.currentBrandValue;
      this.searchParams.models5 = this.currentModelsValue;
      this.searchParams.modelVariants5 = this.currentModelVariantsValue;
      break;
    default:
      console.error('unknown vehicle count in updateCurrentVehicle()');
      break;
    }

    // propagate updated search params to parent component
    this.updateSearchParamsInStore();
  }

  /**
   * Remove the cleared vehicle from the search params and set other parameters accordingly
   */
  private removeVehicleFromSearchParams(vehicleNumber: number) {
    // fall through in this switch is intentional
    switch (vehicleNumber) {
    case 1:
      this.searchParams.brand = this.searchParams.brand2;
      this.searchParams.models = this.searchParams.models2;
      this.searchParams.modelVariants = this.searchParams.modelVariants2;
      // fall through
    case 2:
      this.searchParams.brand2 = this.searchParams.brand3;
      this.searchParams.models2 = this.searchParams.models3;
      this.searchParams.modelVariants2 = this.searchParams.modelVariants3;
      // fall through
    case 3:
      this.searchParams.brand3 = this.searchParams.brand4;
      this.searchParams.models3 = this.searchParams.models4;
      this.searchParams.modelVariants3 = this.searchParams.modelVariants4;
      // fall through
    case 4:
      this.searchParams.brand4 = this.searchParams.brand5;
      this.searchParams.models4 = this.searchParams.models5;
      this.searchParams.modelVariants4 = this.searchParams.modelVariants5;
      // fall through
    case 5:
      this.searchParams.brand5 = undefined;
      this.searchParams.models5 = undefined;
      this.searchParams.modelVariants5 = undefined;
      break;
    default:
      console.error('unknown vehicleNumber');
      break;
    }
  }

  /**
   * Sets the three dropdowns for brand, model and modelVariant to a given vehicle selection
   */
  private setDropdownSelections(vehicleNumber: number) {

    this.aggregatedBrandStates = [];
    this.aggregatedModelStates = [];
    this.aggregatedModelVariantStates = [];

    let searchParamBrand = this.getBrandFromSearchParams(vehicleNumber);
    let searchParamModels = this.getModelsFromSearchParams(vehicleNumber);
    let searchParamModelVariants = this.getVariantsFromSearchParams(vehicleNumber);

    this.initSearchAggregations(vehicleNumber);

    const brandState = this.aggregatedBrandStates.find(x => x.value === searchParamBrand);

    let modelState = [];
    if (searchParamModels?.length > 0) {
      modelState = this.aggregatedModelStates.filter(x => searchParamModels.includes(x.value));
      this.modelSelectComponentRef?.overrideSelection(searchParamModels);
    }

    let modelVariantState = [];
    if (searchParamModelVariants?.length > 0) {
      modelVariantState = this.aggregatedModelVariantStates.filter(x => searchParamModelVariants.includes(x.value));
      this.variantSelectComponentRef?.overrideSelection(searchParamModelVariants);
    }

    if (brandState) {
      this.selectedAggregatedBrand = brandState.option;
      this.currentBrandValue = brandState.value;
      this.disableModelSelection = false;
    } else {
      this.selectedAggregatedBrand = undefined;
      this.currentBrandValue = undefined;
    }
    if (modelState.length > 0) {
      this.currentModelsValue = modelState.map(modelAgg => modelAgg.value);
      this.disableModelVariantSelection = modelState.length > 1;
    } else {
      this.selectedAggregatedModel = undefined;
      this.selectedAggregatedModelVariant = undefined;
      this.currentModelsValue = undefined;
      this.currentModelVariantsValue = undefined;
    }
    if (modelVariantState.length > 0) {
      this.currentModelVariantsValue = modelVariantState.map(variantAgg => variantAgg.value);
    } else {
      this.selectedAggregatedModelVariant = undefined;
      this.currentModelVariantsValue = undefined;
    }
  }

  /**
   * Trigger an update in the store to fetch from backend and bind to the results of the update in the store
   */
  private updateSearchParamsInStore() {
    // save updated search params
    if (this.currentScope === SalesScope.PIA_AUCTION) {
      this.store.dispatch(new EnforcedAuctionOfferAction.UpdateSearchParamsAction(this.searchParams));
      this.store.dispatch(new EnforcedAuctionOfferAction.UpdateSearchAggregationAction());
    } else {
      this.store.dispatch(new SearchActions.UpdateSearchParamsAction(this.searchParams));
      this.store.dispatch(new SearchActions.UpdateSearchAggregationAction());
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
  protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
