import {ApplicationRef, Injectable} from '@angular/core';
import {concatAll, filter, map, take} from 'rxjs/operators';
import {BehaviorSubject, interval, Observable, SchedulerLike, timer} from 'rxjs';

@Injectable()
/**
 * Service that works around a very nasty bug in zone.js
 * @see https://github.com/angular/angular/issues/20970
 */
export class TimerService {

  private isStable = new BehaviorSubject(false);

  constructor(private app: ApplicationRef) {
    // app.isStable
    //   .pipe(
    //     filter((val) => val), // only true is interesing
    //     // take(1)
    //   )
    //   .subscribe((val) => this.isStable.next(val));
  }

  public createInterval(period?: number, scheduler?: SchedulerLike): Observable<number> {
    return this.stable().pipe(map(() => interval(period, scheduler)), take(1), concatAll());
  }

  public createTimer(dueTime?: number | Date, periodOrScheduler?: number, scheduler?: SchedulerLike): Observable<number> {
    return this.stable().pipe(map(() => timer(dueTime, periodOrScheduler, scheduler)), take(1), concatAll());
  }

  private stable(): Observable<boolean> {
    return this.app.isStable.pipe(
      filter((val) => val)
      , take(1)

      // return this.isStable.pipe(
      //   filter(val => val), // only emit once we have a stable app
      //   // take(1)
    );
  }

}

