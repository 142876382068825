import {Component, Input} from '@angular/core';

/**
 * A simple component with a label, value and an optional hint
 */
@Component({
  selector: 'ucs-offer-label-value',
  templateUrl: './offer-label-value.component.html',
  styleUrls: ['./offer-label-value.component.scss']
})
export class OfferLabelValueComponent {
  @Input() label: string;
  @Input() value: string;
  @Input() hint: string;
}
