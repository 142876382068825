<div class="footer row text-truncate d-print-none" data-cy="app-component.footer">
  <div class="col-auto text text-big">
    <strong>&copy; UCS {{ currentYear }}</strong>
  </div>
  <a class="col-auto" routerLink="terms">
    <div class="text text-bright">{{ 'footer.terms' | translate }}</div>
  </a>
  <a class="col-auto" routerLink="legal-notice">
    <div class="text text-bright">{{ 'footer.legal-notice' | translate }}</div>
  </a>
  <a class="col-auto" routerLink="contact">
    <div class="text text-bright">{{ 'footer.contact' | translate }}</div>
  </a>
</div>
