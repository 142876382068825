import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import * as OfferActions from '../../../store/offer/offer.actions';

@Component({
  selector: 'ucs-offer-channel-selection',
  templateUrl: './offer-channel-selection.component.html'
})
export class OfferChannelSelectionComponent implements OnInit {

  searchAggregation$ = this.store.select(fromRoot.getSearchAggregation);
  channel$ = this.store.select(fromRoot.getPageSettingsDistributionChannel);

  @Input() canViewALLUC: boolean;
  @Input() canViewPB: boolean;
  @Input() canViewPIA: boolean;
  @Input() canViewDINBIL: boolean;
  @Input() platformListClass = 'col d-none d-lg-block platform-list'; // default class
  @Input() hideDropDown: boolean;
  @Input() showSidebar: boolean;

  elementsCountALLUC: number;
  elementsCountPB: number;
  elementsCountPIA: number;
  elementsCountDINBIL: number;
  elementsCountVGRX: number;
  channel: DistributionChannel;

  constructor(private store: Store<fromRoot.AppState>) {

  }

  ngOnInit() {
    this.searchAggregation$.subscribe(searchAggregation => {
      if (searchAggregation) {
        this.updateSearchAggregation(searchAggregation);
      }
    });

    this.channel$.subscribe((channel) => {
      if (channel) {
        this.channel = channel;
      }
    });
  }

  private updateSearchAggregation(searchAggregation: VehicleOfferSearchAggregation): void {
    const offersPIA = searchAggregation.channels.find(x => x.value === 'PIA');
    this.elementsCountPIA = offersPIA ? offersPIA.count : 0;
    const offersPB = searchAggregation.channels.find(x => x.value === 'PB');
    this.elementsCountPB = offersPB ? offersPB.count : 0;
    const offersALLUC = searchAggregation.channels.find(x => x.value === 'ALL_UC');
    this.elementsCountALLUC = offersALLUC ? offersALLUC.count : 0;
    const offersDINBIL = searchAggregation.channels.find(x => x.value === 'DIN_BIL');
    this.elementsCountDINBIL = offersDINBIL ? offersDINBIL.count : 0;
    const offersVGRX = searchAggregation.channels.find(x => x.value === 'VGRX');
    this.elementsCountVGRX = offersVGRX ? offersVGRX.count : 0;
  }

  pickDistributionChannel(channel: DistributionChannel) {
    this.channel = channel;
    this.store.dispatch(new OfferActions.UpdatePageSettingsChannel(channel));
  }
}
