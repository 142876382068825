import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) {}

  sendUserNotification(notificationMessage: CreateUserNotificationDto): Observable<any> {
    return this.http.post(this.config.salesApiUrl + '/notifications', notificationMessage);
  }

  getUserNotifications(appScope: AppScope): Observable<NotificationDto[]> {
    const params = new HttpParams().set('appScope', appScope);
    return this.http.get<NotificationDto[]>(this.config.salesApiUrl + '/notifications/user-notifications', {params});
  }

  approveUserNotification(notificationId: number): Observable<any> {
    return this.http.put(this.config.salesApiUrl + '/notifications/' + notificationId + '/approval/', {});
  }

  getChannelNotifications(): Observable<NotificationDto[]> {
    return this.http.get<NotificationDto[]>(this.config.salesApiUrl + '/notifications/channel-notifications', {});
  }

  activateNotification(notificationId: number): Observable<any> {
    return this.http.put(this.config.salesApiUrl + '/notifications/' + notificationId + '/activate', {});
  }

  deactivateNotification(notificationId: number): Observable<any> {
    return this.http.put(this.config.salesApiUrl + '/notifications/' + notificationId + '/deactivate', {});
  }
}
