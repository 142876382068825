import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {isEquipmentDto} from '../misc/typeguard';

/*
 * Filters based on argument
 * Usage:
 *   items | inputFilter:filterText
 * Example:
 *   {{ equipments | inputFilter:seat }}
 *   Filters all equipments that contain 'seat'
*/
@Pipe({
  name: 'inputFilter'
})
@Injectable()
export class InputFilterPipe implements PipeTransform {
  transform(items: any[], filterText: any): any {

    // return all items in case there is no filter text
    if (filterText === undefined) { return items; }

    // if the item is an EquipmentDto, filtering is based on its property 'text', else item is assumed to be a string
    return items.filter(
      item => isEquipmentDto(item) ?
        item.text.toLowerCase().indexOf(filterText.toString().toLowerCase()) !== -1  :
        item.toLowerCase().indexOf(filterText.toString().toLowerCase()) !== -1);
  }
}
