<div class="detail-container mt-3 ps-3 pe-3 pb-2 mb-xl-3">
  <div class="row my-2">
    <div class="col-8 col-lg-8 col-xl-6 title top-height mt-3 text-truncate">
      <span>{{ 'user-settings.headline' | translate }}</span>
    </div>
  </div>

  <div class="d-inline-flex">
    <select class="select form-select" [ngModel]="selectedAppScope">
      @for (scope of appScope; track scope) {
        <option [value]="scope">{{ 'ucs.application-scope.' + scope | translate }}</option>
      }
    </select>
  </div>
  <hr>

    <!--- Image Gallery Config Section --->
    @if (isImageGalleryEnabled) {
      <div class="d-flex justify-content-between">
        <div class="col-md-11 col-10 font-heading icon-height">
          <h4>{{ 'user-settings.image-gallery-config' | translate }}</h4>
        </div>
        <hr>
          <div class="col-auto ps-0 d-print-none">
            <button class="button-borderless w-100 icon-height text-end"
              (click)="showImageGalleryConfig = !showImageGalleryConfig">
              @if (showImageGalleryConfig) {
                <ucs-icon [shape]="'angle_up'" [class]="'black'"></ucs-icon>
              } @else {
                <ucs-icon [shape]="'angle_down'" [class]="'black'"></ucs-icon>
              }
            </button>
          </div>
        </div>
        @if (showImageGalleryConfig) {
          <div class="row custom-row">
            <!--- Sales Gallery Config --->
            @if (this.isBuyer) {
              <div class="col-6 col-md-4">
                <div class="row">
                  <div class="label-center">
                    <span>{{ 'user-settings.image-gallery-config.sales' | translate }}</span>
                  </div>
                  <ucs-search-toggle-button
                    [activeLeft]="imageGalleryConfig?.salesShowGalleryView"
                    [activeRight]="imageGalleryConfig?.salesShowGalleryView === false"
                    [labelLeft]="'user-settings.image-gallery-config.grid' | translate"
                    [labelRight]="'user-settings.image-gallery-config.standard' | translate"
                    (leftClicked)="enableSalesGalleryConfigGridView()"
                    (rightClicked)="enableSalesGalleryConfigStandardView()">
                  </ucs-search-toggle-button>
                </div>
                <div [ngClass]="{'enabled-text': imageGalleryConfig?.salesShowGalleryView, 'disabled-text': !imageGalleryConfig?.salesShowGalleryView}">
                  <span>{{ 'user-settings.image-gallery-config.rowsAndColumns' | translate }}</span>
                </div>
                <div [ngClass]="{'enabled-text': imageGalleryConfig?.salesShowGalleryView, 'disabled-text': !imageGalleryConfig?.salesShowGalleryView}">
                  <input type="radio" id="optionSales1" name="optionSales"
                 [checked]="imageGalleryConfig?.imageGalleryConfig.SALES_STORE[0].enabled
                 && imageGalleryConfig?.salesShowGalleryView"
                    (click)="setGalleryColumnsAndRowsSales(0)"
                    [disabled]="!imageGalleryConfig?.salesShowGalleryView">
                  <label for="optionSales1">{{' 4 x 6'}}</label>
                </div>
                <div [ngClass]="{'enabled-text': imageGalleryConfig?.salesShowGalleryView, 'disabled-text': !imageGalleryConfig?.salesShowGalleryView}">
                  <input type="radio" id="optionSales2" name="optionSales"
                 [checked]="imageGalleryConfig?.imageGalleryConfig.SALES_STORE[1].enabled
                 && imageGalleryConfig?.salesShowGalleryView"
                    (click)="setGalleryColumnsAndRowsSales(1)"
                    [disabled]="!imageGalleryConfig?.salesShowGalleryView">
                  <label for="optionSales2">{{' 6 x 8'}}</label>
                </div>
                <div [ngClass]="{'enabled-text': imageGalleryConfig?.salesShowGalleryView, 'disabled-text': !imageGalleryConfig?.salesShowGalleryView}">
                  <input type="radio" id="optionSales3" name="optionSales"
                 [checked]="imageGalleryConfig?.imageGalleryConfig.SALES_STORE[2].enabled
                 && imageGalleryConfig?.salesShowGalleryView"
                    (click)="setGalleryColumnsAndRowsSales(2)"
                    [disabled]="!imageGalleryConfig?.salesShowGalleryView">
                  <label for="optionSales3">{{' 8 x 10'}}</label>
                </div>
              </div>
            }
            <!--- Maintenance Gallery Config --->
            @if (this.isSeller) {
              <div class="col-6 col-md-4">
                <div class="row">
                  <div class="label-center">
                    <span>{{ 'user-settings.image-gallery-config.maintenance' | translate }}</span>
                  </div>
                  <ucs-search-toggle-button
                    [activeLeft]="imageGalleryConfig?.maintenanceShowGalleryView"
                    [activeRight]="imageGalleryConfig?.maintenanceShowGalleryView === false"
                    [labelLeft]="'user-settings.image-gallery-config.grid' | translate"
                    [labelRight]="'user-settings.image-gallery-config.standard' | translate"
                    (leftClicked)="enableMaintenanceGalleryConfigGridView()"
                    (rightClicked)="enableMaintenanceGalleryConfigStandardView()">
                  </ucs-search-toggle-button>
                </div>
                <div [ngClass]="{'enabled-text': imageGalleryConfig?.maintenanceShowGalleryView, 'disabled-text': !imageGalleryConfig?.maintenanceShowGalleryView}">
                  <span>{{ 'user-settings.image-gallery-config.rowsAndColumns' | translate }}</span>
                </div>
                <div [ngClass]="{'enabled-text': imageGalleryConfig?.maintenanceShowGalleryView, 'disabled-text': !imageGalleryConfig?.maintenanceShowGalleryView}">
                  <input type="radio" id="optionMaintenance1" name="optionMaintenance"
                 [checked]="imageGalleryConfig?.imageGalleryConfig.SALES_MAINTENANCE[0].enabled
                 && imageGalleryConfig?.maintenanceShowGalleryView"
                    (click)="setGalleryColumnsAndRowsMaintenance(0)"
                    [disabled]="!imageGalleryConfig?.maintenanceShowGalleryView">
                  <label for="optionMaintenance1">{{' 4 x 6'}}</label>
                </div>
                <div [ngClass]="{'enabled-text': imageGalleryConfig?.maintenanceShowGalleryView, 'disabled-text': !imageGalleryConfig?.maintenanceShowGalleryView}">
                  <input type="radio" id="optionMaintenance2" name="optionMaintenance"
                 [checked]="imageGalleryConfig?.imageGalleryConfig.SALES_MAINTENANCE[1].enabled
                 && imageGalleryConfig?.maintenanceShowGalleryView"
                    (click)="setGalleryColumnsAndRowsMaintenance(1)"
                    [disabled]="!imageGalleryConfig?.maintenanceShowGalleryView">
                  <label for="optionMaintenance2">{{' 6 x 8'}}</label>
                </div>
                <div [ngClass]="{'enabled-text': imageGalleryConfig?.maintenanceShowGalleryView, 'disabled-text': !imageGalleryConfig?.maintenanceShowGalleryView}">
                  <input type="radio" id="optionMaintenance3" name="optionMaintenance"
                 [checked]="imageGalleryConfig?.imageGalleryConfig.SALES_MAINTENANCE[2].enabled
                 && imageGalleryConfig?.maintenanceShowGalleryView"
                    (click)="setGalleryColumnsAndRowsMaintenance(2)"
                    [disabled]="!imageGalleryConfig?.maintenanceShowGalleryView">
                  <label for="optionMaintenance3">{{' 8 x 10'}}</label>
                </div>
              </div>
            }
          </div>
        }
        @if (showImageGalleryConfig) {
          <div class="mt-4">
            <button class="btn btn-primary"
              (click)="saveImageGalleryConfig()">
              <span>{{ 'user-settings.image-gallery-save-settings' | translate }}</span>
            </button>
          </div>
        }
      }
      <!--- Image Gallery Config Section end --->

      <!--- Features section --->
      <ng-container>
        <hr>
          <div class="d-flex justify-content-between">
            <div class="col-md-11 col-10 font-heading icon-height accordion-title">
              <h4>{{ 'user-settings.features.headline' | translate }}</h4>
            </div>
            <hr>
              <div class="col-auto ps-0 d-print-none">
                <button class="button-borderless w-100 icon-height text-end"
                  (click)="showFeatures = !showFeatures">
                  @if (showFeatures) {
                    <ucs-icon [shape]="'angle_up'" [class]="'black'"></ucs-icon>
                  } @else {
                    <ucs-icon [shape]="'angle_down'" [class]="'black'"></ucs-icon>
                  }
                </button>
              </div>
            </div>

            @if (showFeatures) {
              <div class="row-cols-lg-1">
                @for (channel of enabledFeatures; track channel; let offsetChannel = $index) {
                  <div
                    class="col-12 col-sm-6 col-md-4">
                    <div class="row justify-content-between">
                      <div class="col-md-11 col-10 font-heading icon-height mb-2 accordion-title">
                        <strong>{{ channel.channel }}</strong>
                      </div>
                      <div class="col-1 ps-0 d-print-none">
                        <button class="button-borderless w-100 icon-height text-end"
                          (click)="showFeaturesChannel(channel.channel)">
                          @if (!showFeaturesMap.get(channel.channel)) {
                            <ucs-icon [shape]="'angle_down'" [class]="'black'"></ucs-icon>
                          } @else {
                            <ucs-icon [shape]="'angle_up'" [class]="'black'"></ucs-icon>
                          }
                        </button>
                      </div>
                    </div>
                    @for (companyFeature of channel.companyFeatures; track companyFeature) {
                      <div class="mb-1">
                        <div>
                          <p>{{ companyFeature.companyNumber }}</p>
                        </div>
                        @if (showFeaturesMap.get(channel.channel)) {
                          <div style="overflow-x:auto;">
                            <table class="table">
                              <tbody>
                                @for (row of [1, 2, 3, 4, 5,6,7,8,9,10,11,12,13,14]; track row) {
                                  <tr>
                                    @for (col of [1, 2, 3]; track col) {
                                      <td>
                                        <input type="checkbox"
                                          [id]="allFeatures[(row -1) * 3 + col - 1]+offsetChannel"
                                          [name]="allFeatures[(row -1) * 3 + col - 1]"
                                          [checked]="hasFeatureEnabled(channel.channel, companyFeature.companyNumber, allFeatures[(row -1) * 3 + col - 1])"
                                          [disabled]="true">
                                        <label> {{ 'ucs.feature.' + allFeatures[(row - 1) * 3 + col - 1] | translate }} </label>
                                      </td>
                                    }
                                  </tr>
                                }
                              </tbody>
                            </table>
                          </div>
                        }
                      </div>
                    }
                    <hr>
                    </div>
                  }
                </div>
              }
            </ng-container>
            <!--- Features section end --->

            <!--- User settings start --->
            <hr>
              <div class="d-flex justify-content-between">

                <div class="col-md-11 col-10 font-heading icon-height accordion-title">
                  <h4>{{ 'user-settings.settings.headline' | translate }}</h4>
                </div>

                <div class="col-auto ps-0 d-print-none">
                  <button class="button-borderless w-100 icon-height text-end"
                    (click)="showUserSettings = !showUserSettings">
                    @if (showUserSettings) {
                      <ucs-icon [shape]="'angle_up'" [class]="'black'"></ucs-icon>
                    } @else {
                      <ucs-icon [shape]="'angle_down'" [class]="'black'"></ucs-icon>
                    }
                  </button>
                </div>
              </div>

              @if (showUserSettings) {
                <div class="row-cols-lg-1">
                  @for (channel of settings; track channel) {
                    <div class="col-12 col-sm-6 col-md-4 mt-2">
                      <div class="row justify-content-between">
                        <div class="col-md-11 col-10 font-heading icon-height mb-2 accordion-title">
                          <strong>{{ channel.channel }}</strong>
                        </div>
                        <div class="col-1 ps-0 d-print-none">
                          <button class="button-borderless w-100 icon-height text-end"
                            (click)="showSettingsChannel(channel.channel)">
                            @if (!showSettingsMap.get(channel.channel)) {
                              <ucs-icon [shape]="'angle_down'" [class]="'black'"></ucs-icon>
                            } @else {
                              <ucs-icon [shape]="'angle_up'" [class]="'black'"></ucs-icon>
                            }
                          </button>
                        </div>
                      </div>
                      @for (companyFeature of channel.companyFeatureSettings; track companyFeature) {
                        <div>
                          <p>{{ companyFeature.companyNumber }}</p>
                          @if (showSettingsMap.get(channel.channel)) {
                            @for (setting of companyFeature.featureSettings; track setting) {
                              <div>
                                <strong><label>{{ 'ucs.feature.setting.' + setting.key | translate }}: </label></strong>
                                <textarea style="width: 100%" rows="1">{{ setting.value }}</textarea><br><br>
                              </div>
                            }
                          }
                        </div>
                      }
                      <hr>
                      </div>
                    }
                  </div>
                }
              </div>
