/**
 * Dialog for thermal window check
 */
import {
  Component, EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit, Output, SimpleChanges
} from '@angular/core';
import {SystemSettingsService} from '../../service/system-settings.service';
import {Subject} from 'rxjs';
import {ApprovalService} from '../../service/approval.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import * as SalesOfferActions from '../../store/sales-offer/sales-offer.actions';
import * as OfferActions from '../../store/offer/offer.actions';
import {first} from 'rxjs/operators';

@Component({
  selector: 'ucs-thermal-window-dialog',
  templateUrl: './thermal-window-dialog.component.html',
  styleUrls: ['./thermal-window-dialog.component.scss']
})
export class ThermalWindowDialogComponent implements OnInit, OnDestroy, OnChanges {
  @Input() offer: OfferDto;
  @Input() overFollowupProposal = false;
  @Output() acceptEventEmitter = new EventEmitter<boolean>();

  isThermalWindowAffectedEnabled = false;

  private unsubscribe: Subject<void> = new Subject<void>();
  ucsVehicle: VehicleItemBaseDto;
  terms: boolean = false;
  clicked = false;
  modalTarget = '';
  isOwnBrand: boolean;
  ownBrand = [10, 20, 30, 40, 50, 60, 70, 80, 96];

  constructor(private systemSettingsService: SystemSettingsService,
              private approvalService: ApprovalService,
              private store: Store<fromRoot.AppState>) {
  }

  ngOnInit() {

    if (!this.overFollowupProposal) {
      this.modalTarget = ['AUCTION','ENFORCED_AUCTION'].includes(this.offer.offerType.data)
        ? '#confirmBidModal'+this.offer.id : '#confirmBuyNowModal'+this.offer.id;
    }

    this.systemSettingsService
      .isSystemFeatureActivatedForAnyChannel('THERMAL_WINDOW_AFFECTED')
      .subscribe(value => {
        this.isThermalWindowAffectedEnabled = value;
        if (this.isThermalWindowAffectedEnabled) {

          this.systemSettingsService.getSystemFeatureSettingForChannel('THERMAL_WINDOW_AFFECTED.thermal_window_affected_step_two', this.offer.channel.data)
            .pipe(first())
            .subscribe(stringValue => {
              this.isThermalWindowAffectedEnabled = JSON.parse(stringValue);
            });
        }
      });

    this.ucsVehicle = <VehicleItemBaseDto>this.offer.items[0];
    this.isOwnBrand = this.ownBrand.includes(this.ucsVehicle.makeId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'offer') {
        this.terms = false;
        this.clicked = false;
        this.ucsVehicle = <VehicleItemBaseDto>this.offer.items[0];
        this.isOwnBrand = this.ownBrand.includes(this.ucsVehicle.makeId);
      }
    }
  }

  acceptTerms() {
    this.clicked = true;
    this.approvalService.approve(this.offer.id, this.offer.channel.data).subscribe(value => {
      this.offer.approvals = [];
      this.offer.approvals.push(value);

      this.store.dispatch(new SalesOfferActions.UpdateOfferAction(this.offer));
      this.store.dispatch(new OfferActions.UpdatedOfferAction(this.offer));

      if(this.overFollowupProposal) {
        this.acceptEventEmitter.emit(true);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  updateFollowupProposalStatus(b: boolean) {
    this.acceptEventEmitter.emit(b);
  }
}
