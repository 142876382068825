@if (offer) {
  <div id="{{ 'offer' + offer.id }}" class="offer rounded container-fluid">
    <div
      [ngClass]="{'opacity-50': userState?.userInfo?.accredited === false && offer.channel.data === 'PIA'}">
      <div class="px-2 pb-lg-2 pt-2 row">
        <div class="col-auto px-0">
          <div class="image">
            <ucs-bundle-collage [images]="imageUrls" [amount]="offer.items.length"
            [iconSize]="20" class="d-block d-sm-none"></ucs-bundle-collage>
            <ucs-bundle-collage [images]="imageUrls" [amount]="offer.items.length"
            [iconSize]="25" class="d-none d-sm-block d-lg-none"></ucs-bundle-collage>
            <ucs-bundle-collage [images]="imageUrls" [amount]="offer.items.length"
            [iconSize]="30" class="d-none d-lg-block"></ucs-bundle-collage>
          </div>
        </div>
        <div class="col ps-1 ps-sm-3">
          <div class="row height-40">
            <div class="col model-name link-title" routerLink="/offer/{{offer.id}}" (click)="storeBacklink()"
              data-cy="offer-component.offer-title">
              <strong>{{ offer.title }}</strong>
            </div>
            <div class="ms-auto col-auto px-0 fav">
              @if (enableNote) {
                <span class="d-inline-block cursor-pointer pe-3">
                  <ucs-icon class="pe-1" [shape]="'feather/edit-2'" [size]="24" [class]="'note feather-blue'"
                  (click)="showNoteInput(); favNoteElement.focus()"></ucs-icon>
                  <strong class="d-none d-md-inline"
                    (click)="showNoteInput(); favNoteElement.focus()">{{ 'offer-item.note' | translate }}
                  </strong>
                </span>
              }
              @if (!userState.isGerman && vehicle?.infoText && vehicle.infoText !== '') {
                <ucs-info-icon
                [infoText]="vehicle.infoText"></ucs-info-icon>
              }
              <ucs-offer-bookmarking [offer]="offer"></ucs-offer-bookmarking>
            </div>
          </div>
          <div class="row d-block height-40 location">
            <span class="bundle-summary">
              {{ 'offer-item.package' | translate }}: {{ offer.items.length }} {{ 'offer-item.vehicles' | translate }}
            </span>
          </div>
          <div class="row">
            @for (item of uniqueBundleVehicles; track item; let i = $index) {
              @if (i < 4 || showAllVehicles) {
                <div class="col-lg-6 col-12 height-30">
                  {{ item.key + ' (' + item.value + ')' }}
                </div>
              }
            }
          </div>
          @if (uniqueBundleVehicles.length > 4) {
            <div class="row technical height-30">
              <div class="col">
                @if (!showAllVehicles) {
                  <a (click)="showAllVehicles = !showAllVehicles">
                    {{ '+' + (uniqueBundleVehicles.length - 4) + ' ' + ('offer-item.more' | translate) }}
                  </a>
                }
                @if (showAllVehicles) {
                  <a (click)="showAllVehicles = !showAllVehicles">
                    {{ '-' + (uniqueBundleVehicles.length - 4) + ' ' + ('offer-item.less' | translate) }}
                  </a>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
    <div class="row justify-content-between footer rounded-bottom bundle">
      <div class="col-12 col-md-4 text-start">
        <span class="amount">
          @if (offer?.offerType?.data === 'AUCTION') {
            <div
            class="highest-bid">{{ 'offer-item.highest-bid' | translate }}</div>
          }
          <strong>{{ offer.offerType.data === 'AUCTION' ?
            (offer.highestBid.net | price: offer.highestBid.currency.data) :
          (offer.price.net | price: offer.price.currency.data) }}</strong>
          <span class="gross-net d-inline-block font-weight-light ps-2">
          {{ 'api.type.pricetype.NET' | translate }}</span>
        </span>
      </div>
      @if (offer?.status?.data==='ACTIVE') {
        <div class="ms-auto col-auto buy">
          @if (offer.offerType.data === 'AUCTION') {
            <span class="d-inline-block ps-2 pe-3 duration">
              <ucs-countdown [expirationDate]="offer.expiration"></ucs-countdown>
            </span>
          }
          @if (offer.eligible && !offer?.purchased && ['ACTIVE', 'EXTENDED'].includes(offer.status.data)) {
            <ucs-bid-buy
              [offerId]="offer.id" [offerType]="offer.offerType.data"
            [inputPrice]="offer.offerType.data === 'AUCTION' ? offer.minimumBid.net : offer.price.net"></ucs-bid-buy>
          }
        </div>
      }
      <div class="ms-auto col-auto buy">
        @if (offer.offerType.data === 'BUYNOW'
          && offer.purchase?.buyingDealer?.id === userState.userInfo.currentDealerId) {
          <ucs-buy-now-purchase-status
          [offerStatus]="offer?.status?.data"></ucs-buy-now-purchase-status>
        }
      </div>
      @if (offer.offerType.data === 'BUYNOW') {
        <ucs-buy-now-dialog [offer]="offer"
        (newOfferStatus)="offer.status.data = $event"></ucs-buy-now-dialog>
      }
      @if (offer.offerType.data === 'AUCTION') {
        <ucs-bid-dialog [offer]="offer"
        (isOfferExpired)="isExpired = $event"></ucs-bid-dialog>
      }
      <ucs-thermal-window-dialog [offer]="offer"></ucs-thermal-window-dialog>
    </div>
    <div class="favnote d-none" (blur)="saveNote()" contenteditable #favNoteElement></div>
  </div>
}
