import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import {SystemSettingsService} from '../../service/system-settings.service';
import {OfferService} from '../../service/offer.service';
import {SpinnerService} from '../../service/spinner.service';
import {
  UpdatedFinishedUserCompanyOffersAllUcAction,
  UpdatedFinishedUserCompanyOffersDinBilAction,
  UpdatedFinishedUserCompanyOffersPbAction,
  UpdatedFinishedUserCompanyOffersPiaAction,
  UpdatedFinishedUserCompanyOffersVgrxAction
} from '../../store/offer/offer.actions';
import {UpdateFinishedSearchParamsAction} from '../../store/search/search.actions';
import {compareSets} from '../../misc/utils';
import {ArchiveSearchService} from '../../service/archive-search.service';
import {PageSettings} from '../../model/page-settings.model';

/**
 * The search filter sidebar component indirectly (via redux-store) orchestrates the result list of offers
 */
@Component({
  selector: 'ucs-search-filter-finished',
  templateUrl: './search-filter-finished.component.html',
  styleUrls: ['./search-filter-finished.component.scss']
})
export class SearchFilterFinishedComponent implements OnInit, OnDestroy {
  @Input() selectedDistributionChannel: DistributionChannel;
  @Output() hideSidebar = new EventEmitter();
  @Output() updateFilter = new EventEmitter();
  @Output() directSearch = new EventEmitter();
  @ViewChild('toggleButtonSingleBundle', {static: true}) toggleButtonSingleBundle;
  @ViewChild('toggleAuctionBuyNow', {static: true}) toggleAuctionBuyNow;

  // create an instance of VehicleOfferSearchParams and set all values to undefined initially
  searchParams: VehicleOfferSearchParams;
  finishedSearchParams: VehicleOfferSearchParams;
  allPurchasesDisabled: boolean;
  highestBidDisabled: boolean;
  surchargeReceivedDisabled: boolean;
  onlyOfferedDisabled: boolean;
  allPurchasesColor: string;
  highestBidColor: string;
  onlyOfferedColor: string;
  purchaseNotReceievedDisabled: boolean;
  purchaseNotReceivedColor: string;
  pageSettings: PageSettings;
  private offerDtoReturnValue: any;
  private searchString: string;
  private unsubscribe: Subject<void> = new Subject<void>();
  private allButtonOfferTypes: OfferType[] = ['AUCTION', 'BUYNOW'];

  constructor(
    private store: Store<fromRoot.AppState>,
    private systemSettingsService: SystemSettingsService,
    private spinnerService: SpinnerService,
    private offerService: OfferService,
    private archiveSearchService: ArchiveSearchService) {
  }

  /**
   * When initializing the component, update aggregations and subscribe to changes of search params
   */
  ngOnInit() {
    this.allPurchasesDisabled = false;
    this.highestBidDisabled = false;
    this.surchargeReceivedDisabled = false;
    this.onlyOfferedDisabled = false;
    this.purchaseNotReceievedDisabled = false;
    this.allPurchasesColor = 'blue';
    this.highestBidColor = 'blue';
    this.onlyOfferedColor = 'blue';
    this.purchaseNotReceivedColor = 'blue';

    this.store.select(fromRoot.getSearchState)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(searchState => {
        this.finishedSearchParams = searchState.finishedSearchParams;
        if (!this.finishedSearchParams.bundling) {
          this.finishedSearchParams.bundling = 'SINGLE';
          this.finishedSearchParams.allPurchases = true;
          this.finishedSearchParams.onlyOffered = true;
          this.finishedSearchParams.highestBid = true;
          this.finishedSearchParams.purchaseNotReceived = true;
        }
        this.updateSelections(); // update search filter selections with the values received from store
      });

    this.store.dispatch(new UpdateFinishedSearchParamsAction(this.finishedSearchParams));
  }

  /**
   * Unsubscribe from Observables when component is destroyed to prevent memory leaks
   */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Updates all selected values in search filter with the current finishedSearchParams object
   */
  updateSelections() {
    if (this.toggleButtonSingleBundle) {
      this.toggleButtonSingleBundle.overrideSelection(this.finishedSearchParams.bundling);
    }
    if (this.toggleAuctionBuyNow) {
      // undefined means, both auction and buyNow are active
      if (this.finishedSearchParams.bundling === 'SINGLE') {
        if (compareSets(this.finishedSearchParams.types, this.allButtonOfferTypes)
          || this.finishedSearchParams.types === null
          || this.finishedSearchParams.types === undefined
          || this.finishedSearchParams.types.length === 0) {
          this.toggleAuctionBuyNow.setFilter('ALL');
        } else {
          this.toggleAuctionBuyNow.setFilter(this.finishedSearchParams.types?.toString());
        }
      } else {
        this.toggleAuctionBuyNow.toggleForBundle();
      }
    }
  }

  /**
   * Trigger an update in the store to fetch from backend and bind to the results of the update in the store
   */
  updateSearchParams() {
    this.updateFilter.emit();
    // save updated search params
    this.store.dispatch(new UpdateFinishedSearchParamsAction(this.finishedSearchParams));
  }

  /**
   * When input field for is changed, after string length of >5 the list is updated
   * @param $event the change value
   */
  onChangeSearchString($event) {
    this.searchString = $event;
    this.search(this.searchString);
  }

  /**
   * When the enter key was released a search is started on any three digits long string
   * @param $event the change value
   */
  onKeyUpEnter($event) {
    if ($event && $event.toString().trim().length >= 3) {
      this.searchString = $event;
      this.search(this.searchString);
    }
  }

  /**
   * Emits to parent component that sidebar is to be hidden
   */
  onToggleSidebar() {
    this.hideSidebar.emit();
  }

  /**
   * Handles checkbox for All Buyer
   */
  onChangeAllPurchasesSale() {
    this.finishedSearchParams.allPurchases = !this.finishedSearchParams.allPurchases;
    this.updateSearchParams();
  }

  /**
   * Handles checkbox for Highest bid
   */
  onChangeHighestBidSale() {
    this.finishedSearchParams.highestBid = !this.finishedSearchParams.highestBid;
    this.updateSearchParams();
  }

  /**
   * Handles checkbox for Only offered
   */
  onChangeOnlyOfferedSale() {
    this.finishedSearchParams.onlyOffered = !this.finishedSearchParams.onlyOffered;
    this.updateSearchParams();
  }

  onChangePurchaseNotReceieved() {
    this.finishedSearchParams.purchaseNotReceived = !this.finishedSearchParams.purchaseNotReceived;
    this.updateSearchParams();
  }

  /**
   * updates search param and dispatch update in redux store
   */
  onChangeSingleBundle() {
    this.finishedSearchParams.bundling = this.toggleButtonSingleBundle.activeLeft ? 'SINGLE' : 'BUNDLE';
    this.updateTypesBasedOnBundling();
    this.handleSaleChangesBasedOnBundling();
    this.updateSearchParams();
  }

  private updateTypesBasedOnBundling() {
    if (this.toggleButtonSingleBundle.activeLeft) {
      this.finishedSearchParams.types = this.allButtonOfferTypes;
    } else {
      this.finishedSearchParams.types = ['BUYNOW'];
    }
  }

  private handleSaleChangesBasedOnBundling() {
    const isActiveLeft = this.toggleButtonSingleBundle.activeLeft;

    if (isActiveLeft) {
      this.invokeChangeAllPurchases(true);
      this.invokeChange('onlyOffered', this.onChangeOnlyOfferedSale, false, 'blue');
      this.invokeChange('highestBid', this.onChangeHighestBidSale, false, 'blue');
      this.invokeChange('purchaseNotReceived', this.onChangePurchaseNotReceieved, false, 'blue');
    } else {
      this.invokeChangeAllPurchases(false);
      this.invokeChange('highestBid', this.onChangeHighestBidSale, true, 'grey');
      this.invokeChange('onlyOffered', this.onChangeOnlyOfferedSale, true, 'grey');
      this.invokeChange('purchaseNotReceived', this.onChangePurchaseNotReceieved, true, 'grey');
    }
  }

  private invokeChange(property: string, method: () => void, disable: boolean, color: string) {
    const condition = disable ? this.finishedSearchParams[property] : !this.finishedSearchParams[property];
    if (condition) {
      method.call(this);
      this.updatePropertyState(property, disable, color);
    }
  }

  private invokeChangeAllPurchases(expectAllPurchases: boolean) {
    const condition = this.finishedSearchParams.allPurchases === expectAllPurchases;
    if (condition) {
      this.onChangeAllPurchasesSale();
    }
  }

  private updatePropertyState(property: string, disabled: boolean, color: string) {
    this[`${property}Disabled`] = disabled;
    this[`${property}Color`] = color;
  }

  /**
   * updates search param and dispatch update in redux store
   */
  onChangeAuctionBuyNow($event: any) {
    this.setFinishedSearchParamsType($event);
    this.handleSaleChanges();
    this.updateSearchParams();
  }

  private handleSaleChanges() {
    const isUndefined = this.finishedSearchParams.types === this.allButtonOfferTypes;
    const isAuction = this.finishedSearchParams.types.includes('AUCTION');
    const isBuyNow = this.finishedSearchParams.types.includes('BUYNOW');

    if (!this.finishedSearchParams.allPurchases && (isUndefined || isBuyNow) || this.finishedSearchParams.allPurchases && isAuction) {
      this.onChangeAllPurchasesSale();
    }

    this.handleConditionalSaleChange(isBuyNow, this.finishedSearchParams.highestBid, 'onChangeHighestBidSale', 'grey');
    this.handleConditionalSaleChange(isBuyNow, this.finishedSearchParams.onlyOffered, 'onChangeOnlyOfferedSale', 'grey');
    this.handleConditionalSaleChange(isBuyNow, this.finishedSearchParams.purchaseNotReceived, 'onChangePurchaseNotReceived', 'grey');

    this.handleConditionalSaleChange(!this.finishedSearchParams.highestBid && (isAuction || isUndefined), true, 'onChangeHighestBidSale', 'blue');
    this.handleConditionalSaleChange(!this.finishedSearchParams.onlyOffered && (isAuction || isUndefined), true, 'onChangeOnlyOfferedSale', 'blue');
    this.handleConditionalSaleChange(!this.finishedSearchParams.purchaseNotReceived && (isAuction || isUndefined), true, 'onChangePurchaseNotReceived', 'blue');
  }

  private handleConditionalSaleChange(condition: boolean, flag: boolean, changeMethodName: string, color: 'grey' | 'blue') {
    if (condition) {
      this[changeMethodName](); // dynamically call the method based on the name
      this.updateSalePropertyStatus(flag, color, changeMethodName);
    }
  }

  private updateSalePropertyStatus(flag: boolean, color: string, changeMethodName: string) {
    let propertyBaseName = changeMethodName.replace('onChange', '').replace('Sale', '');
    propertyBaseName = propertyBaseName.charAt(0).toLowerCase() + propertyBaseName.slice(1);

    this[`${propertyBaseName}Disabled`] = !flag;
    this[`${propertyBaseName}Color`] = color;
  }

  /**
   * set search params for auction or buy now toggle
   */
  private setFinishedSearchParamsType($event: any) {
    if ($event === 'ALL' && this.finishedSearchParams.bundling === 'SINGLE') {
      this.finishedSearchParams.types = this.allButtonOfferTypes;
    } else {
      this.finishedSearchParams.types = [$event];
    }
  }

  /**
   * Emits search term to parent.
   * @param term the search term
   */
  search(term: string) {
    this.directSearch.emit(term);
    this.spinnerService.spinner(
      this.offerService.getOffersByIdentificationMatch(this.selectedDistributionChannel, term)
        .pipe(
          map(resultOfferDto => {

            this.offerDtoReturnValue = resultOfferDto as OfferDto[];
            if (this.offerDtoReturnValue.content.length === 0) {
              // directSearchField 'VIN' will be ignored in backend
              this.pageSettings = new PageSettings();
              this.pageSettings.channel = this.selectedDistributionChannel;
              this.pageSettings.size = 25;
              this.pageSettings.page = 0;
              this.spinnerService.spinner(
                this.archiveSearchService.getByDirectSearchField(this.searchString, 'VIN', 'OFFER', this.pageSettings)
              ).subscribe(resultArchiveSearch => {
                this.updateFinishedUserCompanyOffers(resultArchiveSearch);
              });
            } else {
              this.updateFinishedUserCompanyOffers(resultOfferDto);
            }
          })
        )
    ).subscribe();
  }
  updateFinishedUserCompanyOffers(resultArchiveSearch: OfferDto[]) {
    switch (this.selectedDistributionChannel) {
    case 'ALL_UC':
      this.store.dispatch(new UpdatedFinishedUserCompanyOffersAllUcAction(resultArchiveSearch));
      break;
    case 'PB':
      this.store.dispatch(new UpdatedFinishedUserCompanyOffersPbAction(resultArchiveSearch));
      break;
    case 'PIA':
      this.store.dispatch(new UpdatedFinishedUserCompanyOffersPiaAction(resultArchiveSearch));
      break;
    case 'DIN_BIL':
      this.store.dispatch(new UpdatedFinishedUserCompanyOffersDinBilAction(resultArchiveSearch));
      break;
    case 'VGRX':
      this.store.dispatch(new UpdatedFinishedUserCompanyOffersVgrxAction(resultArchiveSearch));
      break;
    }
  }
}
