<div class="filter-backdrop" (click)="onToggleSidebar()" ></div>
<div class="container-fluid search-filter-container sidebar pb-2">

  <!-- the ng-container with ngIf loads when data is received and automatically unsubscribe store selections via async pipe -->
  @if (searchParams && searchAggregation &&
    (searchAggregation$ && searchState$ && pageSettingsDistributionChannel$) | async) {
    <!--toggle-button-sidebar-->
    <div class="row box mb-0 pt-2 sticky-top position-relative-md bg-white">
      <div class="col-12 mt-2 mb-2">
        <button class="button-borderless w-100 d-flex justify-content-between icon-height"
          (click)="onToggleSidebar()">
          {{'search-filter.hide' | translate}}
          <div class="d-inline-flex">
            <ucs-icon [size]="24" [shape]="'search'" [class]="'blue'"></ucs-icon>
            <ucs-icon [size]="16" [shape]="'double_angle_left'" class="me-1 blue"></ucs-icon>
          </div>
        </button>
      </div>
    </div>
    <div class="row mt-0">
      <div class="col-8 mt-3 pe-0">
        <button class="btn button w-100" data-bs-toggle="modal"
          data-bs-target="#saveSearchModal">
          <ucs-icon [shape]="'save'" [class]="'blue'"></ucs-icon>
          <span><strong>{{'ucs.save' | translate}}</strong></span>
        </button>
      </div>
      <div class="col-4 mt-3 ps-3" [floatUi]="'quick-search.delete-tooltip' | translate" [showTrigger]="NgxFloatUiTriggers.hover">
        <button class="btn button w-100"
                (click)="onClearSearchParams();
                  dropdownRangePrice.clear();
                  dropdownRangePower.clear();
                  dropdownRangeMileage.clear();
                  dropdownRangeInitialRegistration.clear();
                  searchLocation.clear();
                  searchCombinedDealer.deselectAll();
                  searchEquipment.clear();
                  dropdownCo2Emission.clear();
                  dropdownRangeMotorCapacity.clear();
                  dropdownConsumption.clear();
                  dropdownSalesTax.clear();
                  toggleButtonSingleBundle.clear();
                  toggleAuctionBuyNow.clear();
                  inputExtVehicleId.clear();
                  inputExtDealerId.clear();
                  inputVin.clear();">
          <ucs-icon [shape]="'delete'" [class]="'blue'"></ucs-icon>
        </button>
      </div>
      <ucs-save-dialog [parent]="'searchresults'" [search]="searchParams | cast : UserVehicleSearchParamsRequestDto"></ucs-save-dialog>
    </div>
    <!-- Single - Bundle -->
    @if (!isPiaAuctionScope()) {
      <ucs-search-toggle-button #toggleButtonSingleBundle
        [id]="'toggleButtonSingleBundle'"
        [label]="'SingleBundle'"
        [activeLeft]=true
        [activeRight]=false
        [labelLeft]="'quick-search.onlySingleVehicle' | translate"
        [labelRight]="'quick-search.onlyPackets' | translate"
        (rightClicked)="toggleAuctionBuyNow.toggleForBundle(); onChangeSingleBundle();"
        (leftClicked)="toggleAuctionBuyNow.clear(); onChangeSingleBundle();"
        data-cy="search-filter.component.bnt-singleBundle"
        >
      </ucs-search-toggle-button>
    }
    <!-- Auction - Buy now -->
    @if (!isPiaAuctionScope()) {
      <ucs-search-toggle-auction-buynow #toggleAuctionBuyNow
        id="search-filter.offer-type-toggle"
        data-cy="search-filter-offer-type-toggle"
        (toggleChanged)="onChangeAuctionBuyNow($event)"
        data-cy="search-filter.component.bnt-auctionBuynow"
        >
      </ucs-search-toggle-auction-buynow>
    }
    <div class="row mt-3">
      <div class="col-12">
        <hr class="hr mb-0">
      </div>
    </div>
    @if (!isPiaAuctionScope()) {
      <ucs-search-multi-select #dropDownChannel
        [id]="'search-filter.channel'"
        [placeholder]="'search-dropdown.placeholder' | translate"
        [label]="'search-filter.channel' | translate"
        [states]="searchAggregation.channels"
        (onSelected)="onChangeSearchParamChannels($event)"
        data-cy="search-filter-dropdown-channels">
      </ucs-search-multi-select>
    }
    <ucs-search-multi-select #dropdownCountry
      [id]="'dropdownCountry'"
      [placeholder]="'search-dropdown.placeholder' | translate"
      [label]="'search-filter.country' | translate"
      [states]="searchAggregation.countries"
                             (onSelected)="onChangeSearchParamCountry($event);
                                                    searchLocation.clear()"
      data-cy="search-filter-dropdown-country">
    </ucs-search-multi-select>
    <div class="row mt-3">
      <div class="col-12">
        <hr class="hr mb-0">
      </div>
    </div>
    <!-- search vehicles filters -->
    <ucs-search-offer-variant [horizontal]="false"
      (onSearchParamsChanged)="onChangeVehiclesSearchParams($event)">
    </ucs-search-offer-variant>
    <div class="row mt-3">
      <div class="col-12">
        <hr class="hr mb-0">
      </div>
    </div>
    <!-- further options -->
    <ucs-search-range-dropdown #dropdownRangePrice
      [id]="'dropdownRangePrice'"
      [showUntil]="false"
      [label]="('search-filter.price' | translate) + ' ('+('api.type.pricetype.NET' | translate)+')'"
      [statesFrom]="searchAggregation.pricesFrom"
      [statesTo]="searchAggregation.pricesTo"
      (onSelectedFrom)="onChangeSearchParamPriceFrom($event)"
      (onSelectedTo)="onChangeSearchParamPriceTo($event)">
    </ucs-search-range-dropdown>
    <ucs-search-range-dropdown #dropdownRangePower
      [id]="'dropdownRangePower'"
      [showUntil]="false"
      [label]="'search-filter.performance' | translate"
      [statesFrom]="searchAggregation.powersFrom"
      [statesTo]="searchAggregation.powersTo"
      (onSelectedFrom)="onChangeSearchParamPowerFrom($event)"
      (onSelectedTo)="onChangeSearchParamPowerTo($event)">
    </ucs-search-range-dropdown>
    <ucs-search-range-dropdown #dropdownRangeMileage
      [id]="'dropdownRangeMileage'"
      [showUntil]="false"
      [label]="'search-filter.mileage' | translate"
      [statesFrom]="searchAggregation.mileagesFrom"
      [statesTo]="searchAggregation.mileagesTo"
      (onSelectedFrom)="onChangeSearchParamMileageFrom($event)"
      (onSelectedTo)="onChangeSearchParamMileageTo($event)">
    </ucs-search-range-dropdown>
    <ucs-search-range-dropdown #dropdownRangeInitialRegistration
      [id]="'dropdownRangeInitialRegistration'"
      [showUntil]="false"
      [label]="'search-filter.first-registration-date' | translate"
      [statesFrom]="searchAggregation.initialRegistrationFrom"
      [statesTo]="searchAggregation.initialRegistrationTo"
      (onSelectedFrom)="onChangeSearchParamRegistrationFrom($event)"
      (onSelectedTo)="onChangeSearchParamRegistrationTo($event)">
    </ucs-search-range-dropdown>
    <ucs-search-multi-select #dropdownFuel
      [id]="'dropdownFuel'"
      [placeholder]="'search-dropdown.placeholder' | translate"
      [label]="'search-filter.fuel' | translate"
      [states]="searchAggregation.fuels"
      (onSelected)="onChangeSearchParamFuel($event)"
      data-cy="search-filter-dropdown-fuel">
    </ucs-search-multi-select>
    <div class="row mt-3">
      <div class="col-12">
        <hr class="hr mb-0">
      </div>
    </div>
    <ucs-search-location #searchLocation
      [id]="'searchLocation'"
      [placeholder]="'search-dropdown.placeholder' | translate"
      [label]="'search-filter.location-title-alt' | translate"
                         (onSelected)="onChangeSearchParamPostcodeCity($event);
                                       dropdownCountry.overrideSelection($event)">
    </ucs-search-location>
    <ucs-search-multi-select #searchCombinedDealer
      [id]="'search-filter.combined-dealer'"
      [placeholder]="'search-dropdown.placeholder' | translate"
      [label]="'search-filter.selling-dealer' | translate"
      [states]="searchAggregation.combinedSellingDealers"
      (onSelected)="onChangeSearchParamCombinedDealer($event);"
      data-cy="quick-search-component-dropdown-combined-dealer">
    </ucs-search-multi-select>
    <div class="row mt-3">
      <div class="col-12">
        <hr class="hr mb-0">
      </div>
    </div>
    <!-- popular equipment -->
    <div class="mt-3 mb-2 text">
      <strong>{{'search-filter.popular-equipment' | translate}}</strong>
    </div>
    <div class="mb-3">
      <a>
        <ucs-icon (click)="onChangeSearchParamFourWD()"
          [shape]="searchParams.fourWD ? 'square_checked' : 'square_outline'"
        [class]="'blue me-1'"></ucs-icon>
      </a>
      <span class="text text-bright me-1 ucs-input-label"
      (click)="onChangeSearchParamFourWD()">{{ 'quick-search.all-wheel' | translate }}</span>
      <ucs-icon [shape]="'awd'" [size]="16" [class]="'grey opacity-05'"></ucs-icon>
      <span class="text text-bright ms-1">
        ({{searchAggregation.fourWD !== null ? searchAggregation.fourWD.count : 0}})
      </span>
    </div>
    <div class="mb-3">
      <a>
        <ucs-icon (click)="onChangeSearchParamTrailerHitch()"
          [shape]="searchParams.trailerHitch ? 'square_checked' : 'square_outline'"
        [class]="'blue me-1'"></ucs-icon>
      </a>
      <span class="text text-bright me-1 ucs-input-label"
      (click)="onChangeSearchParamTrailerHitch()">{{ 'quick-search.tow-hitch' | translate }}</span>
      <ucs-icon [shape]="'tow_hitch'" [size]="16" [class]="'grey opacity-05'"></ucs-icon>
      <span class="text text-bright ms-1">
        ({{searchAggregation.trailerHitch !== null ? searchAggregation.trailerHitch.count : 0}})
      </span>
    </div>
    <div class="mb-3">
      <a>
        <ucs-icon (click)="onChangeSearchParamAutomaticTransmission()"
          [shape]="searchParams.automaticTransmission ? 'square_checked' : 'square_outline'"
        [class]="'blue me-1'"></ucs-icon>
      </a>
      <span class="text text-bright me-1 ucs-input-label"
      (click)="onChangeSearchParamAutomaticTransmission()">{{ 'quick-search.automatic' | translate }}</span>
      <ucs-icon [shape]="'automatic_transmission'" [size]="16" [class]="'grey opacity-05'"></ucs-icon>
      <span class="text text-bright ms-1">
        ({{searchAggregation.automaticTransmission !== null ? searchAggregation.automaticTransmission.count : 0}})
      </span>
    </div>
    <div class="mb-3">
      <a>
        <ucs-icon (click)="onChangeSearchParamLeatherSeats()"
          [shape]="searchParams.leatherSeats ? 'square_checked' : 'square_outline'"
        [class]="'blue me-1'"></ucs-icon>
      </a>
      <span class="text text-bright me-1 ucs-input-label"
      (click)="onChangeSearchParamLeatherSeats()">{{ 'quick-search.leather-seats' | translate }}</span>
      <ucs-icon [shape]="'leather'" [size]="16" [class]="'grey opacity-05'"></ucs-icon>
      <span class="text text-bright ms-1">
        ({{searchAggregation.leatherSeats !== null ? searchAggregation.leatherSeats.count : 0}})
      </span>
    </div>
    <div class="mb-3">
      <a>
        <ucs-icon (click)="onChangeSearchParamNavigation()"
          [shape]="searchParams.navigation ? 'square_checked' : 'square_outline'"
        [class]="'blue me-1'"></ucs-icon>
      </a>
      <span class="text text-bright me-1 ucs-input-label"
      (click)="onChangeSearchParamNavigation()">{{ 'quick-search.navigation' | translate }}</span>
      <ucs-icon [shape]="'sat_nav'" [size]="16" [class]="'grey opacity-05'"></ucs-icon>
      <span class="text text-bright ms-1">
        ({{searchAggregation.navigation !== null ? searchAggregation.navigation.count : 0}})
      </span>
    </div>
    <div class="mb-3">
      <a>
        <ucs-icon (click)="onChangeSearchParamHeatedSeats()"
          [shape]="searchParams.heatedSeats ? 'square_checked' : 'square_outline'"
        [class]="'blue me-1'"></ucs-icon>
      </a>
      <span class="text text-bright me-1 ucs-input-label"
      (click)="onChangeSearchParamHeatedSeats()">{{ 'quick-search.seat-heating' | translate }}</span>
      <ucs-icon [shape]="'seat_heating'" [size]="16" [class]="'grey opacity-05'"></ucs-icon>
      <span class="text text-bright ms-1">
        ({{searchAggregation.heatedSeats !== null ? searchAggregation.heatedSeats.count : 0}})
      </span>
    </div>
    <div class="mb-3">
      <a>
        <ucs-icon (click)="onChangeSearchParamXenonLedLight()"
          [shape]="searchParams.xenonLedLight ? 'square_checked' : 'square_outline'"
        [class]="'blue me-1'"></ucs-icon>
      </a>
      <span class="text text-bright me-1 ucs-input-label"
      (click)="onChangeSearchParamXenonLedLight()">{{ 'quick-search.light' | translate }}</span>
      <ucs-icon [shape]="'light_xenon'" [size]="16" [class]="'grey opacity-05 me-1'"></ucs-icon>
      <ucs-icon [shape]="'light_LED'" [size]="16" [class]="'grey opacity-05'"></ucs-icon>
      <span class="text text-bright ms-1">
        ({{searchAggregation.xenonLedLight !== null ? searchAggregation.xenonLedLight.count : 0}})
      </span>
    </div>
    <div class="row mt-2">
      <div class="col-12 mt-2">
        <button id="search-filter.more-option"
          data-cy="search-filter-more-option"
          class="button-borderless w-100 d-flex justify-content-between icon-height"
          (click)="onToggleDetailedSearchOptions()">
          @if (detailedSearchOptions) {
            {{'search-filter.less-options' | translate}}
            <ucs-icon [shape]="'angle_up'" [class]="'blue'"></ucs-icon>
          } @else {
            {{'search-filter.more-options' | translate}}
            <ucs-icon [shape]="'angle_down'" [class]="'blue'"></ucs-icon>
          }
          <ng-template #quickSearch>
            {{'search-filter.more-options' | translate}}
            <ucs-icon [shape]="'angle_down'" [class]="'blue'"></ucs-icon>
          </ng-template>
        </button>
      </div>
    </div>
    <div [ngClass]="{'d-none': !detailedSearchOptions}">
      <div class="row">
        <div class="col-12">
          <!-- this blocked is removed for now visually, see [UCSDEV-301] -->
          <div class="d-none">
            <!-- Vehicle related numbers {{{ -->
            <ucs-simple-input #inputExtDealerId
              [id]="'inputExtDealerId'"
              [placeholder]="'search-dropdown.placeholder' | translate"
              [label]="'search-filter.extDealerId' | translate"
              (onInput)="onChangeInputExtDealerId($event);">
            </ucs-simple-input>
            <ucs-simple-input #inputExtVehicleId
              [id]="'inputExtVehicleId'"
              [placeholder]="'search-dropdown.placeholder' | translate"
              [label]="'search-filter.extVehicleId' | translate"
              (onInput)="onChangeInputExtVehicleId($event);">
            </ucs-simple-input>
            <ucs-simple-input #inputVin
              [id]="'inputVin'"
              [placeholder]="'search-dropdown.placeholder' | translate"
              [label]="'search-filter.vin' | translate"
              (onInput)="onChangeInputVin($event);">
            </ucs-simple-input>
            <!-- }}} -->
            <div class="row mt-3">
              <div class="col-12">
                <hr class="hr mb-0">
              </div>
            </div>
          </div>
          <!-- Chassis -->
          <ucs-search-multi-select #dropdownChassis
            [id]="'dropdownChassis'"
            [placeholder]="'search-dropdown.placeholder' | translate"
            [label]="'search-filter.chassis' | translate"
            [states]="searchAggregation.chassis"
            (onSelected)="onChangeSearchParamChassis($event)"
            data-cy="search-filter-dropdown-chassis">
          </ucs-search-multi-select>
          <!-- Seats -->
          <ucs-search-multi-select #dropdownSeats
            [id]="'dropdownSeats'"
            [placeholder]="'search-dropdown.placeholder' | translate"
            [label]="'search-filter.seats' | translate"
            [states]="searchAggregation.seats"
            (onSelected)="onChangeSearchParamSeats($event)"
            data-cy="search-filter-dropdown-seats">
          </ucs-search-multi-select>
          <!-- Colors -->
          <ucs-search-multi-select #dropdownColor
            [id]="'dropdownColor'"
            [placeholder]="'search-dropdown.placeholder' | translate"
            [label]="'search-filter.color' | translate"
            [states]="searchAggregation.colors"
            (onSelected)="onChangeSearchParamColor($event)"
            data-cy="search-filter-dropdown-color">
          </ucs-search-multi-select>
          <!-- Gear -->
          <ucs-search-multi-select #dropdownGear
            [id]="'dropdownGear'"
            [placeholder]="'search-dropdown.placeholder' | translate"
            [label]="'search-filter.gear' | translate"
            [states]="searchAggregation.gearTypes"
            (onSelected)="onChangeSearchParamGear($event)"
            data-cy="search-filter-dropdown-gear">
          </ucs-search-multi-select>
          <div class="row mt-3">
            <div class="col-12">
              <hr class="hr mb-0">
            </div>
          </div>
          <!-- Motor Capacity -->
          <ucs-search-range-dropdown #dropdownRangeMotorCapacity
            [id]="'dropdownRangeMotorCapacity'"
            [showUntil]="false"
            [label]="'search-filter.motorCapacity' | translate"
            [statesFrom]="searchAggregation.motorCapacitiesFrom"
            [statesTo]="searchAggregation.motorCapacitiesTo"
            (onSelectedFrom)="onChangeSearchParamMotorCapacityFrom($event)"
            (onSelectedTo)="onChangeSearchParamMotorCapacityTo($event)">
          </ucs-search-range-dropdown>
          <!-- Consumption -->
          <ucs-search-range-dropdown #dropdownConsumption
            [id]="'search-filter.consumption'"
            [showUntil]="false"
            [label]="'search-filter.consumption' | translate"
            [statesFrom]="searchAggregation.consumptionsFrom"
            [statesTo]="searchAggregation.consumptionsTo"
            (onSelectedFrom)="onChangeSearchParamConsumptionFrom($event)"
            (onSelectedTo)="onChangeSearchParamConsumptionTo($event)">
          </ucs-search-range-dropdown>
          <!-- Co2Emission -->
          <ucs-search-dropdown #dropdownCo2Emission
            [id]="'dropdownCo2Emission'"
            [placeholder]="'search-dropdown.placeholder' | translate"
            [label]="'search-filter.co2Emission' | translate"
            [states]="searchAggregation.co2Emissions"
            (onSelected)="onChangeSearchParamCo2Emission($event)">
          </ucs-search-dropdown>
          <!-- EmissionClass -->
          <ucs-search-multi-select #dropdownEmissionClass
            [id]="'dropdownEmissionClass'"
            [placeholder]="'search-dropdown.placeholder' | translate"
            [label]="'search-filter.emissionClass' | translate"
            [states]="searchAggregation.emissionClasses"
            (onSelected)="onChangeSearchParamEmissionClass($event)"
            data-cy="search-filter-dropdown-emission-class">
          </ucs-search-multi-select>
          <!-- Exploitation types -->
          <ucs-search-multi-select #dropdownExploitationTypes
            [id]="'dropdownExploitationTypes'"
            [placeholder]="'search-dropdown.placeholder' | translate"
            [label]="('search-filter.exploitationTypes' | translate)"
            [states]="searchAggregation.exploitationTypes"
            (onSelected)="onChangeSearchParamExploitationTypes($event)"
            data-cy="search-filter-dropdown-exploitation-type">
          </ucs-search-multi-select>
          <!-- SalesTax -->
          <ucs-search-dropdown #dropdownSalesTax
            [id]="'dropdownSalesTax'"
            [placeholder]="'search-dropdown.placeholder' | translate"
            [label]="'search-filter.salesTax' | translate"
            [states]="searchAggregation.vatTypes"
            (onSelected)="onChangeSearchParamVatType($event)">
          </ucs-search-dropdown>
          <div class="row mt-3">
            <div class="col-12">
              <hr class="hr mb-0">
            </div>
          </div>
          <div class="mt-3">
            <a>
              <ucs-icon (click)="onChangeDirectSale()"
                [shape]="searchParams.directSale ? 'square_checked' : 'square_outline'"
              [class]="'blue me-1'"></ucs-icon>
            </a>
            <span class="text text-bright me-1">{{ 'search-filter.directSale' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- all equipment -->
    <ucs-search-equipment #searchEquipment
      [id]="'searchEquipment'"
      [quickSearch]="false"
      [searchAggregation]="searchAggregation"
      (onEquipmentChanged)="onEquipmentChanged($event)">
    </ucs-search-equipment>
  }
</div>
