<button class="w-100 btn btn-secondary" data-bs-toggle="modal" data-bs-target="#surveyAssignmentDialog"
  (click)="openDialog()">
  {{ (surveyRequest ? 'survey-request.button.existing-label' : 'survey-request.button.label') | translate }}
</button>

<div class="modal fade" id="surveyAssignmentDialog" aria-labelledby="surveyAssignmentDialog" aria-hidden="true"
  tabindex="-1"
  role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ 'survey-request.modal.title' | translate }}</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
      </div>
      @if (!!surveyors) {
        <div class="modal-body">
          <div class="row">
            <strong class="col col-12 ms-3 mt-3 mb-1">{{ 'survey-request.modal.choose-surveyor' | translate }}</strong>
          </div>
          @if (surveyors) {
            <div class="d-grid">
              <ul class="list-group ps-3 pe-3">
                @for (surveyor of getSurveyors(); track surveyor.id) {
                  <li class="list-group-item list-group-item-action"
              [ngClass]="{'active': surveyCreation.surveyorId === surveyor.id, 'assigned': surveyor.assigned,
              'locked': surveyor.locked}"
                    (click)="!surveyor.locked && (setSelectedSurveyor(surveyor))">
                    <strong>{{ surveyor.name }}@if (surveyor.linkedToAudaNet) {
                      <span>*</span>
                    }</strong>
                    <span class="badge badge-pill ms-1">{{ surveyor.numberOfWeekAssignments }}</span>
                    <button type="button" data-container="body"
                      [floatUi]="popSurveyor"
                      [showTrigger]="NgxFloatUiTriggers.hover"
                      [placement]="NgxFloatUiPlacements.TOP"
                      [hideOnScroll]="true"
                      >
                      <ucs-icon [shape]="'info'"
                      [class]="surveyCreation.surveyorId === surveyor.id ? 'white' : 'blue'"></ucs-icon>
                    </button>
                    <div class="contract-special-types">
                      {{ loadTranslatedContractTypes(surveyor) }}
                    </div>
                    <float-ui-content  #popSurveyor>
                      <div class="float-ui-content">
                        <div>{{ surveyor.name }}</div>
                        <div>{{ surveyor.address }}</div>
                        <div>{{ surveyor.zipCode + ' ' + surveyor.city }}</div>
                        <div>{{ surveyor.country }}</div>
                        <span><a href="{{ 'mailto:' + surveyor.email }}">{{ surveyor.email }}</a></span>
                        <div>{{ surveyor.phone }}</div>
                        @if (surveyor.locked) {
                          <div>{{ ('survey-request.modal.locked' | translate) + surveyor.lockReason }}</div>
                        }
                      </div>
                    </float-ui-content>
                  </li>
                }
              </ul>
              @if (!showAllSurveyors && !hasAssignedSurveyors) {
                <div class="d-flex justify-content-start">
                  <em class="ms-3">{{ 'survey-request.modal.no-assigned-surveyors' | translate }}</em>
                  @if (isCustodyDealerMaintenanceEnabled) {
                    <div class="col-md-6 ms-2">
                      <a [routerLink]="'/administration/settings/surveyor-maintenance/' + dealerId" data-bs-dismiss="modal">
                        {{ 'settings.surveyor-maintenacne.headline-text' | translate }}
                      </a>
                    </div>
                  }
                </div>
              }
            </div>
          }
          <!--is feature toggle not enabled show this to be removed when toggle 'CUSTODY_DEALER_MAINTENANCE' activated-->
          @if (!isCustodyDealerMaintenanceEnabled) {
            <div class="d-flex">
              @if (!surveyRequest && isUserAT) {
                <div class="ms-auto accordion mt-2 me-3 pe-3">
                  @if (showAllSurveyors) {
                    <button (click)="showAllSurveyors = false" class="button-borderless">
                      <ucs-icon [shape]="'chevron-up'"></ucs-icon>
                    {{ 'survey-request.modal.only-show-assigned-surveyors' | translate }}</button>
                  }
                  @if (!showAllSurveyors) {
                    <button (click)="showAllSurveyors = true" class="button-borderless">
                      <ucs-icon [shape]="'chevron-down'"></ucs-icon>
                    {{ 'survey-request.modal.show-assigned-surveyors' | translate }}</button>
                  }
                </div>
              }
            </div>
          }
          @if (isCustodyDealerMaintenanceEnabled) {
            <div class="d-flex justify-content-between">
              @if (!surveyRequest && isUserAT) {
                <div class="col ms-3 mt-1">
                  @if (showAllSurveyors) {
                    <button (click)="showAllSurveyors = false" class="button-borderless">
                      <ucs-icon [shape]="'chevron-up'"></ucs-icon>
                    {{ 'survey-request.modal.only-show-assigned-surveyors' | translate }}</button>
                  }
                  @if (!showAllSurveyors) {
                    <button (click)="showAllSurveyors = true" class="button-borderless">
                      <ucs-icon [shape]="'chevron-down'"></ucs-icon>
                    {{ 'survey-request.modal.show-assigned-surveyors' | translate }}</button>
                  }
                </div>
              }
              @if (isCustodyDealerMaintenanceEnabled && hasAssignedSurveyors) {
                <div class="ms-auto accordion mt-2 me-3 pe-3">
                  <a [routerLink]="'/administration/settings/surveyor-maintenance/' + dealerId" data-bs-dismiss="modal">
                    {{ 'settings.surveyor-maintenacne.headline-text' | translate }}
                  </a>
                </div>
              }
            </div>
          }
          <div class="row ms-3">
            <div class="col-5 ps-0">
              <ucs-survey-request-detail
                [audanetArea]="false"
                [surveyRequest]=surveyRequest
                [selectedSurveyor]="selectedSurveyor"
                [userCountry]=userCountry
                [contractTypeInfo]="contractTypeInfo">
              </ucs-survey-request-detail>
            </div>
            @if (['CORRECTION_COMPLETE','COMPLETE'].includes(surveyRequest?.status) && isUserAT && infosFromAudanet) {
              <div
                class="col-5 background">
                <ucs-survey-request-detail
                  [audanetArea]="true"
                  [surveyRequest]=surveyRequest
                  [selectedSurveyor]="selectedSurveyor"
                  [userCountry]=userCountry
                  [contractTypeInfo]="contractTypeInfo">
                </ucs-survey-request-detail>
              </div>
            }
          </div>
          <div class="row">
            <div class="col col-12 mt-3 ms-3"><strong>{{ 'survey-request.modal.comment' | translate }}</strong></div>
            <div class="col-8">
              <textarea class="form-control ms-3"
                [(ngModel)]="surveyCreation.comment"
              maxlength="500"></textarea>
            </div>
            @if (surveyCreation?.comment?.length > 0) {
              <span class="ms-3 pt-1">{{surveyCreation.comment.length}} / 500</span>
            }
          </div>
          @if (!['CORRECTION_COMPLETE'].includes(surveyRequest?.status)) {
            <div
              class="email-copy mt-3 mb-3 ps-3 pe-3">
              <ucs-icon (click)="surveyCreation.emailNotification = !surveyCreation.emailNotification"
                [shape]="surveyCreation.emailNotification ? 'square_checked' : 'square_outline'"
              [class]="'blue'"></ucs-icon>
              <strong class="ms-2 label" (click)="surveyCreation.emailNotification = !surveyCreation.emailNotification">
                {{'survey-request.modal.email-notification' | translate}}
              </strong>
            </div>
          }
        </div>
      } @else {
        <div class="modal-body">
          <div class="warning-text">
            <ucs-icon [class]="'no-surveyor-warning-icon'" [shape]="'lock-alert'"></ucs-icon><br/>
            <br>
              <br><span> {{ 'survey-request.modal.no-surveyors-warning-first' | translate}} </span>
              <span><strong> „{{ 'survey-request.modal.no-surveyors-surveyor' | translate}}“  </strong></span>
              <span> {{ 'survey-request.modal.no-surveyors-warning-second' | translate}} </span>
            </div>
            <div class="info-text">
              <ucs-icon [shape]="'info'" [class]="'no-surveyor-info-icon'"></ucs-icon>
              <span>{{ 'survey-request.modal.no-surveyors-info' | translate}}</span>
            </div>
          </div>
        }
        <div class="modal-footer d-flex justify-content-between">
          @if (isUserAT) {
            <div class="justify-content-start">
              <em>{{ 'survey-request.modal.request-via-audanet' | translate}}</em>
            </div>
          }

          <div class="justify-content-end">
            @if (surveyRequest && surveyRequest.status === 'IN_PROGRESS') {
              <div class="btn btn-link urge me-1" (click)="urgeSurveyRequest()">
                <ucs-icon [shape]="'time_clock'" [class]="'blue ms-1 me-1'"
                ></ucs-icon>
                <span>{{ 'survey-request.modal.urge' | translate }}</span>
              </div>
            }
            @if (surveyRequest && surveyRequest.status === 'COMPLETE') {
              <div class="btn btn-link discard me-1" (click)="discardSurvey()">
                <ucs-icon [shape]="'x'" [class]="'blue ms-1 me-1'"></ucs-icon>
                <span>{{ 'survey-request.modal.discard' | translate }}</span>
              </div>
            }

            <button type="button" class="btn btn-secondary me-1 p-2" data-bs-dismiss="modal">
              {{ 'survey-request.modal.button.cancel' | translate }}
            </button>

            @if (!surveyRequest) {
              <button type="button" class="btn btn-primary p-2 me-1" (click)="requestSurvey()"
                data-bs-dismiss="modal" [disabled]="isSurveyButtonDisabled" [hidden]="isSurveyButtonHidden" >
                <strong>{{ 'survey-request.modal.button.request' | translate }}</strong>
              </button>
            }
            @if (surveyRequest && surveyRequest.status === 'IN_PROGRESS') {
              <button
                class="btn btn-primary p-2"
                (click)="cancelSurveyRequest()" data-bs-dismiss="modal">
                <strong>{{ 'survey-request.modal.button.cancel-request' | translate }}</strong>
              </button>
            }
            @if (surveyRequest && surveyRequest.status === 'COMPLETE') {
              <button
                class="btn btn-primary p-2"
                (click)="correctSurvey()" data-bs-dismiss="modal">
                <strong>{{ 'survey-request.modal.button.correct' | translate }}</strong>
              </button>
            }
          </div>

        </div>
      </div>
    </div>
  </div>
