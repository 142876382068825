import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SystemSettingsService} from '../../../service/system-settings.service';
import {State} from '../../../store/user/user.reducers';
import {PushBacklinkAction} from '../../../store/detail-page/detail-page.actions';
import {NgxFloatUiPlacements, NgxFloatUiTriggers} from 'ngx-float-ui';

@Component({
  selector: 'ucs-offer-status-buy-bid',
  templateUrl: './offer-status-buy-bid.component.html',
  styleUrls: ['./offer-status-buy-bid.component.scss']
})
export class OfferStatusBuyBidComponent implements OnChanges, OnInit, OnDestroy {
  @Input() expirationDate: any;
  @Input() expectedExpirationDate: any;
  @Input() purchaseDate: any;
  @Input() offerId: number;
  @Input() showOfferLink: boolean;
  @Input() footerStatus: VehicleFooterStatus;
  @Input() hideFooterStatus =  false;
  @Input() inputPrice: number;
  @Input() showBidBuyButtons: boolean;
  @Input() channel: DistributionChannel;
  @Input() offerStatus: OfferStatus;
  @Input() vehicle: any;
  @Input() disableBidding: boolean;
  @Input() offerType: OfferType;
  @Input() thermalWindowAffected = false;
  @Output() linkToOfferClicked = new EventEmitter<void>();
  userInfo: UserInfoDto;
  isExpired: boolean;
  showCountdown: boolean;
  userState: State;
  quickLinkToDirectSaleDealerIds: boolean;
  currentDealerIsDirectSaleDealer: boolean;
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private store: Store<fromRoot.AppState>,
              private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit(): void {
    this.store.select(fromRoot.getUserInfo)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userInfo => {
        if (userInfo) {
          this.userInfo = userInfo;
          this.evaluatePiaAccreditation();
          if (!!this.vehicle?.offerDataDto?.directSaleDealerIds) {
            this.currentDealerIsDirectSaleDealer = this.vehicle.offerDataDto.directSaleDealerIds.includes(this.userInfo.currentDealerId);
          } else {
            this.currentDealerIsDirectSaleDealer = false;
          }
        }
      });
    this.showCountdown = (this.offerStatus === 'ACTIVE') || (this.offerStatus === 'EXTENDED');

    this.store.select(fromRoot.getUserState)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userState => {
        if (userState) {
          this.userState = userState;
        }
      });

    // Check feature only if vehicle exists
    if(!!this.vehicle?.channel) {
      this.systemSettingsService.isSystemFeatureActivatedForChannel('DIRECT_SALE_IDS_IN_SALES_MAINTENANCE',
        this.vehicle?.channel)
        .subscribe(enableQuickLinkForDirectSale => this.quickLinkToDirectSaleDealerIds = enableQuickLinkForDirectSale);
    }
  }

  ngOnChanges(): void {
    this.evaluatePiaAccreditation();
    this.updateFooterStatusOnOfferStatusChange();
  }

  /**
   * Evaluates whether the bid/buy button needs to be hidden based on user's PIA docflow accreditation status.
   */
  private evaluatePiaAccreditation(): void {
    if (this.channel && this.channel === 'PIA' && this.userInfo?.accredited && !this.userInfo?.accredited) {
      this.showBidBuyButtons = false; // overwrite showBidBuyButtons if the user is not PIA accredited to buy/bid
    }
  }

  /**
   * Stores the backlink target for the maintenance vehicle list page in the NgRx store
   */
  storeBacklink() {
    this.store.dispatch(new PushBacklinkAction('/maintenance/vehicle/'));
  }

  returnIsExpired($event) {
    this.isExpired = $event;
  }

  updateFooterStatusOnOfferStatusChange() {
    if (['PREPARATION', 'ACTIVE', 'EXTENDED','FINISHED','CANCELLED', 'COPIED', 'DELETED','EXPIRED']
      .includes(this.offerStatus)) {
      // followup proposal status exists
      if (this.vehicle?.offerDataDto?.latestFollowUpProposalStatus) {
        this.footerStatus =<VehicleFooterStatus>('FOLLOWUP_PROPOSAL_' + this.vehicle.offerDataDto.latestFollowUpProposalStatus);
      } else if (this.offerType === 'ENFORCED_AUCTION') {
        this.footerStatus = <VehicleFooterStatus>('AUCTION' + '_' + this.offerStatus);
      } else {
        this.footerStatus = <VehicleFooterStatus>(this.offerType + '_' + this.offerStatus);
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
  protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
