/**
 * Encapsulates a pair of string key and a numeric value and an optional index (can be helpful when used as an array)
 */
export class KeyValuePair {
  key: string;
  value: number;
  index: number;

  constructor (key: string, value: number, index?: number) {
    this.key = key;
    this.value = value;
    this.index = index;
  }
}
