import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {OfferService} from '../../../../service/offer.service';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {SystemSettingsService} from '../../../../service/system-settings.service';
import {VehicleMaintenanceService} from '../../../../service/vehicle-maintenance.service';

/**
 * Hosts an vehicle card containing compact information on the vehicle as well as the possibility to bid/buy
 */
@Component({
  selector: 'ucs-vehicle-footer',
  templateUrl: './vehicle-footer.component.html',
  styleUrls: ['./vehicle-footer.component.scss']
})
export class VehicleFooterComponent implements OnInit, OnDestroy {
  @Input() vehicle: VehicleBaseDto;
  @Input() mayCreateBuyNow: boolean;
  @Input() mayCreateAuction: boolean;

  vehicleFooterStatus: VehicleFooterStatus;

  private unsubscribe: Subject<void> = new Subject<void>();
  private hiddenFields = [] as string[];

  constructor(private offerService: OfferService, private router: Router, private systemSettingsService: SystemSettingsService,
              private vehicleMaintenanceService: VehicleMaintenanceService) {
  }

  ngOnInit() {
    this.vehicleFooterStatus = this.vehicleMaintenanceService.deriveVehicleFooterStatusFromVehicle(this.vehicle);

    this.systemSettingsService.getHiddenVehicleData(this.vehicle.channel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(hiddenData => {
        this.hiddenFields = hiddenData;
      });
  }


  /**
   * Creates an offer with the passed type.
   *
   * @param type: the offer type of the offer to create
   */
  createOffer(type: OfferType) {
    const createOfferDto = {vehicleIds: [], offerType: type} as CreateOfferDto;
    createOfferDto.vehicleIds.push(this.vehicle.id);

    // on success navigate to the newly created offer's maintenance detail page
    this.offerService.createOffer(createOfferDto).subscribe(offerId => {
      if (offerId) {
        this.router.navigateByUrl('/maintenance/offer/' + offerId);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Use to determine the view state of a field
   *
   * @param dataField the data field name
   */
  view(dataField: string): boolean {
    return !this.hiddenFields.includes(dataField);
  }
}
