<div class="row mt-2 mb-2">
    <div class="btn-group btn-group-toggle">
        <label class="btn btn-sm button-toggle-options" data-cy="all"
               [ngClass]="{'active': filter === 'ALL', 'disabled': disabled}">
            <input type="radio" [(ngModel)]="filter" (ngModelChange)="toggleChanged.emit($event)" value="ALL" > {{'quick-search.toggle-all' | translate}}
        </label>
        <label class="btn btn-sm button-toggle-options" data-cy="left"
               [ngClass]="{'active': filter === 'AUCTION', 'disabled': disabled}">
            <input type="radio" [(ngModel)]="filter" (ngModelChange)="toggleChanged.emit($event)" value="AUCTION" > {{'quick-search.onlyAuctions' | translate}}
        </label>
        <label class="btn btn-sm button-toggle-options" data-cy="right"
               [ngClass]="{'active': filter === 'BUYNOW', 'disabled': disabled}">
            <input type="radio" [(ngModel)]="filter" (ngModelChange)="toggleChanged.emit($event)" value="BUYNOW" > {{'quick-search.onlyBuyNow' | translate}}
        </label>
    </div>
</div>
