/**
 * This component displays the message a
 */
import {Component, Input} from '@angular/core';

@Component({
  selector: 'ucs-dealer-locked-message',
  templateUrl: './dealer-locked-message.component.html',
  styleUrls: ['./dealer-locked-message.component.scss']
})
export class DealerLockedMessageComponent {
  @Input() lockReason: string;
}
