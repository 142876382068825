import {Component, Input} from '@angular/core';
import {OfferService} from '../../../service/offer.service';
import * as OfferAction from '../../../store/offer/offer.actions';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {UpdateOffersAction} from '../../../store/sales-offer/sales-offer.actions';

@Component({
  selector: 'ucs-offer-bookmarking',
  templateUrl: './offer-bookmarking.component.html',
  styleUrls: ['./offer-bookmarking.component.scss']
})
export class OfferBookmarkingComponent {
  @Input() offer: OfferDto;

  constructor(public store: Store<fromRoot.AppState>,
              private offerService: OfferService) {
  }

  /**
   * Bookmarks this offer for the current user, or if already bookmarked, deletes the bookmark
   */
  toggleBookmark() {
    if (this.offer.bookmarked) {
      this.offerService.deleteBookmark(this.offer.id).subscribe(() => this.updateBookmarks());
    } else {
      this.offerService.addBookmark(this.offer.id).subscribe();
    }
    this.offer.bookmarked = !this.offer.bookmarked;
  }

  /**
   *  dispatch an Update-Action to the store
   */
  updateBookmarks() {
    switch (this.offer.channel.data) {
    case 'ALL_UC':
      this.store.dispatch(new OfferAction.UpdateBookmarkedOffersAllUcAction());
      break;
    case 'PB':
      this.store.dispatch(new OfferAction.UpdateBookmarkedOffersPbAction());
      break;
    case 'PIA':
      this.store.dispatch(new OfferAction.UpdateBookmarkedOffersPiaAction());
      break;
    case 'DIN_BIL':
      this.store.dispatch(new OfferAction.UpdateBookmarkedOffersDinBilAction());
      break;
    case 'VGRX':
      this.store.dispatch(new OfferAction.UpdateBookmarkedOffersVgrxAction());
      break;
    }
    this.store.dispatch(new UpdateOffersAction());
  }

}
