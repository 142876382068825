<div class="row footer-font">
  <div class="d-inline-block mt-2 mb-2 ms-2">
    <div>
      <span class="pe-1">{{ 'vehicle-footer.stationary-days' | translate }}</span>
      <span class="font-weight-bold" data-cy="enforced-auction.stationary-days">{{ stationaryDays }}</span>
    </div>
    <div>
      <span class="pe-1">{{ 'vehicle-footer.num-of-sale-attempts' | translate }}</span>
      <span class="font-weight-bold">{{numOfSaleAttempts }}</span>
    </div>
  </div>
</div>

