import {Component, Input} from '@angular/core';

@Component({
  selector: 'ucs-no-match',
  templateUrl: './no-match.component.html',
  styleUrls: ['./no-match.component.scss']
})
export class NoMatchComponent {
    @Input() translationKey: string;
}
