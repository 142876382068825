import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/app.reducers';

@Pipe({
  name: 'kwhPerHundredKm'
})
export class KwhPerHundredKmPipe implements PipeTransform {

  private local: string;

  constructor(private store: Store<fromRoot.AppState>) {
  }

  transform(value: number): string {

    this.store.select(fromRoot.getUserState)
      .pipe()
      .subscribe(
        s => {
          this.local = s.local;
        }
      );
    // format number based on local
    const formattedValue = new DecimalPipe(this.local).transform(value);
    // append unit
    return formattedValue + ' kWh / 100km';
  }
}
