@if (vehicles) {
  <div>
    <!-- optionsList.type 'vehicle' is used to display results in vehicle result list -->
    @if (optionsList.type === 'vehicle') {
      <div [ngClass]="{'scrollable-section rounded mb-3': doNewScrollingBehavior}">
        @for (vehicle of vehicles; track vehicle.id) {
          <div class="mb-3">
            <ucs-vehicle [vehicle]="vehicle"
              [activeCategory]="activeCategory"
              [attr.data-cy]="'vehicle-' + vehicle.id">
            </ucs-vehicle>
          </div>
        }
      </div>
    }
  </div>
}
