import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';
import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class UcsMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    const key = params.key;
    if (isDevMode()) {
      console.warn('Untranslated key: '
          + (params['translateService.currentLang'] ? params['translateService.currentLang'] : '').toUpperCase() + ' - ' + key);
      return 'Translate <' + key + '>';
    }
    return key;
  }
}
