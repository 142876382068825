import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {APP_CONFIG} from '../misc/inject-tokens';
import {AppConfig} from '../model/app-config.model';

/**
 * Service to handle tax relevant topics, like translating between gross and net
 */
@Injectable()
export class TaxService {

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) {
  }

  /**
   * Calculates the gross price to the given net price according to the given VAT type
   *
   * @param netPrice  The given net price, which must be a positive integer
   *                  where the least two digits represent the fractional part.
   * @param vatType The VAT type
   * @param country the country for specific calculation
   * @param channel The distributionChannel
   */
  getGrossPrice(netPrice: number, vatType: VatType, country: Country, channel: DistributionChannel): Observable<number> {
    return this.getGrossPriceWithExtraTax(netPrice, vatType, undefined, 0, country, channel);
  }

  /**
   *
   * @param netPrice
   * @param vatType
   * @param extraTax a tax which is applied additionally to the vat and is computed on the net value;
   *                 for one percent 100 is expected.
   * @param country the country for specific calculation
   * @param extraTaxAmount the amount from the extra tax
   * @param channel The distributionChannel
   */
  getGrossPriceWithExtraTax(netPrice: number,
    vatType: VatType,
    extraTax: number,
    extraTaxAmount: number,
    country: Country,
    channel: DistributionChannel): Observable<number> {
    if (netPrice === 0) {
      if (country === 'HR') {
        return of(extraTaxAmount);
      }
      return of(0);
    }
    if (!netPrice) {
      return of(undefined);
    }
    if (!extraTax) {
      extraTax = 0;
    }
    const priceCalculationDto: PriceCalculationDto = {
      price: netPrice,
      country: country,
      extraTax: extraTax,
      extraTaxAmount: extraTaxAmount,
      vatType: vatType,
      channel: channel
    };
    return this.getGrossPriceFromBackend(priceCalculationDto);
  }

  /**
   * Calculates the net price to the given gross price according to the given VAT type
   *
   * @param grossPrice The given gross price.
   * @param vatType The VAT type
   * @param country the country for specific calculation
   * @param channel The distributionChannel
   */
  getNetPrice(grossPrice: number, vatType: VatType, country: Country, channel: DistributionChannel): Observable<number> {
    return this.getNetPriceWithoutExtraTax(grossPrice, vatType, 0, 0, country, channel);
  }

  /**
   *
   * @param grossPrice
   * @param vatType
   * @param extraTax a tax which is applied additionally to the vat and is computed on the net value;
   *                 for one percent 100 is expected.
   * @param country the country for specific calculation
   * @param extraTaxAmount the amount from the extra tax
   * @param channel The distributionChannel
   */
  getNetPriceWithoutExtraTax(grossPrice: number, vatType: VatType, extraTax: number, extraTaxAmount: number, country: Country,
    channel: DistributionChannel): Observable<number> {

    if (grossPrice === 0) {
      return of(0);
    }
    if (!grossPrice) {
      return of(undefined);
    }
    if (!extraTax) {
      extraTax = 0;
    }

    if (!extraTaxAmount) {
      extraTaxAmount = 0;
    }
    const priceCalculationDto: PriceCalculationDto = {
      price: grossPrice,
      country: country,
      extraTax: extraTax,
      extraTaxAmount: extraTaxAmount,
      vatType: vatType,
      channel: channel
    };
    return this.getNetPriceFromBackend(priceCalculationDto);
  }

  /**
   * get the net price from the gross price depending on the country
   * @returns Observable<number>: the calculated net price
   * @param priceCalculationDto
   */
  private getNetPriceFromBackend(priceCalculationDto: PriceCalculationDto): Observable<number> {
    return this.http.post<number>(this.config.salesApiUrl
      + '/price/net-price', priceCalculationDto);
  }

  /**
   * get the gross price from the net price depending on the country
   * @returns Observable<number>: the calculated gross price
   * @param priceCalculationDto
   */
  private getGrossPriceFromBackend(priceCalculationDto: PriceCalculationDto): Observable<number> {
    return this.http.post<number>(this.config.salesApiUrl
      + '/price/gross-price', priceCalculationDto);
  }

  /**
   * gets the calculated vat amount for the given country
   * @returns Observable<number>: the calculated vat amount
   * @param netPrice
   * @param country
   */
  getVatAmountFromBackend(netPrice: number, country: Country): Observable<number> {
    return this.http.get<number>(this.config.salesApiUrl + '/price/vat-amount/' + netPrice + '/' + country);
  }

  /**
   * get the vat factor depending on the country
   * @returns Observable<number>: the vat factor for the country
   * @param country the country we want the vat factor for
   */
  public getVatFactorFromBackend(country: Country): Observable<number> {
    return this.http.get<number>(this.config.salesApiUrl
      + '/price/vat-factor/' + country);
  }

}
