import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {Subject} from 'rxjs';
import {first} from 'rxjs/operators';
import {OfferService} from '../../../service/offer.service';
import {State} from '../../../store/user/user.reducers';
import {ToastAlertService} from '../../../service/toast-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {KeyValuePair} from '../../../model/key-value-pair.model';
import {isVehicleItemBaseDto} from '../../../misc/typeguard';
import {PushBacklinkAction} from '../../../store/detail-page/detail-page.actions';

/**
 * An offer card for UCS sales list views, providing information based on its display mode
 */
@Component({
  selector: 'ucs-sales-bundle-offer-card',
  templateUrl: './sales-bundle-offer-card.component.html',
  styleUrls: ['./sales-bundle-offer-card.component.scss']
})
export class SalesBundleOfferCardComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() offer: BuyNowOfferDto & AuctionOfferDto;
  @Input() enableNote = true;

  @ViewChild('favNoteElement') favnote: ElementRef;

  vehicle: VehicleItemBaseDto;
  userState: State;
  isExpired: boolean = false;
  imageUrls = [];

  uniqueBundleVehicles: KeyValuePair[]; // contains modelVariant as key and vehicle count as value
  showAllVehicles = false; // indicates whether all vehicles in a bundle are to be displayed in the offer card

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(public store: Store<fromRoot.AppState>, private offerService: OfferService,
              private myAlert: ToastAlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.initProperties();
    this.initReduxStates();
  }

  private initProperties() {
    this.vehicle = this.offer.items[0] as VehicleItemBaseDto;
    for (let image of this.vehicle.images) {
      if (image?.url) {
        this.imageUrls.push(image.url);
      }
    }
    this.calculatePackageVehicleCounts();
  }

  private initReduxStates() {
    this.store
      .select(fromRoot.getUserState)
      .pipe(first())
      .subscribe(userState => {
        if (userState) {
          this.userState = userState;
        }
      });
  }

  /**
   * We need the ngOnChanges method to ensure vehicleDetails is updated when offerItem is updated in parent
   * Otherwise Angular does not propagate changes, only directly to the input variable (offer)
   * @param {SimpleChanges} changes: The changes that occurred
   */
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'offer') {
        this.initProperties();
      }
    }
  }

  ngAfterViewInit() {
    if (this.supportsLocalStorage() && this.enableNote) {
      const items = localStorage.getItem('ucs-favnote-' + this.offer.id);
      if (items !== null) {
        this.favnote.nativeElement.textContent = items;
        this.showNoteInput();
      }
    }
  }

  /**
   * Check if the current device supports local storage.
   * @returns {boolean} If the current device supports local storage.
   */
  private supportsLocalStorage() {
    try {
      return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
      return false;
    }
  }

  /**
   * Stores the backlink target for the offer detail page in the NgRx store
   */
  storeBacklink() {
    this.store.dispatch(new PushBacklinkAction('/offer#offer' + this.offer.id));
  }

  showNoteInput() {
    this.favnote.nativeElement.className = 'favnote col-12 mb-1 ps-1';
  }

  /**
   * Save a note to a bookmarked offer, if it is non-empty
   */
  saveNote() {
    if (!this.enableNote) {
      return;
    }
    if (this.favnote.nativeElement.textContent === '') {
      this.favnote.nativeElement.className = 'favnote d-none';
      try {
        localStorage.removeItem('ucs-favnote-' + this.offer.id);
      } catch (e) {
        this.myAlert.showAlerts('warning', this.translate.instant('error.localstorage'));
      }
    } else {
      try {
        localStorage.setItem('ucs-favnote-' + this.offer.id, this.favnote.nativeElement.textContent);
      } catch (e) {
        this.myAlert.showAlerts('warning', this.translate.instant('error.localstorage'));
      }
    }
  }

  /**
   * This helper method calculates the count per vehicle, which is brand + model + modelVariant
   * Since modelVariant contains all three of brand + model + modelVariant, we  use it as the key
   */
  private calculatePackageVehicleCounts() {
    let index = 0;

    if (isVehicleItemBaseDto(this.offer.items[0])) {
      this.uniqueBundleVehicles = [];

      for (const vehicleItem of (<VehicleItemBaseDto[]>this.offer.items)) {

        const existingVehicle = this.uniqueBundleVehicles.find(x => x.key === vehicleItem.modelVariant);

        if (existingVehicle) { // increase count for existing vehicle
          this.uniqueBundleVehicles[existingVehicle.index].value = existingVehicle.value + 1;
        } else { // start a new count for vehicle
          const newVehicle = new KeyValuePair(vehicleItem.modelVariant, 1, index++);
          this.uniqueBundleVehicles.push(newVehicle);
        }
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}

