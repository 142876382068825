import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';

@Component({
  selector: 'ucs-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {

  @Input() pageable: Pageable;
  @Input() totalPages: number;
  @Output() paginationRefresh = new EventEmitter();

  // the available pagination sizes (pre-defined)
  paginationPages: Array<number>;

  /**
   * selects page of pagination-menu
   * @param {number} page
   */
  selectPage(page: number) {
    this.pageable.pageNumber = page;
    this.paginationRefresh.emit();
    window.scrollTo(0, 0);
  }



  getPagesSubSelection() {
    //show only 5 selectable pages at once
    let start = this.pageable.pageNumber < 3 ? 0 : this.pageable.pageNumber - 2; //inklusive
    let end = this.pageable.pageNumber + 5-(this.pageable.pageNumber-start); //exklusive
    return this.paginationPages.slice(start, end);
  }

  getFirstPage() {
    return this.paginationPages[0];
  }

  getLastPage() {
    return this.paginationPages[this.paginationPages.length - 1];
  }

  /**
   * refresh the size of pages after changes
   */
  ngOnChanges() {
    this.paginationPages = Array.from(Array(this.totalPages).keys());
  }
}
