import { Pipe, PipeTransform } from '@angular/core';

/**
 * Could be used as:
 *
 * class ClassEvent {
 *   prop: string;
 * }
 *
 * interface InterfaceEvent {
 *   prop: string;
 * }
 *
 * export class MyComponent {
 *
 *   MyClass = ClassEvent; // class constructor
 *
 *   MyInterface: InterfaceEvent; // typed property
 *
 *   propString: any; // primitive, string
 *
 *   propNumber: any; // primitive, number
 *
 * }
 *
 * Example in template:
 *
 * <td mat-cell *matCellDef="let row">
 *   Type from class constructor: {{ (row | cast : MyClass).prop }}
 *   Type from interface: {{ (row | cast : MyInterface).prop }}
 *   Type from primitive, string: {{ (propString | cast : '').substr(1) }}
 *   Type from primitive, number: {{ (propNumber | cast : 123).toFixed(2) }}
 * </td>
 */
@Pipe({
  name: 'cast',
  pure: true,
})
export class CastPipe implements PipeTransform {
  transform<T>(value: any, _type: (new (...args: any[]) => T) | T): T {
    return value as T;
  }
}
