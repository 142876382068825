/**
 * This component displays if documents are missing
 */
import {Component} from '@angular/core';

@Component({
  selector: 'ucs-document-missing-message',
  templateUrl: './document-missing-message.component.html',
  styleUrls: ['./document-missing-message.component.scss']
})
export class DocumentMissingMessageComponent {
}
