import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';

/**
 * This component encapsulates two ngx-bootstrap typeahead-dropdowns with a label
 */
@Component({
  selector: 'ucs-search-range-dropdown',
  templateUrl: './search-range-dropdown.component.html',
  styleUrls: ['./search-range-dropdown.component.scss']
})
export class SearchRangeDropdownComponent implements OnInit, OnDestroy, OnChanges {
  @Input() label: string;
  @Input() showUntil: boolean;
  @Input() statesFrom: any[];
  @Input() statesTo: any[];
  @Input() id: string;
  @Output() onSelectedFrom = new EventEmitter<any>();
  @Output() onSelectedTo = new EventEmitter<any>();
  aggregatedStatesFrom: any[] = [];
  aggregatedStatesTo: any[] = [];
  selectedValueFrom: string;
  selectedValueTo: string;
  controlFrom = new UntypedFormControl();
  controlTo = new UntypedFormControl();
  private unsubscribe: Subject<void> = new Subject<void>();

  /**
   * Initialize the component
   */
  ngOnInit() {
    // init range dropdowns from and to
    this.initRangeFrom();
    this.initRangeTo();


    // since we allow regular input on top of typeahead, we debounce keystroke events (to not fire on every keystroke)
    this.controlFrom.valueChanges
      .pipe(debounceTime(500)) // debounce 500ms before emitting value change
      .pipe(distinctUntilChanged()) // do not emit the value change event, if the value has not actually changed
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(newFromValue => this.onInputFrom(newFromValue));
    this.controlTo.valueChanges
      .pipe(debounceTime(500)) // debounce 500ms before emitting value change
      .pipe(distinctUntilChanged()) // do not emit the value change event, if the value has not actually changed
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(newToValue => this.onInputTo(newToValue));
  }

  /**
   * We need the ngOnChanges method to ensure aggregatedStates[From|To] are updated when states[From|To] is updated
   * Otherwise Angular does not propagate changes, only directly to the input variable (states[From|To])
   * @param {SimpleChanges} changes: The changes that occurred
   */
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'statesFrom') {
        this.initRangeFrom();
      } else if (propName === 'statesTo') {
        this.initRangeTo();
      }
    }
  }

  /**
   * Unsubscribe from Observables when component is destroyed to prevent memory leaks
   */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Handles typeahead select in 'from' range dropdown
   */
  onSelectFrom(event: any) {
    this.selectedValueFrom = event;
    // do not remove comma from search-filter consumption
    if (this.id === 'search-filter.consumption') {
      this.selectedValueFrom = this.selectedValueFrom.replace(',', '.');
    } else {
      this.selectedValueFrom = this.selectedValueFrom.replace(',', '').replace('.', '');
    }
    this.onSelectedFrom.emit(this.selectedValueFrom);
  }

  /**
   * Handles typeahead select in 'to' range dropdown
   */
  onSelectTo(event: any) {
    this.selectedValueTo = event;
    // do not remove comma from search-filter consumption
    if (this.id === 'search-filter.consumption') {
      this.selectedValueTo = this.selectedValueTo.replace(',', '.');
    } else {
      this.selectedValueTo = this.selectedValueTo.replace(',', '').replace('.', '');
    }
    this.onSelectedTo.emit(this.selectedValueTo);
  }

  /**
   * Clears the selection of the two dropdowns
   */
  clear() {
    this.selectedValueFrom = undefined;
    this.selectedValueTo = undefined;
  }

  /**
   * Allows overriding the current selections in 'from' and 'to' dropdowns (intended to be used by parent component)
   */
  overrideSelection(fromValue: number, toValue: number) {
    if (!fromValue) {
      this.selectedValueFrom = undefined;
    } else {
      this.selectedValueFrom = String(fromValue);
    }

    if (!toValue) {
      this.selectedValueTo = undefined;
    } else {
      this.selectedValueTo = String(toValue);
    }
  }

  /**
   * Called whenever input value of the 'from' dropdown changes
   * Enables input of all values instead of just typeahead selections
   * When empty or 'any' is set, emit to parent to clear the search param
   */
  onInputFrom(value: string) {
    if (this.selectedValueFrom === '') {
      if (document['documentMode'] && !this.selectedValueFrom || this.selectedValueFrom.length === 0) {
        this.selectedValueFrom = ' ';
      }
      this.selectedValueFrom = undefined;
      this.onSelectedFrom.emit(undefined);
    }

    this.onSelectedFrom.emit(value);
  }

  /**
   * Called whenever input value of the 'to' dropdown changes
   * Enables input of all values instead of just typeahead selections
   * When empty or 'any' is set, emit to parent to clear the search param
   */
  onInputTo(value: string) {
    if (this.selectedValueTo === '') {
      if (document['documentMode'] && !this.selectedValueTo || this.selectedValueTo.length === 0) {
        this.selectedValueTo = ' ';
      }
      this.selectedValueTo = undefined;
      this.onSelectedTo.emit(undefined);
    }

    this.onSelectedTo.emit(value);
  }

  /**
   * Initializes the 'from' range dropdown
   */
  private initRangeFrom() {
    this.aggregatedStatesFrom = this.statesFrom;
  }

  /**
   * Initializes the 'to' range dropdown
   */
  private initRangeTo() {
    this.aggregatedStatesTo = this.statesTo;
  }
}
