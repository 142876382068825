import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {FilterBullet} from '../../../model/filter-bullet.model';
import * as fromRoot from '../../../store/app.reducers';
import {combineLatest, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SystemSettingsService} from '../../../service/system-settings.service';
import {
  DeleteSearchParamsAction,
  UpdateSearchAggregationAction,
  UpdateSearchParamsAction
} from '../../../store/search/search.actions';
import {SalesScope} from '../../../model/sales-scope';
import * as EnforcedAuctionOfferAction from '../../../store/enforced-auction-offer/enforced-auction-offer.actions';
import {ucsDeepCopy} from '../../../misc/utils';

/**
 * This component hosts the filter bullet list containing FilterBulletComponents
 */
@Component({
  selector: 'ucs-filter-bullet-list',
  templateUrl: './filter-bullet-list.component.html',
  styleUrls: ['./filter-bullet-list.component.scss']
})
export class FilterBulletListComponent implements OnInit, OnDestroy {
  searchParams: VehicleOfferSearchParams;
  searchAggregation: VehicleOfferSearchAggregation; // we use this only to access translations here
  filterBullets: FilterBullet[];
  equipment: string[];
  searchState$ = this.store.select(fromRoot.getSearchState);
  enforcedAuctionOfferSearchParams$ = this.store.select(fromRoot.getEnforcedAuctionOfferSearchParams);
  enforcedAuctionOfferSearchAggregations$ = this.store.select(fromRoot.getEnforcedAuctionOfferSearchAggregation);
  currentScope: SalesScope;

  private unsubscribe: Subject<void> = new Subject<void>();

  // don't mess with order
  // array types like equipment not handled here
  private GROUPS = [
    // vehicle (type default)
    ['brand'],
    ['brand2'],
    ['brand3'],
    ['brand4'],
    ['brand5'],

    // range (type range)
    ['priceFrom', 'priceTo'],
    ['powerPsFrom', 'powerPsTo'],
    ['mileageFrom', 'mileageTo'],
    ['initialRegistrationFrom', 'initialRegistrationTo'],

    // city (type city)
    ['zipCode', 'city'], //9

    // equipment (type default)
    ['automaticTransmission'], //10
    ['buyNow'],
    ['fourWD'],
    ['heatedSeats'],
    ['leatherSeats'],
    ['trailerHitch'],
    ['navigation'],
    ['xenonLedLight'], //17

    // detail pairs
    ['motorCapacityFrom', 'motorCapacityto'], //18
    ['consumptionFrom', 'consumptionTo'], //19

    // detail
    ['co2Emission'], //20
    ['vatType'], //21

    // default
    ['extDealerId'],
    ['extVehicleId'],
    ['vin']
  ];

  private MULTI_FILTERS = [
    'channels',
    'countries',
    'models',
    'modelVariants',
    'fuels',
    'colors',
    'chassis',
    'seats',
    'gears',
    'gearTypes',
    'emissionClass',
    'exploitationType',
    'combinedSellingDealers',
  ];

  constructor(private store: Store<fromRoot.AppState>, private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit() {
    this.store.select(fromRoot.getSalesScope).pipe(takeUntil(this.unsubscribe))
      .subscribe(scope => {
        this.currentScope = scope;
      });

    if (this.currentScope === SalesScope.PIA_AUCTION) {

      combineLatest([this.enforcedAuctionOfferSearchParams$, this.enforcedAuctionOfferSearchAggregations$])
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(([searchParams, aggregations]) => {

          if (searchParams) {
            this.searchParams = searchParams;
          }

          if (aggregations) {
            this.searchAggregation = aggregations;
          }

          this.initFilterBullets();
        });

    } else {

      this.searchState$.pipe(takeUntil(this.unsubscribe))
        .subscribe((searchState) => {
          if (searchState.searchParams && searchState.searchAggregation) {
            this.searchParams = searchState.searchParams;
            this.searchAggregation = searchState.searchAggregation;
            this.initFilterBullets();
          }
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * ListenerMethod for Sub FilterBulletComponent
   * Listens to delete-events
   * @param bullet: FilterBullet
   */
  onDeleteBullet(bullet: FilterBullet): void {
    if (bullet.type === 'equipment') {
      this.removeEquipment(bullet.value[0]);
    }

    if (this.MULTI_FILTERS.some(filterKey => bullet.key.startsWith(filterKey))) {
      const paramKey = bullet.key.split('-')[0];
      const paramValue = bullet.key.substring(paramKey.length + 1);
      this.searchParams[paramKey] = this.searchParams[paramKey].filter(elem => elem !== paramValue);
      if(paramKey.startsWith('models')) {
        this.searchParams = this.checkIfSearchParamsConsistent(this.searchParams);
      }
    }

    this.handleDeleteForGroups(bullet);

    if (this.currentScope === SalesScope.PIA_AUCTION) {
      this.dispatchEnforcedAuctionOffersAction();
      this.store.dispatch(new EnforcedAuctionOfferAction.UpdateSearchAggregationAction());
    } else {
      this.dispatchRegularOffersAction();
      this.store.dispatch(new UpdateSearchAggregationAction());
    }

  }

  private dispatchEnforcedAuctionOffersAction() {
    if (this.isFilterBulletsEmpty()) {
      this.store.dispatch(new EnforcedAuctionOfferAction.DeleteSearchParamsAction());
    } else {
      this.store.dispatch(new EnforcedAuctionOfferAction.UpdateSearchParamsAction(ucsDeepCopy(this.searchParams)));
    }
  }

  private dispatchRegularOffersAction() {
    if (this.isFilterBulletsEmpty()) {
      this.store.dispatch(new DeleteSearchParamsAction());
    } else {
      this.store.dispatch(new UpdateSearchParamsAction(ucsDeepCopy(this.searchParams))); //send deep copy otherwise not detected as change
    }
  }

  private handleDeleteForGroups(bullet: FilterBullet) {
    this.GROUPS.forEach(group => {
      if (bullet['key'] === group[0]) {
        if (bullet['key'].startsWith('brand')) {
          this.removeVehicleFromSearchParams(bullet['key']);
        } else {
          group.forEach(key => {
            this.searchParams[key] = undefined;
          });
        }
      }
    });
  }

  private checkIfSearchParamsConsistent(searchParams: VehicleOfferSearchParams) {
    if ((!searchParams.models || searchParams.models.length === 0) && searchParams.modelVariants) {
      searchParams.modelVariants = undefined;
    }
    if ((!searchParams.models2 || searchParams.models2.length === 0) && searchParams.modelVariants2) {
      searchParams.modelVariants2 = undefined;
    }
    if ((!searchParams.models3 || searchParams.models3.length === 0) && searchParams.modelVariants3) {
      searchParams.modelVariants3 = undefined;
    }
    if ((!searchParams.models4 || searchParams.models4.length === 0) && searchParams.modelVariants4) {
      searchParams.modelVariants4 = undefined;
    }
    if ((!searchParams.models5 || searchParams.models5.length === 0) && searchParams.modelVariants5) {
      searchParams.modelVariants5 = undefined;
    }
    return searchParams;
  }

  /**
   * takes searchParams from Store and converts them to FilterBullets with types,
   * to manage grouping and suffixes/prefixes
   */
  private initFilterBullets(): void {
    this.filterBullets = new Array<FilterBullet>();
    this.equipment = new Array<string>();

    this.initGroups();

    // handle array type equipment
    if (this.searchParams['equipments']) {
      this.searchParams['equipments'].forEach((equipment, index) =>
        this.filterBullets.push(new FilterBullet(index.toString(), [equipment], 'equipment'))
      );
    }

    // handle array type
    this.addBulletForMultiselect(this.searchParams.countries, 'countries');
    this.addBulletForMultiselect(this.searchParams.colors, 'colors');
    this.addBulletForMultiselect(this.searchParams.seats, 'seats');
    this.addBulletForMultiselect(this.searchParams.emissionClass, 'emissionClass');
    this.addBulletForMultiselect(this.searchParams.exploitationType, 'exploitationType');
    this.addBulletForMultiselect(this.searchParams.fuels, 'fuels', this.searchAggregation?.fuels);
    this.addBulletForMultiselect(this.searchParams.chassis, 'chassis', this.searchAggregation?.chassis);
    this.addBulletForMultiselect(this.searchParams.gearTypes, 'gearTypes', this.searchAggregation?.gearTypes);
    this.addBulletForMultiselect(this.searchParams.combinedSellingDealers, 'combinedSellingDealers',
      this.searchAggregation?.combinedSellingDealers);

    this.addBulletForMultiselect(this.searchParams.models, 'models', this.searchAggregation?.modelSelection?.models);
    this.addBulletForMultiselect(this.searchParams.models2, 'models2', this.searchAggregation?.modelSelection2?.models);
    this.addBulletForMultiselect(this.searchParams.models3, 'models3', this.searchAggregation?.modelSelection3?.models);
    this.addBulletForMultiselect(this.searchParams.models4, 'models4', this.searchAggregation?.modelSelection4?.models);
    this.addBulletForMultiselect(this.searchParams.models5, 'models5', this.searchAggregation?.modelSelection5?.models);
    this.addBulletForMultiselect(this.searchParams.modelVariants, 'modelVariants', this.searchAggregation?.modelSelection?.modelVariants);
    this.addBulletForMultiselect(this.searchParams.modelVariants2, 'modelVariants2', this.searchAggregation?.modelSelection2?.modelVariants);
    this.addBulletForMultiselect(this.searchParams.modelVariants3, 'modelVariants3', this.searchAggregation?.modelSelection3?.modelVariants);
    this.addBulletForMultiselect(this.searchParams.modelVariants4, 'modelVariants4', this.searchAggregation?.modelSelection4?.modelVariants);
    this.addBulletForMultiselect(this.searchParams.modelVariants5, 'modelVariants5', this.searchAggregation?.modelSelection5?.modelVariants);

    this.addBulletForMultiselect(this.searchParams.channels, 'channels');
  }

  private initGroups() {
    this.GROUPS.forEach(group => {
      this.filterBullets.push(new FilterBullet(group[0], ['', '', ''], 'default'));
    });

    let groupCount = 0;
    this.GROUPS.forEach(group => {

      Object.keys(this.searchParams).forEach(key => {
        if (this.searchParams[key] && group.indexOf(key) >= 0) {

          // handle vehicles
          if (groupCount <= 4) {
            let counter = (groupCount === 0) ? '' : groupCount + 1;
            this.filterBullets[groupCount].value[0] = this.searchParams['brand' + counter];

            // handle from to
            // special behavior for priceFrom/priceTo as value is in subunits (e.g. cents) but we display unit (e.g. EUR)
          } else if (groupCount >= 5 && groupCount <= 8 || groupCount >= 18 && groupCount <= 19) {
            this.filterBullets[groupCount].type = 'range';
            if (key === group[0]) {
              this.filterBullets[groupCount].value[0] = this.searchParams[key];
            } else {
              this.filterBullets[groupCount].value[1] = this.searchParams[key];
            }

            // handle city
          } else if (groupCount === 9) {
            this.filterBullets[groupCount].type = 'city';
            if (key === group[0]) {
              this.filterBullets[groupCount].value[0] = this.searchParams[key];
            } else if (key === group[1]) {
              this.filterBullets[groupCount].value[1] = this.searchParams[key];
            }

            // handle popular equipment
          } else if (groupCount >= 10 && groupCount <= 17) {
            this.filterBullets[groupCount].type = 'equipmentHighlight';
            this.filterBullets[groupCount].value[0] = this.searchParams[key] ? key : '';

          } else {
            this.filterBullets[groupCount].value[0] = this.searchParams[key];
          }

        }
      });

      groupCount++;
    });
  }

  private addBulletForMultiselect(elements: any[], bulletKey: string, aggregations?: any[]) {
    if (elements?.length > 0) {
      elements.forEach(filterElement => {
        let bulletValue = filterElement;
        if (aggregations && bulletValue) {
          bulletValue = aggregations.find(aggregationElem => aggregationElem.value === filterElement)?.text;
        }
        if(bulletValue) {
          this.filterBullets.push(new FilterBullet(bulletKey + '-' + String(filterElement),
            [bulletValue], 'default'));
        }
      });
    }
  }

  /**
   * removes an item from the equipment-array
   * @param equipment: string value of equipment
   */
  private removeEquipment(equipment: string) {
    const indexToRemove = this.searchParams['equipments'].findIndex(equ => equ === equipment);
    if (indexToRemove !== -1) {
      this.searchParams['equipments'].splice(indexToRemove, 1);
    }
  }

  /**
   * Remove the cleared vehicle from the search params and set other parameters accordingly
   * Duplicate from search-offer-variant.component.ts
   */
  private removeVehicleFromSearchParams(brandKey: string) {
    // fall through in this switch is intentional
    switch (brandKey) {
    case 'brand':
      this.searchParams.brand = this.searchParams.brand2;
      this.searchParams.models = this.searchParams.models2;
      this.searchParams.modelVariants = this.searchParams.modelVariants2;

      // fall through
    case 'brand2':
      this.searchParams.brand2 = this.searchParams.brand3;
      this.searchParams.models2 = this.searchParams.models3;
      this.searchParams.modelVariants2 = this.searchParams.modelVariants3;
      // fall through
    case 'brand3':
      this.searchParams.brand3 = this.searchParams.brand4;
      this.searchParams.models3 = this.searchParams.models4;
      this.searchParams.modelVariants3 = this.searchParams.modelVariants4;
      // fall through
    case 'brand4':
      this.searchParams.brand4 = this.searchParams.brand5;
      this.searchParams.models4 = this.searchParams.models5;
      this.searchParams.modelVariants4 = this.searchParams.modelVariants5;
      // fall through
    case 'brand5':
      this.searchParams.brand5 = undefined;
      this.searchParams.models5 = undefined;
      this.searchParams.modelVariants5 = undefined;
      break;
    default:
      console.error('unknown vehicleNumber');
      break;
    }
  }

  /**
   * checks whether all filter-bullet relevant search params (hence not necessarily ALL search params!) are undefined
   */
  private isFilterBulletsEmpty() {
    let isEmpty = true;
    Object.keys(this.searchParams).forEach((key) => {
      // we need special consideration for search params, which are not part of the bullets by design
      if (this.searchParams[key] !== undefined && key !== 'bundling' && key !== 'types') {
        isEmpty = false;
      }
    });

    return isEmpty;
  }
}
