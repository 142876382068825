import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/app.reducers';

/*
 * Returns the formatted number and appends the 'g / 100km' (grams per hundred kilometers) unit
 * Usage:
 *   value | gramsPerHundredKm
 * Example:
 *   {{ vehicle.co2Emission | gramsPerHundredKm }}
 *   Let vehicle.co2Emission be 132, then the returned text will be "132g / 100km" (dots or commas depending on localisation)
*/
@Pipe({
  name: 'gramsPerHundredKm'
})
@Injectable()
export class GramsPerHundredKmPipe implements PipeTransform {

  private local: string;

  constructor(private store: Store<fromRoot.AppState>) {
  }

  transform(value: number): string {

    this.store.select(fromRoot.getUserState)
      .pipe()
      .subscribe(
        s => {
          this.local = s.local;
        }
      );
    // format number based on local
    const formattedValue = value === null ? '-' : new DecimalPipe(this.local).transform(value);
    // append unit
    return formattedValue + ' g / 100km';
  }

}
